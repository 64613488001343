import React, { useContext, useMemo } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Info from '@material-ui/icons/Info'
import Close from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import MuiDialog from '@material-ui/core/Dialog'
import { ActivityId } from 'src/utils/golfConstants'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import {
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
} from '@material-ui/core/'

import { TranslationContext } from 'src/utils/TranslationContext'
import { breakpoints } from 'src/utils/constants'

const I18N_KEY = 'InstructionDialog'

interface Props {
  open: boolean
  activityId: ActivityId
  handleClose: () => void
}

const Dialog = styled(MuiDialog)(
  ({ theme }) =>
    css`
      min-width: 415px;

      .MuiDialogContent-root {
        padding-bottom: 0;
      }

      .MuiDialogContent-root:first-child {
        padding-top: ${theme.spacing(3)}px;
      }
    `
)

const DialogContent = styled(MuiDialogContent)(
  ({ theme }) => css`
    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(5)}px;
    }
    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(7.5)}px;
    }
  `
)

const DialogActions = styled(MuiDialogActions)(
  ({ theme }) => css`
    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(2, 5)};
    }
    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(2, 7.5)};
    }
  `
)

const InfoIcon = styled(Info)(
  ({ theme }) =>
    css`
      margin-left: ${theme.spacing(-1)}px;
      margin-right: ${theme.spacing(0.5)}px;
      color: ${theme.palette.primary.light};
      font-size: ${theme.typography.pxToRem(18)};
    `
)

const InstructionList = styled.ul(
  ({ theme }) =>
    css`
      margin-left: ${theme.spacing(2)}px;
    `
)

const InstructionDialog: React.FC<Props> = ({
  open,
  activityId,
  handleClose,
}) => {
  const { activityLabels, rangeInstructions, courseInstructions, t } =
    useContext(TranslationContext)!

  const rangeList = rangeInstructions[activityId]
  const courseList = courseInstructions[activityId]

  const rangeGroupLabel = useMemo(() => {
    const activitiesOnGreen = [
      ActivityId.PuttsUnder6,
      ActivityId.Putts7To11,
      ActivityId.Putts12To16,
      ActivityId.Putts17To21,
      ActivityId.PuttsOver21,
      ActivityId.ApproachPuttPerformance,
    ]
    if (activitiesOnGreen.indexOf(activityId) > -1) {
      return t(`${I18N_KEY}.onGreen`)
    }
    return t(`${I18N_KEY}.onRange`)
  }, [t, activityId])

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <DialogContent>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <InfoIcon />
            <Typography variant="body2" color="textSecondary">
              <Trans i18nKey={`${I18N_KEY}.howTo`}>
                How to complete activity
              </Trans>
            </Typography>
          </Box>
          <IconButton size="small" onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        <Typography gutterBottom variant="h3" id="dialog-title">
          {activityLabels[activityId as ActivityId]}
        </Typography>
        {!!courseList.length && (
          <Box mt={3}>
            <Typography variant="body2">
              <Trans i18nKey={`${I18N_KEY}.onCourse`}>On Course</Trans>
            </Typography>
            <InstructionList>
              {courseList.map(instruction => (
                <li key={instruction}>{instruction}</li>
              ))}
            </InstructionList>
          </Box>
        )}
        {!!rangeList.length && (
          <Box mt={3}>
            <Typography variant="body2">{rangeGroupLabel}</Typography>
            <InstructionList>
              {rangeList.map(instruction => (
                <li key={instruction}>{instruction}</li>
              ))}
            </InstructionList>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          <Trans i18nKey={`${I18N_KEY}.close`}>Got it</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InstructionDialog
