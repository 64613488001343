import { CustomTimeRange } from './ioSlice'
import { BenchmarkId } from '../utils/golfConstants'
import {
  Languages,
  TimeFilter,
  TypeFilter,
  WhereFilter,
} from '../utils/constants'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorPayload } from '../../typings/store'
import { AppThunk } from './index'
import {
  authRequestFail,
  getErrorStatus,
  getUserInfo,
  isForbiddenOrUnauthorised,
} from '../utils/helpers'
import { api } from '../utils/api'
import { getErrorToast, openToast } from './toastSlice'
import { RootState } from './rootReducer'

export interface DiscussionDocumentRequestBody {
  type: TypeFilter
  customTimeRange: CustomTimeRange | undefined
  time: TimeFilter
  where: WhereFilter
  baseBenchmarkId: BenchmarkId
  stretchBenchmarkId: BenchmarkId
  language: Languages
}

interface DiscussionDocumentState {
  requestInProgress: boolean
  isOpen: boolean
  error: string | null
}

const initialState: DiscussionDocumentState = {
  requestInProgress: false,
  isOpen: false,
  error: null,
}

const SLICE_NAME = 'discussionDocument'

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    requestInProgress: state => {
      state.requestInProgress = true
    },
    requestFinished: state => {
      state.requestInProgress = false
    },
    requestError: (state, action: PayloadAction<ErrorPayload>) => {
      return {
        ...state,
        requestInProgress: false,
        error: action.payload,
      }
    },
    updateDialogVisibility: (
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ) => {
      const { isOpen } = action.payload

      state.isOpen = isOpen
    },
  },
})

export const {
  requestInProgress,
  requestFinished,
  requestError,
  updateDialogVisibility,
} = actions

export default reducer

export const discussionDocumentState = (state: RootState) => state[SLICE_NAME]

export const requestIsInProgress = createSelector(
  discussionDocumentState,
  (state: DiscussionDocumentState): boolean => state.requestInProgress
)

export const requestErrorSelector = createSelector(
  discussionDocumentState,
  (state: DiscussionDocumentState): string | null => state.error
)

export const isOpenSelector = createSelector(
  discussionDocumentState,
  (state: DiscussionDocumentState): boolean => state.isOpen
)

export const createDiscussionDocumentReport =
  (payload: DiscussionDocumentRequestBody): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { playerUuid } = getUserInfo(state)
    dispatch(requestInProgress())
    const endpoint = `/overview/player/${playerUuid}/report/discussion`

    try {
      await api.post(endpoint, payload)
      dispatch(requestFinished())
      dispatch(updateDialogVisibility({ isOpen: false }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(
        openToast(
          getErrorToast(
            'You have no rounds matching this filter. A minimum of 1 round is required to generate Improvement Opportunities. Please select a different filter'
          )
        )
      )
      dispatch(requestError(getErrorStatus(error)))
      dispatch(updateDialogVisibility({ isOpen: true }))
    }
  }
