import React, { useState, MouseEvent } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { AnimateSharedLayout } from 'framer-motion'
import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import {
  ActiveBar,
  CheckIcon,
} from 'src/components/layout/SharedLayoutComponents'
import { CategoryMetricIds, MetricId } from 'src/utils/golfConstants'
import { TabButton } from 'src/modules/common'
import styled, { css } from 'styled-components'

const I18N_KEY = 'CategorySelector'

interface Props {
  selectedCategory: string
  onChange: (value: MetricId) => void
}

const CategoryMenu = styled(MenuItem)<{ selected?: boolean }>(
  ({ theme, selected }) => css`
    border: 1px #e7e8e9 solid;
    border-radius: 6px;
    margin: 1em;
    width: 18em;
    font-size: smaller;
    background-color: 'transparent';
    color: ${selected ? theme.palette.primary.main : '#000'};
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.MuiMenuItem-root {
      background-color: ${selected
        ? theme.palette.primary.contrastText
        : 'transparent'};
    }
  `
)

const CategorySelector: React.FC<Props> = ({ selectedCategory, onChange }) => {
  const [selectedParentCategory, setSelectedParentCategory] =
    useState<MetricId>(MetricId.All)
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleCategorySelection = (
    event: MouseEvent<HTMLElement>,
    category: string
  ) => {
    event.stopPropagation()
    onChange(category as MetricId)
    setAnchorEl(null)
  }

  const openMenu = (event: MouseEvent<HTMLElement>, category: string) => {
    event.stopPropagation()
    setSelectedParentCategory(category as MetricId)
    if (category === MetricId.All || category === MetricId.Drives) {
      onChange(category)
    } else {
      // onChange(category as MetricId)
      setAnchorEl(event.currentTarget)
    }
  }
  const closeMenu = () => setAnchorEl(null)

  return (
    <>
      <AnimateSharedLayout>
        <Box display="flex" justifyContent="flex-end">
          {Object.values(CategoryMetricIds).map(category => (
            <TabButton
              key={category}
              selected={
                selectedParentCategory === (category as unknown as MetricId)
              }
              onClick={(event: React.MouseEvent<HTMLElement>) =>
                openMenu(event, category)
              }
            >
              <Trans i18nKey={`${I18N_KEY}.${category}`}>
                {t(`Enums:CategoryMetricLabels.${category}`)}
              </Trans>
              {selectedParentCategory === (category as unknown as MetricId) && (
                <ActiveBar layoutId={category} />
              )}
            </TabButton>
          ))}
        </Box>
      </AnimateSharedLayout>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {selectedParentCategory === MetricId.Approach &&
          [
            MetricId.Approach,
            MetricId.Approach101To150,
            MetricId.Approach151To200,
            MetricId.Approach201To250,
            MetricId.ApproachOver250,
          ].map(category => (
            <CategoryMenu
              key={category}
              onClick={e => handleCategorySelection(e, category)}
              selected={selectedCategory === category}
            >
              <Trans i18nKey={`${I18N_KEY}.${category}`}>
                {t(`Enums:MetricLabels.${category}`)}
              </Trans>
              {selectedCategory === category && <CheckIcon />}
            </CategoryMenu>
          ))}

        {selectedParentCategory === MetricId.Short &&
          [
            MetricId.Short,
            MetricId.ShortUnder20,
            MetricId.Short21To60,
            MetricId.Short61To100,
            MetricId.ShortBunkers,
          ].map(category => (
            <CategoryMenu
              key={category}
              onClick={e => handleCategorySelection(e, category)}
              selected={selectedCategory === category}
            >
              <Trans i18nKey={`${I18N_KEY}.${category}`}>
                {t(`Enums:MetricLabels.${category}`)}
              </Trans>
            </CategoryMenu>
          ))}

        {selectedParentCategory === MetricId.Putt &&
          [
            MetricId.Putt,
            MetricId.PuttUnder6,
            MetricId.Putt7To21,
            MetricId.PuttOver21,
          ].map(category => (
            <CategoryMenu
              key={category}
              onClick={e => handleCategorySelection(e, category)}
              selected={selectedCategory === category}
            >
              <Trans i18nKey={`${I18N_KEY}.${category}`}>
                {t(`Enums:MetricLabels.${category}`)}
              </Trans>
            </CategoryMenu>
          ))}
      </Menu>
    </>
  )
}

export default CategorySelector
