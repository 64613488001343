import React, { useMemo } from 'react'
import { FormikConfig, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import Box from '@material-ui/core/Box'
import { PaperProps } from '@material-ui/core/Paper'

import {
  cardSelector,
  updateBilling,
  billingAddress,
  dataSelector,
} from 'src/store/subscriptionSlice'
import { playerNameSelector } from 'src/store/playerSlice'
import { Content } from 'src/modules/player-settings/common'
import { Billing as BillingModel } from 'src/models/subscription'
import Invoices from 'src/modules/player-settings/billing/Invoices'
import CardInfo from 'src/modules/player-settings/billing/CardInfo'
import { createBillingAddressSchema } from 'src/utils/validationSchemas'
import MembershipInfo from 'src/modules/player-settings/billing/MembershipInfo'
import { openToast, getSuccessToast, getErrorToast } from 'src/store/toastSlice'
import BillingAddressForm from 'src/modules/player-settings/billing/BillingAddressForm'
import { isChina } from 'src/utils/constants'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { config } from '../../../configLoader'

const I18N_KEY = 'SettingsBilling'

interface StyledContentProps extends PaperProps {
  $gutterTop?: number
}
const StyledContent = styled(Content)<StyledContentProps>(
  ({ theme, $gutterTop }) => css`
    padding-top: ${$gutterTop ? `${theme.spacing($gutterTop)}px` : 0};
    padding-bottom: ${theme.spacing(5)}px;
  `
)

export interface BillingAddressFields {
  city: string
  address: string
  country: string
  fullName: string
  postcode: string
}

const stripePromise = loadStripe(config.REACT_APP_STRIPE_KEY!)

const Billing: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { stripeUpgrade } = useFlags()

  const card = useSelector(cardSelector)
  const billing = useSelector(billingAddress)
  const userName = useSelector(playerNameSelector)
  const subscription = useSelector(dataSelector)
  const stripeOptions: StripeElementsOptions = {
    mode: 'subscription',
    amount: subscription?.plan?.price,
    currency: 'usd',
  }

  const handleBillingAddressSubmit = async (billing: BillingModel) => {
    try {
      const result = await dispatch(updateBilling(billing))
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.successToast`,
              'Billing address successfully updated'
            )
          )
        )
      )
      return result
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.errorToast`,
              'Error updating your billing address. Please try again later'
            )
          )
        )
      )
      return billing
    }
  }

  const billingAddressValidationSchema = useMemo(
    () => createBillingAddressSchema(t),
    [t]
  )
  const billingAddressFormikOptions: FormikConfig<BillingModel> = {
    initialValues: {
      fullName: `${userName.firstName} ${userName.lastName}`,
      address: '',
      city: '',
      country: {
        name: '',
        dialCode: '',
        code: '',
      },
      postcode: '',
      ...billing,
    },
    validationSchema: billingAddressValidationSchema,
    onSubmit: values => handleBillingAddressSubmit(values),
  }

  return (
    <Box>
      {billing && (
        <StyledContent $gutterTop={6}>
          <Formik {...billingAddressFormikOptions}>
            <BillingAddressForm />
          </Formik>
        </StyledContent>
      )}
      {/* Allow them to add/change card if billing info is present */}
      {(card || billing) && (
        <StyledContent $gutterTop={billing ? 0 : 6}>
          {stripeUpgrade ? (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <CardInfo />
            </Elements>
          ) : (
            <CardInfo />
          )}
        </StyledContent>
      )}
      <StyledContent $gutterTop={card || billing ? 0 : 6}>
        <MembershipInfo />
      </StyledContent>
      {/* TODO: reinstate invoices for China when supported */}
      {!isChina && <Invoices />}
    </Box>
  )
}

export default Billing
