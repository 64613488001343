import React from 'react'
import { Trans } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

const I18N_KEY = 'UnconnectedPlayer'

const UnconnectedPlayer: React.FC = () => (
  <>
    <Divider />
    <Box position="relative" p={3} minHeight={185}>
      <Typography variant="body2">
        <Trans i18nKey={`${I18N_KEY}.roundsTraining`}>Rounds & Training</Trans>
      </Typography>
      <Typography color="textSecondary">
        <Trans i18nKey={`${I18N_KEY}.roundsTrainingDescription`}>
          This player&apos;s activity over the past 7 days.
        </Trans>
      </Typography>
    </Box>
  </>
)

export default UnconnectedPlayer
