import React from 'react'
import { Trans } from 'react-i18next'

import Button from '@material-ui/core/Button'
import { Dialog as MuiDialog } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import DeleteButton from 'src/components/inputs/DeleteButton'
import styled, { css } from 'styled-components'

const I18N_KEY = 'DeleteConfirmDialog'

const Dialog = styled(MuiDialog)<{ $width: number }>(
  ({ $width = 500 }) => css`
    .MuiDialog-paperWidthSm {
      width: 100%;
      max-width: ${$width}px;
    }
  `
)

interface Props {
  open: boolean
  title: string
  deleteText: string
  inProgress: boolean
  description: string
  handleClose: () => void
  handleAction: () => Promise<void>
  width?: number
}

const DeleteRoundDialog: React.FC<Props> = ({
  open,
  title,
  deleteText,
  inProgress,
  description,
  handleClose,
  handleAction,
  width = 500,
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="form-dialog-title"
    $width={width}
  >
    <DialogContent>
      <Typography gutterBottom variant="h4" id="form-dialog-title">
        {title}
      </Typography>
      <Typography color="textSecondary">{description}</Typography>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={handleClose}>
        <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Cancel</Trans>
      </Button>
      <DeleteButton $width={145} loading={inProgress} onClick={handleAction}>
        {deleteText}
      </DeleteButton>
    </DialogActions>
  </Dialog>
)

export default DeleteRoundDialog
