import { createGlobalStyle } from 'styled-components'

import 'src/styles/fonts.css'

/**
 * Global base styles
 */

const GlobalStyle = createGlobalStyle`
  html,
  body {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  }

  html {
    box-sizing: border-box;
    height: 100%;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  strong {
    font-weight: 500;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  img {
    max-width: 100%;
  }

  fieldset {
    margin: 0;
    padding: 0;
    border: 0;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  /* clears the 'X' from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the 'X' from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none; /* stylelint-disable-line property-no-vendor-prefix */
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield; /* stylelint-disable-line property-no-vendor-prefix */
  }


  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px white inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill::first-line {
    font-size: initial;
    font-family: ${({ theme }) => theme.typography.fontFamily};
  }

  .intercom-lightweight-app {
    z-index: 1299 !important;
  }

  // Hide reCaptcha in bottom right
  .grecaptcha-badge { 
    visibility: hidden;
  }

  .recharts-wrapper * { outline: none; }
 `

export default GlobalStyle
