import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Box, Typography } from '@material-ui/core'

import { DECIMAL_PLACES } from 'src/utils/constants'
import { handleNumberFormat } from 'src/utils/helpers'

const I18N_KEY = 'PerformanceToGoalLabel'

interface Props {
  dataExists: boolean
  value: number | null | undefined
  unit: string
  averagePercent: number
}

const PerformanceText = styled(Typography)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(20)};
      line-height: ${theme.typography.pxToRem(24)};
    }
  `
)

const PercentageText = styled(Typography)<{ $percent: number }>(
  ({ theme, $percent }) => css`
    font-weight: bold;
    color: ${$percent > 100
      ? theme.palette.success.main
      : $percent < 100
      ? theme.palette.error.main
      : theme.palette.background.grey};
    padding: ${theme.spacing(0.5, 0, 0, 1)};

    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

const PerformanceToGoalLabel: React.FC<Props> = ({
  value,
  unit,
  averagePercent,
  dataExists,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Box display="flex" mb={1} {...props}>
      {dataExists ? (
        value ? (
          <PerformanceText variant="h3">
            {`${parseFloat(value?.toFixed(DECIMAL_PLACES)).toString()}${t(
              `Enums:Measurement.${unit}`
            )}`}
          </PerformanceText>
        ) : (
          <PerformanceText variant="h3" color="textSecondary">
            N/A
          </PerformanceText>
        )
      ) : (
        <Typography variant="body1">
          {t(
            `${I18N_KEY}.noShotsInThisTimePeriod`,
            'No shots or practices in this time period'
          )}
        </Typography>
      )}
      {dataExists && (
        <PercentageText variant="h5" $percent={averagePercent}>
          {handleNumberFormat(averagePercent as number, true)}%
        </PercentageText>
      )}
    </Box>
  )
}

export default PerformanceToGoalLabel
