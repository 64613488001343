import { CustomTimeRange } from 'src/components/inputs/CustomDatePicker'
import { api } from 'src/utils/api'
import { TimeFilter, TypeFilter, WhereFilter } from 'src/utils/constants'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'

export const fetchRoundCount = async (
  isPlayer?: boolean,
  playerUuid?: string,
  filters?: {
    time: TimeFilter
    type: TypeFilter
    where: WhereFilter
    customTimeRange: CustomTimeRange | null
  }
) => {
  let endpoint = isPlayer
    ? `/round/count`
    : `overview/player/${playerUuid}/round/count`
  if (filters) {
    if (filters?.time) {
      endpoint += `?time=${filters.time}`
    }
    if (filters?.type) {
      endpoint += `${filters?.time ? '&' : '?'}type=${filters.type}`
    }
    if (filters?.where) {
      endpoint += `${filters?.type ? '&' : '?'}where=${filters.where}`
    }
    if (filters?.customTimeRange) {
      endpoint += `${filters?.type ? '&' : '?'}customTimeRange=${JSON.stringify(
        filters.customTimeRange
      )}`
    }
  }
  const roundCountResponse = await api.get(endpoint)
  return getSinglePayloadFromResponse(roundCountResponse)?.count
}

export const fetchLastRoundUpdated = async (
  isPlayer?: boolean,
  playerUuid?: string
) => {
  const endpoint = isPlayer
    ? `/round/latest`
    : `overview/player/${playerUuid}/round/latest`
  const latestRoundResponse = await api.get(endpoint)
  return getSinglePayloadFromResponse(latestRoundResponse)
}
