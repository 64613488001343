import React, { useState } from 'react'

import InputAdornment from '@material-ui/core/InputAdornment'

import FormTextField, {
  FormTextFieldProps,
} from 'src/components/inputs/FormTextField'
import PasswordVisibility from 'src/components/inputs/PasswordVisibility'

const FormPasswordField: React.FC<FormTextFieldProps> = props => {
  const [showPassword, setShowPassword] = useState(false)
  const toggleVisibility = () => setShowPassword(!showPassword)
  return (
    <FormTextField
      autoComplete="new-password"
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PasswordVisibility
              isVisible={showPassword}
              toggleVisibility={toggleVisibility}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default FormPasswordField
