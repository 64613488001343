import React, { useContext } from 'react'
import CountUp from 'react-countup'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Typography, { TypographyProps } from '@material-ui/core/Typography'

import { fadeIn } from 'src/utils/animations'
import { reportDonutInfoSelector } from 'src/store/reportSlice'
import { TranslationContext } from 'src/utils/TranslationContext'
import ProgressDonut from 'src/components/dataDisplay/ProgressDonut'

const I18N_KEY = 'ReportDonut'

const DONUT_SIZE = 150

const DonutContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    cursor: normal;
    position: relative;
    align-items: center;
    flex-direction: column;
    width: ${DONUT_SIZE}px;
    height: ${DONUT_SIZE}px;
    justify-content: center;

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(6, 0, 0)};
    }
  `
)

const fadeInAnimation = css`
  animation: ${fadeIn} 0.4s ease-in forwards;
`

const TypographyElementP = (props: TypographyProps<'p'>) => (
  <Typography component="p" {...props} />
)

const Value = styled(TypographyElementP)(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.palette.background.paper};
    ${fadeInAnimation}

    @media (hover: none) {
      user-select: none;
    }
  `
)

const Benchmark = styled(TypographyElementP)(
  ({ theme }) => css`
    color: #b2b2b2;
    max-width: 100px;
    margin-top: ${theme.spacing(-1)}px;
    ${fadeInAnimation}

    @media (hover: none) {
      user-select: none;
    }
  `
)

const ReportDonut: React.FC = () => {
  const { getBenchmarkName } = useContext(TranslationContext)!

  const { benchmarkDetails, progressDonut } = useSelector(
    reportDonutInfoSelector
  )

  const progress = progressDonut?.progressValue as number
  const toBenchmark = progressDonut?.toBenchmark as number

  const current = benchmarkDetails?.current
  const benchmarkLabel = getBenchmarkName(current?.id)

  return (
    <DonutContainer>
      <ProgressDonut progress={progress} showGoalIcon />
      <Value variant="h2">
        <CountUp
          decimals={2}
          duration={1.5}
          useEasing={false}
          end={Number(toBenchmark?.toFixed(2))}
        />
      </Value>
      <Benchmark align="center" variant="h6" color="textSecondary">
        <Trans i18nKey={`${I18N_KEY}.vsBenchmarkLabel`}>
          vs {{ benchmarkLabel }}
        </Trans>
      </Benchmark>
    </DonutContainer>
  )
}

export default ReportDonut
