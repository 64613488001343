import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import {
  Form as FormikForm,
  Field,
  FormikProps,
  useFormikContext,
} from 'formik'
import { Trans, useTranslation } from 'react-i18next'

import {
  Grid,
  Button,
  Divider,
  Typography,
  // Autocomplete
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import {
  PlusSign,
  PersonIcon,
  StyledAvatar,
  AvatarActions,
  AvatarContainer,
  SettingsSubmitButton,
} from 'src/modules/common'
import {
  UpdateCoachRequest,
  setCoachProfileImage,
  deleteCoachProfileImage,
} from 'src/store/coachSlice'
import useEnum from 'src/hooks/useEnum'
import {
  FieldGroup,
  Title,
  Row,
  ActionRow,
} from 'src/modules/player-settings/common'
import useCropper from 'src/hooks/useCropper'
import { FormDatePicker } from 'src/components/common'
import CropDialog from 'src/components/feedback/CropDialog'
import FormTextField from 'src/components/inputs/FormTextField'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'
import { Gender, Languages, DateFormat } from 'src/utils/constants'
import FormToggleButton from 'src/components/inputs/FormToggleButton'
import { Label } from '../../../components/Label'
import Box from '@material-ui/core/Box'
import { formatPhoneNumber } from '../../../utils/helpers'
import { Coach } from '../../../models/coach'

// will be released later
// import countries from 'src/assets/data/countries.json'
// import { nationalities } from 'src/assets/data/nationalities'

const I18N_KEY = ' CoachProfileForm'

const Form = styled(FormikForm)`
  display: block;
`

interface ProfileFormProps {
  coach: Coach
  profileImageUrl: string | undefined | null
  onChange?: (change: FormikProps<UpdateCoachRequest>) => void
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  coach,
  onChange,
  profileImageUrl,
}) => {
  const {
    previewSrc,
    selectedFile,
    cropDialogOpen,
    closeCropDialog,
    handleFileSelection,
  } = useCropper()

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { options: genderOptions } = useEnum(Gender, 'Gender')

  const formik = useFormikContext<UpdateCoachRequest>()
  const { values, dirty, isValid, isSubmitting } = formik

  const saveProfileImage = async (file: File) => {
    try {
      await dispatch(setCoachProfileImage(file))
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.profileImageeError`, 'Could not upload image')
          )
        )
      )
    } finally {
      closeCropDialog()
    }
  }

  const handleProfileImageDelete = async () => {
    try {
      await dispatch(deleteCoachProfileImage())
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.profileImageeError`, 'Could not delete image')
          )
        )
      )
    }
  }

  useEffect(() => {
    if (onChange) {
      onChange(formik)
    }
  }, [formik, onChange, values])

  // Will be added in the future
  // const handleNationalityChange = (
  //   event: React.ChangeEvent<{}>,
  //   value: string | null
  // ) => {
  //   setFieldValue('nationality', value)
  // }

  return (
    <>
      <Form>
        <AvatarContainer>
          {profileImageUrl ? (
            <StyledAvatar
              src={profileImageUrl}
              alt={t(`${I18N_KEY}.userAlt`, 'User profile')}
            />
          ) : (
            <StyledAvatar>
              <PersonIcon />
            </StyledAvatar>
          )}
          <AvatarActions>
            <Button color="primary" variant="outlined" component="label">
              <PlusSign>+</PlusSign>
              <Trans i18nKey={`${I18N_KEY}.uploadButtonLabel`}>
                Upload new picture
              </Trans>
              <input
                hidden
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileSelection}
              />
            </Button>
            {profileImageUrl && (
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleProfileImageDelete}
              >
                <Trans i18nKey={`${I18N_KEY}.deleteButtonLabel`}>Delete</Trans>
              </Button>
            )}
          </AvatarActions>
        </AvatarContainer>
        <Divider />
        <FieldGroup>
          <Title variant="h4">
            <Trans i18nKey={`${I18N_KEY}.personalInformation`}>
              Personal Information
            </Trans>
          </Title>
          <Grid container spacing={0}>
            <Row item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    fullWidth
                    name="firstName"
                    label={t(`${I18N_KEY}.firstNameLabel`, 'First name')}
                    placeholder={t(
                      `${I18N_KEY}.firstNamePlaceholder`,
                      'First name'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    fullWidth
                    name="lastName"
                    label={t(`${I18N_KEY}.lastNameLabel`, 'Last name')}
                    variant="outlined"
                    placeholder={t(
                      `${I18N_KEY}.lastNamePlaceholder`,
                      'Last name'
                    )}
                  />
                </Grid>
              </Grid>
            </Row>
            <Row item xs={12} sm={6}>
              <FormToggleButton
                name="gender"
                label={t(`${I18N_KEY}.genderLabel`, 'Gender')}
                options={genderOptions}
              />
            </Row>
            <Grid item xs={12} sm={6} />
            <Row item xs={12} sm={6}>
              <Typography variant="caption">
                <Trans i18nKey={`${I18N_KEY}.dateOfBirth`}>Date of birth</Trans>
              </Typography>
              <Field
                autoOk
                fullWidth
                name="dob"
                helperText=""
                disableFuture
                inputVariant="outlined"
                component={FormDatePicker}
                InputLabelProps={{ shrink: true }}
                InputProps={{ endAdornment: <CalendarIcon /> }}
                format={DateFormat[i18n.language as Languages].datePicker}
              />
            </Row>
            <Grid item xs={12} sm={6} />
            <Row item xs={12} sm={6}>
              <Label $hasError={false}>
                <Trans i18nKey={`${I18N_KEY}.phoneNumber`}>Phone number</Trans>
              </Label>
              <Box>{formatPhoneNumber(coach.phoneNumber)}</Box>
            </Row>
            <Grid item xs={12} sm={6} />
            {/* 
          Will be added in the future
          <Row item xs={12} sm={6}>
            <Autocomplete
              options={nationalities[i18n.language as Languages]}
              renderInput={props => (
                <FormTextField
                  {...props}
                  fullWidth
                  name="nationality"
                  label={t(`${I18N_KEY}.nationalityLabel`, 'Nationality')}
                  placeholder={t(
                    `${I18N_KEY}.nationalityPlaceHolder`,
                    'Please select'
                  )}
                />
              )}
              onChange={handleNationalityChange}
            />
          </Row> */}
            <Grid item xs={12} sm={6} />
            <ActionRow item xs={12}>
              <SettingsSubmitButton
                type="submit"
                color="primary"
                variant="contained"
                disabled={!dirty || !isValid || isSubmitting}
                loading={isSubmitting}
              >
                <Trans i18nKey={`${I18N_KEY}.saveChangesButtonLabel`}>
                  Save Changes
                </Trans>
              </SettingsSubmitButton>
            </ActionRow>
          </Grid>
        </FieldGroup>
      </Form>
      <CropDialog
        file={selectedFile}
        open={cropDialogOpen}
        previewSrc={previewSrc}
        onClose={closeCropDialog}
        onSave={saveProfileImage}
      />
    </>
  )
}

export default ProfileForm
