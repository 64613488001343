import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import MuiContainer from '@material-ui/core/Container'

import { breakpoints } from 'src/utils/constants'
import useBenchmark from 'src/hooks/useBenchmark'
import Statistics from 'src/modules/rounds/summary/Statistics'
import ScoreDetails from 'src/modules/rounds/summary/ScoreDetails'
import RadarComparison from 'src/components/dataDisplay/RadarComparison'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import SubCategoryTable from 'src/modules/rounds/summary/SubCategoryTable'
import { Round, CategoryType, perShotMetrics } from 'src/utils/golfConstants'

const I18N_KEY = 'RoundsSummary'
interface Props {
  handleEdit: () => void
  roundData: Round | null
}

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(5)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(5, 2, 0)};
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 0, 0)};
    }
  `
)

const StyledTitle = styled(ReferencedTitle)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const Comparison = styled(RadarComparison)(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0, 6)};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      margin: ${theme.spacing(2, 0, 4)};
    }
  `
)

const Summary: React.FC<Props> = ({ roundData, handleEdit }) => {
  const { t } = useTranslation()
  const { benchmarkName } = useBenchmark()

  const strokesGained = roundData?.summary?.strokesGained
  const radarData = [
    {
      reference: 0,
      category: CategoryType.Drives,
      overall: strokesGained?.drives?.overall,
      benchmark: strokesGained?.drives?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Approach,
      overall: strokesGained?.approach?.overall,
      benchmark: strokesGained?.approach?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Short,
      overall: strokesGained?.short?.overall,
      benchmark: strokesGained?.short?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Putt,
      overall: strokesGained?.putt?.overall,
      benchmark: strokesGained?.putt?.benchmark,
    },
  ]

  const performanceIndicators = useMemo(
    () =>
      roundData?.summary?.performanceIndicators.filter(
        ({ metricId }) => !perShotMetrics.includes(metricId)
      ),
    [roundData]
  )

  return (
    <>
      <ScoreDetails
        benchmarkName={benchmarkName}
        handleEdit={handleEdit}
        score={roundData?.summary?.score}
        strokesGained={roundData?.summary?.strokesGained?.total}
      />
      <Container>
        <StyledTitle title={t(`${I18N_KEY}.title`, 'Comparison To Goal')} />
        <Comparison
          isRoundSummary
          data={radarData}
          benchmarkName={benchmarkName}
        />
        <SubCategoryTable categories={roundData?.summary?.categories || []} />
        <Statistics performanceIndicators={performanceIndicators || []} />
      </Container>
    </>
  )
}

export default Summary
