import React from 'react'
import {
  Route,
  Redirect,
  LinkProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'

import {
  SettingsMenu,
  SettingsButton,
  SettingsContainer,
} from 'src/modules/common'
import {
  userPlanSelector,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import useAppState from 'src/hooks/useAppState'
import { localizeRoutePath } from 'src/utils/i18n'
import Account from 'src/modules/player-settings/account'
import Profile from 'src/modules/player-settings/profile'
import Billing from 'src/modules/player-settings/billing'
import LocalizedSwitch from 'src/components/i18n/LocalizedSwitch'
import { isChina, PlayerSettingsRoutes, Routes } from 'src/utils/constants'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'
import { useFlags } from 'launchdarkly-react-client-sdk'

const Page = styled.div<{ $addPagePadding: boolean }>(
  ({ theme, $addPagePadding }) => css`
    background-color: ${theme.palette.background.xlight};
    margin: ${$addPagePadding ? theme.spacing(6, 0, 0) : 0};
  `
)

const DEFAULT_PATH = PlayerSettingsRoutes.Profile

const MenuButton: React.FC<LinkProps> = ({ children, ...props }) => {
  const { to } = props
  const match = useRouteMatch({
    path: localizeRoutePath(to as string),
    strict: true,
    sensitive: true,
  })
  const isExact = match?.isExact
  return (
    <SettingsButton {...props}>
      {children}
      {isExact && <ActiveBar layoutId="profile-menu" />}
    </SettingsButton>
  )
}

const settingRouteEnties = Object.entries(PlayerSettingsRoutes)

const PlayerSettings: React.FC = () => {
  const history = useHistory()
  const { isPlayer } = useAppState()
  const { chinaBilling } = useFlags()

  const userNotSubscribed = !useSelector(userPlanSelector)
  const playerIsTrialing = useSelector(userIsTrialingSelector)

  const addPagePadding = isPlayer && (userNotSubscribed || playerIsTrialing)

  const handleMenuItemClick = (path: string): void => {
    history.push(localizeRoutePath(path) as string)
  }

  return (
    <Page $addPagePadding={addPagePadding}>
      <SettingsContainer>
        <AnimateSharedLayout>
          <SettingsMenu>
            {settingRouteEnties.map(([key, path]) => {
              if (
                isChina &&
                !chinaBilling &&
                path === PlayerSettingsRoutes.Billing
              ) {
                return null
              }
              const fullPath: string = `${Routes.Settings}${path}`
              return (
                <MenuButton
                  key={fullPath}
                  to={fullPath}
                  onClick={() => handleMenuItemClick(fullPath)}
                >
                  <Trans i18nKey={`Enums:SettingsRoutes.${key}`} />
                </MenuButton>
              )
            })}
          </SettingsMenu>
        </AnimateSharedLayout>
        <LocalizedSwitch>
          <Route path={`${Routes.Settings}${PlayerSettingsRoutes.Profile}`}>
            <Profile />
          </Route>
          <Route path={`${Routes.Settings}${PlayerSettingsRoutes.Account}`}>
            <Account />
          </Route>
          {(!isChina || chinaBilling) && (
            <Route path={`${Routes.Settings}${PlayerSettingsRoutes.Billing}`}>
              <Billing />
            </Route>
          )}
          <Route path={`${Routes.Settings}`}>
            <Redirect to={`${Routes.Settings}${DEFAULT_PATH}`} />
          </Route>
        </LocalizedSwitch>
      </SettingsContainer>
    </Page>
  )
}

export default PlayerSettings
