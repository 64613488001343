import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import useQuery from 'src/hooks/useQuery'
import { UPGRADE_QUERY_PARAM_NAME } from 'src/utils/constants'

const useUpgradeFlow = () => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()

  const upgradeLocation = useMemo(() => {
    if (query.has(UPGRADE_QUERY_PARAM_NAME)) {
      return location
    }
    const { search, ...restOfLocation } = location
    return {
      ...restOfLocation,
      search: `${search ? `${search}&` : '?'}${UPGRADE_QUERY_PARAM_NAME}`,
    }
  }, [location, query])

  const open = () => {
    if (query.has(UPGRADE_QUERY_PARAM_NAME)) {
      return
    }
    history.push(upgradeLocation)
  }
  const close = () => {
    query.delete(UPGRADE_QUERY_PARAM_NAME)
    history.push({
      ...location,
      search: query.toString(),
    })
  }
  return {
    open,
    close,
  }
}

export default useUpgradeFlow
