import styled, { css } from 'styled-components'

import LoaderButton from 'src/components/inputs/LoaderButton'

interface ButtonProps {
  $width?: number
}

const DeleteButton = styled(LoaderButton)<ButtonProps>(
  ({ theme, $width = 145 }) => css`
    width: ${$width}px;
    color: ${theme.palette.error.main};

    &:hover {
      background-color: ${theme.palette.error.light};
    }
  `
)

export default DeleteButton
