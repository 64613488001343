import React, { useMemo } from 'react'
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import {
  NegativeGradient,
  PositiveGradient,
} from '../../components/graph/Gradient'
import { RenderXAxisTick, RenderYAxisTick } from '../../components/graph/Axis'
import { renderScatterDot } from '../../components/graph/composed/ScatterDot'
import styled, { css, StyledProps } from 'styled-components'
import { FadingDotIllustration } from '../common'
import { useTheme } from '@material-ui/core'
import { getXAxisTicks } from '../../components/graph/utils'
import { Strength } from '../../store/summarySlice'
import Typography from '@material-ui/core/Typography'
import StrokesProgressLabel from '../../components/dataDisplay/StrokesProgressLabel'
import Legend from '../../components/graph/composed/Legend'
import Paper from '@material-ui/core/Paper'
import useBenchmark from '../../hooks/useBenchmark'
import TooltipContentStrength from '../../components/graph/composed/TooltipContentStrength'
import { Trans } from 'react-i18next'
import MuiButton from '@material-ui/core/Button'
import history from '../../utils/history'

const I18N_KEY = 'OpportunityGraph'

interface PlaceholderProps {
  height: string | number
}

const Container = styled(Paper)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(3, 3, 3, 0)};
    box-shadow: none;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 2, 2, 0)};
    }
  `
)

const NoData = styled(({ height, ...props }) => <div {...props} />)(
  ({ height }: StyledProps<PlaceholderProps>) => css`
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    min-height: ${typeof height === 'string' ? height : `${height}px`};
  `
)

const Illustration = styled(FadingDotIllustration)(
  ({ theme }) => css`
    margin: ${theme.spacing(3, 0, 0)};

    g {
      fill: ${theme.palette.background.grey};
    }
  `
)

const HeaderContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${theme.breakpoints.up(750)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

const HeaderInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${theme.spacing(4.5)}px;

    ${theme.breakpoints.down('xs')} {
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin: ${theme.spacing(0, 0, 1.5)};
      padding-left: ${theme.spacing(2)}px;
      width: 100%;
    }
  `
)

interface Props {
  strength: Strength[]
  currentValue: number
  isActive: boolean
  title: string
  showLegend?: boolean
}

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin-left: auto;
    margin-top: ${theme.spacing(2)}px;
    background-color: ${theme.palette.background.xlight};
  `
)

const StrengthGraph: React.FC<Props> = ({
  strength,
  currentValue,
  isActive,
  title,
  showLegend,
}) => {
  const theme = useTheme()
  const { benchmarkName, referencePoint } = useBenchmark()

  const xTicks = useMemo(
    () => getXAxisTicks(strength.map(({ datePlayed }) => datePlayed) || []),
    [strength]
  )

  const handleDetailsClick = () => {
    history.push(strength[0].path)
  }

  const calculateProgress = () => {
    if (strength) {
      return (
        strength[strength.length - 1]?.rollingAverage -
        strength[0]?.rollingAverage
      )
    }
    return 0
  }

  return (
    <Container>
      {isActive && strength ? (
        <>
          <HeaderContent>
            <HeaderInfo>
              <Typography>{title}</Typography>
              <StrokesProgressLabel
                dataExists={!!strength}
                sgValue={currentValue}
                progress={calculateProgress()}
              />
            </HeaderInfo>
            {showLegend && <Legend />}
          </HeaderContent>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart data={strength}>
              <defs>
                <PositiveGradient
                  id="strength"
                  color={theme.palette.primary.light}
                />
                <NegativeGradient
                  id="strength"
                  color={theme.palette.primary.light}
                />
              </defs>
              <CartesianGrid
                vertical={false}
                stroke={theme.palette.background.light}
              />
              <YAxis
                type="number"
                tickLine={false}
                axisLine={false}
                tick={props => <RenderYAxisTick {...props} />}
              />
              <XAxis
                type="number"
                dataKey="datePlayed"
                tickLine={false}
                axisLine={false}
                domain={['dataMin', 'dataMax']}
                ticks={xTicks}
                tickFormatter={() => 'XXX 99'}
                tick={props => <RenderXAxisTick {...props} />}
              />
              <ReferenceLine
                strokeWidth={2}
                y={strength[0].benchmark}
                ifOverflow="extendDomain"
                stroke={theme.palette.warning.main}
              />
              <ReferenceLine
                y={0}
                strokeWidth={2}
                strokeDasharray="4"
                ifOverflow="extendDomain"
                stroke={theme.palette.warning.main}
              />
              <Area
                dot={false}
                type="monotone"
                strokeWidth={2}
                fillOpacity={1}
                dataKey="rollingAverage"
                activeDot={false}
                animationDuration={1000}
                stroke={theme.palette.primary.light}
                fill={
                  currentValue > 0
                    ? `url(#positive-average-strength)`
                    : `url(#negative-average-strength)`
                }
              />
              <Tooltip
                cursor={{
                  strokeWidth: 2,
                  stroke: theme.palette.background.grey,
                }}
                wrapperStyle={{ top: 175, zIndex: 10 }}
                content={(props: TooltipProps<number, string>) => (
                  <TooltipContentStrength
                    {...props}
                    benchmarkName={benchmarkName}
                    referencePoint={referencePoint}
                  />
                )}
              />
              <Scatter
                dataKey="difference"
                shape={renderScatterDot}
                animationDuration={1000}
                fill={theme.palette.primary.light}
                stroke={theme.palette.primary.light}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <Button size="small" color="primary" onClick={handleDetailsClick}>
            <Trans i18nKey={`${I18N_KEY}.detailedView`}>Detailed view</Trans>
          </Button>
        </>
      ) : (
        <NoData height={150}>
          <Illustration />
        </NoData>
      )}
    </Container>
  )
}

export default StrengthGraph
