import { api } from '../utils/api'
import { MonthlyReport } from '../models/monthly-report'

export const getMonthlyReport = async (
  start: Date,
  end: Date,
  isPlayer: boolean,
  playerUuid?: string
): Promise<MonthlyReport> => {
  const endpoint = isPlayer
    ? `/performance/period/${start.getTime()}/${end.getTime()}`
    : `/performance/overview/${playerUuid}/period/${start.getTime()}/${end.getTime()}`
  const response = await api.get(endpoint)
  return response.data.data[0]
}
