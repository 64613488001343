import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import enTranslation from 'src/locales/en/translation.json'
import enEnums from 'src/locales/en/enums.json'
import zhTranslation from 'src/locales/zh/translation.json'
import zhEnums from 'src/locales/zh/enums.json'
import { DefaultLanguage } from './utils/constants'

const resources = {
  en: {
    translation: enTranslation,
    Enums: enEnums,
  },
  zh: {
    translation: zhTranslation,
    Enums: zhEnums,
  },
}

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: DefaultLanguage,
    fallbackLng: DefaultLanguage,
    debug: !(
      process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'production'
    ),

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
