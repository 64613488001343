import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import ExpandMore from '@material-ui/icons/ExpandMore'
import MUISelect, { SelectProps } from '@material-ui/core/Select'

import { Options } from 'src/components/inputs/FormSelect'

type Props = SelectProps & ExtraProps

interface ExtraProps {
  name: string
  options: Options
}

const Select: React.FC<Props> = ({ options, ...props }) => {
  return (
    <MUISelect
      IconComponent={ExpandMore}
      MenuProps={{
        elevation: 1,
        getContentAnchorEl: null,
      }}
      {...props}
    >
      {options.map(({ label, value }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </MUISelect>
  )
}

export default React.memo(Select)
