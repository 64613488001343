import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Control, FieldErrors } from 'react-hook-form'

import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentElement,
} from '@stripe/react-stripe-js'
import {
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useTheme } from '@material-ui/core'

import {
  SubscriptionHookedTextField,
  SubscriptionFieldLabel,
} from 'src/components/subscriptionFlow/common'

const I18N_KEY = 'CardForm'

interface StripeFieldProps {
  $isDark?: boolean
}
const StripeField = styled(Box)<StripeFieldProps>(
  ({ theme: { palette, spacing }, $isDark = true }) => css`
    .StripeElement {
      position: relative;
      &::before {
        border: 1px solid
          ${$isDark ? palette.background.black : palette.background.grey};
        border-radius: ${spacing(0.75)}px;
        content: ' ';
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
        position: absolute;
      }
      background-color: ${$isDark
        ? palette.background.black
        : palette.background.paper} !important;
      border-radius: ${spacing(0.75)}px;
      color: ${$isDark ? palette.text.white : palette.text.primary};
      font-size: ${spacing(2)}px;
      padding: ${spacing(2.3125, 1.75)};
      &:hover::before {
        border-color: ${palette.background.black};
      }
      &.StripeElement--focus::before {
        border-color: ${palette.primary.main};
        border-width: 2px;
      }
      &.StripeElement--invalid::before {
        border-color: ${palette.error.main};
      }
    }
  `
)

const PaymentElementContainer = styled.div`
  margin-top: 24px;
`

export enum CardFormTheme {
  Light,
  Dark,
}

interface Props {
  control: Control
  errors?: FieldErrors
  handleChange: (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => void
  theme?: CardFormTheme
  showPostCode?: boolean
  stripeUpgrade?: boolean
}

const CardForm: React.FC<Props> = ({
  control,
  errors,
  handleChange,
  theme = CardFormTheme.Dark,
  showPostCode,
  stripeUpgrade,
}) => {
  const { t } = useTranslation()
  const muiTheme = useTheme()
  const { palette } = muiTheme
  const isDark = theme === CardFormTheme.Dark

  const stripeElementStyle = useMemo(
    () => ({
      base: {
        backgroundColor: isDark
          ? palette.background.black
          : palette.background.paper,
        color: isDark ? palette.text.white : palette.text.primary,
        fontSize: `${muiTheme.spacing(2)}px`,
        '::placeholder': {
          color: palette.text.secondary,
        },
        ':-webkit-autofill': {
          backgroundColor: isDark
            ? palette.background.black
            : palette.background.paper,
          color: isDark ? palette.text.white : palette.text.primary,
        },
      },
      invalid: {
        color: palette.error.main,
      },
    }),
    [
      isDark,
      muiTheme,
      palette.background.black,
      palette.background.paper,
      palette.error.main,
      palette.text.primary,
      palette.text.secondary,
      palette.text.white,
    ]
  )

  const options: StripePaymentElementOptions = {
    fields: {
      billingDetails: {
        address: {
          country: 'never',
          postalCode: 'never',
        },
      },
    },
  }

  return (
    <>
      {stripeUpgrade ? (
        <PaymentElementContainer>
          <PaymentElement id="payment" options={options} />
        </PaymentElementContainer>
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SubscriptionFieldLabel
              $isDark={isDark}
              htmlFor="card-form-name-on-card"
            >
              <Trans i18nKey={`${I18N_KEY}.fullNameFieldLabel`}>
                Name on card
              </Trans>
            </SubscriptionFieldLabel>
            <SubscriptionHookedTextField
              name="nameOnCard"
              id="card-form-name-on-card"
              fullWidth
              control={control}
              errors={errors}
              placeholder={t(`${I18N_KEY}.fullNamePlaceholder`, 'Enter name')}
              $isDark={isDark}
            />
          </Grid>
          <Grid item xs={12}>
            <SubscriptionFieldLabel
              $isDark={isDark}
              htmlFor="card-form-card-number"
            >
              <Trans i18nKey={`${I18N_KEY}.cardNumberFieldLabel`}>
                Card Number
              </Trans>
            </SubscriptionFieldLabel>
            <StripeField $isDark={isDark}>
              <CardNumberElement
                id="card-form-card-number"
                options={{
                  style: stripeElementStyle,
                  showIcon: false,
                  placeholder: 'XXXX XXXX XXXX XXXX',
                }}
                onChange={handleChange}
              />
            </StripeField>
          </Grid>
          <Grid item xs={6}>
            <SubscriptionFieldLabel
              $isDark={isDark}
              htmlFor="card-form-card-expiry"
            >
              <Trans i18nKey={`${I18N_KEY}.cardExpiryFieldLabel`}>Expiry</Trans>
            </SubscriptionFieldLabel>
            <StripeField $isDark={isDark}>
              <CardExpiryElement
                id="card-form-card-expiry"
                options={{
                  style: stripeElementStyle,
                }}
                onChange={handleChange}
              />
            </StripeField>
          </Grid>
          <Grid item xs={6}>
            <SubscriptionFieldLabel
              $isDark={isDark}
              htmlFor="card-form-card-cvc"
            >
              <Trans i18nKey={`${I18N_KEY}.cardCvcFieldLabel`}>CVC</Trans>
            </SubscriptionFieldLabel>
            <StripeField $isDark={isDark}>
              <CardCvcElement
                id="card-form-card-cvc"
                options={{
                  style: stripeElementStyle,
                }}
                onChange={handleChange}
              />
            </StripeField>
          </Grid>
          {showPostCode && (
            <Grid item xs={6}>
              <SubscriptionFieldLabel
                $isDark={isDark}
                htmlFor="card-form-post-code"
              >
                <Trans i18nKey={`${I18N_KEY}.postCodeFieldLabel`}>
                  Post/ZIP code
                </Trans>
              </SubscriptionFieldLabel>
              <SubscriptionHookedTextField
                name="postcode"
                id="card-form-post-code"
                control={control}
                errors={errors}
                $isDark={isDark}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}

export default CardForm
