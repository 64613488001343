import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'

import { HeadingContainer } from 'src/components/subscriptionFlow/payment/common'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import ShotsDistribution, {
  PieDataFormat,
} from 'src/modules/player-dash/shotsDistribution'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import {
  PracticeTimeSpent,
  ShotDistributionColorsArray,
  TimeSpentItem,
} from 'src/utils/golfConstants'

import {
  practiceSummaryStrokesGainedSelector,
  practiceTimeSpentLoadingSelector,
  practiceTimeSpentSelector,
} from 'src/store/practiceSlice'
import ErrorCard from 'src/components/dataDisplay/ErrorCard'

const I18N_KEY = 'PracticeDistribution'

const PracticeDistribution: React.FC = () => {
  const { t } = useTranslation()
  const timeSpent = useSelector(practiceTimeSpentSelector)
  const timeSpentLoading = useSelector(practiceTimeSpentLoadingSelector)
  const strokesGained = useSelector(practiceSummaryStrokesGainedSelector)

  const sortStrokesGained = (timeSpent: PracticeTimeSpent) => {
    return Object.fromEntries(
      Object.entries(timeSpent.aggregate).sort(([key1], [key2]) => {
        const order = ['drives', 'approach', 'short', 'putt']
        return order.indexOf(key1) - order.indexOf(key2)
      })
    )
  }

  const getShotsDistributionChartData = (): PieDataFormat[] => {
    const elements: PieDataFormat[] = []
    // if (metricIdForCategory === MetricId.All) {
    if (timeSpent?.aggregate) {
      const sortedAggregate = sortStrokesGained(timeSpent)
      for (const [key, value] of Object.entries(sortedAggregate)) {
        elements.push({
          name: t(`Enums:CategoryType.${key}`),
          value: value.percentage,
        })
      }
    }
    // } else {
    //   if (timeSpent?.breakdown) {
    //     for (const [key, value] of Object.entries(timeSpent.breakdown)) {
    //       if (key === selectedCategory) {
    //         for (const breakdown of value) {
    //           const name = t(`Enums:MetricLabels.${breakdown.metricId ?? key}`)
    //           elements.push({
    //             name: name.includes(':') ? name.split(':')[1].trim() : name,
    //             value: breakdown.percentage,
    //           })
    //         }
    //       }
    //     }
    //   }
    // }
    return elements
  }

  const getShotsDistributionItems = (): TimeSpentItem[] => {
    const items: TimeSpentItem[] = []
    // if (metricIdForCategory === MetricId.All) {
    if (timeSpent?.aggregate) {
      const sortedAggregate = sortStrokesGained(timeSpent)
      for (const [key, value] of Object.entries(sortedAggregate)) {
        const strokesGainedForCategory =
          strokesGained[key as keyof typeof strokesGained]
        items.push({
          name: t(`Enums:CategoryType.${key}`),
          percentage: value.percentage,
          averagePerMonth: value.averagePerMonth,
          averagePercentageToGoal: value.averagePercentageToGoal,
          strokesGained: strokesGainedForCategory.overall,
        })
      }
    }
    // } else {
    //   if (timeSpent?.breakdown) {
    //     for (const [key, value] of Object.entries(timeSpent.breakdown)) {
    //       if (key === selectedCategory) {
    //         for (const breakdown of value) {
    //           const name = t(`Enums:MetricLabels.${breakdown.metricId ?? key}`)
    //           items.push({
    //             name: name.includes(':') ? name.split(':')[1].trim() : name,
    //             percentage: breakdown.percentage,
    //           })
    //         }
    //       }
    //     }
    //   }
    // }
    return items
  }

  const getShotsDistributionColorScheme = (): Map<string, string> => {
    const colors: Map<string, string> = new Map()
    let index = 0
    // if (metricIdForCategory === MetricId.All) {
    if (timeSpent?.aggregate) {
      const sortedAggregate = sortStrokesGained(timeSpent)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      for (const [key, value] of Object.entries(sortedAggregate)) {
        colors.set(
          t(`Enums:CategoryType.${key}`),
          ShotDistributionColorsArray[index]
        )
        index = index + 1
      }
    }
    // } else {
    //   if (timeSpent?.breakdown) {
    //     for (const [key, value] of Object.entries(timeSpent.breakdown)) {
    //       if (key === selectedCategory) {
    //         for (const breakdown of value) {
    //           const name = t(`Enums:MetricLabels.${breakdown.metricId ?? key}`)
    //           colors.set(
    //             name.includes(':') ? name.split(':')[1].trim() : name,
    //             ShotDistributionColorsArray[index]
    //           )
    //           index = index + 1
    //         }
    //       }
    //     }
    //   }
    // }
    return colors
  }

  return (
    <Box mb={8}>
      <HeadingContainer>
        <Box>
          <ReferencedTitle
            title={t(
              `${I18N_KEY}.practiceDistribution`,
              'Practice Distribution'
            )}
          />
        </Box>
      </HeadingContainer>
      {timeSpentLoading ? (
        <Skeleton variant="rect" width="100%" height={350} animation="wave" />
      ) : !timeSpent ? (
        <ErrorCard
          errorMessage={t(
            `${I18N_KEY}.noPracticeSessionsError`,
            'No practice sessions match your selected filters'
          )}
        />
      ) : (
        <ShotsDistribution
          data={getShotsDistributionChartData()}
          items={getShotsDistributionItems()}
          colors={getShotsDistributionColorScheme()}
          isPracticeDistribution
        />
      )}
    </Box>
  )
}

export default PracticeDistribution
