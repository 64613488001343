import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  getUserInfo,
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import history from 'src/utils/history'
import { Player } from 'src/models/player'
import { RootState } from 'src/store/rootReducer'
import { BenchmarkId } from 'src/utils/golfConstants'
import { Routes, Gender, UserType, PlayerType } from 'src/utils/constants'

export interface PlayerState extends Player {
  inProgress: boolean
  benchmarkData: BenchmarkData
}

interface BenchmarkData {
  list: Benchmark[]
  inProgress: boolean
}

interface Benchmark {
  name: string
  rank: number
  gender: Gender
  category: string
  playerType: PlayerType
  benchmarkId: BenchmarkId
}

export type UpdatePlayerRequest = Pick<
  Player,
  'dob' | 'lastName' | 'firstName' | 'unit' | 'gender' | 'playerType'
>

const benchmarkFilter: Record<string, Record<string, string[]>> = {
  amateur: {
    male: ['B01', 'B15', 'B17', 'B20', 'B23'],
    female: ['B08', 'B16', 'B26', 'B29', 'B32'],
  },
  professional: {
    male: ['B02', 'B03', 'B04', 'B05', 'B06'],
    female: ['B09', 'B10', 'B11', 'B12', 'B13'],
  },
}

const initialState: PlayerState = {
  uuid: '',
  userUuid: '',
  dob: null,
  coach: [],
  unit: null,
  gender: null,
  lastName: '',
  firstName: '',
  playerType: null,
  inProgress: false,
  profileImageUrl: null,
  completedProfile: false,
  benchmarkSelected: false,
  benchmarkId: BenchmarkId.MaleScratch,
  subscription: {
    planType: null,
    status: null,
    details: {
      rounds: null,
    },
  },
  benchmarkData: { list: [], inProgress: false },
}

const { actions, reducer } = createSlice({
  name: 'player',
  initialState,
  reducers: {
    updateProgress: (
      state,
      action: PayloadAction<Pick<PlayerState, 'inProgress'>>
    ) => {
      state.inProgress = action.payload.inProgress
    },
    updatePlayerUuid: (state, action: PayloadAction<Pick<Player, 'uuid'>>) => {
      state.uuid = action.payload.uuid
    },
    updatePlayer: (state, action: PayloadAction<Player>) => ({
      ...state,
      ...action.payload,
      inProgress: false,
    }),
    updatePlayerProfileImage: (
      state,
      action: PayloadAction<{ profileImageUrl: string | null }>
    ) => {
      state.profileImageUrl = action.payload.profileImageUrl
    },
    updateBenchmarkData: (state, action: PayloadAction<BenchmarkData>) => {
      state.benchmarkData = action.payload
    },
    reinitialisePlayer: () => initialState,
  },
})

export default reducer
export const {
  updatePlayer,
  updateProgress,
  updatePlayerUuid,
  reinitialisePlayer,
  updateBenchmarkData,
  updatePlayerProfileImage,
} = actions

// Selectors
export const playerSelector = (state: RootState): PlayerState => state.player

export const playerNameSelector = createSelector(
  playerSelector,
  ({ lastName, firstName }) => ({ lastName, firstName })
)

export const userTypeInfoSelector = createSelector(
  (state: RootState) => state.user,
  playerSelector,
  ({ metadata }, { completedProfile }) => ({
    completedProfile,
    userType: metadata?.userType as UserType,
  })
)

export const unitSelector = createSelector(
  playerSelector,
  player => player.unit
)

export const playerInfoSelector = createSelector(
  playerSelector,
  ({ unit, gender, playerType }) => ({ unit, gender, playerType })
)

export const playerSubsriptionSelector = createSelector(
  playerSelector,
  ({ subscription }) => subscription
)

export const benchmarkDataSelector = createSelector(
  playerSelector,
  player => player.benchmarkData
)

export const benchmarkIdSelector = createSelector(
  playerSelector,
  player => player.benchmarkId
)

// Action Creators
export const getPlayer =
  (playerUuid?: string): AppThunk =>
  async dispatch => {
    // Having playerUuid means we are in Coach View
    const endpoint = playerUuid ? `overview/player/${playerUuid}` : 'player'

    try {
      const response = await api.get(endpoint)
      const player: Player = getSinglePayloadFromResponse(response)

      dispatch(updatePlayer(player))
    } catch (error: any) {
      dispatch(updateProgress({ inProgress: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
        return
      }

      // In Coach view navigate to 404 route when we cannot get player
      if (playerUuid) {
        history.push(Routes.NotFound)
      }
    }
  }

export const updatePlayerProfile =
  (user: UpdatePlayerRequest): AppThunk =>
  async dispatch => {
    dispatch(updateProgress({ inProgress: true }))

    try {
      const response = await api.put('player', user)
      const player: Player = getSinglePayloadFromResponse(response)

      dispatch(updatePlayer(player))
      return player
    } catch (error: any) {
      dispatch(updateProgress({ inProgress: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Unable to update profile')
    }
  }

export const setPlayerProfileImage =
  (file: File): AppThunk =>
  async dispatch => {
    const data = new FormData()
    data.append('file', file)
    try {
      const response = await api.post('player/image', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      const profileImageUrl =
        getSinglePayloadFromResponse(response).profileImageUrl

      dispatch(updatePlayerProfileImage({ profileImageUrl }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error('Unable to upload image')
      }
    }
  }

export const deletePlayerProfileImage = (): AppThunk => async dispatch => {
  try {
    await api.delete('player/image')

    dispatch(updatePlayerProfileImage({ profileImageUrl: null }))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    } else {
      throw new Error('Unable to delete image')
    }
  }
}

export const updatePlayerBenchmark =
  (benchmarkId: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateProgress({ inProgress: true }))

    const { isPlayer, playerUuid } = getUserInfo(getState())
    const endpoint = isPlayer
      ? 'player/benchmark'
      : `/overview/player/${playerUuid}/benchmark`

    try {
      const response = await api.put(endpoint, {
        benchmarkId,
      })
      const player: Player = getSinglePayloadFromResponse(response)

      dispatch(updatePlayer(player))
    } catch (error: any) {
      dispatch(updateProgress({ inProgress: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }

export const getBenchmarkList = (): AppThunk => async (dispatch, getState) => {
  const { list: currentList } = benchmarkDataSelector(getState())
  const { gender, playerType } = playerInfoSelector(getState())
  const benchmarkIdFilter =
    benchmarkFilter[`${playerType || 'amateur'}`][`${gender || 'male'}`]
  dispatch(updateBenchmarkData({ inProgress: true, list: currentList }))

  try {
    const response = await api.get(
      `benchmark/list?options={filters:{gender:$eq_${gender}}}`
    )
    const list = getPayloadFromResponse(response).filter(
      (benchmark: any) =>
        benchmarkIdFilter.length === 0 ||
        benchmarkIdFilter.includes(benchmark.benchmarkId)
    )

    dispatch(updateBenchmarkData({ list, inProgress: false }))
  } catch (error: any) {
    dispatch(updateBenchmarkData({ inProgress: false, list: currentList }))

    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}
