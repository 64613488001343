import React, { useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import Typography from '@material-ui/core/Typography'

import {
  textualSummarySelector,
  strengthOpportunitySelector,
} from 'src/store/summarySlice'
import useRoundFilters from 'src/hooks/useRoundFilters'
import { getAbsolutelyFixedNumber } from 'src/utils/helpers'
import { TranslationContext } from 'src/utils/TranslationContext'
import { TimeFilter, DECIMAL_PLACES, DateFormats } from 'src/utils/constants'
import PerformanceCards from 'src/modules/player-dash/performance/PerformanceCards'

const I18N_KEY = 'DashboardTextSummary'

interface Props {
  loading: boolean
  timeFilter: TimeFilter
}

const Container = styled.div(
  ({ theme }) => css`
    margin: 0;
    width: 65%;
    max-width: 700px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `
)

const WhiteText = styled(Typography)`
  z-index: 2;
  color: #fff;
`

const SGDisplay = styled.span<{ $isImproving: boolean }>(
  ({ theme, $isImproving }) => css`
    color: ${$isImproving
      ? theme.palette.success.main
      : theme.palette.error.main};
  `
)

const TextSummary: React.FC<Props> = ({ loading, timeFilter }) => {
  const { t } = useTranslation()
  const { timeFilterOptions } = useRoundFilters()
  const { format, getBenchmarkName } = useContext(TranslationContext)!
  const { date, progress, strokesGained, benchmarkDetails } = useSelector(
    textualSummarySelector
  )
  const strengthOpportunity = useSelector(strengthOpportunitySelector)

  const isImproving = progress! > 0
  const progressAdjective = isImproving
    ? t(`${I18N_KEY}.improved`, 'improved')
    : t(`${I18N_KEY}.declined`, 'declined')
  const formattedDate =
    !loading && date && format(new Date(date), DateFormats.MonthDay)

  const below = benchmarkDetails?.below
  const current = benchmarkDetails?.current
  const belowName = getBenchmarkName(below?.id)
  const currentName = getBenchmarkName(current?.id)
  const belowFormatted = below?.value.toFixed(DECIMAL_PLACES)
  const currentFormatted = current?.value.toFixed(DECIMAL_PLACES)

  const progressIcon = isImproving ? '▴' : '▾'
  const progressFormatted = getAbsolutelyFixedNumber(progress)
  const strokesGainedFormatted = strokesGained?.toFixed(DECIMAL_PLACES)

  const timePeriod = useMemo(
    () =>
      timeFilterOptions
        .find(filter => filter.value === timeFilter)
        ?.label.toLowerCase(),
    [timeFilter, timeFilterOptions]
  )

  const getDescriptionText = useMemo(() => {
    if ((strokesGained as number) > (current?.value as number)) {
      return (
        <Trans i18nKey={`${I18N_KEY}.performingAbove`}>
          This means you are currently performing above {{ currentName }} (
          {{ currentFormatted }}).
        </Trans>
      )
    } else if ((strokesGained as number) < (below?.value as number)) {
      return (
        <Trans i18nKey={`${I18N_KEY}.performingBelow`}>
          This means you are currently performing below {{ belowName }} (
          {{ belowFormatted }}).
        </Trans>
      )
    } else {
      return (
        <Trans i18nKey={`${I18N_KEY}.performingBetween`}>
          This means you are currently performing between {{ belowName }} (
          {{ belowFormatted }}) and {{ currentName }} ({{ currentFormatted }}).
        </Trans>
      )
    }
  }, [
    below,
    current,
    belowName,
    currentName,
    strokesGained,
    belowFormatted,
    currentFormatted,
  ])

  return (
    <Container>
      <WhiteText variant="h3">
        <Trans i18nKey={`${I18N_KEY}.summary`}>
          For the last {{ timePeriod }} you averaged{' '}
          {{ strokesGainedFormatted }}SG per round. Since {{ formattedDate }}{' '}
          you {{ progressAdjective }} by{' '}
          <SGDisplay $isImproving={isImproving}>
            {{ progressIcon }}
            {{ progressFormatted }}SG
          </SGDisplay>
          .
        </Trans>
      </WhiteText>
      <WhiteText>{getDescriptionText}</WhiteText>
      <PerformanceCards {...strengthOpportunity} />
    </Container>
  )
}

export default TextSummary
