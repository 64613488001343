import React from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import { Control, FieldErrors } from 'react-hook-form'
import Typography from '@material-ui/core/Typography'

import { Label } from 'src/components/inputs/FormTextField'
import { FormValues } from 'src/modules/auth/ForgotPassword'
import LoaderButton from 'src/components/inputs/LoaderButton'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import { Form, Container, TitleContainer } from 'src/modules/auth/common'

const I18N_KEY = 'ForgotPassword'

interface Props {
  control: Control
  isDirty: boolean
  isValid: boolean
  errors: FieldErrors<FormValues>
  isSubmitting: boolean
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
}

const SubmitButton = styled(LoaderButton)(
  ({ theme }) => css`
    margin: ${theme.spacing(4, 0, 0)};
  `
)

const ForgotPasswordForm: React.FC<Props> = ({
  errors,
  control,
  isDirty,
  isValid,
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation()

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transitionDuration: '0.4s' }}
    >
      <TitleContainer>
        <Typography variant="h1" align="center">
          <Trans i18nKey={`${I18N_KEY}.title`}>Forgot password</Trans>
        </Typography>
      </TitleContainer>
      <Box mb={5} textAlign="center">
        <Trans i18nKey={`${I18N_KEY}.description`}>
          Enter the email you use to sign in.
          <br />
          We’ll send you a link to reset your password
        </Trans>
      </Box>
      <Form onSubmit={onSubmit}>
        <Label variant="caption">{t(`${I18N_KEY}.emailLabel`, 'Email')}</Label>
        <HookFormTextField
          fullWidth
          errors={errors}
          control={control}
          name="email"
          placeholder={t(`${I18N_KEY}.emailPlaceholder`, 'Enter email')}
        />
        <SubmitButton
          fullWidth
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid || !isDirty}
        >
          <Trans i18nKey={`${I18N_KEY}.forgotButtonLabel`}>
            Reset Password
          </Trans>
        </SubmitButton>
      </Form>
    </Container>
  )
}

export default ForgotPasswordForm
