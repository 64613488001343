import { CategoryRoutes, Routes } from 'src/utils/constants'
import { MetricId, CategoryType } from 'src/utils/golfConstants'

export const generateCategoryPath = (path: string, playerUuid?: string) =>
  `${
    playerUuid ? `/player/${playerUuid}/categories` : Routes.Categories
  }${path}`

export const getCategories = (playerUuid?: string) => [
  {
    props: {
      isSubcategory: false,
      metricId: MetricId.Short,
      category: CategoryType.Short,
    },
    path: generateCategoryPath(CategoryRoutes.Short, playerUuid),
  },
  {
    props: {
      isSubcategory: false,
      metricId: MetricId.Drives,
      category: CategoryType.Drives,
    },
    path: generateCategoryPath(CategoryRoutes.Drives, playerUuid),
  },
  {
    props: {
      isSubcategory: false,
      metricId: MetricId.Approach,
      category: CategoryType.Approach,
    },
    path: generateCategoryPath(CategoryRoutes.Approach, playerUuid),
  },
  {
    props: {
      isSubcategory: false,
      metricId: MetricId.All,
      category: CategoryType.All,
    },
    path: generateCategoryPath(CategoryRoutes.All, playerUuid),
  },
  {
    props: {
      isSubcategory: false,
      metricId: MetricId.Putt,
      category: CategoryType.Putt,
    },
    path: generateCategoryPath(CategoryRoutes.Putt, playerUuid),
  },
]

export const getSubcategories = (playerUuid?: string) => [
  // Putting
  {
    props: {
      category: CategoryType.Putt,
      metricId: MetricId.PuttUnder6,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Putt}/${MetricId.PuttUnder6}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Putt,
      metricId: MetricId.Putt7To21,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Putt}/${MetricId.Putt7To21}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Putt,
      metricId: MetricId.PuttOver21,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Putt}/${MetricId.PuttOver21}`,
      playerUuid
    ),
  },
  // Approaches
  {
    props: {
      category: CategoryType.Approach,
      metricId: MetricId.Approach101To150,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Approach}/${MetricId.Approach101To150}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Approach,
      metricId: MetricId.Approach151To200,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Approach}/${MetricId.Approach151To200}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Approach,
      metricId: MetricId.Approach201To250,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Approach}/${MetricId.Approach201To250}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Approach,
      metricId: MetricId.ApproachOver250,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Approach}/${MetricId.ApproachOver250}`,
      playerUuid
    ),
  },
  // Drives
  {
    props: {
      category: CategoryType.Drives,
      metricId: MetricId.DrivesPar4,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Drives}/${MetricId.DrivesPar4}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Drives,
      metricId: MetricId.DrivesPar5,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Drives}/${MetricId.DrivesPar5}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Drives,
      metricId: MetricId.Par4LayupStrokesGained,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Drives}/${MetricId.Par4LayupStrokesGained}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Drives,
      metricId: MetricId.Par4DriverStrokesGained,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Drives}/${MetricId.Par4DriverStrokesGained}`,
      playerUuid
    ),
  },
  // Short game
  {
    props: {
      category: CategoryType.Short,
      metricId: MetricId.ShortUnder20,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Short}/${MetricId.ShortUnder20}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Short,
      metricId: MetricId.Short21To60,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Short}/${MetricId.Short21To60}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Short,
      metricId: MetricId.Short61To100,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Short}/${MetricId.Short61To100}`,
      playerUuid
    ),
  },
  {
    props: {
      category: CategoryType.Short,
      metricId: MetricId.ShortBunkers,
    },
    path: generateCategoryPath(
      `${CategoryRoutes.Short}/${MetricId.ShortBunkers}`,
      playerUuid
    ),
  },
]
