// Based on: https://github.com/vlaja/multilanguage-routing-react/blob/master/src/modules/i18n/components/LocalizedSwitch.tsx
import React from 'react'
import { Switch, RouteProps } from 'react-router'

import { useTranslation } from 'react-i18next'
import { Languages } from 'src/utils/constants'
import { localizeRoutePath } from 'src/utils/i18n'

export const LocalizedSwitch: React.FC = ({ children }) => {
  const { i18n } = useTranslation()

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, child =>
        React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path:
                child.props.path &&
                localizeRoutePath(child.props.path, i18n.language as Languages),
            })
          : child
      )}
    </Switch>
  )
}

export default LocalizedSwitch
