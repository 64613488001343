import { useDispatch, useSelector } from 'react-redux'

import { setAuthTokenHeader } from 'src/utils/api'
import {
  authTokensSelector,
  requestRefreshSession,
  isLoggedInSelector,
  refreshStatusSelector,
} from 'src/store/authSlice'

const useLogin = () => {
  const dispatch = useDispatch()

  const isLoggedIn = useSelector(isLoggedInSelector)
  const refreshStatus = useSelector(refreshStatusSelector)
  const { refreshToken, token } = useSelector(authTokensSelector)

  const refreshSession = async () => {
    if (token && refreshToken) {
      setAuthTokenHeader(token)
      await dispatch(requestRefreshSession(refreshToken))
    }
  }

  return {
    isLoggedIn,
    refreshStatus,
    refreshSession,
  }
}

export default useLogin
