import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import { TooltipProps } from 'recharts'

import { Box, Typography } from '@material-ui/core'

import {
  DashIcon,
  CircleIcon,
  ScratchIcon,
  TooltipText,
  TooltipTitle,
  TooltipContainer,
} from 'src/components/graph/common'
import { roundTo2DP } from 'src/utils/helpers'
import { DateFormats } from 'src/utils/constants'
import { TranslationContext } from 'src/utils/TranslationContext'

const I18N_KEY = 'ScoreTooltip'

interface TooltipContentProps extends TooltipProps<number, string> {
  referencePoint: string
}

const TooltipContent: React.FC<TooltipContentProps> = ({
  payload,
  referencePoint,
}) => {
  const { format } = useContext(TranslationContext)!

  const data = payload && payload[0]?.payload

  return (
    <TooltipContainer>
      <Box px={2}>
        <TooltipTitle>{data?.roundTitle}</TooltipTitle>
        <Typography variant="caption" component="p" color="textSecondary">
          {data?.datePlayed && format(data?.datePlayed, DateFormats.Tooltip)}
        </Typography>
      </Box>
      <Box
        px={2}
        pt={1}
        mt={1}
        display="flex"
        borderTop="1px solid"
        borderColor="#282828"
        justifyContent="space-between"
      >
        <TooltipText variant="h6">
          <CircleIcon />
          <Trans i18nKey={`${I18N_KEY}.thisRound`}>This round</Trans>
        </TooltipText>
        <TooltipText $isValue>{data?.toPar}</TooltipText>
      </Box>
      <Box px={2} display="flex" justifyContent="space-between">
        <TooltipText>
          <DashIcon />
          <Trans i18nKey={`${I18N_KEY}.rollingAverage`}>Rolling Average</Trans>
        </TooltipText>
        <TooltipText $isValue>
          {data?.rollingAverage && roundTo2DP(data.rollingAverage)}
        </TooltipText>
      </Box>
      <Box px={2} display="flex" justifyContent="space-between">
        <TooltipText variant="h6">
          <ScratchIcon />
          <Trans i18nKey={`${I18N_KEY}.prediction`}>{referencePoint}</Trans>
        </TooltipText>
        <TooltipText $isValue>0</TooltipText>
      </Box>
    </TooltipContainer>
  )
}

export default TooltipContent
