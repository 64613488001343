import { gatewayApi } from '../utils/api'

const IGOLF_API = 'igolf-api'

export interface iGolfCourse {
  courseName: string
  // eslint-disable-next-line camelcase
  id_course: string
  city: string
  stateShort: string
}

export interface iGolfTee {
  teeName: string
  teeColorName: string
  yds1to9: string
  yds10to18: string
  yds1to18: string
  ydsTotal: number
  ydsHole: number[]
  gender: string
}

export interface Course {
  courseName: string
  courseId: string
  city: string
  state: string
}

export interface Tee {
  name: string
  gender: string
  color: string
  yds1to9: string
  yds10to18: string
  yds1to18: string
  ydsTotal: number
}
export const getCoursesList = async (courseName: string): Promise<Course[]> => {
  const gatewayResponse = await gatewayApi.post(`${IGOLF_API}/CourseList`, {
    courseName,
    active: 1,
  })
  return gatewayResponse.data.courseList.map((course: iGolfCourse) => {
    return {
      courseName: course.courseName,
      courseId: course.id_course,
      city: course.city,
      state: course.stateShort,
    }
  })
}

export const getTeeList = async (courseId: string): Promise<Tee[]> => {
  try {
    const gatewayResponse = await gatewayApi.post(
      `${IGOLF_API}/CourseTeeDetails`,
      {
        id_course: courseId,
        detailLevel: 2,
      }
    )
    return gatewayResponse.data.teesList.map((tee: iGolfTee) => {
      return {
        name: tee.teeName,
        gender: tee.gender,
        color: tee.teeColorName,
        yds1to9: tee.yds1to9,
        yds10to18: tee.yds10to18,
        yds1to18: tee.yds1to18,
        ydsTotal: tee.ydsTotal,
      }
    })
  } catch (error) {
    throw new Error(`Error on retrieving tees for course id: ${courseId}.`)
  }
}
