import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  getErrorStatus,
  authRequestFail,
  getPayloadFromResponse,
  isForbiddenOrUnauthorised,
} from 'src/utils/helpers'

import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { getBasicReducers } from 'src/utils/store'

import { State } from 'typings/store'
import { Plan } from 'src/models/plan'
import { RootState } from 'src/store/rootReducer'

const SLICE_NAME = 'plans'

type PlansState = State<Plan[]>

const initialState: PlansState = {
  data: null,
  requestInProgress: false,
  loaded: false,
  error: null,
}

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...getBasicReducers<Plan[]>(initialState),
    loaded: (state: PlansState, action: PayloadAction<Plan[]>) => {
      state.data = action.payload
      state.error = null
      state.requestInProgress = false
      state.loaded = true
    },
  },
})

export default reducer

// Actions
export const {
  loaded,
  reinitialise,
  requestInProgress,
  requestFinished,
  requestError,
} = actions

// Selectors

export const plansState = (state: RootState) => state[SLICE_NAME]

export const plansSelector = createSelector(
  plansState,
  (state: PlansState): Plan[] | null => state.data
)

export const requestIsInProgress = createSelector(
  plansState,
  (state: PlansState): boolean => state.requestInProgress
)

export const getError = createSelector(
  plansState,
  (state: PlansState): string | null => state.error
)

export const hasError = createSelector(
  plansState,
  (state: PlansState): boolean => !!state.error
)

export const getPlans = (): AppThunk => async dispatch => {
  dispatch(requestInProgress())
  try {
    const response = await api.get('subscription/plan')
    const plans: Plan[] = getPayloadFromResponse(response) as Plan[]
    dispatch(loaded(plans))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
    dispatch(requestError(getErrorStatus(error)))
  }
}
