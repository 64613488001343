import React from 'react'

const GolfBallLogo: React.FC = props => (
  <svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#fff" clipPath="url(#clip0)">
      <path d="M14.67 8.284a3.208 3.208 0 10-5.341-3.553 3.208 3.208 0 005.34 3.553zM20.162 13.777a3.207 3.207 0 10-5.341-3.553 3.207 3.207 0 005.341 3.553zM9.18 13.775a3.207 3.207 0 10-5.341-3.553 3.207 3.207 0 005.34 3.553zM14.67 19.267a3.207 3.207 0 10-5.34-3.554 3.207 3.207 0 005.34 3.554zM18.187 2.99A1.924 1.924 0 1014.982.86a1.924 1.924 0 003.205 2.132zM23.678 8.482a1.925 1.925 0 10-3.205-2.132 1.925 1.925 0 003.205 2.132zM9.018 2.99A1.924 1.924 0 105.814.858 1.924 1.924 0 009.018 2.99zM3.527 8.481A1.924 1.924 0 10.322 6.349a1.924 1.924 0 003.205 2.132zM23.678 17.65a1.924 1.924 0 10-3.205-2.132 1.924 1.924 0 003.205 2.132zM18.187 23.14a1.924 1.924 0 10-3.205-2.131 1.924 1.924 0 003.205 2.131zM3.527 17.649a1.925 1.925 0 10-3.204-2.132 1.925 1.925 0 003.204 2.132zM9.019 23.14a1.925 1.925 0 10-3.205-2.132 1.925 1.925 0 003.205 2.132z" />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0H24V24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default React.memo(GolfBallLogo)
