import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiCircularProgress from '@material-ui/core/CircularProgress'

import { ReactComponent as LoadingLogo } from 'src/assets/images/loadingLogo.svg'

const I18N_KEY = 'SessionLoading'

const Container = styled.div(
  ({ theme }) => css`
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: ${theme.palette.background.black};
  `
)

const WhiteText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};
  `
)

const CircularProgress = styled(MuiCircularProgress)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(1)}px;

    .MuiCircularProgress-svg {
      color: ${theme.palette.background.paper};
    }
  `
)

const LoadingSession: React.FC = () => (
  <Container>
    <LoadingLogo />
    <Box display="flex" mt={3}>
      <WhiteText variant="h5">
        <Trans i18nKey={`${I18N_KEY}.loading`}>Loading</Trans>
      </WhiteText>
      <CircularProgress size={20} thickness={5} />
    </Box>
  </Container>
)

export default LoadingSession
