import React, { FunctionComponent, ReactNode } from 'react'
import { motion } from 'framer-motion'
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'

import { spring } from 'src/utils/animations'
import { breakpoints } from 'src/utils/constants'
interface Props {
  showLine?: boolean
  children: ReactNode
  icon: StyledComponent<FunctionComponent<any>, DefaultTheme, {}, never>
}

const ListItem = styled(motion.li)(
  ({ theme }) => css`
    width: 100%;
    list-style: none;
    position: relative;
    margin: ${theme.spacing(0.5, 0)};
  `
)

const LeftLine = styled.div(
  ({ theme }) => css`
    top: 46px;
    width: 1px;
    left: -28px;
    height: 40px;
    position: absolute;
    background-color: ${theme.palette.background.light};
    ${theme.breakpoints.down(breakpoints.mobile)} {
      left: -14px;
    }
  `
)

const FixedStep: React.FC<Props> = ({
  children,
  icon: Icon,
  showLine = true,
  ...props
}) => (
  <ListItem {...props} layout transition={spring}>
    <Icon />
    {showLine && <LeftLine />}
    {children}
  </ListItem>
)

export default React.memo(FixedStep)
