import React from 'react'

import styled, { css } from 'styled-components'

import { TickProps } from 'src/utils/chartTypes'
import { DECIMAL_PLACES } from 'src/utils/constants'

const AxisLabel = styled.text(
  ({ theme }) => css`
    font-weight: 500;
    text-transform: uppercase;
    fill: ${theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(12)};
    line-height: ${theme.typography.pxToRem(16)};
    letter-spacing: ${theme.typography.pxToRem(0.3)};
  `
)

export const renderAxisTicks = ({
  x,
  y,
  payload,
  textAnchor,
}: TickProps<number>) => (
  <AxisLabel x={x + 4} y={y} dy="0.35em" textAnchor={textAnchor}>
    {payload.value.toFixed(DECIMAL_PLACES)}
  </AxisLabel>
)
