import React, { useMemo } from 'react'
import Box from '@material-ui/core/Box'
import { AnimateSharedLayout, motion } from 'framer-motion'
import {
  ActiveBar,
  SharedLayoutButton,
} from '../../components/layout/SharedLayoutComponents'
import {
  breakpoints,
  DateFormat,
  Languages,
  TimeFilter,
} from '../../utils/constants'
import { Field, Form, Formik, FormikConfig } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import { FormDatePicker } from '../../components/common'
import CalendarIcon from '../../assets/svgComponents/CalendarIcon'
import i18n from '../../i18n'
import LoaderButton from '../../components/inputs/LoaderButton'
import styled, { css } from 'styled-components'
import Select from '../../components/inputs/Select'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSummary,
  summaryFiltersSelector,
  updateFilters,
} from '../../store/summarySlice'
import useRoundFilters from '../../hooks/useRoundFilters'
import { CategoryType } from '../../utils/golfConstants'
import * as Yup from 'yup'
import { dateTransform } from '../../utils/helpers'
import { CustomTimeRange } from '../../components/inputs/CustomDatePicker'
import moment from 'moment/moment'
import Typography from '@material-ui/core/Typography'

const Filters = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin: ${theme.spacing(2, 0)};
  `
)

const FiltersContainer = styled.div(
  () => css`
    display: flex;
    justify-self: center;
  `
)

const FiltersSubContainer = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
  `
)

const StyledSelect = styled(Select)`
  font-weight: 500;

  &.MuiInputBase-root.Mui-disabled {
    background-color: initial;
  }

  .MuiSelect-select:focus {
    background-color: initial;
  }

  ::before,
  ::after {
    display: none;
  }
`

const FieldContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 0.25rem;

    .MuiFormControl-root {
      width: auto;
      margin-top: 0;
    }

    .MuiOutlinedInput-input {
      width: auto;
    }

    .MuiOutlinedInput-input {
      width: 6rem;
      padding: 1rem;
      color: ${theme.palette.common.black};
      text-align: right;

      ${theme.breakpoints.down('xs')} {
        width: 10.5rem;
        font-size: ${theme.typography.pxToRem(12)};
      }
    }
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    margin-left: 1rem;
    ${theme.breakpoints.down('xs')} {
      margin-left: 0.25rem;
      width: 100%;
    }

    .MuiButtonBase-root {
      width: 100px;

      ${theme.breakpoints.down('xs')} {
        width: 4rem;
        font-size: ${theme.typography.pxToRem(12)};
        padding: ${theme.spacing(0.5, 0)};
      }

      &:disabled {
        background-color: #eaf0ff;
        color: ${theme.palette.text.disabled};
      }
    }

    .MuiCircularProgress-root {
      color: ${theme.palette.primary.main};
    }
  `
)

const HeroText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.black};
    padding: 0 0.5rem 0 1rem;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      text-align: center;
    }

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(12)};
      margin: 0;
    }
  `
)

interface Props {
  category: CategoryType
  isLoading: boolean
  isDesktop: boolean
}

const I18N_KEY = 'CategoryHeader'

const CategoryFilter: React.FC<Props> = ({
  category,
  isLoading,
  isDesktop,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const filters = useSelector(summaryFiltersSelector)
  let customTimeFilters = null
  if (filters.customTimeRange != null) {
    customTimeFilters = JSON.parse(filters.customTimeRange)
  }

  const {
    typeFilter,
    timeFilter,
    whereFilter,
    typeFilterOptions,
    timeFilterOptions,
    whereFilterOptions,
    getTimeFilterClick,
    handleTypeSelection,
    handleTimeSelection,
    handleWhereSelection,
  } = useRoundFilters(category)

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        startDate: Yup.date()
          .transform(dateTransform)
          .required(
            t(
              `${I18N_KEY}.birthDateRequiredErrorMessage`,
              'Please enter a valid start date'
            )
          ),
        endDate: Yup.date()
          .transform(dateTransform)
          .required(
            t(
              `${I18N_KEY}.birthDateRequiredErrorMessage`,
              'Please enter a valid end date'
            )
          ),
      }),
    [t]
  )

  const customFormikOptions: FormikConfig<CustomTimeRange> = {
    initialValues: {
      startDate: customTimeFilters?.startDate || new Date(),
      endDate: customTimeFilters?.endDate || new Date(),
    },
    validationSchema,
    onSubmit: (values: CustomTimeRange) => {
      dispatch(
        updateFilters({
          customTimeRange: JSON.stringify({
            startDate: moment(values.startDate).startOf('day').toDate(),
            endDate: moment(values.endDate).endOf('day').toDate(),
          }),
        })
      )
      dispatch(getSummary())
      return Promise.resolve()
    },
  }

  return (
    <FiltersContainer>
      <FiltersSubContainer>
        <Filters>
          <Box mr={3}>
            {isDesktop && (
              <StyledSelect
                value={typeFilter}
                disabled={isLoading}
                name="round-type-filter"
                options={typeFilterOptions}
                onChange={handleTypeSelection}
              />
            )}
          </Box>
          <Box mr={3}>
            {isDesktop && (
              <StyledSelect
                value={whereFilter}
                disabled={isLoading}
                name="where-type-filter"
                options={whereFilterOptions}
                onChange={handleWhereSelection}
              />
            )}
          </Box>
          {isDesktop ? (
            <AnimateSharedLayout>
              {timeFilterOptions.map(({ label, value }, index) => {
                const isSelected = timeFilter === value

                return (
                  <motion.div
                    key={value}
                    animate={{ y: 0, opacity: 1, rotateX: 0 }}
                    initial={{ y: -15, opacity: 0, rotateX: -40 }}
                    transition={{ delay: 0.15 * index, duration: 0.3 }}
                  >
                    <SharedLayoutButton
                      name={value}
                      disabled={isLoading}
                      selected={isSelected}
                      onClick={getTimeFilterClick(value)}
                    >
                      {label}
                      {isSelected && (
                        <ActiveBar layoutId="time-filter-selection" />
                      )}
                    </SharedLayoutButton>
                  </motion.div>
                )
              })}
            </AnimateSharedLayout>
          ) : (
            isDesktop && (
              <StyledSelect
                value={timeFilter}
                disabled={isLoading}
                name="round-time-filter"
                options={timeFilterOptions}
                onChange={handleTimeSelection}
              />
            )
          )}
        </Filters>
        {isDesktop && timeFilter === TimeFilter.Custom && (
          <FieldContainer>
            <Formik {...customFormikOptions}>
              {({ isSubmitting }) => (
                <Form>
                  <FieldContainer>
                    <Trans i18nKey={`${I18N_KEY}.from`}>
                      <HeroText>From:</HeroText>
                    </Trans>
                    <Field
                      autoOk
                      fullWidth
                      helperText=""
                      name="startDate"
                      inputVariant="outlined"
                      disabled={isLoading}
                      component={FormDatePicker}
                      views={undefined}
                      openTo={undefined}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: isDesktop ? <CalendarIcon /> : null,
                      }}
                      format={DateFormat[i18n.language as Languages].datePicker}
                    />
                    <Trans i18nKey={`${I18N_KEY}.to`}>
                      <HeroText>To:</HeroText>
                    </Trans>
                    <Field
                      autoOk
                      fullWidth
                      helperText=""
                      name="endDate"
                      inputVariant="outlined"
                      disabled={isLoading}
                      component={FormDatePicker}
                      views={undefined}
                      openTo={undefined}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: isDesktop ? <CalendarIcon /> : null,
                      }}
                      format={DateFormat[i18n.language as Languages].datePicker}
                    />
                    <ButtonContainer>
                      <LoaderButton
                        type="submit"
                        color="primary"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={isLoading}
                      >
                        <Trans i18nKey={`${I18N_KEY}.apply`}>Apply</Trans>
                      </LoaderButton>
                    </ButtonContainer>
                  </FieldContainer>
                </Form>
              )}
            </Formik>
          </FieldContainer>
        )}
      </FiltersSubContainer>
    </FiltersContainer>
  )
}

export default CategoryFilter
