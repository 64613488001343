import * as Yup from 'yup'
import { TFunction } from 'i18next'

import { dateTransform } from 'src/utils/helpers'
import { Gender } from 'src/utils/constants'

const I18N_KEY = 'ValidationSchemas'

export const createSignInSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    password: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
      ),
  })

export const createSignInProviderSchema = (t: TFunction) =>
  Yup.object().shape({
    providerCode: Yup.string().required(t(`${I18N_KEY}.providerCode`, '')),
  })

export const createRegisterSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    phoneNumber: Yup.string().required(
      t(`${I18N_KEY}.phoneNumberRequired`, 'Please enter a valid phone number')
    ),
    password: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
      ),
    confirmPassword: Yup.string()
      .required(
        t(`${I18N_KEY}.passwordConfirmRequired`, 'Please confirm the password')
      )
      .oneOf(
        [Yup.ref('password'), null],
        t(`${I18N_KEY}.passwordsMustMatch`, 'Passwords must match')
      ),
    firstName: Yup.string().required(
      t(
        `${I18N_KEY}.missingFirstNameErrorMessage`,
        'Please enter your first name'
      )
    ),
    lastName: Yup.string().required(
      t(
        `${I18N_KEY}.missingLastNameErrorMessage`,
        'Please enter your last name'
      )
    ),
    brokeredId: Yup.string().notRequired(),
    recaptchaToken: Yup.string().notRequired(),
  })

export const createForgotPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
  })

export const createResetPasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    newPassword: Yup.string()
      .required(t(`${I18N_KEY}.passwordRequired`, 'Please enter a password'))
      .min(
        8,
        t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters')
      ),
    passwordConfirm: Yup.string()
      .required(
        t(`${I18N_KEY}.passwordConfirmRequired`, 'Please confirm the password')
      )
      .oneOf(
        [Yup.ref('newPassword'), null],
        t(`${I18N_KEY}.passwordsMustMatch`, 'Passwords must match')
      ),
    token: Yup.string().required(
      t(
        `${I18N_KEY}.tokenRequired`,
        'Please enter a valid password reset token'
      )
    ),
  })

export const createProfileSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string().required(
      t(
        `${I18N_KEY}.missingFirstNameErrorMessage`,
        'Please enter your first name'
      )
    ),
    lastName: Yup.string().required(
      t(
        `${I18N_KEY}.missingLastNameErrorMessage`,
        'Please enter your last name'
      )
    ),
    gender: Yup.string().required(
      t(`${I18N_KEY}.missingGenderErrorMessage`, 'Please select your gender')
    ),
    dob: Yup.date()
      .transform(dateTransform)
      .required(
        t(
          `${I18N_KEY}.invalidBirthDateErrorMessage`,
          'Please enter a valid birth date'
        )
      ),
  })

export const createChangePasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    currentPassword: Yup.string().required(
      t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
    ),
    newPassword: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.newPasswordRequired`, 'Please enter your new password')
      ),
    confirmPassword: Yup.string()
      .required(
        t(`${I18N_KEY}.confirmPasswordRequired`, 'Please confirm your password')
      )
      .oneOf(
        [Yup.ref('newPassword')],
        t(`${I18N_KEY}.passwordMatch`, 'Passwords must match')
      ),
  })

export const createBillingAddressSchema = (t: TFunction) =>
  Yup.object().shape({
    fullName: Yup.string().required(
      t(
        `${I18N_KEY}.missingFirstNameErrorMessage`,
        'Please enter the first name'
      )
    ),
    address: Yup.string().required(
      t(`${I18N_KEY}.missingAddressErrorMessage`, 'Please enter the address')
    ),
    city: Yup.string().required(
      t(`${I18N_KEY}.missingCityErrorMessage`, 'Please enter the city')
    ),
    postcode: Yup.string().required(
      t(
        `${I18N_KEY}.missingPostcodeErrorMessage`,
        'Please enter the Post/ZIP code'
      )
    ),
  })

export const createPaymentSchema = (t: TFunction) =>
  createBillingAddressSchema(t).concat(
    Yup.object().shape({
      nameOnCard: Yup.string().required(
        t(`${I18N_KEY}.missingPaymentFullName`, 'Please enter your full name')
      ),
    })
  )

export const createCoachAuthSchema = (t: TFunction) =>
  Yup.object().shape({
    lastName: Yup.string().required(
      t(`${I18N_KEY}.lastNameRequired`, 'Enter your last name')
    ),
    firstName: Yup.string().required(
      t(`${I18N_KEY}.firstNameRequired`, 'Enter your first name')
    ),
    gender: Yup.mixed()
      .oneOf(Object.values(Gender))
      .required(t(`${I18N_KEY}.genderRequired`, 'Please select your gender')),
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter your email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    password: Yup.string()
      .min(8, t(`${I18N_KEY}.passwordLength`, 'Password must be 8+ characters'))
      .required(
        t(`${I18N_KEY}.passwordRequired`, 'Please enter your password')
      ),
  })

export const createInviteFormSchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string()
      .required(t(`${I18N_KEY}.emailRequired`, 'Please enter an email'))
      .email(t(`${I18N_KEY}.invalidEmail`, 'Must be a valid email')),
    recaptchaToken: Yup.string().notRequired(),
  })
