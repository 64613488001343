import { useElements, useStripe } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/payment'
import { api } from '../../../utils/api'
import { getSinglePayloadFromResponse } from '../../../utils/helpers'
import { SetupIntentResult } from '@stripe/stripe-js'
import {
  getErrorToast,
  getSuccessToast,
  openToast,
} from '../../../store/toastSlice'
import {
  billingAddress,
  getSubscriptionInfo,
} from '../../../store/subscriptionSlice'

interface StripeCardSetupProps {
  I18N_KEY: string
  dialogOpened: boolean
  handleClose: () => void
}

export const useStripeCardSetup = ({
  I18N_KEY,
  dialogOpened,
  handleClose,
}: StripeCardSetupProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)
  const [isPaymentValid, setIsPaymentValid] = useState(false)
  const billing = useSelector(billingAddress)

  useEffect(() => {
    if (elements && dialogOpened) {
      let paymentElement = elements.getElement('payment')
      if (!paymentElement) {
        setTimeout(() => {
          paymentElement = elements.getElement('payment')
          if (paymentElement) {
            paymentElement.on(
              'change',
              (event: StripePaymentElementChangeEvent) => {
                setIsPaymentValid(event.complete)
              }
            )
          }
        }, 1000)
      }
      if (paymentElement) {
        paymentElement.on(
          'change',
          (event: StripePaymentElementChangeEvent) => {
            setIsPaymentValid(event.complete)
          }
        )
      }
    }
  }, [elements, dialogOpened])

  const handleAddCard = async () => {
    if (submitting || !stripe || !elements) {
      // Stripe.js has not loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setSubmitting(true)

    if (!elements) {
      return
    }

    try {
      const response = await api.post('subscription/v2/setupIntent')

      const clientSecret = getSinglePayloadFromResponse(response)

      await elements.submit()

      const result: SetupIntentResult = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'https://drawmorecircles.com',
          payment_method_data: {
            billing_details: {
              address: {
                postal_code: billing?.postcode,
                country: billing?.country.code,
              },
            },
          },
        },
        redirect: 'if_required',
      })

      if (!result.error) {
        await api.put(
          `subscription/v2/defaultPaymentMethod/${
            result.setupIntent.payment_method as string
          }`
        )
      }
      dispatch(getSubscriptionInfo())
      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.addCardSuccess`, 'Your card has been added')
          )
        )
      )
      handleClose()
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.addCardError`, 'Error adding the new card')
          )
        )
      )
    } finally {
      setSubmitting(false)
    }
  }

  return {
    submitting,
    isPaymentValid,
    handleAddCard,
  }
}
