import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import {
  reportIOPotential,
  reportIOGraphDataSelector,
} from 'src/store/reportSlice'
import { ResponsiveGridxs2 } from 'src/modules/common'
import { ReportComments } from 'src/utils/golfConstants'
import { Box, Grid, Typography } from '@material-ui/core'
import { getImprovementCaption } from 'src/utils/helpers'
import IOGraph from 'src/modules/reports/ReportDetail/io/IOGraph'

const I18N_KEY = 'ReportIO'

interface ReportIOProps {
  isCoachView: boolean
  benchmarkName: string
  referencePoint: string
  isCreatedByUser: boolean
  comments?: ReportComments
  isCoachGenerated: boolean
  generateSaveComment: (param: string) => (comment: string) => Promise<boolean>
}

const HeadingContainer = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const ReportIO: React.FC<ReportIOProps> = ({
  comments,
  isCoachView,
  benchmarkName,
  referencePoint,
  isCreatedByUser,
  isCoachGenerated,
  generateSaveComment,
}) => {
  const data = useSelector(reportIOGraphDataSelector)
  const improvementPotential = useSelector(reportIOPotential) || 0

  //   TODO: Check what to do when no IOs available
  if (!data || data.length === 0) {
    return null
  }

  return (
    <Box mt={8}>
      <HeadingContainer>
        <Typography variant="h3">
          <Trans i18nKey={`${I18N_KEY}.io`}>Improvement Opportunities</Trans>
        </Typography>
        <Typography component="p" variant="caption" color="textSecondary">
          {getImprovementCaption(improvementPotential, referencePoint)}
        </Typography>
      </HeadingContainer>
      <ResponsiveGridxs2 container spacing={2}>
        {data?.map(opportunity => (
          <Grid key={opportunity.metricId} item xs={12} sm={12} md={4}>
            <IOGraph
              payload={opportunity}
              isCoachView={isCoachView}
              benchmarkName={benchmarkName}
              referencePoint={referencePoint}
              isCreatedByUser={isCreatedByUser}
              isCoachGenerated={isCoachGenerated}
              generateSaveComment={generateSaveComment}
              comment={comments ? comments[opportunity.metricId as string] : ''}
            />
          </Grid>
        ))}
      </ResponsiveGridxs2>
    </Box>
  )
}

export default ReportIO
