import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ToastType } from 'src/utils/constants'
import { RootState } from 'src/store/rootReducer'

interface OpenPayload {
  message: string
  duration?: number
  variant: ToastType
}

const initialState = {
  message: '',
  isOpen: false,
  hideDuration: 4000,
  variant: ToastType.Info,
}

const { actions, reducer } = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    openToast: (state, { payload }: PayloadAction<OpenPayload>) => ({
      ...state,
      ...payload,
      isOpen: true,
    }),
    closeToast: state => ({ ...state, isOpen: false }),
  },
})

export default reducer
export const { openToast, closeToast } = actions

// Selectors
export const toastSelector = (state: RootState) => state.toast

// Helpers
export const getErrorToast = (
  message: string,
  duration: number = 4000
): OpenPayload => ({
  message,
  duration,
  variant: ToastType.Error,
})

export const getSuccessToast = (
  message: string,
  duration: number = 4000
): OpenPayload => ({
  message,
  duration,
  variant: ToastType.Success,
})
