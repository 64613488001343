import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimatePresence } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'

import { Routes } from 'src/utils/constants'
import { requestResetPassword } from 'src/store/authSlice'
import ForgotPasswordForm from 'src/modules/auth/ForgotPasswordForm'
import { createForgotPasswordSchema } from 'src/utils/validationSchemas'
import { openToast, getSuccessToast, getErrorToast } from 'src/store/toastSlice'

const I18N_KEY = 'ForgotPassword'

export interface FormValues {
  email: string
}

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const validationSchema = useMemo(() => createForgotPasswordSchema(t), [t])

  const { formState, control, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: '',
    },
  })

  const handleFormSubmission = handleSubmit(async (values: FormValues) => {
    try {
      await dispatch(requestResetPassword(values))
      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.emailSent`, 'Reset link sent. Check your inbox')
          )
        )
      )
      history.push(Routes.SignIn)
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.sendingError`,
              'Error sending link. Please try it later'
            )
          )
        )
      )
    }
  })

  return (
    <AnimatePresence>
      <ForgotPasswordForm
        control={control}
        key="forgot-password-form"
        onSubmit={handleFormSubmission}
        {...formState}
      />
    </AnimatePresence>
  )
}

export default ForgotPassword
