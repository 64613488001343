import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { FilterSelect } from 'src/components/common'
import useRoundFilters from 'src/hooks/useRoundFilters'
import {
  summaryFiltersSelector,
  SummaryStatus,
  summaryStatusSelector,
} from 'src/store/summarySlice'
import { breakpoints, TimeFilter } from 'src/utils/constants'
import useBreakpoints from 'src/hooks/useBreakpoints'
import CustomDatePicker from 'src/components/inputs/CustomDatePicker'

const I18N_KEY = 'DashboardFilters'

const Container = styled.div(
  () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `
)

const HeroText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      text-align: center;
    }

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(12)};
      margin: 0;
    }
  `
)

const WhiteText = styled(Typography)(
  ({ theme }) => css`
    color: #fff;
    text-transform: none;

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(12)};
    }
  `
)

const FiltersContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    ${theme.breakpoints.down('sm')} {
      margin: 0 0 0.25rem 1rem;
      align-items: baseline;
    }
  `
)

const PaddedContainer = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      padding-left: calc(1rem + 6px);
    }
  `
)

const DashboardFilters: React.FC = () => {
  const {
    whereFilter,
    typeFilter,
    timeFilter,
    whereFilterOptions,
    typeFilterOptions,
    timeFilterOptions,
    handleWhereSelection,
    handleTypeSelection,
    handleTimeSelection,
  } = useRoundFilters()

  const summaryStatus = useSelector(summaryStatusSelector)
  const isLoadingSummary = summaryStatus === SummaryStatus.Loading
  const { isDesktop } = useBreakpoints()
  const filters = useSelector(summaryFiltersSelector)

  const filterOptionText = (timeFilter: TimeFilter) => {
    switch (timeFilter) {
      case TimeFilter.All:
      case TimeFilter.Custom:
        return 'filterOptionCustom'
      default:
        return 'filterOption'
    }
  }

  return (
    <Container>
      <FiltersContainer>
        {isDesktop && (
          <Trans i18nKey={`${I18N_KEY}.showing`}>
            <WhiteText>
              <HeroText>Showing</HeroText>
            </WhiteText>
          </Trans>
        )}
        <FilterSelect
          value={typeFilter}
          name="round-type-filter"
          options={typeFilterOptions}
          disabled={isLoadingSummary}
          onChange={handleTypeSelection}
        />
        <Trans i18nKey={`${I18N_KEY}.of`}>
          <HeroText>of</HeroText>
        </Trans>
        <FilterSelect
          value={whereFilter}
          name="where-type-filter"
          options={whereFilterOptions}
          disabled={isLoadingSummary}
          onChange={handleWhereSelection}
        />
        {isDesktop ? (
          <Trans i18nKey={`${I18N_KEY}.${filterOptionText(timeFilter)}`}>
            <HeroText>for the last</HeroText>
          </Trans>
        ) : (
          <Trans i18nKey={`${I18N_KEY}.for`}>
            <HeroText>for</HeroText>
          </Trans>
        )}
        <FilterSelect
          style={{ minWidth: 'max-content' }}
          value={timeFilter}
          name="round-time-filter"
          options={timeFilterOptions}
          disabled={isLoadingSummary}
          onChange={handleTimeSelection}
        />
      </FiltersContainer>
      {timeFilter === TimeFilter.Custom && (
        <PaddedContainer>
          <CustomDatePicker
            filters={filters}
            isDesktop={isDesktop}
            isDarkTheme
          />
        </PaddedContainer>
      )}
    </Container>
  )
}

export default DashboardFilters
