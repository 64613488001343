import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import MuiDialogContent from '@material-ui/core/DialogContent'

import { Languages } from 'src/utils/constants'
import {
  ActiveBar,
  CheckIcon,
  LargeLayoutButton,
} from 'src/components/layout/SharedLayoutComponents'
import { LanguageContext } from 'src/utils/LanguageProvider'

const I18N_KEY = 'LanguageSelectionDialog'

const DialogContent = styled(MuiDialogContent)`
  min-width: 416px;
`

interface Props {
  open: boolean
  language: string
  handleClose: () => void
}

const LanguageSelectionDialog: React.FC<Props> = ({
  open,
  language,
  handleClose,
}) => {
  const { setLanguage, options } = useContext(LanguageContext)

  const getLanguageChangeCallback = (value: Languages) => () => {
    setLanguage(value)
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <DialogContent>
        <Typography gutterBottom variant="body2" id="form-dialog-title">
          <Trans i18nKey={`${I18N_KEY}.languageDialogTitle`}>
            Choose your language
          </Trans>
        </Typography>
        <AnimateSharedLayout>
          <Box mt={2} display="flex" flexDirection="column">
            {options.map(({ label, value }) => (
              <LargeLayoutButton
                key={value}
                onClick={getLanguageChangeCallback(value)}
              >
                {label}
                {value === language && (
                  <>
                    <CheckIcon />
                    <ActiveBar layoutId="language-selection" />
                  </>
                )}
              </LargeLayoutButton>
            ))}
          </Box>
        </AnimateSharedLayout>
      </DialogContent>
    </Dialog>
  )
}

export default LanguageSelectionDialog
