import React, { useMemo, useState, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TablePagination,
} from '@material-ui/core'

import {
  getRounds,
  roundsSelector,
  duplicateRound,
  totalRoundsSelector,
  roundsLoadingSelector,
  deleteRound,
  updateSelectedRoundByUuid,
} from 'src/store/roundSlice'
// import {
//   hasActiveSubscription,
//   userIsTrialingSelector,
// } from 'src/store/subscriptionSlice'
import {
  TableContainer,
  PaginationContainer,
} from 'src/components/dataDisplay/tableStyles'
import useAppState from 'src/hooks/useAppState'
import LoadingRow from 'src/modules/activity/LoadingRow'
// import { PlanType } from 'src/utils/subscriptionConstants'
import RoundsRow from 'src/modules/activity/rounds/RoundsRow'
import { PAGINATION_LIMIT, Routes } from 'src/utils/constants'
import { openToast, getErrorToast } from 'src/store/toastSlice'
import EmptyRounds from 'src/modules/activity/rounds/EmptyRounds'
import { EndCell, StyledCell } from 'src/modules/activity/common'
import UpgradeDialog from 'src/components/membership/UpgradeDialog'
import ActionConfirmDialog from 'src/modules/activity/DeleteConfirmDialog'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'
import { useFlags } from 'launchdarkly-react-client-sdk'

const I18N_KEY = 'RoundsTable'

const RoundsTable: React.FC = () => {
  const [page, setPage] = useState(0)
  const [deleteUuid, setDeleteUuid] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)
  const [upradeDialogOpen, setUpradeDialogOpen] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isCoachView, playerUuid } = useAppState()

  const { teeLayup } = useFlags()

  // const hasLitePlan = useSelector(hasActiveSubscription(PlanType.Lite))
  // const isTrialing = useSelector(userIsTrialingSelector)

  const canCrudRounds = true // hasLitePlan && !isTrialing

  const roundColumns = [
    t(`${I18N_KEY}.roundColumnsRound`, 'Round'),
    t(`${I18N_KEY}.roundColumnsDrives`, 'Drives'),
    t(`${I18N_KEY}.roundColumnsApproaches`, 'Approaches'),
    t(`${I18N_KEY}.roundColumnsShortGame`, 'Short Game'),
    t(`${I18N_KEY}.roundColumnsPutting`, 'Putting'),
    t(`${I18N_KEY}.roundColumnsTotal`, 'Total'),
    t(`${I18N_KEY}.roundColumnsScore`, 'Score'),
  ]

  const rounds = useSelector(roundsSelector)
  const totalRounds = useSelector(totalRoundsSelector)
  const roundsLoading = useSelector(roundsLoadingSelector)

  const loadingArray = useMemo(
    () =>
      Array.from(
        Array(
          totalRounds > 0 && totalRounds < PAGINATION_LIMIT
            ? totalRounds
            : PAGINATION_LIMIT
        ).keys()
      ),
    [totalRounds]
  )

  const noRoundsEntered = !roundsLoading && totalRounds === 0

  const openUpgradeDialog = () => {
    setUpradeDialogOpen(true)
  }

  const closeUpgradeDialog = () => {
    setUpradeDialogOpen(false)
  }

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getRounds(newPage * PAGINATION_LIMIT, teeLayup))
    setPage(newPage)
  }

  const handleRoundClick = (event: MouseEvent<HTMLTableRowElement>) => {
    const selectedRoundUuid = event.currentTarget.id
    dispatch(updateSelectedRoundByUuid({ selectedRoundUuid }))
    const route = isCoachView
      ? Routes.CoachPlayerRoundDetail
      : Routes.RoundDetail
    history.push(
      route
        .replace(':playerUuid', playerUuid ?? '')
        .replace(':roundUuid', selectedRoundUuid)
    )
  }

  const closeDialog = () => {
    setDeleteUuid('')
  }

  const generateDeleteClick =
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()

      if (canCrudRounds) {
        setDeleteUuid(uuid)
      } else {
        openUpgradeDialog()
      }
    }

  const generateHandleDuplicate =
    (uuid: string) => async (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()

      if (canCrudRounds) {
        try {
          await dispatch(duplicateRound(uuid))
        } catch {
          dispatch(
            openToast(
              getErrorToast(
                t(`${I18N_KEY}.duplicateToast`, 'Could not duplicate round')
              )
            )
          )
        }
      } else {
        openUpgradeDialog()
      }
    }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await dispatch(deleteRound(deleteUuid))
      setIsDeleting(false)
    } catch {
      dispatch(
        openToast(
          getErrorToast(t(`${I18N_KEY}.deleteToast`, 'Could not delete round'))
        )
      )
    }
    setDeleteUuid('')
  }

  return (
    <>
      {!noRoundsEntered && (
        <TableContainer>
          <Table aria-label="rounds table">
            <TableHead>
              <TableRow>
                {roundColumns.map((column, index) => (
                  <StyledCell
                    key={column}
                    align={index > 0 ? 'center' : 'left'}
                  >
                    <Typography variant="caption" color="textSecondary">
                      {column}
                    </Typography>
                  </StyledCell>
                ))}
                <EndCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {roundsLoading
                ? loadingArray.map(index => <LoadingRow key={index} />)
                : rounds.map(round => (
                    <RoundsRow
                      round={round}
                      key={round.uuid}
                      handleRoundClick={handleRoundClick}
                      handleDeleteClick={generateDeleteClick(round.uuid)}
                      handleDuplicateClick={generateHandleDuplicate(round.uuid)}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <PaginationContainer>
        {noRoundsEntered ? (
          <EmptyRounds />
        ) : (
          <TablePagination
            page={page}
            component="div"
            count={totalRounds}
            rowsPerPageOptions={[]}
            rowsPerPage={PAGINATION_LIMIT}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </PaginationContainer>
      <ActionConfirmDialog
        open={!!deleteUuid}
        inProgress={isDeleting}
        handleClose={closeDialog}
        handleAction={handleDelete}
        title={t(
          `${I18N_KEY}.dialogTitle`,
          'Are you sure you want to delete this round?'
        )}
        deleteText={t(`${I18N_KEY}.dialogDeleteText`, 'Delete Round')}
        description={t(
          `${I18N_KEY}.dialogDescription`,
          'Removing this round will cause your statistics to change. This action can’t be undone.'
        )}
      />
      <UpgradeDialog
        open={upradeDialogOpen}
        onClose={closeUpgradeDialog}
        onUpgradeOpen={closeUpgradeDialog}
        title={t(`${I18N_KEY}.upgradeTitle`, 'Can not delete or edit rounds')}
        description={t(
          `${I18N_KEY}.upgradeDescription`,
          'This action requires a paid subscription. Please upgrade your membership if you want to edit or delete existing rounds. '
        )}
      />
    </>
  )
}

export default RoundsTable
