import styled, { css } from 'styled-components'

import {
  Grid,
  Avatar,
  Button,
  Toolbar as MuiToolbar,
  Container as MuiContainer,
} from '@material-ui/core'
import { Done, Person } from '@material-ui/icons'

import { breakpoints } from 'src/utils/constants'
import LoaderButton from 'src/components/inputs/LoaderButton'
import FullScreenDialog from 'src/components/feedback/FullScreenDialog'
import { SharedLayoutButton } from 'src/components/layout/SharedLayoutComponents'

import { ReactComponent as Illustration } from 'src/assets/images/fadingDotIllustration.svg'

interface StepProps {
  $selected: boolean
  $completed: boolean
}

export const Dialog = styled(FullScreenDialog)`
  display: flex;
  flex-direction: column;
`

export const Toolbar = styled(MuiToolbar)(
  ({ theme }) => css`
    padding: ${theme.spacing(4, 0, 2)};
    svg {
      color: #000;
    }

    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(4, 4, 2)};
    }
  `
)

export const StepInfo = styled(Button)<StepProps>(
  ({ theme, $selected, $completed }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(1, 1.5)};
    margin-right: ${theme.spacing(1)}px;
    color: ${$selected
      ? theme.palette.primary.main
      : $completed
      ? theme.palette.text.primary
      : theme.palette.background.grey};
    transition: color 0.3s ease-out, background-color 0.3s ease-out;
    border: 1px solid ${$completed ? theme.palette.background.light : '#fff'};
    background-color: ${$selected
      ? theme.palette.primary.contrastText
      : '#fff'};

    & > .MuiSvgIcon-root {
      width: ${$completed ? '14px' : '8px'};
      margin-right: ${theme.spacing(0.5)}px;
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1.25, 0.75)};
      margin-right: ${theme.spacing(0.5)}px;
      font-size: ${theme.typography.pxToRem(12)};
      line-height: ${theme.typography.pxToRem(20)};
    }
  `
)

export const DoneIcon = styled(Done)(
  ({ theme }) => css`
    font-size: 1rem;
    color: ${theme.palette.primary.main};
    margin-right: ${theme.spacing(0.5)}px;
  `
)

export const MainContentBackground = styled.div(
  ({ theme }) => css`
    flex: 1;
    width: 100%;
    background-color: ${theme.palette.background.xlight};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(2, 0)};
    }
  `
)

export const SummaryContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    background-color: #fff;
    padding: ${theme.spacing(4, 0, 6)};
    border-top: 1px solid ${theme.palette.background.light};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      border-top: none;
    }
  `
)

export const FadingDotIllustration = styled(Illustration)(
  ({ theme }) => css`
    right: 0;
    top: 20px;
    z-index: 1;
    height: 60%;
    position: absolute;

    g {
      fill: ${theme.palette.background.grey};
    }
  `
)

export const SettingsContainer = styled(MuiContainer)(
  ({ theme }) => css`
    display: flex;
    align-items: flex-start;
    min-height: calc(100vh - 74px);
    padding-top: ${theme.spacing(7.5)}px;
    padding-bottom: ${theme.spacing(7.5)}px;

    > :nth-child(2) {
      flex: 1;
    }

    ${theme.breakpoints.down(breakpoints.mobile)} {
      flex-direction: column;

      > :nth-child(2) {
        width: 100%;
      }
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 0, 14)};
    }
  `
)

export const SettingsMenu = styled.div(
  ({ theme }) => css`
    width: 142px;
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(0, 2, 0, 0)};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      width: unset;
      flex-direction: row;
      margin: ${theme.spacing(0, 0, 2)};
    }

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(0, 2, 2)};
    }
  `
)

export const SettingsButton = styled(SharedLayoutButton)(
  ({ theme }) => css`
    width: 100%;
    justify-content: flex-start;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      width: unset;
    }

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(12)};
      line-height: ${theme.typography.pxToRem(20)};
    }
  `
)

export const SettingsSubmitButton = styled(LoaderButton)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
)

export const ResponsiveGridxs2 = styled(Grid)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      &.MuiGrid-spacing-xs-2 {
        margin: 0;
        width: 100%;
      }

      &.MuiGrid-spacing-xs-2 > .MuiGrid-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  `
)

export const TabButton = styled(SharedLayoutButton)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(12)};
      line-height: ${theme.typography.pxToRem(20)};
    }
  `
)

export const XsPaddingZeroContainer = styled(MuiContainer)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding-left: 0;
      padding-right: 0;
    }
  `
)

export const AvatarContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing(0, 0, 5)};

    & > button {
      margin-left: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `
)

export const AvatarActions = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    margin: ${theme.spacing(0, 0, 0, 3)};

    & > button {
      margin-left: ${theme.spacing(1)}px;
    }

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(2, 0, 0)};
    }
  `
)

export const StyledAvatar = styled(Avatar)`
  width: 128px;
  height: 128px;
  overflow: hidden;
  position: relative;
  background-color: #e2f1fc;
`

export const PersonIcon = styled(Person)(
  ({ theme }) => css`
    top: 4px;
    left: -10px;
    color: #fff;
    position: absolute;
    font-size: ${theme.typography.pxToRem(150)};
  `
)

export const PlusSign = styled.span(
  ({ theme }) => css`
    margin-right: ${theme.spacing(1.5)}px;
    color: ${theme.palette.primary.light};
  `
)
