import React from 'react'

import Box, { BoxProps } from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

interface Props extends BoxProps {
  title: string
  description: string
}

const EmptyMessage: React.FC<Props> = ({ title, description, ...props }) => (
  <Box
    minHeight={200}
    display="flex"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
    {...props}
  >
    <Typography variant="h3" color="textSecondary">
      {title}
    </Typography>
    <Typography color="textSecondary">{description}</Typography>
  </Box>
)

export default EmptyMessage
