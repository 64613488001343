export interface Attributes {
  [key: string]: string | object
}

export enum NotificationType {
  ROUND_ADDED = 'RoundAdded',
  IMPROVEMENT_OPPORTUNITY = 'ImprovementOpportunity',
  IMPROVEMENT_OPPORTUNITY_AVAILABLE = 'ImprovementOpportunityAvailable',
  PRACTICE_ADDED = 'PracticeAdded',
  PRACTICE_SCHEDULE_UPDATED = 'PracticeScheduleUpdated',
}

export interface Notification {
  uuid: string
  title: string
  message: string
  type: NotificationType
  attributes: Attributes
  read: Date
}
