import React from 'react'
import styled, { css } from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import LastPageIcon from '@material-ui/icons/LastPage'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { useTranslation } from 'react-i18next'

const I18N_KEY = 'TablePaginationActions'

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const PageCountContainer = styled.div`
  display: flex;
  min-width: 60px;
  text-align: center;
`

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 44px;
    height: 44px;
    border-radius: 3px;
    margin: ${theme.spacing(0, 0.75)};
    background-color: ${theme.palette.tooltip.secondary} !important;
  `
)

const TablePaginationActions = ({
  page,
  count,
  rowsPerPage,
  onPageChange,
}: TablePaginationActionsProps) => {
  const { t } = useTranslation()
  const startVal = 1 + rowsPerPage * page
  const endVal =
    count < rowsPerPage ? count : Math.min(rowsPerPage * (page + 1), count)

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Container>
      <StyledIconButton
        disabled={page === 0}
        aria-label={t(`${I18N_KEY}.firstPage`, 'first page')}
        onClick={handleFirstPageButtonClick}
      >
        <FirstPageIcon />
      </StyledIconButton>
      <StyledIconButton
        disabled={page === 0}
        aria-label={t(`${I18N_KEY}.previousPage`, 'previous page')}
        onClick={handleBackButtonClick}
      >
        <KeyboardArrowLeft />
      </StyledIconButton>
      <PageCountContainer>
        <Typography variant="h6" color="textSecondary">
          {startVal} - {endVal} of {count}
        </Typography>
      </PageCountContainer>

      <StyledIconButton
        aria-label={t(`${I18N_KEY}.nextPage`, 'next page')}
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <KeyboardArrowRight />
      </StyledIconButton>
      <StyledIconButton
        aria-label={t(`${I18N_KEY}.lastPage`, 'last page')}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
      >
        <LastPageIcon />
      </StyledIconButton>
    </Container>
  )
}

export default TablePaginationActions
