import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import styled, { css, StyledProps } from 'styled-components'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Circle from '@material-ui/icons/FiberManualRecord'

import { fadeSlide } from 'src/utils/animations'
import { MetricId } from 'src/utils/golfConstants'
import { DECIMAL_PLACES, breakpoints } from 'src/utils/constants'
import { TranslationContext } from 'src/utils/TranslationContext'
const I18N_KEY = 'PerformanceCards'

interface Props {
  strength: PerformanceOpportunity
  opportunity: PerformanceOpportunity
}

interface PerformanceOpportunity {
  metricId: MetricId
  strokesGained: number | null | undefined
}

interface CircleProps {
  $isStrength: boolean
}

const Card = styled(Paper)(
  ({ theme }) => css`
    z-index: 1;
    width: 250px;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(2, 3)};
    animation: ${fadeSlide} 0.45s ease-out forwards;
    background-color: ${theme.palette.background.darkestGrey};

    :first-child {
      margin-right: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down(breakpoints.mobile)} {
      width: 100%;
      margin-bottom: ${theme.spacing(2)}px;
      :first-child {
        margin-right: 0;
      }
    }
  `
)

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const WhiteText = styled(Typography).attrs({ component: 'p' })`
  color: #fff;
`

const CircleIcon = styled(Circle)(
  ({ theme, $isStrength }: StyledProps<CircleProps>) => css`
    color: ${$isStrength
      ? theme.palette.success.main
      : theme.palette.error.main};
    margin-right: ${theme.spacing(0.5)}px;
    font-size: ${theme.typography.pxToRem(12)};
  `
)

const PerformanceCards: React.FC<Props> = ({ strength, opportunity }) => {
  const { metricLabels } = useContext(TranslationContext)!

  const strengthName = metricLabels[strength?.metricId]
  const opportunityName = metricLabels[opportunity?.metricId]

  return (
    <Box mt={3} display="flex" flexWrap="wrap">
      <Card>
        <Box display="flex" alignItems="center">
          <CircleIcon $isStrength />
          <Typography variant="caption" component="h3" color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.topStrength`}>Top Strength</Trans>
          </Typography>
        </Box>
        <ContentContainer>
          <WhiteText>{strengthName}</WhiteText>
          <WhiteText variant="h3">
            {strength?.strokesGained?.toFixed(DECIMAL_PLACES)}
          </WhiteText>
        </ContentContainer>
      </Card>
      <Card>
        <Box display="flex" alignItems="center">
          <CircleIcon $isStrength={false} />
          <Typography variant="caption" component="h3" color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.biggestOpportunity`}>
              Biggest Opportunity
            </Trans>
          </Typography>
        </Box>
        <ContentContainer>
          <WhiteText>{opportunityName}</WhiteText>
          <WhiteText variant="h3">
            {opportunity?.strokesGained?.toFixed(DECIMAL_PLACES)}
          </WhiteText>
        </ContentContainer>
      </Card>
    </Box>
  )
}

export default PerformanceCards
