import styled, { css } from 'styled-components'
import { Typography, Paper as MuiPaper } from '@material-ui/core'

export const PaperTitle = styled(Typography).attrs({ variant: 'h3' })`
  font-weight: normal;
`

export const Paper = styled(MuiPaper)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.darkestGrey};
    color: ${theme.palette.text.white};
    padding: ${theme.spacing(4)}px;
    width: 100%;
    min-height: 620px;
    height: 100%;

    ${theme.breakpoints.down('sm')} {
      min-height: unset;
      padding: ${theme.spacing(3, 2)};
    }
  `
)

export const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacing(3.5)}px;
    align-items: flex-end;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

export const PaymentContainer = styled.div(
  ({ theme }) => css`
    margin: auto;
    max-width: 1152px;

    ${theme.breakpoints.down('sm')} {
      max-width: 456px;
    }
  `
)
