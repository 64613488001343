import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Add from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { MembershipLevel, TemplateLink } from 'src/utils/constants'
import { updateDialogVisibility } from 'src/store/roundSlice'
import { hasActiveSubscription } from '../../../store/subscriptionSlice'
import UpgradeDialog from '../../../components/membership/UpgradeDialog'

const I18N_KEY = 'EmptyRounds'

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    padding: ${theme.spacing(8, 0)};
  `
)

const AddButton = styled(Button)`
  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 1rem;
  }
`

const EmptyRounds: React.FC = () => {
  const dispatch = useDispatch()
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)
  const canAddRoundsAndPractice = useSelector(
    hasActiveSubscription(MembershipLevel.Basic)
  )

  const openRoundsDialog = () => {
    if (!canAddRoundsAndPractice) {
      setUpgradeDialogOpen(true)
      return
    }
    dispatch(updateDialogVisibility({ isOpen: true }))
  }

  const handleUpgradeDialogClose = () => setUpgradeDialogOpen(false)

  return (
    <>
      <Container>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.mainDescription`}>
            Add rounds to begin analysing your game.
          </Trans>
        </Typography>
        <Box pt={3} pb={5}>
          <AddButton
            color="primary"
            variant="contained"
            startIcon={<Add />}
            onClick={openRoundsDialog}
          >
            <Trans i18nKey={`${I18N_KEY}.addButtonLabel`}>Add Round</Trans>
          </AddButton>
        </Box>
        <Typography variant="h6" color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.description`}>
            Unsure what information to collect on course?
          </Trans>
        </Typography>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={TemplateLink.ShotCollection}
        >
          <Typography variant="h6" color="primary">
            <Trans i18nKey={`${I18N_KEY}.templateButtonLabel`}>
              Shot Collection Template
            </Trans>
          </Typography>
        </a>
      </Container>
      <UpgradeDialog
        open={upgradeDialogOpen}
        onClose={handleUpgradeDialogClose}
        onUpgradeOpen={handleUpgradeDialogClose}
      />
    </>
  )
}

export default EmptyRounds
