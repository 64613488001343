import React, { MouseEvent, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'

import {
  PaginationContainer,
  TableContainer,
} from 'src/components/dataDisplay/tableStyles'
import useAppState from 'src/hooks/useAppState'
import LoadingRow from 'src/modules/activity/LoadingRow'
import ReportsRow from 'src/modules/reports/ReportsTable/ReportsRow'
import {
  PAGINATION_LIMIT,
  REPORT_OWNER_ERROR,
  Routes,
} from 'src/utils/constants'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'
import EmptyReports from 'src/modules/reports/ReportsTable/EmptyReports'
import { EndCell, StyledCell } from 'src/modules/activity/common'
import ActionConfirmDialog from 'src/modules/activity/DeleteConfirmDialog'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'

import { deleteReport, getReports, reportSelector } from 'src/store/reportSlice'
import {
  ReportGenerationStatus,
  ReportType,
} from '../../../utils/golfConstants'
import ReportDetailDialog from '../ReportDetail'
import CreateReportDialog from '../CreateReportDialog'
import Add from '@material-ui/icons/Add'
import { Box, Button } from '@material-ui/core'
import styled, { css } from 'styled-components'
import { hasActiveSubscription } from '../../../store/subscriptionSlice'
import UpgradeDialog from '../../../components/membership/UpgradeDialog'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import DiscussionDocumentGeneration from '../discussion-document/generation'
import { updateDialogVisibility } from '../../../store/discussionDocumentSlice'
import { coachPermissionsSelector } from '../../../store/coachSlice'
import { FeatureType } from '../../../utils/roleConstants'
import { downloadDiscussionDocument } from '../../../service/discussionDocumentService'
import { playerSelector } from '../../../store/playerSlice'

const I18N_KEY = 'ReportsTable'

const AddButton = styled(Button)(
  ({ theme }) => css`
    border-color: ${theme.palette.background.light};
    background: ${theme.palette.background.paper};
    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 1rem;
    }
  `
)

const ReportCreationContainer = styled(Box)(
  ({ theme }) => css`
    display: flex;
    justify-content: end;
    margin-top: ${theme.spacing(2)}px;
  `
)

const ReportsTable: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isCoachView, playerUuid } = useAppState()
  const { firstName, lastName } = useSelector(playerSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const coachPermissions = useSelector(coachPermissionsSelector)

  const [isDownloading, setIsDownloading] = useState<string[]>([])
  const [deleteUuid, setDeleteUuid] = useState('')
  const [type, setType] = useState<ReportType>()
  const [isDeleting, setIsDeleting] = useState(false)
  const [createReportOpen, setCreateReportOpen] = useState(false)
  const [upgradeDialogOpen, setUpgradeDialog] = useState(false)

  const [page, setPage] = useState(0)
  const { totalReports, isReportsLoading, isReportsLoaded, reports } =
    useSelector(reportSelector)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const emptyList = isReportsLoaded && totalReports === 0
  useEffect(() => {
    dispatch(getReports())
  }, [dispatch])

  useEffect(() => {
    if (
      reports.some(
        report => report.status === ReportGenerationStatus.InProgress
      )
    ) {
      const interval = setInterval(() => {
        dispatch(getReports(0, false))
      }, 4000)
      if (interval) {
        return () => {
          clearInterval(interval)
        }
      }
    }
  }, [dispatch, reports])

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const reportColumns = [
    t(`${I18N_KEY}.reportColumnsTitle`, 'Report Title'),
    t(`${I18N_KEY}.reportColumnsCreatedBy`, 'Created By'),
  ]

  const loadingArray = useMemo(
    () =>
      Array.from(
        Array(
          totalReports > 0 && totalReports < PAGINATION_LIMIT ? totalReports : 1
        ).keys()
      ),
    [totalReports]
  )

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getReports(newPage * PAGINATION_LIMIT))
    setPage(newPage)
  }

  const handleReportClick = (event: MouseEvent<HTMLTableRowElement>) => {
    const selectedReport = event.currentTarget.id
    const route = isCoachView
      ? Routes.CoachPlayerReportDetail
      : Routes.ReportDetail
    history.push(
      route
        .replace(':playerUuid', playerUuid ?? '')
        .replace(':reportUuid', selectedReport)
    )
  }

  const addToDownloadingList = (uuid: string) => {
    const downloadingList = [...isDownloading]
    downloadingList.push(uuid)
    setIsDownloading(downloadingList)
  }

  const removeFromDownloadingList = (uuid: string) => {
    const downloadingList = [...isDownloading]
    downloadingList.splice(downloadingList.indexOf(uuid), 1)
    setIsDownloading(downloadingList)
  }

  const checkIfDownloading = (uuid: string): boolean => {
    return !!isDownloading.find(course => course === uuid)
  }

  const convertNumberToMonth = (month: number): string => {
    switch (month) {
      case 0:
        return 'January'
      case 1:
        return 'February'
      case 2:
        return 'March'
      case 3:
        return 'April'
      case 4:
        return 'May'
      case 5:
        return 'June'
      case 6:
        return 'July'
      case 7:
        return 'August'
      case 8:
        return 'September'
      case 9:
        return 'October'
      case 10:
        return 'November'
      case 11:
        return 'December'
      default:
        return 'None'
    }
  }

  const handleDiscussionDocumentClick = async (
    event: MouseEvent<HTMLTableRowElement>
  ) => {
    const selectedReport = event.currentTarget.id
    const reportDate = reports.find(
      report => report.reportUUID === selectedReport
    )?.createdAt
    dispatch(
      openToast(
        getSuccessToast(
          t(
            `${I18N_KEY}.downloadDiscussionDocumentToast`,
            'Your download will begin soon. Please do not navigate from this page'
          )
        )
      )
    )
    addToDownloadingList(selectedReport)
    const data = await downloadDiscussionDocument(selectedReport, playerUuid)
    const url = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = url
    if (reportDate) {
      const creationDate = new Date(reportDate)
      link.download = `${firstName} ${lastName} Discussion Document ${convertNumberToMonth(
        creationDate.getMonth()
      )} ${creationDate.getFullYear()}.pdf`
    } else {
      link.download = `${firstName} ${lastName} Discussion Document.pdf`
    }
    document.body.appendChild(link)
    link.click()
    link.remove()
    removeFromDownloadingList(selectedReport)
  }

  const closeDialog = () => {
    setDeleteUuid('')
  }

  const generateDeleteClick =
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      const report = reports.find(rep => rep.reportUUID === uuid)
      setDeleteUuid(uuid)
      if (report) {
        setType(report.type)
      }
    }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await dispatch(deleteReport(deleteUuid, type))
      await dispatch(getReports(page * PAGINATION_LIMIT))
    } catch (error: any) {
      let errorMessage = t(`${I18N_KEY}.deleteToast`, 'Could not delete report')

      if (error.message === REPORT_OWNER_ERROR) {
        errorMessage = t(
          `${I18N_KEY}.deleteError`,
          'Report can only be deleted by the author'
        )
      }

      dispatch(openToast(getErrorToast(errorMessage)))
    } finally {
      setIsDeleting(false)
      setDeleteUuid('')
    }
  }

  const openReport = (reportUuid: string) => {
    const route = isCoachView
      ? Routes.CoachPlayerReportDetail
      : Routes.ReportDetail
    history.push(
      route
        .replace(':playerUuid', playerUuid ?? '')
        .replace(':reportUuid', reportUuid)
    )
  }

  const createReportSuccessHandler = (reportUuid: string) => {
    setCreateReportOpen(false)
    openReport(reportUuid)
  }

  const openCreateReportDialog = () => {
    if (isPlayerPremium) {
      setCreateReportOpen(true)
    } else {
      setUpgradeDialog(true)
    }
  }

  const handleCreateReportClick = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    openCreateReportDialog()
  }

  const handleCreateDiscussionDocumentClick = () => {
    if (isPlayerPremium) {
      dispatch(updateDialogVisibility({ isOpen: true }))
    } else {
      setUpgradeDialog(true)
    }
  }

  const closeUpgradeDialog = () => {
    setUpgradeDialog(false)
  }

  const evaluateCreateReportMenu = () => {
    if (
      coachPermissions.some(
        permission =>
          permission.feature === FeatureType.DiscussionDocument &&
          permission.write
      )
    ) {
      return (
        <>
          <AddButton
            color="primary"
            variant="outlined"
            startIcon={<Add />}
            onClick={openMenu}
          >
            <Trans i18nKey={`${I18N_KEY}.createReport`}>Create report</Trans>
          </AddButton>
          <Menu
            elevation={1}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={closeMenu}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem
              onClick={e => {
                handleCreateReportClick(e)
                closeMenu()
              }}
            >
              <Trans i18nKey={`${I18N_KEY}.roundReport`}>Round Report</Trans>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleCreateDiscussionDocumentClick()
                closeMenu()
              }}
            >
              <Trans i18nKey={`${I18N_KEY}.discussionDocument`}>
                Discussion Document
              </Trans>
            </MenuItem>
          </Menu>
        </>
      )
    }
    return (
      <AddButton
        color="primary"
        variant="outlined"
        startIcon={<Add />}
        onClick={openCreateReportDialog}
      >
        <Trans i18nKey={`${I18N_KEY}.createReport`}>Create report</Trans>
      </AddButton>
    )
  }

  return (
    <>
      {!emptyList && (
        <div>
          <ReportCreationContainer>
            {isCoachView && evaluateCreateReportMenu()}
          </ReportCreationContainer>
          <TableContainer>
            <Table aria-label="reports table">
              <TableHead>
                <TableRow>
                  {reportColumns.map((column, index) => (
                    <StyledCell
                      key={column}
                      align={index > 0 ? 'right' : 'left'}
                    >
                      <Typography variant="caption" color="textSecondary">
                        {column}
                      </Typography>
                    </StyledCell>
                  ))}
                  {isCoachView && <EndCell />}
                </TableRow>
              </TableHead>
              <TableBody>
                {isReportsLoading
                  ? loadingArray.map(index => <LoadingRow key={index} />)
                  : reports.map(report => (
                      <ReportsRow
                        report={report}
                        key={report.reportUUID}
                        isCoachView={isCoachView}
                        handleReportClick={
                          report.type === ReportType.DiscussionDocument
                            ? handleDiscussionDocumentClick
                            : handleReportClick
                        }
                        handleDeleteClick={generateDeleteClick(
                          report.reportUUID
                        )}
                        isDownloading={checkIfDownloading(report.reportUUID)}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <PaginationContainer>
        {emptyList ? (
          <>
            <ReportCreationContainer>
              {isCoachView && evaluateCreateReportMenu()}
            </ReportCreationContainer>
            <EmptyReports />
          </>
        ) : (
          <TablePagination
            page={page}
            component="div"
            count={totalReports}
            rowsPerPageOptions={[]}
            rowsPerPage={PAGINATION_LIMIT}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        )}
        <ActionConfirmDialog
          open={!!deleteUuid}
          inProgress={isDeleting}
          handleClose={closeDialog}
          handleAction={handleDelete}
          title={t(
            `${I18N_KEY}.dialogTitle`,
            'Are you sure you want to delete this report?'
          )}
          deleteText={t(`${I18N_KEY}.dialogDeleteText`, 'Delete Report')}
          description={t(
            `${I18N_KEY}.dialogDescription`,
            'This action can’t be undone.'
          )}
        />
      </PaginationContainer>
      <CreateReportDialog
        open={createReportOpen}
        onCancel={() => setCreateReportOpen(false)}
        onSuccess={createReportSuccessHandler}
      />
      <DiscussionDocumentGeneration />
      <ReportDetailDialog />
      <UpgradeDialog
        open={upgradeDialogOpen}
        onClose={closeUpgradeDialog}
        onUpgradeOpen={closeUpgradeDialog}
      />
    </>
  )
}

export default ReportsTable
