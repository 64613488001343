import { api } from '../utils/api'

export const downloadCourseStrategyDocument = async (
  uuid: string,
  isPlayer?: boolean,
  playerUuid?: string
) => {
  const endpoint = isPlayer
    ? `/strategy/${uuid}/document`
    : `overview/player/${playerUuid}/strategy/${uuid}/document`
  const response = await api.get(endpoint, {
    responseType: 'blob',
  })
  return response.data
}
