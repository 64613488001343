import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'

import { ResponsiveGridxs2 } from 'src/modules/common'
import { OpportunityItem } from 'src/store/summarySlice'
import OpportunityGraph from 'src/modules/player-dash/improvement/OpportunityGraph'
import UpgradeDialog from 'src/components/membership/UpgradeDialog'

interface Props {
  data: OpportunityItem[]
  isPlayerPremium: boolean
}

const OpportunityGraphGrid: React.FC<Props> = ({ data, isPlayerPremium }) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  const openUpgradeDialog = () => {
    setUpgradeDialogOpen(true)
  }

  const closeUpgradeDialog = () => {
    setUpgradeDialogOpen(false)
  }

  return (
    <>
      <ResponsiveGridxs2 container spacing={2}>
        {data.map(opportunity => (
          <Grid key={opportunity.metricId} item xs={12} sm={12} md={12} lg={4}>
            <OpportunityGraph
              {...opportunity}
              isPlayerPremium={isPlayerPremium}
              handleUpgrade={openUpgradeDialog}
            />
          </Grid>
        ))}
      </ResponsiveGridxs2>
      <UpgradeDialog
        open={upgradeDialogOpen}
        onClose={closeUpgradeDialog}
        onUpgradeOpen={closeUpgradeDialog}
      />
    </>
  )
}

export default OpportunityGraphGrid
