import React from 'react'
import { FlowComponentProps } from '../types'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { usePricing } from '../usePricing'
import { Elements } from '@stripe/react-stripe-js'
import { config } from '../../../configLoader'
import NewStripePayment from './NewStripePayment'

const stripePromise = loadStripe(config.REACT_APP_STRIPE_KEY!)

export const NewStripePaymentContainer: React.FC<FlowComponentProps> = ({
  data,
  onNext,
  onChange,
}) => {
  const { finalPrice } = usePricing(data.plan)

  const appearance = {
    variables: {
      gridRowSpacing: '34.5px',
      colorPrimary: '#0570de',
      colorBackground: '#121212',
      colorText: '#ffffff',
      colorDanger: '#df1b41',
      fontFamily: 'IBM Plex Sans, system-ui, sans-serif',
      spacingUnit: '6.3px',
      borderRadius: '6px',
      // See all possible variables below
    },
    rules: {
      '.Input': {
        border: '0',
      },
      '.Label': {
        fontVariant: 'all-small-caps',
        fontSize: '14px',
        fontWeight: '500',
      },
    },
  }

  const stripeOptions: StripeElementsOptions = {
    mode: 'subscription',
    amount: finalPrice,
    currency: 'usd',
    appearance,
    fonts: [
      {
        cssSrc:
          'https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjR7_AIFsdP3pBms.woff2',
      },
    ],
  }

  return (
    <Elements stripe={stripePromise} options={stripeOptions}>
      <NewStripePayment onNext={onNext} onChange={onChange} data={data} />
    </Elements>
  )
}
