import filesize from 'filesize'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useState, useEffect, ChangeEvent } from 'react'

import { getErrorToast, openToast } from 'src/store/toastSlice'

const I18N_KEY = 'useCropper'

// 5MB in bytes
const MAX_FILE_SIZE = 5000000

const useCropper = () => {
  const [cropDialogOpen, setCropDialogOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewSrc, setPreviewSrc] = useState<string | ArrayBuffer | null>(
    null
  )

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files
    const file = files && files[0] // use only the first file only

    if (file && file.size > MAX_FILE_SIZE) {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.fileSizeError`, {
              fileSizeLimit: filesize(MAX_FILE_SIZE),
              defaultValue:
                'File is too large. Please select a file less than {{ fileSizeLimit }}',
            })
          )
        )
      )
    } else {
      setSelectedFile(file)
      setCropDialogOpen(true)
    }

    // Make sure ChangeEvent is fired if user selects the same photo twice
    event.target.value = ''
  }

  const closeCropDialog = () => {
    setCropDialogOpen(false)

    setSelectedFile(null)
    setPreviewSrc(null)
  }

  useEffect(() => {
    if (selectedFile) {
      const reader = new FileReader()
      reader.onload = function (e) {
        if (e?.target?.result) {
          setPreviewSrc(e.target.result)
        }
      }
      reader.readAsDataURL(selectedFile)
      return
    }
    setPreviewSrc(null)
  }, [selectedFile])

  return {
    previewSrc,
    selectedFile,
    cropDialogOpen,
    closeCropDialog,
    handleFileSelection,
  }
}

export default useCropper
