import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'

import {
  isNumber,
  handleNumberFormat,
  getMeasurementSymbol,
} from 'src/utils/helpers'
import NoData from 'src/components/dataDisplay/NoData'
import HelpTooltip from 'src/modules/practice/HelpTooltip'
import { Activity, Measurement } from 'src/utils/golfConstants'
import { TranslationContext } from 'src/utils/TranslationContext'
import {
  TableContainer,
  TableCell,
  TitleCell,
} from 'src/components/dataDisplay/tableStyles'

const I18N_KEY = 'PracticeSummaryTable'

interface Props {
  activities: Activity[]
}

const PracticeSummaryTable: React.FC<Props> = ({ activities }) => {
  const { t } = useTranslation()
  const { activityLabels, measurementLabels } = useContext(TranslationContext)!

  const practiceGoalText = t(`${I18N_KEY}.practiceGoal`, 'Practice Goal')

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.activity`}>Activity</Trans>
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.previousRounds`}>
                  Your Previous rounds
                </Trans>
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" color="textSecondary">
                  {practiceGoalText}
                </Typography>
                <HelpTooltip />
              </Box>
            </TableCell>
            <TableCell align="center">
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.you`}>You</Trans>
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.vs`}>VS</Trans> {practiceGoalText}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activities?.map(
            ({
              unit,
              input,
              target,
              activityId,
              currentValue,
              inputDifference,
            }) => {
              const hasCurrentValue = isNumber(currentValue)
              const symbol = getMeasurementSymbol(unit, measurementLabels)

              const isPuttsOfTen = unit === Measurement.PuttsOfTen
              // Putts of 10 needs to be converted from a decimal to a number out of 10
              const actualCurrentValue = isPuttsOfTen
                ? (currentValue as number) * 10
                : (currentValue as number)

              return (
                <TableRow key={activityId}>
                  <TitleCell>
                    <Typography>{activityLabels[activityId]}</Typography>
                  </TitleCell>
                  <TableCell align="center">
                    <Typography>
                      {hasCurrentValue ? (
                        `${handleNumberFormat(actualCurrentValue) + symbol}`
                      ) : (
                        <NoData />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {target !== null && target !== undefined ? (
                        <>
                          {handleNumberFormat(target as number)}
                          {symbol}
                        </>
                      ) : (
                        <NoData />
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {handleNumberFormat(input as number)}
                      {symbol}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography>
                      {typeof target === 'number' ? (
                        <Box
                          component="span"
                          color={
                            (inputDifference as number) < 0
                              ? 'error.main'
                              : 'success.main'
                          }
                        >
                          {handleNumberFormat(inputDifference as number, true)}
                          {symbol}
                        </Box>
                      ) : (
                        <NoData />
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PracticeSummaryTable
