import React, { useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { breakpoints } from 'src/utils/constants'
import { CategoryTypeFromMetricId } from './util/helper'
import { MetricId } from 'src/utils/golfConstants'
import { SummarySingleItem } from 'src/store/summarySlice'
import Box from '@material-ui/core/Box'
import CategorySelector from './categorySelector'
import { HeadingContainer } from 'src/components/subscriptionFlow/payment/common'
import MuiContainer from '@material-ui/core/Container'
import PracticeDashboardFilters from '../header/PracticeDashboardFilters'
import PracticeDistribution from './practiceDistribution'
import PracticeResults from './practiceResults'
import PracticeStrokesGainedGraph from './strokesGained'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import Typography from '@material-ui/core/Typography'
import { TranslationContext } from 'src/utils/TranslationContext'
import { getGreeting } from 'src/utils/helpers'
import {
  getPracticeSummary,
  getPracticeTimeSpent,
  practiceSummaryComposedDataSelector,
  practiceSummaryLoadingSelector,
  practiceSummaryPracticeResultsSelector,
  practiceSummarySubCategoryData,
} from 'src/store/practiceSlice'
import { playerNameSelector } from 'src/store/playerSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  userIsTrialingSelector,
  userPlanSelector,
} from 'src/store/subscriptionSlice'
import useAppState from 'src/hooks/useAppState'
import useBenchmark from 'src/hooks/useBenchmark'
import useBreakpoints from 'src/hooks/useBreakpoints'
import { useTranslation } from 'react-i18next'

const I18N_KEY = 'PracticeDashboard'

const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100%;
  position: absolute;
  flex-direction: column;
`

const DashHero = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.background.black};
  `
)

const HeroContent = styled(MuiContainer)<{ $addPagePadding: boolean }>(
  ({ theme, $addPagePadding }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: ${theme.spacing(12)}px;
    padding-top: ${theme.spacing($addPagePadding ? 20 : 12)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      flex-direction: column-reverse;
      padding-bottom: ${theme.spacing(5)}px;
      padding-top: ${theme.spacing($addPagePadding ? 14 : 6)}px;
      align-items: center;
    }
  `
)

const HeroText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      text-align: center;
      margin: ${theme.spacing(2, 0, 0)};
    }
  `
)

const Content = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(6, 0, 20)};
    background-color: ${theme.palette.background.xlight};
  `
)

const ContentContainer = styled(MuiContainer)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding-left: 0;
      padding-right: 0;
    }
  `
)

const SpaceBetweenContainer = styled.div(
  () => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  `
)

const PracticeDashboard: React.FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPracticeTimeSpent())
    dispatch(getPracticeSummary())
  }, [dispatch])

  const { t } = useTranslation()
  const { isDesktop } = useBreakpoints()
  const { isPlayer } = useAppState()
  const userNotSubscribed = !useSelector(userPlanSelector)
  const isPlayerTrialing = useSelector(userIsTrialingSelector)

  const { firstName } = useSelector(playerNameSelector)
  const { greetingLabels } = useContext(TranslationContext)!
  const greeting = greetingLabels[getGreeting()]

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { benchmarkId, ...benchmarkInfo } = useBenchmark()

  const [selectedCategory, setSelectedCategory] = useState(MetricId.All)
  const category = CategoryTypeFromMetricId(selectedCategory)

  const practiceSummaryLoading = useSelector(practiceSummaryLoadingSelector)
  const practiceResults = useSelector(practiceSummaryPracticeResultsSelector)

  const categoryData = useSelector(practiceSummaryComposedDataSelector)[
    category
  ]
  const subCategoryData = useSelector(practiceSummarySubCategoryData(category))

  const parentCategories = [
    MetricId.All,
    MetricId.Approach,
    MetricId.Drives,
    MetricId.Short,
    MetricId.Putt,
  ]
  const isSubcategory = !parentCategories.includes(selectedCategory)

  const data = isSubcategory
    ? (subCategoryData[selectedCategory] as SummarySingleItem)
    : categoryData

  const addPagePadding = isPlayer && (userNotSubscribed || isPlayerTrialing)

  const handleCategoryChange = (category: MetricId) => {
    setSelectedCategory(category)
  }

  const practiceResultsSubCategory = practiceResults.filter(result => {
    if (parentCategories.includes(selectedCategory)) {
      return result.category === category
    } else {
      return result.subcategories.includes(selectedCategory)
    }
  })

  const filteredPracticeResults =
    selectedCategory !== MetricId.All
      ? practiceResultsSubCategory
      : practiceResults

  return (
    <Container>
      <DashHero>
        <HeroContent $addPagePadding={addPagePadding}>
          <Box>
            <HeroText variant="h1">
              {greeting} {firstName}
            </HeroText>
            {isDesktop && <PracticeDashboardFilters />}
          </Box>
          {/* <ComplianceDonut loading /> */}
        </HeroContent>
      </DashHero>
      <Content>
        <ContentContainer>
          <Box mb={8}>
            <SpaceBetweenContainer>
              <HeadingContainer>
                <Box mb={2}>
                  <ReferencedTitle
                    title={t(
                      `${I18N_KEY}.trendGraphs`,
                      'Strokes Gained Trend Graph'
                    )}
                  />
                </Box>
              </HeadingContainer>
              <CategorySelector
                selectedCategory={selectedCategory}
                onChange={handleCategoryChange}
              />
            </SpaceBetweenContainer>
            <PracticeStrokesGainedGraph
              strokesGainedData={data}
              practiceResults={practiceResults}
              category={selectedCategory}
              isSubcategory={isSubcategory}
              isLoading={practiceSummaryLoading}
            />
          </Box>
          <PracticeDistribution />
          <PracticeResults
            isLoading={practiceSummaryLoading}
            practiceResults={filteredPracticeResults}
          />
        </ContentContainer>
      </Content>
    </Container>
  )
}

export default PracticeDashboard
