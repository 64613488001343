import React from 'react'

interface GradientProps {
  id: string
  color: string
}

export const PositiveGradient: React.FC<GradientProps> = ({ id, color }) => (
  <linearGradient id={`positive-average-${id}`} x1="0" y1="0" x2="0" y2="1">
    <stop offset="0%" stopOpacity={0.3} stopColor={color} />
    <stop offset="10%" stopOpacity={0.2} stopColor={color} />
    <stop offset="30%" stopOpacity={0.1} stopColor={color} />
    <stop offset="95%" stopOpacity={0.04} stopColor={color} />
  </linearGradient>
)

export const NegativeGradient: React.FC<GradientProps> = ({ id, color }) => (
  <linearGradient id={`negative-average-${id}`} x1="0" y1="0" x2="0" y2="1">
    <stop offset="0%" stopOpacity={0.04} stopColor={color} />
    <stop offset="65%" stopOpacity={0.1} stopColor={color} />
    <stop offset="85%" stopOpacity={0.2} stopColor={color} />
    <stop offset="95%" stopOpacity={0.3} stopColor={color} />
  </linearGradient>
)
