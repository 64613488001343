import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { UserType } from 'src/utils/constants'
import { userTypeInfoSelector } from 'src/store/playerSlice'

const useAppState = () => {
  const match = useRouteMatch<{ playerUuid: string }>(
    '/:lang/player/:playerUuid'
  )
  const playerUuid = match?.params.playerUuid
  const { userType, completedProfile } = useSelector(userTypeInfoSelector)

  const isCoach = userType === UserType.Coach
  const isPlayer = userType === UserType.Player

  const isCoachView = isCoach && !!playerUuid

  return { isCoach, isPlayer, playerUuid, isCoachView, completedProfile }
}

export default useAppState
