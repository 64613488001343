import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TooltipProps } from 'recharts'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import {
  DashIcon,
  CircleIcon,
  ScratchIcon,
  TooltipText,
  TooltipTitle,
  DifferenceText,
  DifferenceIcon,
  TooltipContainer,
  TooltipPracticeActivityText,
} from 'src/components/graph/common'
import { getAbsolutelyFixedNumber } from 'src/utils/helpers'
import { DECIMAL_PLACES, DateFormats } from 'src/utils/constants'
import { TranslationContext } from 'src/utils/TranslationContext'
import styled, { css } from 'styled-components'

const I18N_KEY = 'GraphComposedTooltip'

interface Props extends TooltipProps<number, string> {
  benchmarkName: string
  referencePoint: string
}

const PracticesBox = styled(Box)(
  () => css`
    overflow-y: scroll;
    scrollbar-color: white transparent;
  `
)

const TooltipContent: React.FC<Props> = ({
  payload: data,
  benchmarkName,
  referencePoint,
}) => {
  const { t } = useTranslation()
  const { format } = useContext(TranslationContext)!

  const dataValues = data && data[0]

  const player = dataValues?.payload?.value
  const average = dataValues?.payload?.average
  const rollingAverage = dataValues?.payload.rollingAverage
  const benchmark = dataValues?.payload?.benchmark
  const roundTitle = dataValues?.payload?.roundTitle
  const datePlayed = dataValues?.payload?.datePlayed
  const roundType = dataValues?.payload?.roundType
  const events = dataValues?.payload?.events ?? []

  const playerIsNull = player === null

  const playerValue = player as number
  const averageValue = average as number
  const rollingAverageValue = rollingAverage as number
  const benchmarkValue = benchmark as number

  const isGreater = averageValue > benchmarkValue
  const absDifference = getAbsolutelyFixedNumber(averageValue - benchmarkValue)

  return (
    <TooltipContainer>
      <Box px={2}>
        <TooltipTitle>{roundTitle}</TooltipTitle>
        <Typography variant="caption" component="p" color="textSecondary">
          {datePlayed && format(datePlayed, DateFormats.Tooltip)}
        </Typography>

        {roundType === 'Practice' && (
          <PracticesBox>
            {events.map((session: any, index: number) => (
              <React.Fragment key={`plan-${index}`}>
                <TooltipText variant="h6">
                  <DashIcon />
                  {session.planName}
                </TooltipText>
                {session?.activities &&
                  session.activities.map((activityId: string) => (
                    <TooltipPracticeActivityText key={activityId}>
                      {t(`Enums:Activity.${activityId}`)}
                    </TooltipPracticeActivityText>
                  ))}
              </React.Fragment>
            ))}
          </PracticesBox>
        )}

        {roundType !== 'Practice' && (
          <Box display="flex" justifyContent="space-between" my={1}>
            <TooltipText variant="h6">
              <CircleIcon $valueIsNull={playerIsNull} />
              <Trans i18nKey={`${I18N_KEY}.thisRound`}>This round</Trans>
            </TooltipText>
            {playerIsNull ? (
              <Typography component="p" variant="h6" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.noShots`}>No shots</Trans>
              </Typography>
            ) : (
              <TooltipText $isValue>
                {playerValue?.toFixed(DECIMAL_PLACES)}
              </TooltipText>
            )}
          </Box>
        )}
      </Box>
      {roundType !== 'Practice' && (
        <>
          <Box
            px={2}
            pt={1}
            display="flex"
            borderTop="1px solid"
            borderColor="#282828"
            justifyContent="space-between"
          >
            <TooltipText variant="h6">
              <ScratchIcon />
              {referencePoint}
            </TooltipText>
            <TooltipText $isValue>0.00</TooltipText>
          </Box>
          <Box px={2} display="flex" justifyContent="space-between">
            <TooltipText variant="h6">
              <DashIcon />
              <Trans i18nKey={`${I18N_KEY}.rollingAverage`}>
                Rolling Average
              </Trans>
            </TooltipText>
            <TooltipText $isValue>
              {rollingAverageValue?.toFixed(DECIMAL_PLACES)}
            </TooltipText>
          </Box>
          <Box px={2} display="flex" justifyContent="space-between">
            <TooltipText variant="h6">
              <DashIcon $isScratch />
              {benchmarkName}
            </TooltipText>
            <TooltipText $isValue>
              {benchmarkValue?.toFixed(DECIMAL_PLACES)}
            </TooltipText>
          </Box>
          {!playerIsNull && (
            <Box px={2} display="flex" justifyContent="space-between">
              <TooltipText variant="h6">
                <DifferenceIcon />
                To {benchmarkName}
              </TooltipText>
              <DifferenceText $isGreater={isGreater}>
                {absDifference}
              </DifferenceText>
            </Box>
          )}
        </>
      )}
    </TooltipContainer>
  )
}

export default TooltipContent
