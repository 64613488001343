import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Paper, Typography } from '@material-ui/core'

const I18N_KEY = 'EvaluationCard'

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    width: 300px;
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(1)}px;
    padding: ${theme.spacing(3)}px;
    color: ${theme.palette.text.white};
    background-color: ${theme.palette.background.darkestGrey};

    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
)

const List = styled.ul(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0, 3)};
    padding-left: ${theme.spacing(3)}px;

    li {
      margin: ${theme.spacing(0, 0, 1)};

      :last-child {
        margin: 0;
      }
    }
  `
)

const CurrentBox = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    margin-top: auto;
    border-radius: 4px;
    align-items: center;
    align-content: center;
    justify-content: center;
    padding: ${theme.spacing(1.5, 2)};
    border: 1px solid ${theme.palette.background.grey};
  `
)

const EvaluationCard = () => {
  const { t } = useTranslation()

  const features: string[] = useMemo(
    () => t(`Enums:PlanFeatures.evaluate`, { returnObjects: true }),
    [t]
  )

  const description = useMemo(() => t(`Enums:PlanDescription.evaluate`), [t])

  return (
    <StyledPaper>
      <Typography variant="h3">
        <Trans i18nKey={`${I18N_KEY}.evaluation`}>Evaluation</Trans>
      </Typography>
      <Typography>
        <Trans i18nKey={`${I18N_KEY}.fiveFree`}>5 Rounds Free</Trans>
      </Typography>
      <Box mt={2}>
        <Typography variant="h3">
          <Trans i18nKey={`${I18N_KEY}.free`}>Free</Trans>
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="h6" component="p">
          {description}
        </Typography>
      </Box>
      <List>
        {features.map((feature, i) => (
          <li key={i}>
            <Typography variant="h6" component="p">
              {feature}
            </Typography>
          </li>
        ))}
      </List>
      <CurrentBox>
        <Typography variant="body2">
          <Trans i18nKey={`${I18N_KEY}.Select`}>Current</Trans>
        </Typography>
      </CurrentBox>
    </StyledPaper>
  )
}

export default React.memo(EvaluationCard)
