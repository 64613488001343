import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion'

import { Box, Typography } from '@material-ui/core'

import {
  reportRadarDataSelector,
  reportTotalSGDataSelector,
} from 'src/store/reportSlice'
import { TabButton } from 'src/modules/common'
import { CategoryType } from 'src/utils/golfConstants'
import { tabVariants, defaultTransition } from 'src/utils/animations'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'
import RadarComparison from 'src/components/dataDisplay/RadarComparison'
import StrokesGainedGraph from 'src/modules/reports/ReportDetail/StrokesGained/StrokesGainedGraph'

const I18N_KEY = 'ReportStrokesGained'
const LAYOUT_ID = 'report-strokes-gained'

interface ReportStrokesGainedProps {
  benchmarkName: string
  referencePoint: string
}

const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const ReportStrokesGained: React.FC<ReportStrokesGainedProps> = ({
  benchmarkName,
  referencePoint,
}) => {
  const [showTrends, setShowTrends] = useState(false)

  const { t } = useTranslation()

  const radarData = useSelector(reportRadarDataSelector)
  const totalStrokesGained = useSelector(reportTotalSGDataSelector)

  const handleShowTrends = () => {
    setShowTrends(true)
  }

  const handleShowRadar = () => {
    setShowTrends(false)
  }

  return (
    <>
      <HeadingContainer>
        <Box>
          <Typography variant="h3">
            <Trans i18nKey={`${I18N_KEY}.sg`}>Strokes Gained</Trans>
          </Typography>
          <Typography variant="caption" component="p" color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.vs`}>
              VS {{ referencePoint }} (0.0)
            </Trans>
          </Typography>
        </Box>
        <AnimateSharedLayout>
          <Box>
            <TabButton selected={!showTrends} onClick={handleShowRadar}>
              <Trans i18nKey={`${I18N_KEY}.radarTitle`}>Radar</Trans>
              {!showTrends && <ActiveBar layoutId={LAYOUT_ID} />}
            </TabButton>
            <TabButton selected={showTrends} onClick={handleShowTrends}>
              <Trans i18nKey={`${I18N_KEY}.trendsTitle`}>Trends</Trans>
              {showTrends && <ActiveBar layoutId={LAYOUT_ID} />}
            </TabButton>
          </Box>
        </AnimateSharedLayout>
      </HeadingContainer>
      <Box mt={2}>
        <AnimatePresence exitBeforeEnter initial={false} custom={showTrends}>
          <motion.div
            exit="exit"
            initial="enter"
            animate="visible"
            custom={showTrends}
            variants={tabVariants}
            transition={defaultTransition}
            key={showTrends ? 'composed-strokes' : 'radar-comparison'}
          >
            {showTrends ? (
              <StrokesGainedGraph
                width="100%"
                height={220}
                payload={totalStrokesGained}
                title={t(`Enums:CategoryType.${CategoryType.All}`)}
              />
            ) : (
              <RadarComparison
                data={radarData}
                isRoundSummary={false}
                benchmarkName={benchmarkName}
              />
            )}
          </motion.div>
        </AnimatePresence>
      </Box>
    </>
  )
}

export default ReportStrokesGained
