import React from 'react'

interface Props {
  stroke: string
}

const Ring: React.FC<Props> = ({ stroke, ...props }) => (
  <svg
    fill="none"
    viewBox="0 0 150 150"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      stroke={stroke}
      strokeWidth="10"
      d="M74.5 5C112.884 5 144 36.1162 144 74.5C144 112.884 112.884 144 74.5 144C36.1162 144 5 112.884 5 74.5C5 36.1162 36.1162 5 74.5 5Z"
    />
  </svg>
)

export default React.memo(Ring)
