import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'

import {
  Title as CommonTitle,
  FieldGroup,
} from 'src/modules/player-settings/common'
import AccountCloseConfirmDialog from 'src/modules/player-settings/account/AccountCloseConfirmDialog'
import { openToast, getSuccessToast, getErrorToast } from 'src/store/toastSlice'
import { closeAccount } from 'src/store/userSlice'
import { logout } from 'src/store/authSlice'

const I18N_KEY = 'SettingsCloseAccount'

const Title = styled(CommonTitle)`
  margin-bottom: 0;
`

const Button = styled(MuiButton)(
  ({ theme }) => css`
    .MuiButton-label {
      color: ${theme.palette.error.main};
    }
  `
)

const CloseAccount: React.FC = props => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)

  const handleCloseAccountClick = () => setConfirmDialogOpen(true)

  const handleCloseConfirmDialog = () => setConfirmDialogOpen(false)
  const handleActionConfirmDialog = async () => {
    setInProgress(true)
    try {
      await dispatch(closeAccount())
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.closeAccountSuccessToast`,
              'Your account has been removed'
            )
          )
        )
      )
      await dispatch(logout())
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.passwordChangeErrorToast`,
              'Error closing your account'
            )
          )
        )
      )
    } finally {
      setInProgress(false)
    }
  }

  return (
    <Box {...props}>
      <FieldGroup>
        <Title variant="h4">
          <Trans i18nKey={`${I18N_KEY}.title`}>Close Account</Trans>
        </Title>
        <Box mb={4}>
          <Trans i18nKey={`${I18N_KEY}.description`}>
            Remove all data and access to your profile from Circles
          </Trans>
        </Box>
        <Button variant="outlined" onClick={handleCloseAccountClick}>
          <Trans i18nKey={`${I18N_KEY}.closeAccountButtonLabel`}>
            Close Account
          </Trans>
        </Button>
      </FieldGroup>
      <AccountCloseConfirmDialog
        inProgress={inProgress}
        open={confirmDialogOpen}
        handleClose={handleCloseConfirmDialog}
        handleAction={handleActionConfirmDialog}
      />
    </Box>
  )
}

export default CloseAccount
