import styled, { css, DefaultTheme } from 'styled-components'

import MuiPaper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import FormLabel, { FormLabelProps } from '@material-ui/core/FormLabel'

import HookFormTextField from 'src/components/inputs/HookFormTextField'

export const AutocompletePaper = styled(MuiPaper)(
  ({
    theme,
    $isDark = true,
  }: {
    theme: DefaultTheme
    $isDark?: boolean
  }) => css`
    background-color: ${$isDark
      ? theme.palette.background.black
      : theme.palette.background.paper};
    color: ${$isDark ? theme.palette.text.white : theme.palette.text.primary};
  `
)

const getFormCss = ({
  theme,
  $isDark = true,
}: {
  theme: DefaultTheme
  $isDark?: boolean
}) => css`
  & {
    background-color: ${$isDark
      ? theme.palette.background.black
      : theme.palette.background.paper};
    border-radius: ${theme.spacing(0.75)}px;

    .MuiInputBase-input {
      color: ${$isDark ? theme.palette.text.white : theme.palette.text.primary};
      font-size: ${theme.typography.pxToRem(16)};

      &::placeholder {
        color: ${theme.palette.text.secondary};
        opacity: 1;
      }

      &:-webkit-autofill {
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0),
          inset 0 0 0 1000px ${theme.palette.background.black};
        -webkit-text-fill-color: ${theme.palette.text.white};
      }
    }

    .MuiAutocomplete-endAdornment path {
      fill: ${$isDark
        ? theme.palette.text.white
        : theme.palette.background.black};
    }

    .MuiInputBase-root.Mui-disabled {
      background-color: unset;

      .MuiOutlinedInput-notchedOutline {
        border-color: unset;
      }
    }
  }
`

interface SubscriptionTextFieldProps {
  $isDark?: boolean
}
export const SubscriptionHookedTextField =
  styled(HookFormTextField)<SubscriptionTextFieldProps>(getFormCss)

export const SubscriptionTextField =
  styled(TextField)<SubscriptionTextFieldProps>(getFormCss)

interface SubscriptionFieldLabelProps extends FormLabelProps {
  $isDark?: boolean
}

export const SubscriptionFieldLabel = styled(
  FormLabel
)<SubscriptionFieldLabelProps>(
  ({ theme, $isDark = true }) => css`
    display: block;
    margin: ${theme.spacing(3, 0, 1)};
    color: ${$isDark ? theme.palette.text.white : theme.palette.text.primary};
  `
)
