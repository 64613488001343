import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { AnimateSharedLayout } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { swapIO } from 'src/store/summarySlice'
import { DECIMAL_PLACES } from 'src/utils/constants'
import {
  ActiveBar,
  CheckIcon,
  LargeLayoutButton,
} from 'src/components/layout/SharedLayoutComponents'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { FocusAreaIO, MetricId } from 'src/utils/golfConstants'
import { TranslationContext } from 'src/utils/TranslationContext'
import { openToast, getErrorToast, getSuccessToast } from 'src/store/toastSlice'

const I18N_KEY = 'OpportunitySwap'

interface Props {
  open: boolean
  metricId: MetricId
  handleClose: () => void
  opportunities: FocusAreaIO[]
}

const ConfirmButton = styled(LoaderButton)`
  width: 90px;
`

const OpportunitySwap: React.FC<Props> = ({
  open,
  metricId,
  handleClose,
  opportunities,
}) => {
  const [inProgress, setInProgress] = useState(false)
  const [selectedOpportunity, setSelectedOpportunity] = useState('')

  const { t } = useTranslation()
  const { metricLabels } = useContext(TranslationContext)!

  const dispatch = useDispatch()

  const getOpportunitySelectionHandler = (metricId: string) => () => {
    setSelectedOpportunity(metricId)
  }

  const setOpportunities = async () => {
    setInProgress(true)
    try {
      await dispatch(swapIO(selectedOpportunity as MetricId, metricId))
      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.successToast`, {
              selectedOpportunity: metricLabels[metricId],
              defaultValue:
                '{{ selectedOpportunity }} was set successfully as an opportunity',
            })
          )
        )
      )
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.errorToast`, 'Could not swap opportunity')
          )
        )
      )
    }
    setInProgress(false)
    setSelectedOpportunity('')
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
      <DialogContent>
        <Typography gutterBottom variant="h3" id="dialog-title">
          <Trans i18nKey={`${I18N_KEY}.title`}>
            Select an opportunity to swap
          </Trans>
        </Typography>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.description`}>
            Improvement opportunities are limited to three. This allows you to
            focus on the things that are going to have the most impact on your
            game.
          </Trans>
        </Typography>
        <Box mt={2}>
          <AnimateSharedLayout>
            {opportunities.map(opportunity => {
              const metricId = opportunity?.metricId
              const difference =
                opportunity?.difference?.toFixed(DECIMAL_PLACES)

              const name = metricLabels[metricId]
              const isSelected = selectedOpportunity === metricId

              return (
                <LargeLayoutButton
                  fullWidth
                  disableRipple
                  key={metricId}
                  color="primary"
                  selected={isSelected}
                  onClick={getOpportunitySelectionHandler(metricId)}
                >
                  <Typography>
                    {name}{' '}
                    {opportunity?.difference && <strong>({difference})</strong>}
                  </Typography>
                  {isSelected && (
                    <>
                      <CheckIcon />
                      <ActiveBar layoutId="benchmark-selection" />
                    </>
                  )}
                </LargeLayoutButton>
              )
            })}
          </AnimateSharedLayout>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans i18nKey={`${I18N_KEY}.cancel`}>Cancel</Trans>
        </Button>
        <ConfirmButton
          color="primary"
          variant="contained"
          loading={inProgress}
          onClick={setOpportunities}
          disabled={!selectedOpportunity}
        >
          <Trans i18nKey={`${I18N_KEY}.confirm`}>Confirm</Trans>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  )
}

export default OpportunitySwap
