import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  getPlans,
  hasError,
  plansSelector,
  requestIsInProgress,
} from 'src/store/plansSlice'
import { BillingDuration } from 'src/utils/subscriptionConstants'

const usePlans = (
  billingDuration: BillingDuration = BillingDuration.Annually
) => {
  const dispatch = useDispatch()

  const hasRequestError = useSelector(hasError)
  const currentPlans = useSelector(plansSelector)
  const inProgress = useSelector(requestIsInProgress)

  if (!currentPlans && !inProgress && !hasRequestError) {
    dispatch(getPlans())
  }

  const plans = useMemo(
    () => currentPlans?.filter(plan => billingDuration === plan.duration),
    [currentPlans, billingDuration]
  )

  return {
    plans,
    isLoaded: !inProgress && (hasRequestError || !!currentPlans),
  }
}

export default usePlans
