import { useState, useMemo, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch, useSelector } from 'react-redux'
import {
  useStripe,
  useElements,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js'

import { Routes } from 'src/utils/constants'
import {
  CardInfo,
  FormFields,
  SubscriptionFlowData,
} from 'src/components/subscriptionFlow/types'
import useAppState from 'src/hooks/useAppState'
import { getPlayer } from 'src/store/playerSlice'
import { localizeRoutePath } from 'src/utils/i18n'
import { getSummary } from 'src/store/summarySlice'
import { plansSelector } from 'src/store/plansSlice'
import { getPractices } from 'src/store/practiceSlice'
import { openToast, getErrorToast } from 'src/store/toastSlice'
import { Billing, NewSubscriptionPayload } from 'src/models/subscription'
import { createPaymentSchema } from 'src/utils/validationSchemas'
import { getRound, getRounds, roundSelector } from 'src/store/roundSlice'
import { BillingDuration, PlanType } from 'src/utils/subscriptionConstants'
import { createSubscription, dataSelector } from 'src/store/subscriptionSlice'
import { useFlags } from 'launchdarkly-react-client-sdk'

const I18N_KEY = 'SubscriptionFlowCard'

interface UsePaymentData extends SubscriptionFlowData {
  total: number
  discount: number
  subtotal: number
  promoCode: string
}

type UsePaymentProps = {
  data: UsePaymentData
  onNext: (data?: SubscriptionFlowData) => void
  onChange: (data: SubscriptionFlowData) => void
}

export const useStripePayment = ({
  data,
  onNext,
  onChange,
}: UsePaymentProps) => {
  const stripe = useStripe()
  const dispatch = useDispatch()
  const elements = useElements()
  const { teeLayup } = useFlags()
  const { pathname } = useLocation()
  const { t, i18n } = useTranslation()

  const { playerUuid } = useAppState()
  const plans = useSelector(plansSelector)
  const subscription = useSelector(dataSelector)

  const { isOpen, rounds, selectedRound } = useSelector(roundSelector)

  const [cardInfo, setCardInfo] = useState<CardInfo>({})
  const [hasError, setHasError] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const validationSchema = useMemo(() => createPaymentSchema(t), [t])
  const { billingDuration, promoCode, discount, total, subtotal } = data

  const { getValues, formState, control, errors } = useForm<FormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      nameOnCard: '',
      fullName: '',
      address: '',
      city: '',
      country: {
        name: '',
        dialCode: '',
        code: '',
      },
      postcode: '',
      ...data.billing,
    },
  })

  useEffect(() => {
    const infoValues = Object.values(cardInfo)
    setHasError(
      infoValues.length !== 3 ||
        infoValues.findIndex(info => !!info.error) !== -1 ||
        !formState.isValid
    )
  }, [cardInfo, formState.isValid, getValues])

  const handleChange = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => {
    const { elementType } = event
    setCardInfo({
      ...cardInfo,
      [elementType]: event,
    })
  }

  const handleSubscriptionSubmit = async (promotionCode?: string) => {
    if (submitting || hasError || !stripe || !elements) {
      // Stripe.js has not loaded yet.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardNumberElement)

    if (!cardElement) {
      return
    }

    const { nameOnCard, ...billingRest } = getValues()
    const billing: Billing = {
      fullName: billingRest.fullName,
      address: billingRest.address,
      city: billingRest.city,
      country: billingRest.country,
      postcode: billingRest.postcode,
    }

    setSubmitting(true)
    try {
      const payload = await stripe.createToken(cardElement, {
        name: nameOnCard,
        address_zip: billing.postcode,
      })

      if (payload.error) {
        throw payload.error
      }
      if (payload.token) {
        const planType = data.plan?.planType as PlanType

        const trackData = {
          total,
          discount,
          subtotal,
          shipping: 0,
          revenue: total,
          coupon: promoCode,
          affiliation: 'stripe',
          order_id: subscription?.subscriptionUuid,
          products: [
            {
              category: 'Subscriptions',
              product_id: data.plan?.planStripeUuid,
              name: `${t(`Enums:PlanType.${planType}`)} (${
                data.plan?.duration
              })`,
            },
          ],
        }

        const body: NewSubscriptionPayload = {
          billing,
          planType,
          promotionCode,
          billingDuration,
          paymentToken: payload.token.id,
          trackingData: trackData,
        }
        await dispatch(createSubscription(body, playerUuid))

        let routePath = pathname

        if (playerUuid) {
          await dispatch(getPlayer(playerUuid))
          routePath = pathname.replace(`/player/${playerUuid}`, '/')
        } else {
          await dispatch(getPlayer())
        }

        switch (routePath) {
          case localizeRoutePath(Routes.Dashboard):
            await dispatch(getSummary())
            break
          default:
            if (pathname.includes(Routes.Categories)) {
              await dispatch(getSummary())
            }

            if (pathname.includes(Routes.Activity)) {
              await Promise.all([
                dispatch(getRounds(0, teeLayup)),
                dispatch(getPractices()),
              ])

              // Edge case where round dialog has been opened from a link and does not get loaded with the getRounds()
              if (
                isOpen &&
                !rounds.find(({ uuid }) => uuid === selectedRound?.uuid)
              ) {
                await dispatch(
                  getRound(selectedRound?.uuid as string, teeLayup)
                )
              }
            }
        }

        if (onNext) {
          onNext()
        }
      }
    } catch (error: any) {
      const code = error.message
      let message: string
      const errorKey = `Enums:SubscriptionError.${code}`
      if (i18n.exists(errorKey)) {
        message = t(errorKey)
      } else {
        message = t(
          `${I18N_KEY}.createSubscriptionErrorMessage`,
          'Error creating payment'
        )
      }

      dispatch(openToast(getErrorToast(message)))
    } finally {
      setSubmitting(false)
    }
  }

  const handleBillingDurationChange = (billingDuration: BillingDuration) => {
    const currentPlan = data.plan
    const updatedPlan =
      plans?.find(
        item =>
          item.planType === currentPlan?.planType &&
          item.duration !== currentPlan?.duration
      ) || null

    onChange({
      ...data,
      billingDuration,
      plan: updatedPlan,
    })
  }

  return {
    stripe,
    elements,
    handleChange,
    handleBillingDurationChange,
    handleSubscriptionSubmit,
    submitting,
    errors,
    control,
    hasError,
    isDirty: formState.isDirty,
  }
}
