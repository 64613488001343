import React, { useState, useEffect, ChangeEvent } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'

import Close from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import MuiPaper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'

import {
  Dialog,
  Toolbar,
  DoneIcon,
  StepInfo,
  MainContentBackground,
} from 'src/modules/common'
import {
  PracticeStatus,
  ActivityPayload,
  practiceDialogOpen,
  practiceDataSelector,
  saveExistingPractice,
  updateDialogVisibility,
  practiceStatusSelector,
  selectedPracticeLoadingSelector,
} from 'src/store/practiceSlice'
import Entry from 'src/modules/practice/entry'
import useAppState from 'src/hooks/useAppState'
import Summary from 'src/modules/practice/summary'
import { ArrowForward } from 'src/components/common'
import LoaderButton from 'src/components/inputs/LoaderButton'
import PracticeError from 'src/modules/practice/PracticeError'
import { openToast, getErrorToast } from 'src/store/toastSlice'
import GenericLoader from 'src/components/layout/GenericLoader'
import ActivityTable from 'src/modules/practice/entry/ActivityTable'
import { fadeVariants, defaultTransition } from 'src/utils/animations'
import InstructionDialog from 'src/modules/practice/InstructionDialog'
import { Activity, CategoryType, ActivityId } from 'src/utils/golfConstants'
import { breakpoints, Languages, Routes } from 'src/utils/constants'

const I18N_KEY = 'Practice'

interface FormValues {
  date: Date
  activities: {
    [key: string]: string[]
  }
}

const Footer = styled.footer(
  ({ theme }) =>
    css`
      top: auto;
      bottom: 0;
      width: 100%;
      position: fixed;
      background-color: #fff;
      padding: ${theme.spacing(2, 0)};
      box-shadow: 0px 0px 15px rgba(43, 42, 98, 0.1);

      & > div {
        display: flex;
      }

      ${theme.breakpoints.down(breakpoints.mobile)} {
        left: 0;
        right: 0;
      }
    `
)

const LogButton = styled(LoaderButton)`
  width: 148px;
  margin-left: auto;
`

const DashButton = styled(Button)`
  width: 227px;
  margin-left: auto;
`

const ButtonContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    margin-bottom: ${theme.spacing(2)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(0, 2)};
    }

    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(0, 5)};
    }
  `
)

const TableContainer = styled(MuiPaper)(
  ({ theme }) => css`
    overflow: scroll;
    margin: ${theme.spacing(6, 0, 14)};
    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(6, 2, 14)};
    }
  `
)

const NO_ACTIVITIES_ERROR = 'You must fill in at least one activity'

const Practice: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const [showInstructions, setShowInstructions] = useState(false)
  const [selectedActivity, setSelectedActivity] = useState<ActivityId>(
    ActivityId.DrivingDistance
  )

  const history = useHistory()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const steps = [
    t(`${I18N_KEY}.step1`, 'Practice Activities'),
    t(`${I18N_KEY}.step2`, 'Summary'),
  ]

  const { isCoachView, playerUuid } = useAppState()
  const dialogOpen = useSelector(practiceDialogOpen)
  const practiceStatus = useSelector(practiceStatusSelector)
  const { practice, activities } = useSelector(practiceDataSelector)
  const isLoading = useSelector(selectedPracticeLoadingSelector)

  const hasPracticeInfo = !!practice
  const isValidPractice = practiceStatus === PracticeStatus.Valid

  const { control, setValue, formState, handleSubmit } = useForm<FormValues>()
  const { isDirty, isSubmitting } = formState

  const handleFormSubmission = handleSubmit(
    async ({ date, activities: formActivities }) => {
      if (isDirty || !practice?.hasSubmitted) {
        const activityData: ActivityPayload[] = []

        Object.keys(formActivities).forEach(key => {
          formActivities[key].forEach((activity, index) => {
            if (activity || parseInt(activity) === 0) {
              activityData.push({
                input: Number(activity),
                activityId: activities[key][index].activityId,
                // activityName used for tracking users in the backend.
                activityName: i18n.t(
                  `Enums:Activity.${activities[key][index].activityId}`,
                  {
                    lng: Languages.English,
                  }
                ),
              })
            }
          })
        })

        try {
          if (activityData.length === 0) {
            throw new Error(NO_ACTIVITIES_ERROR)
          }

          await dispatch(
            saveExistingPractice(practice?.uuid as string, date, activityData)
          )

          setCurrentStep(1)
        } catch (error: any) {
          if (error.message === NO_ACTIVITIES_ERROR) {
            dispatch(
              openToast(
                getErrorToast(
                  t(`${I18N_KEY}.noActivitiesError`, NO_ACTIVITIES_ERROR)
                )
              )
            )
          } else {
            dispatch(
              openToast(
                getErrorToast(
                  t(`${I18N_KEY}.genericError`, 'Something went wrong')
                )
              )
            )
          }
        }
      } else {
        setCurrentStep(1)
      }
    }
  )

  const onPuttInputChange =
    (name: string) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = Number(event.target.value)

      if (isNaN(value) || !event.target.value) {
        setValue(name, '')
      } else if (value <= 10 && value >= 0) {
        setValue(name, value)
      }
    }

  const closeDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: false }))
    if (hasPracticeInfo) {
      history.push(
        isCoachView
          ? Routes.CoachPlayerPractices.replace(':playerUuid', playerUuid ?? '')
          : Routes.Practices
      )
    }
  }

  const generateHandleShowInstructions = (activity: ActivityId) => () => {
    setSelectedActivity(activity)
    setShowInstructions(true)
  }

  const handleHideInstructions = () => {
    setShowInstructions(false)
  }

  const generateStepClick = (index: number) => () => {
    switch (index) {
      case 0:
        setCurrentStep(0)
        break
      case 1:
        setCurrentStep(1)
        break
      default:
        setCurrentStep(0)
        break
    }
  }

  const generateStepDisabled = (index: number) => {
    switch (index) {
      case 0:
        return false
      case 1:
        return !hasPracticeInfo || !practice?.hasSubmitted
      default:
        return false
    }
  }

  // Reset dialog state
  useEffect(() => {
    if (dialogOpen) {
      if (practice?.hasSubmitted) {
        setCurrentStep(1)
      } else {
        setCurrentStep(0)
      }
    }
  }, [practice, dialogOpen])

  return (
    <Dialog open={dialogOpen}>
      {isLoading ? (
        <GenericLoader
          text={t(`${I18N_KEY}.loadingText`, 'Getting practice data')}
        />
      ) : (
        <>
          <AppBar elevation={0} color="default" position="relative">
            <Container>
              <Toolbar>
                <IconButton onClick={closeDialog}>
                  <Close />
                </IconButton>
              </Toolbar>
              {isValidPractice && (
                <ButtonContainer>
                  {steps.map((step, index) => {
                    const completed = index < currentStep
                    return (
                      <StepInfo
                        key={index}
                        color="primary"
                        $completed={completed}
                        $selected={index === currentStep}
                        onClick={generateStepClick(index)}
                        disabled={generateStepDisabled(index)}
                      >
                        {completed ? <DoneIcon /> : `${index + 1}. `} {step}
                      </StepInfo>
                    )
                  })}
                </ButtonContainer>
              )}
            </Container>
          </AppBar>
          {practiceStatus === PracticeStatus.CreatingNew && (
            <GenericLoader
              text={
                <Trans i18nKey="PracticeLoading.loadingText">
                  Getting your practice goals
                </Trans>
              }
            />
          )}
          {practiceStatus === PracticeStatus.Error && (
            <PracticeError handleClose={closeDialog} />
          )}
          {isValidPractice && (
            <>
              <MainContentBackground>
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    exit="exit"
                    initial="enter"
                    animate="visible"
                    variants={fadeVariants}
                    key={steps[currentStep]}
                    transition={defaultTransition}
                  >
                    {currentStep === 0 && (
                      <Entry control={control} date={practice?.date}>
                        <Container>
                          <TableContainer elevation={0}>
                            {Object.keys(activities).map(key => (
                              <ActivityTable
                                key={key}
                                control={control}
                                activities={activities[key]}
                                category={key as CategoryType}
                                hasPracticeInfo={hasPracticeInfo}
                                onPuttInputChange={onPuttInputChange}
                                generateHandleShowInstructions={
                                  generateHandleShowInstructions
                                }
                              />
                            ))}
                          </TableContainer>
                        </Container>
                        <InstructionDialog
                          open={showInstructions}
                          activityId={selectedActivity}
                          handleClose={handleHideInstructions}
                        />
                      </Entry>
                    )}
                    {currentStep === 1 && (
                      <Summary
                        date={practice?.date}
                        activities={practice?.activities as Activity[]}
                      />
                    )}
                  </motion.div>
                </AnimatePresence>
              </MainContentBackground>
              <Footer>
                <Container>
                  {currentStep === 0 ? (
                    <LogButton
                      color="primary"
                      variant="contained"
                      loading={isSubmitting}
                      endIcon={<ArrowForward />}
                      onClick={handleFormSubmission}
                    >
                      <Trans i18nKey={`${I18N_KEY}.logPracticeLabel`}>
                        Log Practice
                      </Trans>
                    </LogButton>
                  ) : (
                    <DashButton
                      color="primary"
                      variant="contained"
                      onClick={closeDialog}
                      endIcon={<ArrowForward />}
                    >
                      <Trans i18nKey={`${I18N_KEY}.dashLabel`}>
                        Continue to Dashboard
                      </Trans>
                    </DashButton>
                  )}
                </Container>
              </Footer>
            </>
          )}
        </>
      )}
    </Dialog>
  )
}

export default Practice
