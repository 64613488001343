import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import MuiCloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialog, { DialogProps } from '@material-ui/core/Dialog'

import useBreakpoints from 'src/hooks/useBreakpoints'
import SmallLogo from 'src/assets/svgComponents/SmallLogo'
import UpgradeButton from 'src/components/membership/UpgradeButton'

const I18N_KEY = 'UpgradeDialog'

const Dialog = styled(MuiDialog)(
  ({ theme }) => css`
    .MuiPaper-root {
      background-color: ${theme.palette.background.black};
      color: ${theme.palette.text.white};
      max-width: 100%;
      width: 616px;
      ${theme.breakpoints.down('xs')} {
        width: 100%;
      }
    }
  `
)

const DialogContent = styled(MuiDialogContent)`
  padding: 0;
`

const CloseIcon = styled(MuiCloseIcon)(
  ({ theme }) => css`
    path {
      fill: ${theme.palette.background.paper};
    }
  `
)

const Content = styled.div(
  ({ theme }) => css`
    width: 400px;
    display: flex;
    margin: 0 auto;
    max-width: 100%;
    text-align: center;
    flex-direction: column;
    padding: ${theme.spacing(3, 0)};
  `
)

const StyledLogo = styled(SmallLogo)`
  width: 48px;
  opacity: 0.5;
`

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    right: ${theme.spacing(3)}px;
    top: ${theme.spacing(3)}px;
    position: absolute;
  `
)

const Header = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.spacing(8)}px;
    position: relative;
  `
)

interface UpgradeDialogProps extends DialogProps {
  title?: string
  description?: string
  onUpgradeOpen?: () => void
}

const UpgradeDialog: React.FC<UpgradeDialogProps> = ({
  title,
  onClose,
  children,
  description,
  onUpgradeOpen,
  ...dialogProps
}) => {
  const { t } = useTranslation()
  const { theme, useMediaQuery } = useBreakpoints()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  const dialogTitle =
    title || t(`${I18N_KEY}.title`, 'Upgrade your subscription to continue')
  const dialogDescription =
    description ||
    t(
      `${I18N_KEY}.description`,
      'You are unable to make this action with your current subscription. Please upgrade your account to continue'
    )

  return (
    <Dialog onClose={onClose} fullScreen={fullScreen} {...dialogProps}>
      <Header>
        <CloseButton onClick={evt => onClose && onClose(evt, 'backdropClick')}>
          <CloseIcon />
        </CloseButton>
        <Box textAlign="center">
          <StyledLogo />
        </Box>
      </Header>
      <DialogContent>
        <Content>
          <Typography variant="h4" gutterBottom>
            {dialogTitle}
          </Typography>
          <Typography>{dialogDescription}</Typography>
          <Box>
            <UpgradeButton onUpgradeOpen={onUpgradeOpen} />
          </Box>
        </Content>
      </DialogContent>
    </Dialog>
  )
}

export default UpgradeDialog
