import { useContext } from 'react'
import { useSelector } from 'react-redux'

import { Gender } from 'src/utils/constants'
import { TranslationContext } from 'src/utils/TranslationContext'
import { benchmarkIdSelector, playerSelector } from 'src/store/playerSlice'

const useBenchmark = () => {
  const { getBenchmarkName, getReferencePoint } =
    useContext(TranslationContext)!

  const benchmarkId = useSelector(benchmarkIdSelector)
  const benchmarkName = getBenchmarkName(benchmarkId)

  const { gender } = useSelector(playerSelector)

  const isMale = gender === Gender.Male

  const referencePoint = getReferencePoint(benchmarkId, isMale)

  return {
    benchmarkId,
    benchmarkName,
    referencePoint,
  }
}

export default useBenchmark
