import React from 'react'

const TeeGolfBall: React.FC = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="21"
    fill="none"
    viewBox="0 0 12 21"
    {...props}
  >
    <circle
      cx="6"
      cy="6"
      r="5.25"
      fill="#fff"
      stroke="#fff"
      strokeWidth="1.5"
    />
    <path
      fill="#fff"
      d="M5.027 16.18l-.738-3.32h3.429l-.738 3.32c-.232 1.045-1.72 1.045-1.953 0z"
    />
  </svg>
)

export default React.memo(TeeGolfBall)
