import React, { useEffect, useState, MouseEvent } from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Menu from '@material-ui/core/Menu'
import Button from '@material-ui/core/Button'
import MuiPaper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import MuiMenuItem from '@material-ui/core/MenuItem'
import MuiIconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {
  getCoachPlayerByUuid,
  coachPlayerOverviewSelector,
} from 'src/store/coachOverviewSlice'
import { coachAdminSelector } from 'src/store/coachSlice'
import useBreakpoints from 'src/hooks/useBreakpoints'
import { updatePlayer } from 'src/store/playerSlice'
import PlayerLoading from 'src/modules/coach-dash/player/PlayerLoading'
import ActivityTimeline from 'src/modules/coach-dash/player/ActivityTimeline'
import PlayerCardHeader from 'src/modules/coach-dash/player/PlayerCardHeader'
import UnconnectedPlayer from 'src/modules/coach-dash/player/UnconnectedPlayer'
import { Player as PlayerForCoach } from 'src/models/player'
import { api } from 'src/utils/api'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'
import { resetSummary } from 'src/store/summarySlice'
import { resetForecast } from 'src/store/forecastSlice'

const I18N_KEY = 'PlayerCard'

interface Props {
  playerUuid: string
  playerFirstName: string
  playerLastName: string
  playerAccepted: boolean
  openDeleteDialog: (uuid: string) => void
}

const Paper = styled(MuiPaper)`
  position: relative;
`

const IconButton = styled(MuiIconButton)(
  ({ theme }) => css`
    top: 8px;
    right: 16px;
    position: absolute;

    svg {
      fill: #000;
    }

    ${theme.breakpoints.down('xs')} {
      top: 4px;
      right: 4px;
    }
  `
)

const MenuItem = styled(MuiMenuItem)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
  `
)

const CardActions = styled.div`
  display: flex;

  button {
    flex: 1;
  }

  > .MuiDivider-vertical {
    height: auto;
  }
`

const Player: React.FC<Props> = ({
  playerUuid,
  playerFirstName,
  playerLastName,
  playerAccepted,
  openDeleteDialog,
}) => {
  const [loading, setLoading] = useState(true)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [playerForCoach, setPlayerForCoach] = useState<PlayerForCoach>()

  const { isSmallScreen } = useBreakpoints()

  const { isAdmin } = useSelector(coachAdminSelector)

  const history = useHistory()
  const dispatch = useDispatch()

  const {
    email,
    player,
    status,
    timeline,
    inviteUuid,
    progressDonut,
    benchmarkDetails,
  } = useSelector(coachPlayerOverviewSelector(playerUuid))

  const isAcceptedPlayer = playerAccepted

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const handleRemovePlayer = () => {
    openDeleteDialog(inviteUuid as string)
    closeMenu()
  }

  const generateNavigateToPlayer =
    (toActivity: boolean = false) =>
    () => {
      if (playerForCoach && (isAcceptedPlayer || isAdmin)) {
        // // Set player details before navigating
        dispatch(updatePlayer(playerForCoach))
        // Uninitialise the player summary so that it can be re-fetched on load
        dispatch(resetSummary())
        dispatch(resetForecast())
        history.push(
          toActivity
            ? `/player/${playerUuid}/activity`
            : `/player/${playerForCoach?.uuid}`
        )
      }
    }

  useEffect(() => {
    const getPlayer = async () => {
      if (isAcceptedPlayer || isAdmin) {
        const response = await api.get(`overview/player/${playerUuid}`)
        const playerFetched: PlayerForCoach =
          getSinglePayloadFromResponse(response)
        setPlayerForCoach(playerFetched)
        await dispatch(getCoachPlayerByUuid(playerUuid))
      } else {
        await dispatch(getCoachPlayerByUuid(playerUuid))
        setPlayerForCoach(player)
      }

      setLoading(false)
    }

    if (!playerForCoach) {
      getPlayer()
    }
  }, [playerForCoach, dispatch, playerUuid, isAcceptedPlayer, player, isAdmin])

  return loading ? (
    <PlayerLoading
      firstName={playerFirstName}
      lastName={playerLastName}
      generateNavigateToPlayer={generateNavigateToPlayer}
    />
  ) : (
    <Paper elevation={0}>
      <IconButton onClick={openMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleRemovePlayer}>
          <Trans i18nKey={`${I18N_KEY}.removePlayer`}>Remove Player</Trans>
        </MenuItem>
      </Menu>
      <PlayerCardHeader
        email={email}
        player={player}
        status={status}
        progressDonut={progressDonut}
        benchmarkDetails={benchmarkDetails}
        isAcceptedPlayer={isAcceptedPlayer}
        generateNavigateToPlayer={generateNavigateToPlayer}
      />
      {isAcceptedPlayer ? (
        <>
          <Divider />
          <ActivityTimeline
            timeline={timeline}
            generateNavigateToPlayer={generateNavigateToPlayer}
          />
        </>
      ) : (
        !isSmallScreen && <UnconnectedPlayer />
      )}
      {isSmallScreen && isAcceptedPlayer && (
        <CardActions>
          <Button color="primary" onClick={generateNavigateToPlayer()}>
            <Trans i18nKey={`${I18N_KEY}.playerDash`}>Player Dashboard</Trans>
          </Button>
          <Divider orientation="vertical" />
          <Button color="primary" onClick={generateNavigateToPlayer(true)}>
            <Trans i18nKey={`${I18N_KEY}.activity`}>Activity</Trans>
          </Button>
        </CardActions>
      )}
    </Paper>
  )
}

export default Player
