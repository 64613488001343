import styled, { css } from 'styled-components'
import { Form as FormikForm } from 'formik'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import DeleteButton from 'src/components/inputs/DeleteButton'

export const Form = styled(FormikForm)`
  display: block;
`

export const Content = styled(Paper).attrs({
  elevation: 0,
})(
  ({ theme }) => css`
    padding: ${theme.spacing(6, 5, 8, 5)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(3, 2)};
    }
  `
)

export const FieldGroup = styled.div(
  ({ theme }) => css`
    width: 100%;
    max-width: 480px;

    .MuiToggleButton-label {
      text-transform: capitalize;
    }

    ${theme.breakpoints.down('xs')} {
      max-width: unset;
    }
  `
)

export const ActionRow = styled(Grid)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(4)}px;
  `
)

interface TitleProps {
  $gutterTop?: boolean | number
  $gutterBottom?: boolean | number
}
export const Title = styled(Typography)<TitleProps>(
  ({ theme, $gutterTop, $gutterBottom = 3 }) => css`
    font-size: ${theme.typography.pxToRem(20)};
    ${$gutterTop
      ? `margin-top: ${
          typeof $gutterTop === 'boolean'
            ? theme.spacing(3)
            : theme.spacing($gutterTop)
        }px;`
      : ''}
    ${$gutterBottom
      ? `margin-bottom: ${
          typeof $gutterBottom === 'boolean'
            ? theme.spacing(3)
            : theme.spacing($gutterBottom)
        }px;`
      : ''}
  `
)

export const Row = styled(Grid)(
  ({ theme }) => css`
    && {
      padding-bottom: ${theme.spacing(3)}px;
    }
  `
)

export const CenteredRow = styled(Row)`
  align-items: center;
  display: flex;
`

export const Container = styled(Grid)`
  max-width: 700px;
  width: 100%;
`

export const RemoveButton = styled(DeleteButton)(
  ({ theme }) => css`
    min-width: auto;
    padding-left: ${theme.spacing(1)}px;
    padding-right: ${theme.spacing(1)}px;
    width: auto;
  `
)
