import React, { useContext } from 'react'
import { Control } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import InfoIcon from '@material-ui/icons/Info'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'
import MuiIconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import FocusLabel from 'src/components/dataDisplay/FocusLabel'

import {
  isFloat,
  isNumber,
  acceptNumbersOnly,
  getMeasurementSymbol,
} from 'src/utils/helpers'
import {
  Activity,
  ActivityId,
  Measurement,
  CategoryType,
} from 'src/utils/golfConstants'
import { DECIMAL_PLACES, breakpoints } from 'src/utils/constants'
import NoData from 'src/components/dataDisplay/NoData'
import HelpTooltip from 'src/modules/practice/HelpTooltip'
import {
  DataRow,
  TableCell,
  TitleCell,
} from 'src/components/dataDisplay/tableStyles'
import { TranslationContext } from 'src/utils/TranslationContext'
import HookFormTextField from 'src/components/inputs/HookFormTextField'

const I18N_KEY = 'ActivityTable'

interface Props {
  control: Control
  activities: Activity[]
  category: CategoryType
  hasPracticeInfo: boolean
  generateHandleShowInstructions: (activity: ActivityId) => () => void
  onPuttInputChange: (
    name: string
  ) => (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

interface NameCellProps {
  $hasPracticeInfo: boolean
}

const NameCell = styled(TitleCell)<NameCellProps>(
  ({ theme, $hasPracticeInfo }) => css`
    width: ${$hasPracticeInfo ? 60 : 80}%;
    min-width: 240px !important;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(1.5, 2)};
      & > p {
        line-height: unset;
      }
    }
  `
)

const ValueCell = styled(TableCell)<NameCellProps>(
  ({ theme }) => css`
    width: 160px;
    min-width: 160px !important;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(1.5, 2)};
      & > p {
        line-height: unset;
      }
    }
  `
)

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    ${theme.breakpoints.down(breakpoints.mobile)} {
      font-size: ${theme.typography.pxToRem(14)};
    }
  `
)

const IconButton = styled(MuiIconButton)(
  ({ theme }) => css`
    color: ${theme.palette.primary.light};
    margin: ${theme.spacing(0, 1, 0, -2)};
  `
)

const TextField = styled(HookFormTextField)`
  width: 90px;
  margin: 0 auto;
`

const StyledFocusLabel = styled(FocusLabel)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(1)}px;
  `
)

const ActivityTable: React.FC<Props> = ({
  control,
  category,
  activities,
  hasPracticeInfo,
  onPuttInputChange,
  generateHandleShowInstructions,
}) => {
  const { t } = useTranslation()
  const { activityLabels, categoryLabels, measurementLabels } =
    useContext(TranslationContext)!

  return (
    <Table>
      <TableHead>
        <TableRow>
          <NameCell $hasPracticeInfo={hasPracticeInfo}>
            <Typography variant="caption" color="textSecondary">
              {categoryLabels[category]}
            </Typography>
          </NameCell>
          {hasPracticeInfo && (
            <ValueCell align="center">
              <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="caption" color="textSecondary">
                  <Trans i18nKey={`${I18N_KEY}.practiceGoalColumn`}>
                    Practice Goal
                  </Trans>
                </Typography>
                <HelpTooltip />
              </Box>
            </ValueCell>
          )}
          <ValueCell align="center">
            <Typography variant="caption" color="textSecondary">
              <Trans i18nKey={`${I18N_KEY}.yourPractice`}>Your Practice</Trans>
            </Typography>
          </ValueCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {activities.map(({ unit, focus, input, target, activityId }, index) => {
          const name = `activities.${category}[${index}]`
          const symbol = getMeasurementSymbol(unit, measurementLabels)
          const handleShowInstructions =
            generateHandleShowInstructions(activityId)

          const isOutOfTen = unit === Measurement.PuttsOfTen
          const onKeyDown = isOutOfTen ? acceptNumbersOnly : undefined
          const onChange = isOutOfTen ? onPuttInputChange(name) : undefined

          const targetIsFloat = isFloat(target as number)
          const defaultValue = isNumber(input) ? input?.toString() : ''

          return (
            <DataRow key={activityId}>
              <NameCell
                onClick={handleShowInstructions}
                $hasPracticeInfo={hasPracticeInfo}
              >
                <Box display="flex">
                  <Box display="flex" alignItems="center">
                    <IconButton onClick={handleShowInstructions} size="small">
                      <InfoIcon />
                    </IconButton>
                    <StyledTypography>
                      {activityLabels[activityId]}
                    </StyledTypography>
                  </Box>
                  {focus && (
                    <StyledFocusLabel text={t(`${I18N_KEY}.focus`, 'Focus')} />
                  )}
                </Box>
              </NameCell>
              {hasPracticeInfo && (
                <ValueCell align="center" onClick={handleShowInstructions}>
                  <StyledTypography>
                    {target ? (
                      <>
                        {targetIsFloat
                          ? target?.toFixed(DECIMAL_PLACES)
                          : target}
                        {symbol}
                      </>
                    ) : (
                      <NoData />
                    )}
                  </StyledTypography>
                </ValueCell>
              )}
              <ValueCell align="center">
                <TextField
                  name={name}
                  type="number"
                  control={control}
                  onChange={onChange}
                  defaultValue={defaultValue}
                  InputProps={{
                    onKeyDown: onKeyDown,
                    endAdornment: symbol && (
                      <InputAdornment position="end">{symbol}</InputAdornment>
                    ),
                  }}
                />
              </ValueCell>
            </DataRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default React.memo(ActivityTable)
