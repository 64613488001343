import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { PerformanceIndicator } from 'src/utils/golfConstants'
import PerformanceIndicatorTable from 'src/components/dataDisplay/PerformanceIndicatorTable'

const I18N_KEY = 'RoundStatistics'

interface Props {
  performanceIndicators: PerformanceIndicator[]
}

const Container = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 16)};
  `
)

const Title = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 3)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

/*
 * Duplicate of rounds summary for Partner API implementation
 * TODO: Refactor to remove duplication and allow partner API to use existing code
 * The duplication allows up to easily remove some UI elements and make our own request
 * to the backend partner's API endpoint
 */
const Statistics: React.FC<Props> = ({ performanceIndicators }) => {
  return (
    <Container>
      <Title variant="h3">
        <Trans i18nKey={`${I18N_KEY}.statistics`}>Statistics</Trans>
      </Title>
      <PerformanceIndicatorTable
        isOpen
        showCategoryHeader={false}
        isSingleRound
        performanceIndicators={performanceIndicators}
      />
    </Container>
  )
}

export default Statistics
