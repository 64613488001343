import React, { useCallback, useContext, useEffect, useState } from 'react'
import useAppState from '../../../hooks/useAppState'
import { getMonthlyReport } from '../../../service/monthlyReportService'
import { MonthlyReport } from '../../../models/monthly-report'
import MonthlyReportComponent from './index'
import GenericLoader from '../../../components/layout/GenericLoader'
import { TranslationContext } from '../../../utils/TranslationContext'
import Player from '../../../models/player'

interface MonthlyReportContainerProps {
  startDate: number
  endDate: number
  player: Player & { email: string }
  closeDialog: () => void
  title: string
  createdAt: Date
}

const I18N_KEY = 'ReportDetailDialog'

// @ts-ignore
const MonthlyReportContainer: React.FC<MonthlyReportContainerProps> = ({
  startDate,
  endDate,
  player,
  closeDialog,
  title,
  createdAt,
}) => {
  const { isPlayer } = useAppState()
  const { t } = useContext(TranslationContext)!
  const [currentMonthReport, setCurrentMonthReport] = useState<MonthlyReport>()
  const [lastMonthReport, setLastMonthReport] = useState<MonthlyReport>()
  const [isCurrentMonthReportLoading, setIsCurrentMonthReportLoading] =
    useState(true)
  const [isLastMonthReportLoading, setIsLastMonthReportLoading] = useState(true)

  const getCurrentMonthlyReport = useCallback(async () => {
    const currentReportStartDate = new Date(startDate)
    const currentReportEndDate = new Date(endDate)
    currentReportEndDate.setDate(1)
    currentReportEndDate.setHours(0, 0, 0, 0)

    const currentMonthlyReport = await getMonthlyReport(
      currentReportStartDate,
      currentReportEndDate,
      isPlayer,
      player.uuid
    )
    setCurrentMonthReport(currentMonthlyReport)
    setIsCurrentMonthReportLoading(false)
  }, [isPlayer, player, startDate, endDate])

  const getLastMonthlyReport = useCallback(async () => {
    const lastReportStartDate = new Date(startDate)
    lastReportStartDate.setDate(1)
    lastReportStartDate.setMonth(lastReportStartDate.getMonth() - 1)
    lastReportStartDate.setHours(0, 0, 0, 0)
    const lastReportEndDate = new Date(endDate)
    lastReportEndDate.setDate(1)
    lastReportEndDate.setHours(0, 0, 0, 0)

    const lastMonthlyReport = await getMonthlyReport(
      lastReportStartDate,
      lastReportEndDate,
      isPlayer,
      player.uuid
    )

    if (
      lastMonthlyReport.summary.focusAreas.length >= 1 &&
      lastMonthlyReport.summary.performanceIndicators.length >= 1
    ) {
      setLastMonthReport(lastMonthlyReport)
      setIsLastMonthReportLoading(false)
    }
  }, [isPlayer, player, startDate, endDate])

  useEffect(() => {
    getLastMonthlyReport()
    getCurrentMonthlyReport()
  }, [getLastMonthlyReport, getCurrentMonthlyReport])

  return !isCurrentMonthReportLoading && !isLastMonthReportLoading ? (
    <MonthlyReportComponent
      currentMonthReport={currentMonthReport!}
      lastMonthReport={lastMonthReport!}
      player={player}
      closeDialog={closeDialog}
      title={title}
      createdAt={createdAt}
    />
  ) : (
    <GenericLoader text={t(`${I18N_KEY}.loadingText`, 'Loading report data')} />
  )
}

export default MonthlyReportContainer
