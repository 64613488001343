import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useLocation, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiCircularProgress from '@material-ui/core/CircularProgress'

import { api } from 'src/utils/api'
import { Routes } from 'src/utils/constants'
import { openToast, getSuccessToast, getErrorToast } from 'src/store/toastSlice'
import { ReactComponent as LoadingLogo } from 'src/assets/images/loadingLogo.svg'

const I18N_KEY = 'VerifyEmail'

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    min-height: 100%;
    position: absolute;
    align-items: center;
    padding-bottom: 74px;
    flex-direction: column;
    justify-content: center;
    background-color: ${theme.palette.background.black};
  `
)

const WhiteText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};
  `
)

const CircularProgress = styled(MuiCircularProgress)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(1)}px;

    .MuiCircularProgress-svg {
      color: ${theme.palette.background.paper};
    }
  `
)

const VerifyEmail: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { search } = useLocation()

  useEffect(() => {
    const emailMatch = /email=([^&]*)/.exec(search)
    const tokenMatch = /token=([^&]*)/.exec(search)

    const verifyEmail = async (email: string, token: string) => {
      try {
        await api.post('user/verification/verify', { email, token })
        dispatch(
          openToast(
            getSuccessToast(
              t(
                `${I18N_KEY}.successToast`,
                'Email successfully verified. Sign in to continue'
              )
            )
          )
        )
      } catch {
        dispatch(
          openToast(
            getErrorToast(
              t(
                `${I18N_KEY}.errorToast`,
                'We could not verify your email. Please try again later'
              )
            )
          )
        )
      }
      history.push(Routes.SignIn)
    }

    if (emailMatch && tokenMatch) {
      verifyEmail(emailMatch[1], tokenMatch[1])
    } else {
      history.push(Routes.SignIn)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <LoadingLogo />
      <Box display="flex" alignItems="center" mt={3}>
        <WhiteText variant="h5">
          <Trans i18nKey={`${I18N_KEY}.verifying`}>Verifying your email</Trans>
        </WhiteText>
        <CircularProgress size={20} thickness={5} />
      </Box>
    </Container>
  )
}

export default VerifyEmail
