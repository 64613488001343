import React, { ChangeEvent } from 'react'
import styled, { css } from 'styled-components'

import MuiPagination, { PaginationProps } from '@material-ui/lab/Pagination'

interface Props extends PaginationProps {
  page: number
  count: number
  paginationLimit: number
  onPageChange: (event: ChangeEvent<unknown>, newPage: number) => void
}

const StyledPagination = styled(MuiPagination)(
  ({ theme }) => css`
    .MuiPaginationItem-textPrimary.Mui-selected {
      color: ${theme.palette.primary.main};
    }

    .MuiPaginationItem-root {
      font-weight: 400;
    }
  `
)

const Pagination: React.FC<Props> = ({
  page,
  count,
  children,
  onPageChange,
  paginationLimit,
  ...props
}) => (
  <StyledPagination
    {...props}
    page={page}
    color="primary"
    shape="rounded"
    variant="outlined"
    onChange={onPageChange}
    count={Math.ceil(count / paginationLimit)}
  />
)

export default Pagination
