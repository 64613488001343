import styled from 'styled-components'

import MuiSkeleton from '@material-ui/lab/Skeleton'

const Skeleton = styled(MuiSkeleton)`
  border-radius: 6px;
  background-color: #fff;

  ::after {
    transform: translateX(-100%);
    animation: MuiSkeleton-keyframes-wave 0.8s linear 0.5s infinite;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(0, 0, 0, 0.06),
      transparent
    );
  }
`

export default Skeleton
