import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { api } from 'src/utils/api'
import { plansSelector } from 'src/store/plansSlice'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { BillingDuration } from 'src/utils/subscriptionConstants'
import {
  SubscriptionFlowData,
  ChinaPaymentMethod,
} from 'src/components/subscriptionFlow/types'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'

const I18N_KEY = 'useChinaPayment'

type UsePaymentData = {
  data: SubscriptionFlowData
  paymentMethod: ChinaPaymentMethod
  onChange: (data: SubscriptionFlowData) => void
}

export const useChinaPayment = ({
  data,
  paymentMethod,
  onChange,
}: UsePaymentData) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const plans = useSelector(plansSelector)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const goToPaymentPortal = async () => {
    setIsSubmitting(true)
    let url
    try {
      const response = await api.get(
        `subscription/${paymentMethod}/url/${data.plan?.planStripeUuid}`
      )
      url = getSinglePayloadFromResponse(response)
      window.location.href = url
    } catch {
      setIsSubmitting(false)
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.urlError`,
              'Something went wrong, please try again later'
            )
          )
        )
      )
    }
  }

  const handleBillingDurationChange = (billingDuration: BillingDuration) => {
    const currentPlan = data.plan
    const updatedPlan =
      plans?.find(
        item =>
          item.planType === currentPlan?.planType &&
          item.duration !== currentPlan?.duration
      ) || null

    onChange({
      ...data,
      billingDuration,
      plan: updatedPlan,
    })
  }

  return {
    isSubmitting,
    goToPaymentPortal,
    handleBillingDurationChange,
  }
}
