import {
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js'

import { Plan } from 'src/models/plan'
import { Billing } from 'src/models/subscription'
import { BillingDuration } from 'src/utils/subscriptionConstants'

export interface CardInfo {
  cardCvc?: StripeCardCvcElementChangeEvent
  cardNumber?: StripeCardNumberElementChangeEvent
  cardExpiry?: StripeCardExpiryElementChangeEvent
}

export interface CardFormFields {
  postcode: string
  nameOnCard: string
}

export interface FormFields extends Billing, CardFormFields {}

export interface SubscriptionFlowData {
  billing?: Billing
  plan: Plan | null
  billingDuration: BillingDuration
}

export interface FlowComponentProps {
  onNext: (data?: SubscriptionFlowData) => void
  onChange: (data: SubscriptionFlowData) => void
  data: SubscriptionFlowData
}

export enum ChinaPaymentMethod {
  WeChat = 'wechat',
  Alipay = 'alipay',
}
