import { Notification, NotificationType } from '../models/notification'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from './index'
import { getPayloadFromResponse, getUserInfo } from '../utils/helpers'
import { api } from '../utils/api'
import { playerSelector } from './playerSlice'
import { coachSelector } from './coachSlice'
import { RootState } from './rootReducer'

interface InitialState {
  notifications: Notification[]
  isLoading: boolean
  error: boolean
  errorMessage: string
}

const initialState: InitialState = {
  notifications: [],
  isLoading: false,
  error: false,
  errorMessage: '',
}

type LoadingPayload = Pick<InitialState, 'isLoading'>

const SLICE_NAME = 'notification'

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<LoadingPayload>) => {
      const { isLoading } = action.payload

      state.isLoading = isLoading
    },
    updateNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload
      state.isLoading = false
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const removedNotificationId = state.notifications.findIndex(
        notification => notification.uuid === action.payload
      )
      const updatedNotifications = [...state.notifications]
      updatedNotifications.splice(removedNotificationId, 1)
      state.notifications = updatedNotifications
    },
    updateErrorState: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
      state.isLoading = false
      state.error = true
    },
  },
})

export default reducer

export const {
  updateNotifications,
  updateLoading,
  updateErrorState,
  removeNotification,
} = actions

export const getNotifications = (): AppThunk => async (dispatch, getState) => {
  dispatch(updateLoading({ isLoading: true }))

  const state = getUserInfo(getState())

  const userState = state.isPlayer
    ? playerSelector(getState())
    : coachSelector(getState())

  try {
    const response = await api.get(
      `/notification/recipient/${userState.userUuid}`
    )
    const notifications: Notification[] = getPayloadFromResponse(response)
    dispatch(updateNotifications(notifications))
  } catch (err) {
    updateErrorState('Error retrieving notifications')
  }
}

export const markNotificationAsRead =
  (notificationUuid: string): AppThunk =>
  async dispatch => {
    await api.put(`/notification/${notificationUuid}/read`)
    dispatch(removeNotification(notificationUuid))
  }

export const notificationSelector = (state: RootState) => state.notification

export const unreadNotificationsSelector = createSelector(
  notificationSelector,
  data => {
    return data.notifications
  }
)

export const unreadRoundNotificationsSelector = createSelector(
  unreadNotificationsSelector,
  notifications =>
    notifications.filter(
      notification => notification.type === NotificationType.ROUND_ADDED
    )
)

export const unreadImprovementOpportunityNotificationsSelector = createSelector(
  unreadNotificationsSelector,
  notifications =>
    notifications.filter(
      notification =>
        notification.type === NotificationType.IMPROVEMENT_OPPORTUNITY ||
        notification.type === NotificationType.IMPROVEMENT_OPPORTUNITY_AVAILABLE
    )
)

export const unreadPracticeAddedNotificationsSelector = createSelector(
  unreadNotificationsSelector,
  notifications =>
    notifications.filter(
      notification => notification.type === NotificationType.PRACTICE_ADDED
    )
)
