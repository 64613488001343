import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import LoaderButton from '../../../components/inputs/LoaderButton'
import { useDispatch, useSelector } from 'react-redux'
import { updateDialogVisibility } from '../../../store/ioSlice'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import UpgradeDialog from 'src/components/membership/UpgradeDialog'

const I18N_KEY = 'InsightGeneration'
const InsightDialogButton: React.FC = () => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const isPlayerTrialing = useSelector(userIsTrialingSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())

  const handleUpgradeDialogClose = () => setUpgradeDialogOpen(false)

  const openInsightGenerationDialog = () => {
    if (!(isPlayerTrialing || isPlayerPremium)) {
      setUpgradeDialogOpen(true)
      return
    }
    dispatch(updateDialogVisibility({ isOpen: true }))
  }

  return (
    <>
      <LoaderButton
        fullWidth
        type="submit"
        color="primary"
        variant="contained"
        loading={false}
        onClick={openInsightGenerationDialog}
      >
        <Trans i18nKey={`${I18N_KEY}.openDialog`}>Generate New Insights</Trans>
      </LoaderButton>

      <UpgradeDialog
        open={upgradeDialogOpen}
        onClose={handleUpgradeDialogClose}
        onUpgradeOpen={handleUpgradeDialogClose}
      />
    </>
  )
}

export default InsightDialogButton
