import React from 'react'
import styled, { css } from 'styled-components'
import { motion, MotionProps } from 'framer-motion'

import IconButton from '@material-ui/core/IconButton'

const Button = styled(IconButton)(
  ({ theme }) =>
    css`
      padding: 2px;
      background-color: ${theme.palette.background.darkGrey};

      &:hover {
        background-color: ${theme.palette.background.darkestGrey};
      }

      svg {
        width: 37px;
      }
    `
)

interface Props {
  burgerToggle: () => void
}

const Path = (props: MotionProps & { d?: string }) => (
  <motion.path
    {...props}
    stroke="#fff"
    strokeWidth="2"
    fill="transparent"
    strokeLinecap="round"
  />
)

const Burger: React.FC<Props> = ({ burgerToggle }) => (
  <Button onClick={burgerToggle} aria-label="Menu" disableRipple>
    <svg viewBox="0 0 42 42">
      <Path
        variants={{
          open: { d: 'M 15 15 L 27 27' },
          closed: { d: 'M 13 15 L 29 15' },
        }}
      />
      <Path
        d="M 13 21 L 29 21"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          open: { d: 'M 15 27 L 27 15' },
          closed: { d: 'M 13 27 L 29 27' },
        }}
      />
    </svg>
  </Button>
)

export default Burger
