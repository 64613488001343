import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import { Grid, Typography, ButtonBase } from '@material-ui/core'

import { BillingDuration } from 'src/utils/subscriptionConstants'
import { usePricing } from 'src/components/subscriptionFlow/usePricing'
import {
  FlowComponentProps,
  ChinaPaymentMethod,
} from 'src/components/subscriptionFlow/types'
import { useChinaPayment } from 'src/components/subscriptionFlow/useChinaPayment'
import BillingDurationSwitch from 'src/components/subscriptionFlow/BillingDurationSwitch'

import { ReactComponent as AlipayLogo } from 'src/assets/images/alipay.svg'
import { ReactComponent as WeChatLogo } from 'src/assets/images/wechat.svg'
import OrderSummary from 'src/components/subscriptionFlow/payment/OrderSummary'
import {
  PaperTitle,
  Paper,
  HeadingContainer,
  PaymentContainer,
} from 'src/components/subscriptionFlow/payment/common'

const I18N_KEY = 'ChinaPayment'

export const StyledPaper = styled(Paper)`
  min-height: 448px;
  display: flex;
  flex-direction: column;
`

const StyledOrderSummary = styled(OrderSummary)`
  min-height: 448px;
`

const MethodsContainer = styled.div(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    justify-content: space-between;
    margin: ${theme.spacing(4, 0, 0)};

    ${theme.breakpoints.down('sm')} {
      min-height: 175px;
    }

    ${theme.breakpoints.down('xs')} {
      min-height: 150px;
    }
  `
)

const PaymentMethodCard = styled(ButtonBase)<{ $selected: boolean }>(
  ({ theme, disabled, $selected }) => css`
    width: 47%;
    height: 100%;
    display: flex;
    border: 1px solid;
    position: relative;
    border-radius: 12px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-color: ${$selected
      ? theme.palette.primary.main
      : theme.palette.background.dark};
    font-size: ${theme.typography.pxToRem(20)};
    line-height: ${theme.typography.pxToRem(32)};
    color: ${disabled ? theme.palette.background.dark : '#fff'};

    svg {
      width: 48px;
      margin: ${theme.spacing(0, 0, 1.5)};

      path {
        fill: ${disabled
          ? theme.palette.background.dark
          : theme.palette.background.light};
      }
    }

    span.MuiTypography-root {
      bottom: 16px;
      position: absolute;
    }

    @media (hover: hover) {
      :hover {
        border-color: ${theme.palette.primary.main};
      }
    }

    ${theme.breakpoints.down('sm')} {
      span.MuiTypography-root {
        bottom: 12px;
      }
    }

    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(16)};
      line-height: ${theme.typography.pxToRem(26)};

      svg {
        width: 40px;
      }

      span.MuiTypography-root {
        bottom: 8px;
        font-size: ${theme.typography.pxToRem(12)};
        line-height: ${theme.typography.pxToRem(16)};
      }
    }
  `
)

const ChinaPayment: React.FC<FlowComponentProps> = ({ data, onChange }) => {
  const [paymentMethod, setPaymentMethod] = useState(ChinaPaymentMethod.Alipay)

  const { t } = useTranslation()

  const { isSubmitting, goToPaymentPortal, handleBillingDurationChange } =
    useChinaPayment({
      data,
      paymentMethod,
      onChange,
    })
  const pricing = usePricing(data.plan)

  const { billingDuration } = data

  const generateSelectPaymentMethod = (method: ChinaPaymentMethod) => () => {
    setPaymentMethod(method)
  }

  const onBillingDurationChange = (duration: BillingDuration) => {
    if (duration !== billingDuration) {
      handleBillingDurationChange(duration)
    }
  }

  return (
    <PaymentContainer>
      <HeadingContainer>
        <div>
          <Typography variant="body1">
            <Trans i18nKey={`${I18N_KEY}.checkOutLabel`}>Check out</Trans>
          </Typography>
          <Typography variant="h3">
            {t(`Enums:PlanType.${data.plan?.planType}`)}{' '}
            <Trans i18nKey={`${I18N_KEY}.membership`}>Membership</Trans>
          </Typography>
        </div>
        <BillingDurationSwitch
          value={billingDuration}
          onChange={onBillingDurationChange}
        />
      </HeadingContainer>
      <Grid container spacing={3}>
        <Grid item md={8} sm={12} xs={12}>
          <StyledPaper>
            <PaperTitle>
              <Trans i18nKey={`${I18N_KEY}.paymentMethod`}>
                Payment Method
              </Trans>
            </PaperTitle>
            <MethodsContainer>
              <PaymentMethodCard
                $selected={paymentMethod === ChinaPaymentMethod.Alipay}
                onClick={generateSelectPaymentMethod(ChinaPaymentMethod.Alipay)}
              >
                <AlipayLogo />
                <Trans i18nKey={`${I18N_KEY}.alipay`}>Alipay</Trans>
              </PaymentMethodCard>
              {/* Wechat will be added in the future. Disabled for now */}
              <PaymentMethodCard
                disabled
                $selected={paymentMethod === ChinaPaymentMethod.WeChat}
                onClick={generateSelectPaymentMethod(ChinaPaymentMethod.WeChat)}
              >
                <WeChatLogo />
                <Trans i18nKey={`${I18N_KEY}.wechat`}>WeChat pay</Trans>
                <Typography component="span">
                  <Trans i18nKey={`${I18N_KEY}.comingSoon`}>Coming soon</Trans>
                </Typography>
              </PaymentMethodCard>
            </MethodsContainer>
          </StyledPaper>
        </Grid>
        <Grid item md={4} sm={12} xs={12}>
          <StyledOrderSummary
            data={data}
            pricing={pricing}
            disablePromoCodes
            isSubmitting={isSubmitting}
            isCheckoutDisabled={!paymentMethod}
            onSubmit={goToPaymentPortal}
          />
        </Grid>
      </Grid>
    </PaymentContainer>
  )
}

export default ChinaPayment
