import React from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Controller, Control } from 'react-hook-form'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { DatePicker } from '@material-ui/pickers'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import {
  Languages,
  DateFormat,
  WEBSITE_LINK,
  WebsiteRoutes,
  breakpoints,
} from 'src/utils/constants'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'
import { Description, HeaderSection } from 'src/modules/practice/common'

const I18N_KEY = 'PracticeEntry'

interface Props {
  date?: Date
  control: Control
}

const FormDatePicker = styled(DatePicker)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(0.75)}px;
  `
)

const GuideLink = styled.a<{
  $displayFlex: boolean
}>(
  ({ theme, $displayFlex }) => css`
    align-items: center;
    display: ${!$displayFlex ? 'flex' : 'none'};

    svg {
      margin-right: ${theme.spacing(1)}px;
      color: ${theme.palette.primary.main};
    }

    ${theme.breakpoints.down(breakpoints.mobile)} {
      display: ${$displayFlex ? 'flex' : 'none'};
      margin-top: ${$displayFlex ? theme.spacing(2) : 0}px;
    }
  `
)

interface LinkProps {
  displayFlex: boolean
}

const DownloadLink: React.FC<LinkProps> = ({ displayFlex, ...props }) => (
  <GuideLink
    rel="noopener"
    target="_blank"
    href={`${WEBSITE_LINK}${WebsiteRoutes.PracticeActivityTemplate}`}
    $displayFlex={displayFlex}
    {...props}
  >
    <CloudDownloadIcon />
    <Typography variant="body2" color="primary">
      <Trans i18nKey={`${I18N_KEY}.templateLink`}>Print template</Trans>
    </Typography>
  </GuideLink>
)

const Entry: React.FC<Props> = ({ date, control, children }) => {
  const { i18n } = useTranslation()
  const defaultDate = date ? new Date(date) : new Date()

  return (
    <>
      <HeaderSection>
        <Container>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h1">
                <Trans i18nKey={`${I18N_KEY}.title`}>Log Practice</Trans>
              </Typography>
              <Description>
                <Trans i18nKey={`${I18N_KEY}.description`}>
                  Log any practice activities you completed in a session below.
                </Trans>
              </Description>
            </Box>
            <DownloadLink displayFlex={false} />
          </Box>
          <Grid direction="column" container>
            <Grid item xs={8} sm={3}>
              <Box mt={2} minWidth={290}>
                <Typography variant="caption">
                  <Trans i18nKey={`${I18N_KEY}.date`}>Date</Trans>
                </Typography>
                <Controller
                  name="date"
                  control={control}
                  defaultValue={defaultDate}
                  as={
                    <FormDatePicker
                      autoOk
                      value=""
                      fullWidth
                      name="date"
                      helperText=""
                      disableFuture
                      onChange={() => {}}
                      inputVariant="outlined"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ endAdornment: <CalendarIcon /> }}
                      format={DateFormat[i18n.language as Languages].datePicker}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Box mt={5} maxWidth={635}>
            <Typography color="textSecondary">
              <Trans i18nKey={`${I18N_KEY}.explanation1`}>
                Your practice sheet below is personalised to you based on our
                automatic data analysis.
              </Trans>
            </Typography>
            <Typography color="textSecondary">
              <Trans i18nKey={`${I18N_KEY}.explanation2`}>
                Focus areas are areas where you stand to gain a lot by
                improving, and your practice goal is an achievable target based
                on your goal and prior performance.
              </Trans>
            </Typography>
          </Box>
          <DownloadLink displayFlex />
        </Container>
      </HeaderSection>
      {children}
    </>
  )
}

export default Entry
