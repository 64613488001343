import React from 'react'

const CirclesIcon: React.FC = () => {
  return (
    <svg
      width="80"
      height="80"
      fill="none"
      viewBox="0 0 260 225"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M136.257 103.021c2.905-4.169 1.866-9.893-2.321-12.786-4.187-2.892-9.937-1.858-12.842 2.31-2.905 4.17-1.866 9.894 2.321 12.786 4.187 2.893 9.936 1.858 12.842-2.31zM152.058 118.753c2.905-4.168 1.866-9.893-2.321-12.785-4.187-2.893-9.936-1.858-12.842 2.31-2.905 4.169-1.866 9.893 2.321 12.786 4.187 2.892 9.937 1.858 12.842-2.311zM120.456 118.753c2.906-4.168 1.867-9.893-2.32-12.785-4.187-2.893-9.937-1.858-12.842 2.31-2.906 4.169-1.867 9.893 2.321 12.786 4.187 2.892 9.936 1.858 12.841-2.311zM136.257 134.482c2.905-4.169 1.866-9.893-2.321-12.786-4.187-2.892-9.937-1.858-12.842 2.311-2.905 4.168-1.866 9.893 2.321 12.785 4.187 2.893 9.936 1.858 12.842-2.31zM149.378 85.824a5.5 5.5 0 00-1.393-7.676 5.556 5.556 0 00-7.71 1.387 5.5 5.5 0 001.394 7.676 5.555 5.555 0 007.709-1.387zM164.192 101.556a5.5 5.5 0 00-1.393-7.676c-2.514-1.736-5.966-1.115-7.71 1.387a5.5 5.5 0 001.393 7.676c2.514 1.737 5.966 1.116 7.71-1.387zM118.062 85.82a5.5 5.5 0 00-1.394-7.676 5.555 5.555 0 00-7.709 1.387 5.5 5.5 0 001.393 7.676 5.556 5.556 0 007.71-1.387zM102.261 101.556a5.5 5.5 0 00-1.393-7.676 5.555 5.555 0 00-7.71 1.387 5.5 5.5 0 001.394 7.676 5.554 5.554 0 007.709-1.387zM165.18 132.757a5.5 5.5 0 00-1.394-7.676 5.555 5.555 0 00-7.709 1.388 5.498 5.498 0 001.393 7.675 5.555 5.555 0 007.71-1.387zM149.38 147.499a5.498 5.498 0 00-1.393-7.675 5.555 5.555 0 00-7.71 1.387 5.5 5.5 0 001.394 7.676 5.555 5.555 0 007.709-1.388zM102.261 131.771a5.5 5.5 0 00-1.393-7.676c-2.514-1.736-5.965-1.115-7.71 1.387a5.5 5.5 0 001.394 7.676 5.554 5.554 0 007.709-1.387zM118.062 147.499a5.5 5.5 0 00-1.394-7.675 5.554 5.554 0 00-7.709 1.387 5.5 5.5 0 001.393 7.676 5.556 5.556 0 007.71-1.388z"
        fill="#fff"
      />
    </svg>
  )
}

export default React.memo(CirclesIcon)
