import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { Box, Paper, Button, Typography } from '@material-ui/core'

import { formatCurrency } from 'src/utils/helpers'
import { BillingDuration } from 'src/utils/subscriptionConstants'

const I18N_KEY = 'MembershipCard'

interface MembershipCardProps {
  price: number
  planType: string
  isSelected: boolean
  handleSelectPlan: () => void
  billingDuration: BillingDuration
}

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    width: 300px;
    display: flex;
    flex-direction: column;
    margin: ${theme.spacing(1)}px;
    padding: ${theme.spacing(3)}px;
    color: ${theme.palette.text.white};
    background-color: ${theme.palette.background.darkestGrey};

    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
)

const PricingBox = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: flex-end;
    margin: ${theme.spacing(2, 0, 0)};

    p {
      margin-left: ${theme.spacing(0.5)}px;
    }
  `
)

const List = styled.ul(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0)};
    padding-left: ${theme.spacing(3)}px;

    li {
      margin: ${theme.spacing(0, 0, 1)};

      :last-child {
        margin: 0;
      }
    }
  `
)

const StyledButton = styled(Button)`
  margin-top: auto;
`

const MembershipCard: React.FC<MembershipCardProps> = ({
  price,
  planType,
  isSelected,
  billingDuration,
  handleSelectPlan,
}) => {
  const { t } = useTranslation()

  const features: string[] = useMemo(
    () => t(`Enums:PlanFeatures.${planType}`, { returnObjects: true }),
    [t, planType]
  )

  const description = useMemo(
    () => t(`Enums:PlanDescription.${planType}`),
    [t, planType]
  )

  const billingDurationPricingLabel = useMemo(
    () =>
      billingDuration === BillingDuration.Annually
        ? t(`${I18N_KEY}.perYear`, '/year')
        : t(`${I18N_KEY}.perQuarter`, '/quarter'),
    [t, billingDuration]
  )

  const billingDurationLabel = useMemo(
    () => t(`Enums:BillingDuration.${billingDuration}`),
    [t, billingDuration]
  )

  return (
    <StyledPaper>
      <Typography variant="h3">{t(`Enums:PlanType.${planType}`)}</Typography>
      <Typography>
        <Trans i18nKey={`${I18N_KEY}.paid`}>
          Paid {{ billingDurationLabel }}
        </Trans>
      </Typography>
      <PricingBox>
        <Typography variant="h3">{formatCurrency(price, true)}</Typography>
        <Typography>{billingDurationPricingLabel}</Typography>
      </PricingBox>
      <Box mt={3}>
        <Typography variant="h6" component="p">
          {description}
        </Typography>
      </Box>
      <List>
        {features.map((feature, i) => (
          <li key={i}>
            <Typography variant="h6" component="p">
              {feature}
            </Typography>
          </li>
        ))}
      </List>
      {isSelected ? (
        <StyledButton
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleSelectPlan}
        >
          <Trans i18nKey={`${I18N_KEY}.selected`}>Selected</Trans>
        </StyledButton>
      ) : (
        <StyledButton
          fullWidth
          color="secondary"
          variant="outlined"
          onClick={handleSelectPlan}
        >
          <Trans i18nKey={`${I18N_KEY}.Select`}>Select</Trans>
        </StyledButton>
      )}
    </StyledPaper>
  )
}

export default React.memo(MembershipCard)
