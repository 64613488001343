import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Button, { ButtonProps } from '@material-ui/core/Button'

import { BillingDuration } from 'src/utils/subscriptionConstants'

const I18N_KEY = 'BillingDurationSwitch'

const DurationButton = ({
  active,
  ...props
}: ButtonProps & { active: boolean }) => (
  <Button
    variant={active ? 'contained' : 'outlined'}
    color={active ? 'primary' : 'secondary'}
    {...props}
  />
)

const DurationButtonGroup = styled.div(
  ({ theme }) => css`
    .MuiButton-containedPrimary {
      border: 1px solid ${theme.palette.primary.main};

      @media (hover: hover) {
        :hover {
          border: 1px solid ${theme.palette.primary.light};
        }
      }
    }

    button:not(:first-child) {
      margin-left: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down('sm')} {
      margin-top: ${theme.spacing(4)}px;
    }

    ${theme.breakpoints.down(340)} {
      & > button.MuiButton-root {
        font-size: ${theme.typography.pxToRem(14)};
        line-height: ${theme.typography.pxToRem(20)};
      }
    }
  `
)

export type BillingDurationSwitchProps = {
  value: BillingDuration
  onChange: (value: BillingDuration) => void
}

const BillingDurationSwitch = ({
  value,
  onChange,
}: BillingDurationSwitchProps) => (
  <DurationButtonGroup>
    <DurationButton
      active={value === BillingDuration.Quarterly}
      onClick={() => onChange(BillingDuration.Quarterly)}
    >
      <Trans i18nKey={`${I18N_KEY}.paidQuarterlyLabel`}>Paid Quartely</Trans>
    </DurationButton>
    <DurationButton
      active={value === BillingDuration.Annually}
      onClick={() => onChange(BillingDuration.Annually)}
    >
      <Trans i18nKey={`${I18N_KEY}.paidAnnuallyLabel`}>Paid Annually</Trans>
    </DurationButton>
  </DurationButtonGroup>
)

export default BillingDurationSwitch
