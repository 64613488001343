import React from 'react'
import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

interface Props {
  errorMessage: string
}

const Card = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(16, 4)};
  `
)

const ErrorText = styled(Typography)`
  max-width: 600px;
`

const ErrorCard: React.FC<Props> = ({ errorMessage }) => (
  <Card elevation={0}>
    <ErrorText color="textSecondary" align="center">
      {errorMessage}
    </ErrorText>
  </Card>
)

export default ErrorCard
