import React, { useState, useEffect } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import {
  getPractices,
  getSinglePractice,
  selectedPracticeSelector,
  updateDialogVisibility as updatePracticeDialogVisiblity,
} from 'src/store/practiceSlice'
import {
  getRound,
  getRounds,
  selectedRoundSelector,
  updateDialogVisibility as updateRoundDialogVisiblity,
} from 'src/store/roundSlice'
import {
  userPlanSelector,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import { Routes } from 'src/utils/constants'
import { TabButton } from 'src/modules/common'
import useAppState from 'src/hooks/useAppState'
import { localizeRoutePath } from 'src/utils/i18n'
import RoundsTable from 'src/modules/activity/rounds'
import PracticeTable from 'src/modules/activity/practice'
import { tabVariants, defaultTransition } from 'src/utils/animations'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getCourseStrategies } from '../../store/courseStrategySlice'
import {
  getReport,
  getReports,
  selectedReportSelector,
  updateReportsDialogVisibility,
} from '../../store/reportSlice'
import CourseStrategyTable from '../course-strategy/courseStrategyTable'
import ReportsTable from '../reports/ReportsTable/ReportsTable'

const I18N_KEY = 'Activity'

enum Tab {
  Rounds = 'rounds',
  Practices = 'practices',
  Strategies = 'strategies',
  Reports = 'reports',
}

const ActivityContainer = styled.div<{ $addPagePadding: boolean }>(
  ({ theme, $addPagePadding }) => css`
    width: 100%;
    display: flex;
    min-height: 100%;
    position: absolute;
    background-color: ${theme.palette.background.xlight};
    padding: ${$addPagePadding
      ? theme.spacing(14, 0, 0)
      : theme.spacing(8, 0, 0)};
  `
)

const Content = styled(MuiContainer)(
  ({ theme }) => css`
    flex: 1;

    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const LAYOUT_ID = 'activity-buttons'

const Activity: React.FC = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { teeLayup } = useFlags()
  const { isPlayer, isCoachView, playerUuid } = useAppState()

  const userNotSubscribed = !useSelector(userPlanSelector)
  const playerIsTrialing = useSelector(userIsTrialingSelector)
  const addPagePadding = isPlayer && (userNotSubscribed || playerIsTrialing)

  const roundRouteMatch = useRouteMatch<{ roundUuid: string }>(
    localizeRoutePath(
      isCoachView ? Routes.CoachPlayerRoundDetail : Routes.RoundDetail
    )
  )

  const practiceRouteMatch = useRouteMatch<{ practiceUuid: string }>(
    localizeRoutePath(
      isCoachView ? Routes.CoachPlayerPracticeDetail : Routes.PracticeDetail
    )
  )
  const strategyRouteMatch = useRouteMatch<{ courseStrategyUuid: string }>(
    localizeRoutePath(
      isCoachView
        ? Routes.CoachPlayerCourseStrategyDetail
        : Routes.CourseStrategyDetail
    )
  )
  const reportRouteMatch = useRouteMatch<{ reportUuid: string }>(
    localizeRoutePath(
      isCoachView ? Routes.CoachPlayerReportDetail : Routes.ReportDetail
    )
  )

  const selectInitialTab = () => {
    if (practiceRouteMatch) {
      return Tab.Practices
    }
    if (strategyRouteMatch) {
      return Tab.Strategies
    }
    if (reportRouteMatch) {
      return Tab.Reports
    }
    return Tab.Rounds
  }

  const initialTab = selectInitialTab()
  const [currentTab, setCurrentTab] = useState(initialTab)

  const showRounds = currentTab === Tab.Rounds
  const showPractices = currentTab === Tab.Practices
  const showStrategies = currentTab === Tab.Strategies
  const showReports = currentTab === Tab.Reports

  const selectedRound = useSelector(selectedRoundSelector)
  const selectedPractice = useSelector(selectedPracticeSelector)
  const selectedReport = useSelector(selectedReportSelector)

  useEffect(() => {
    dispatch(getRounds(0, teeLayup))
    dispatch(getPractices())
    dispatch(getCourseStrategies())
    dispatch(getReports())
  }, [dispatch, teeLayup])

  const handleShowRounds = () => {
    const route = isCoachView ? Routes.CoachPlayerRounds : Routes.Rounds
    history.push(route.replace(':playerUuid', playerUuid ?? ''))
    setCurrentTab(Tab.Rounds)
  }

  const handleShowPractices = () => {
    const route = isCoachView ? Routes.CoachPlayerPractices : Routes.Practices
    history.push(route.replace(':playerUuid', playerUuid ?? ''))
    setCurrentTab(Tab.Practices)
  }

  const handleShowStrategies = () => {
    const route = isCoachView
      ? Routes.CoachPlayerCourseStrategy
      : Routes.CourseStrategy
    history.push(route.replace(':playerUuid', playerUuid ?? ''))
    setCurrentTab(Tab.Strategies)
  }

  const handleShowReports = () => {
    const route = isCoachView ? Routes.CoachPlayerReports : Routes.Reports
    history.push(route.replace(':playerUuid', playerUuid ?? ''))
    setCurrentTab(Tab.Reports)
  }

  const directToLocation = () => {
    const path = location.pathname.split(`${Routes.Activity}/`)[1]
    switch (path) {
      case Tab.Practices:
        handleShowPractices()
        break
      case Tab.Strategies:
        handleShowStrategies()
        break
      case Tab.Reports:
        handleShowReports()
        break
      case Tab.Rounds:
        handleShowRounds()
        break
    }
  }

  // TODO: Consider removing this logic and using query param to fetch within the dialogs themselves
  useEffect(() => {
    directToLocation()
    if (roundRouteMatch) {
      const { roundUuid } = roundRouteMatch.params
      if (!selectedRound) {
        dispatch(getRound(roundUuid, teeLayup))
      }
      dispatch(
        updateRoundDialogVisiblity({
          isOpen: true,
          selectedRoundUuid: roundUuid,
        })
      )
    } else if (practiceRouteMatch) {
      const { practiceUuid } = practiceRouteMatch.params
      if (!selectedPractice) {
        dispatch(getSinglePractice(practiceUuid))
      }
      dispatch(
        updatePracticeDialogVisiblity({
          isOpen: true,
          uuid: practiceUuid,
        })
      )
    } else if (reportRouteMatch) {
      const { reportUuid } = reportRouteMatch.params
      if (!selectedReport) {
        dispatch(getReport(reportUuid))
      }
      dispatch(
        updateReportsDialogVisibility({
          isOpen: true,
        })
      )
    } else {
      dispatch(
        showRounds
          ? updateRoundDialogVisiblity({ isOpen: false })
          : updatePracticeDialogVisiblity({ isOpen: false })
      )
    }
  }, [dispatch, roundRouteMatch, practiceRouteMatch, location.pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ActivityContainer $addPagePadding={addPagePadding}>
      <Content>
        <HeadingContainer>
          <Typography variant="h3" gutterBottom>
            <Trans i18nKey={`${I18N_KEY}.activity`}>Activity</Trans>
          </Typography>
          <AnimateSharedLayout>
            <Box>
              <TabButton selected={showRounds} onClick={handleShowRounds}>
                <Trans i18nKey={`${I18N_KEY}.rounds`}>Rounds</Trans>
                {showRounds && <ActiveBar layoutId={LAYOUT_ID} />}
              </TabButton>
              <TabButton selected={showPractices} onClick={handleShowPractices}>
                <Trans i18nKey={`${I18N_KEY}.practices`}>Practices</Trans>
                {showPractices && <ActiveBar layoutId={LAYOUT_ID} />}
              </TabButton>
              <TabButton
                selected={showStrategies}
                onClick={handleShowStrategies}
              >
                <Trans i18nKey={`${I18N_KEY}.strategies`}>Rounds</Trans>
                {showStrategies && <ActiveBar layoutId={LAYOUT_ID} />}
              </TabButton>
              <TabButton selected={showReports} onClick={handleShowReports}>
                <Trans i18nKey={`${I18N_KEY}.reports`}>Reports</Trans>
                {showReports && <ActiveBar layoutId={LAYOUT_ID} />}
              </TabButton>
            </Box>
          </AnimateSharedLayout>
        </HeadingContainer>
        <AnimatePresence initial={false} custom={showRounds} exitBeforeEnter>
          <motion.div
            exit="exit"
            initial="enter"
            animate="visible"
            custom={showRounds}
            variants={tabVariants}
            transition={defaultTransition}
            key={showRounds ? 'rounds-table' : 'practices-table'}
          >
            {showRounds && <RoundsTable />}
            {showPractices && <PracticeTable />}
            {showStrategies && <CourseStrategyTable />}
            {showReports && <ReportsTable />}
          </motion.div>
        </AnimatePresence>
      </Content>
    </ActivityContainer>
  )
}

export default Activity
