import React, { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { Trans } from 'react-i18next'

import CloseIcon from '@material-ui/icons/Close'
import { CircularProgress, Button, Box, Typography } from '@material-ui/core'

import { useVerifyPayment } from 'src/components/subscriptionFlow/useVerifyPayment'
import { ChinaPaymentMethod } from 'src/components/subscriptionFlow/types'
import UpgradeDialog from 'src/components/subscriptionFlow/UpgradeDialog'
import Success from 'src/components/subscriptionFlow/Success'

const I18N_KEY = 'SubscriptionFlowVerifying'

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: ${theme.palette.text.white};
    padding-top: ${theme.spacing(17.5)}px;
  `
)

type VerifyingProps = {
  isError: boolean
  onRetry: () => void
}

const Verifying = ({ isError, onRetry }: VerifyingProps) => {
  return (
    <Container>
      {isError ? (
        <>
          <Typography variant="h1">
            <Trans i18nKey={`${I18N_KEY}.errorTitle`}>Verification Error</Trans>
          </Typography>

          <Box display="flex" flexDirection="column">
            <Trans i18nKey={`${I18N_KEY}.errorDescription`}>
              We are unable to verify your payment at this time.
            </Trans>
            <Box my={4}>
              <Button color="primary" variant="contained" onClick={onRetry}>
                <Trans i18nKey={`${I18N_KEY}.retry`}>Try again</Trans>
              </Button>
            </Box>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto:support@drawmorecircles.com"
            >
              <Trans i18nKey={`${I18N_KEY}.contactSupport`}>
                Contact support
              </Trans>
            </a>
          </Box>
        </>
      ) : (
        <>
          <Typography variant="h1">
            <Trans i18nKey={`${I18N_KEY}.title`}>Verifying Payment...</Trans>
          </Typography>
          <Typography variant="body1">
            <Trans i18nKey={`${I18N_KEY}.description`}>
              Please wait a moment while we verify your payment.
            </Trans>
          </Typography>
          <Box mt={4}>
            <CircularProgress size={80} thickness={2} />
          </Box>
        </>
      )}
    </Container>
  )
}

interface ChinaPaymentVerificationProps {
  open: boolean
}

const ChinaPaymentVerification: React.FC<ChinaPaymentVerificationProps> = ({
  open,
}) => {
  const { startPolling, stopPolling, isError, close, isVerified } =
    useVerifyPayment({
      paymentMethod: ChinaPaymentMethod.Alipay, // WeChat coming later
    })

  useEffect(() => {
    if (open) {
      startPolling()
    } else {
      stopPolling()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <UpgradeDialog
      open={open}
      navButton={<CloseIcon />}
      onNavButtonClick={close}
    >
      {isVerified ? (
        <Success onNext={close} />
      ) : (
        <Verifying isError={isError} onRetry={startPolling} />
      )}
    </UpgradeDialog>
  )
}

export default ChinaPaymentVerification
