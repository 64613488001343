import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import useBreakpoints from 'src/hooks/useBreakpoints'
import { CategoryType } from 'src/utils/golfConstants'
import { ResponsiveGridxs2 } from 'src/modules/common'
import { summaryComposedDataSelector } from 'src/store/summarySlice'
import DashboardGraph from 'src/modules/player-dash/strokesGained/DashboardGraph'

const StrokesGained: React.FC = () => {
  const { t } = useTranslation()
  const { isSmallScreen } = useBreakpoints()

  const summary = useSelector(summaryComposedDataSelector)

  return (
    <ResponsiveGridxs2 container spacing={2}>
      <Grid item xs={12}>
        <DashboardGraph
          showLegend
          width="100%"
          height={220}
          payload={summary.all}
          isCollapsable={false}
          type={CategoryType.All}
          isSmallScreen={isSmallScreen}
          title={t(`Enums:CategoryType.${CategoryType.All}`)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DashboardGraph
          width="100%"
          height={200}
          payload={summary.drives}
          type={CategoryType.Drives}
          isSmallScreen={isSmallScreen}
          title={t(`Enums:CategoryType.${CategoryType.Drives}`)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DashboardGraph
          width="100%"
          height={200}
          payload={summary.approach}
          type={CategoryType.Approach}
          isSmallScreen={isSmallScreen}
          title={t(`Enums:CategoryType.${CategoryType.Approach}`)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DashboardGraph
          width="100%"
          height={200}
          payload={summary.short}
          type={CategoryType.Short}
          isSmallScreen={isSmallScreen}
          title={t(`Enums:CategoryType.${CategoryType.Short}`)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DashboardGraph
          width="100%"
          height={200}
          payload={summary.putt}
          type={CategoryType.Putt}
          isSmallScreen={isSmallScreen}
          title={t(`Enums:CategoryType.${CategoryType.Putt}`)}
        />
      </Grid>
    </ResponsiveGridxs2>
  )
}

export default StrokesGained
