import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import MuiEditIcon from '@material-ui/icons/Edit'

import useBenchmark from 'src/hooks/useBenchmark'
import { Label } from 'src/components/inputs/FormTextField'
import BenchmarkSelection from 'src/components/feedback/BenchmarkSelection'
import { getBenchmarkList, playerInfoSelector } from 'src/store/playerSlice'

const I18N_KEY = 'BenchmarkForm'

const Button = styled(MuiButton)`
  padding: 0;
  &:hover {
    background-color: transparent;
  }
`

const EditIcon = styled(MuiEditIcon)(
  ({ theme }) => css`
    path {
      fill: ${theme.palette.primary.light};
    }
  `
)

const BenchmarkForm: React.FC = () => {
  const dispatch = useDispatch()
  const { benchmarkName } = useBenchmark()
  const [dialogOpen, setDialogOpen] = useState(false)
  const handleChangeGoalClick = () => setDialogOpen(true)
  const handleDialogClose = () => setDialogOpen(false)

  const { playerType } = useSelector(playerInfoSelector)
  const playerTypeRef = useRef(playerType)

  const updateBenchmarkListAndOpenBenchmarkSelectionDialog =
    useCallback(async () => {
      await dispatch(getBenchmarkList())
      setDialogOpen(true)
    }, [dispatch])

  useEffect(() => {
    if (playerType !== playerTypeRef.current) {
      playerTypeRef.current = playerType
      updateBenchmarkListAndOpenBenchmarkSelectionDialog()
    }
  }, [playerType, updateBenchmarkListAndOpenBenchmarkSelectionDialog])

  return (
    <>
      <Box>
        <Label variant="caption">
          <Trans i18nKey={`${I18N_KEY}.goalLabel`}>Goal</Trans>
        </Label>
        <Box display="flex" alignItems="center">
          <Box>{benchmarkName}</Box>
          <Box ml={2.5}>
            <Button
              variant="text"
              onClick={handleChangeGoalClick}
              color="primary"
              disableRipple
              startIcon={<EditIcon />}
            >
              <Trans i18nKey={`${I18N_KEY}.changeGoalButtonLabel`}>
                Change Goal
              </Trans>
            </Button>
          </Box>
        </Box>
      </Box>
      <BenchmarkSelection open={dialogOpen} handleClose={handleDialogClose} />
    </>
  )
}

export default BenchmarkForm
