import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'

import { SummaryStatus } from 'src/store/summarySlice'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import { PerformanceIndicator } from 'src/utils/golfConstants'
import CategoryError from 'src/modules/categories/CategoryError'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import PerformanceIndicatorTable from 'src/components/dataDisplay/PerformanceIndicatorTable'

const I18N_KEY = 'Statistics'

interface Props {
  summaryStatus: SummaryStatus
  performanceIndicators: PerformanceIndicator[]
}

const StyledTitle = styled(ReferencedTitle)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const Statistics: React.FC<Props> = ({
  summaryStatus,
  performanceIndicators,
}) => {
  const { t } = useTranslation()

  const getContent = () => {
    switch (summaryStatus) {
      case SummaryStatus.Loading:
        return (
          <Skeleton variant="rect" width="100%" height={350} animation="wave" />
        )
      case SummaryStatus.Valid:
        return (
          <PerformanceIndicatorTable
            isOpen
            performanceIndicators={performanceIndicators}
          />
        )
      default:
        return <CategoryError summaryStatus={summaryStatus} />
    }
  }

  return (
    <Box mt={8}>
      <StyledTitle title={t(`${I18N_KEY}.statistics`, 'Statistics')} />
      {getContent()}
    </Box>
  )
}

export default Statistics
