import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

import { MetricId } from 'src/utils/golfConstants'
import { ResponsiveGridxs2 } from 'src/modules/common'
import { TranslationContext } from 'src/utils/TranslationContext'
import SubCategoryGraph from 'src/modules/categories/SubCategoryGraph'
import { SubcategoryData, SummarySingleItem } from 'src/store/summarySlice'

interface Props {
  subCategoryData: SubcategoryData
}

const CategoryContent: React.FC<Props> = ({ subCategoryData }) => {
  const { url } = useRouteMatch()
  const { metricLabels } = useContext(TranslationContext)!

  return (
    <ResponsiveGridxs2 container spacing={2}>
      {Object.entries(subCategoryData).map(([key, value]) =>
        key !== 'M135' && key !== 'M136' ? (
          <Grid key={key} item xs={12} md={6}>
            <SubCategoryGraph
              width="100%"
              height={200}
              path={`${url}/${key}`}
              title={metricLabels[key]}
              metricId={key as MetricId}
              payload={value as SummarySingleItem}
            />
          </Grid>
        ) : (
          ''
        )
      )}
    </ResponsiveGridxs2>
  )
}

export default React.memo(CategoryContent)
