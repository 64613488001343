import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import MuiAppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import Logo from 'src/assets/svgComponents/Logo'
import { HEADER_HEIGHT } from 'src/utils/constants'
import Navigation from 'src/modules/header/Navigation'
import LanguageSelector from 'src/components/i18n/LanguageSelector'

const I18N_KEY = 'Header'

interface Props {
  isCoach: boolean
  isPlayer: boolean
  isLoggedIn: boolean
  isCoachView: boolean
  completedProfile: boolean
}

const AppBar = styled(MuiAppBar)(
  ({ theme }) => css`
    min-height: ${HEADER_HEIGHT}px;
    background-color: ${theme.palette.background.black};
  `
)

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: ${theme.spacing(2)}px;
    padding-bottom: ${theme.spacing(2)}px;
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `
)

const StyledLanguageSelector = styled(LanguageSelector)(
  ({ theme }) => css`
    color: ${theme.palette.text.white};
    padding-bottom: 0;
    padding-top: 0;
    position: absolute;
    right: 1em;
    .MuiSelect-icon {
      color: ${theme.palette.text.white};
    }
    &.MuiInput-underline::after {
      border-bottom-color: ${theme.palette.text.white};
    }
    ${theme.breakpoints.down('xs')} {
      position: relative;
      right: unset;
    }
  `
)

const Header: React.FC<Props> = ({ isPlayer, isLoggedIn, isCoachView }) => (
  <AppBar elevation={0} position="fixed" aria-labelledby="mainmenu-label">
    <Typography variant="srOnly" id="mainmenu-label">
      <Trans i18nKey={`${I18N_KEY}.mainMenu`}>Main menu</Trans>
    </Typography>
    {isLoggedIn ? (
      <Navigation isPlayer={isPlayer} isCoachView={isCoachView} />
    ) : (
      <Container>
        <Logo />
        <StyledLanguageSelector />
      </Container>
    )}
  </AppBar>
)

export default Header
