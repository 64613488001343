import React from 'react'
import { Trans } from 'react-i18next'
import Box from '@material-ui/core/Box'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { NO_PROGRESS_LABEL } from '../../utils/constants'

const CardContainer = styled(Paper)(
  ({ theme }) => css`
    width: 100%;
    z-index: 1;
    position: relative;
    margin: 10px;
    background-color: ${theme.palette.background.darkestGrey};

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      max-width: 290px;
    }
  `
)

const WhiteText = styled(Typography)`
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  margin-top: 3px;
`

const BlueText = styled(Typography)`
  color: #618ff7;
  font-size: 12px;
  line-height: 16px;
  font-variant: all-small-caps;
`

const DataText = styled(Typography)`
  color: #fff;
  font-size: 26px;
  line-height: 40px;
`

const DataContainer = styled.div`
  display: flex;
`

const DataComparison = styled.div<{
  comparison: number
  invertedComparison?: boolean
}>`
  font-size: 12px;
  ${({ comparison, invertedComparison }) =>
    !invertedComparison
      ? comparison > 0
        ? css`
            color: #63c132;
          `
        : comparison < 0
        ? css`
            color: #df2a30;
          `
        : css`
            color: #fff;
          `
      : comparison > 0
      ? css`
          color: #df2a30;
        `
      : comparison < 0
      ? css`
          color: #63c132;
        `
      : css`
          color: #fff;
        `}

  & svg {
    font-size: 12px;
  }
`

const NoProgressText = styled(Typography)(
  ({ theme }) => css`
    font-weight: bold;
    font-size: 12px;
    color: ${theme.palette.background.grey};
    padding: ${theme.spacing(0, 0, 0, 0.5)};

    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

export interface DisplayCardProps {
  title: string
  subtitle?: string
  data: number
  dataComparison?: number
  isSGCard?: boolean
  isInverseComparison?: boolean
}

const I18N_KEY = 'SuggestionCard'

const DisplayCard: React.FC<DisplayCardProps> = ({
  title,
  subtitle,
  data,
  dataComparison,
  isSGCard,
  isInverseComparison,
}) => {
  const dataFormatted = Number.isInteger(data) ? data : data.toFixed(2)
  return (
    <CardContainer>
      <Box padding={2}>
        <BlueText>{title}</BlueText>
        <WhiteText>{subtitle}</WhiteText>
        <DataContainer>
          <DataText variant="h3">
            {dataFormatted}{' '}
            {isSGCard ? <Trans i18nKey={`${I18N_KEY}.sg`}> SG</Trans> : ''}
          </DataText>
          {dataComparison || dataComparison === 0 ? (
            <DataComparison
              comparison={dataComparison}
              invertedComparison={isInverseComparison}
            >
              {dataComparison < 0 ? (
                <>
                  <ArrowDropDownIcon />
                  {Number.isInteger(dataComparison)
                    ? Math.abs(dataComparison)
                    : Math.abs(dataComparison).toFixed(2)}
                </>
              ) : dataComparison > 0 ? (
                <>
                  <ArrowDropUpIcon />
                  {Number.isInteger(dataComparison)
                    ? Math.abs(dataComparison)
                    : Math.abs(dataComparison).toFixed(2)}
                </>
              ) : (
                <NoProgressText>{NO_PROGRESS_LABEL}</NoProgressText>
              )}
            </DataComparison>
          ) : (
            ''
          )}
        </DataContainer>
      </Box>
    </CardContainer>
  )
}

export default React.memo(DisplayCard)
