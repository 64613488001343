import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'

import Select from 'src/components/inputs/Select'
import { nameSelector } from 'src/store/userSlice'
import { SummaryErrors, TimeFilter } from 'src/utils/constants'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import GenericLoader from 'src/components/layout/GenericLoader'
import { createReport, getReports } from 'src/store/reportSlice'

import illustration from 'src/assets/images/reportIllustration.svg'
import CustomDatePicker from 'src/components/inputs/CustomDatePicker'
import { summaryFiltersSelector } from 'src/store/summarySlice'
import useBreakpoints from 'src/hooks/useBreakpoints'
import { capitalizeFirstLetter } from 'src/utils/helpers'

const I18N_KEY = 'CreateReportDialog'

const Title = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(2.5, 0)};
    font-weight: 500;
    span {
      margin-left: ${theme.spacing(2)}px;
    }
  `
)

const StyledFilterSelect = styled(Select)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(2)}px;
  `
)

const StyledGenericLoader = styled(GenericLoader)`
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background: white;
  z-index: 1;
  justify-content: center;
`

const StyledDatePickerContainer = styled.div(
  () => css`
    margin-top: 0.5em;
  `
)
interface CreateReportDialogProps extends DialogProps {
  onCancel: () => void
  onSuccess: (uuid: string) => void
}

const CreateReportDialog: React.FC<CreateReportDialogProps> = ({
  onCancel,
  onSuccess,
  onClose,
  ...props
}) => {
  const { t } = useTranslation()
  const { firstName } = useSelector(nameSelector)
  const dispatch = useDispatch()
  const filters = useSelector(summaryFiltersSelector)
  const { isDesktop } = useBreakpoints()
  const [saving, setSaving] = useState(false)

  const timeFilterOptions = useMemo(
    () =>
      Object.values(TimeFilter).map(time => ({
        value: time,
        label: t(`Enums:TimeFilter.${time}`),
      })),
    [t]
  )

  const [selectedTime, setSelectedTime] = useState(timeFilterOptions[0].value)

  const handleCreate = async () => {
    const reportTime = t(`Enums:TimeFilter.${selectedTime}`).toLowerCase()
    const formattedReportTime =
      selectedTime === TimeFilter.All || selectedTime === TimeFilter.Custom
        ? capitalizeFirstLetter(reportTime)
        : reportTime
    const title = t(
      selectedTime === TimeFilter.All || selectedTime === TimeFilter.Custom
        ? `${I18N_KEY}.shortReportTitle`
        : `${I18N_KEY}.reportTitle`,
      `Last {{formattedReportTime}} report`,
      {
        formattedReportTime,
      }
    )
    try {
      setSaving(true)
      const reportUuid = await dispatch(createReport(title, selectedTime))
      dispatch(getReports())
      onSuccess(reportUuid as unknown as string)
    } catch (e) {
      let errorMessage = t(
        `${I18N_KEY}.errorMessage`,
        'Could not create report'
      )

      if (e instanceof Error && e.message === SummaryErrors.NoRoundsEntered) {
        errorMessage = t(
          `${I18N_KEY}.noRoundsError`,
          'Player has not entered any rounds'
        )
      }
      if (
        e instanceof Error &&
        e.message === SummaryErrors.NoRoundsForFilters
      ) {
        errorMessage = t(
          `${I18N_KEY}.noFilterRoundsError`,
          'No rounds match the given filter'
        )
      }

      dispatch(openToast(getErrorToast(errorMessage)))
    } finally {
      setSaving(false)
    }
  }

  return (
    <Dialog onClose={onCancel} {...props}>
      <DialogContent>
        <img
          src={illustration}
          alt={t(`${I18N_KEY}.documentIllustration`, 'document Illustration')}
        />
        <Title variant="h2">
          <Trans i18nKey={`${I18N_KEY}.title`}>Create report</Trans>
          <Typography component="span">
            <Trans i18nKey={`${I18N_KEY}.forPlayer`}>For {{ firstName }}</Trans>
          </Typography>
        </Title>
        <Divider />
        <Box my={2}>
          <Trans i18nKey={`${I18N_KEY}.time`}>For the last</Trans>
          <StyledFilterSelect
            value={selectedTime}
            name="time-filter"
            disabled={saving}
            options={timeFilterOptions}
            onChange={e => setSelectedTime(e.target.value as TimeFilter)}
          />
          {selectedTime === TimeFilter.Custom && (
            <StyledDatePickerContainer>
              <CustomDatePicker
                filters={filters}
                isDesktop={isDesktop}
                autoUpdate
              />
            </StyledDatePickerContainer>
          )}
        </Box>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={saving}>
          <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Cancel</Trans>
        </Button>
        <Button
          onClick={handleCreate}
          variant="contained"
          disabled={saving}
          color="primary"
        >
          <Trans i18nKey={`${I18N_KEY}.create`}>Create</Trans>
        </Button>
      </DialogActions>
      {saving && (
        <StyledGenericLoader
          text={t(`${I18N_KEY}.generatingText`, 'Generating report..')}
        />
      )}
    </Dialog>
  )
}

export default CreateReportDialog
