// Based on: https://github.com/vlaja/multilanguage-routing-react/blob/master/src/modules/i18n/components/LocalizedRouter.tsx
import React, { useContext } from 'react'
import { RouterProps } from 'react-router'
import { I18nextProvider } from 'react-i18next'
import { Router, Route, Redirect } from 'react-router-dom'

import i18n from 'src/i18n'
import { LanguageContext } from 'src/utils/LanguageProvider'

export const LocalizedRouter: React.FC<RouterProps> = ({
  children,
  ...props
}) => {
  const { language } = useContext(LanguageContext)
  return (
    <Router {...props}>
      <Route path="/:lang([a-z]{2})">
        {({ match, location }: { match: any; location: any }) => {
          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const params = match ? match.params : {}
          const { lang = language } = params

          /**
           * Insert language to the route path if not already present
           */
          const { hash, search, pathname } = location
          if (!pathname.startsWith(`/${lang}`)) {
            return <Redirect to={`/${lang}${pathname + search + hash}`} />
          }
          /**
           * Return I18n provider with default language set
           */
          return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
        }}
      </Route>
    </Router>
  )
}
