import { ChangeEvent, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { PracticeTimeFilter } from 'src/utils/constants'
import {
  getPracticeSummary,
  getPracticeTimeSpent,
  practiceFiltersSelector,
  updateFilters,
} from 'src/store/practiceSlice'

const timeFilterValues = Object.values(PracticeTimeFilter)

const usePracticeFilters = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { timeFilter } = useSelector(practiceFiltersSelector)

  const timeFilterOptions = useMemo(
    () =>
      timeFilterValues.map(time => ({
        value: time,
        label: t(`Enums:TimeFilter.${time}`),
      })),
    [t]
  )

  const handleTimeSelection = (event: ChangeEvent<{ value: unknown }>) => {
    const timeFilter = event.target.value as PracticeTimeFilter

    dispatch(updateFilters({ timeFilter }))
    // If custom is selected, fire dispatch once custom dates are selected
    if (timeFilter !== PracticeTimeFilter.Custom) {
      dispatch(getPracticeTimeSpent())
      dispatch(getPracticeSummary())
    }
  }

  const getTimeFilterClick = (timeFilter: PracticeTimeFilter) => () => {
    dispatch(updateFilters({ timeFilter }))
    dispatch(getPracticeTimeSpent())
    dispatch(getPracticeSummary())
  }

  return {
    timeFilter,
    getTimeFilterClick,
    handleTimeSelection,
    timeFilterOptions,
  }
}

export default usePracticeFilters
