import React, {
  useContext,
  useEffect,
  useState,
  MouseEvent,
  useMemo,
} from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Table from '@material-ui/core/Table'
import Skeleton from '@material-ui/lab/Skeleton'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'
import MuiTableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'

import {
  getInvoices,
  dataSelector,
  isLoaded as isLoadedSelector,
  isLoading as isLoadingSelector,
} from 'src/store/invoiceSlice'
import { useAppDispatch } from 'src/store'
import { formatCurrency } from 'src/utils/helpers'
import { TranslationContext } from 'src/utils/TranslationContext'
import { DateFormats, PAGINATION_LIMIT } from 'src/utils/constants'
import { Content, Title } from 'src/modules/player-settings/common'
import { PaginationContainer } from 'src/components/dataDisplay/tableStyles'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'

const I18N_KEY = 'Invoices'

const TableCell = styled(MuiTableCell)(
  ({
    theme: {
      spacing,
      palette,
      typography: { pxToRem },
    },
  }) => css`
    color: ${palette.background.black};
    font-size: ${pxToRem(16)};
    font-weight: 400;
    &:first-child {
      padding-left: ${spacing(5)}px;
    }
    &:last-child {
      padding-right: ${spacing(5)}px;
    }
  `
)

const LoadingRow: React.FC = () => (
  <TableRow>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
    <TableCell>
      <Skeleton />
    </TableCell>
  </TableRow>
)

const StyledContent = styled(Content)`
  padding: 0;
`

const StyledTitle = styled(Title)(
  ({ theme: { spacing } }) => css`
    padding-left: ${spacing(5)}px;
    padding-right: ${spacing(5)}px;
  `
)

const EmptyCell = styled(TableCell)(
  ({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.pxToRem(12)};
    text-align: center;
  `
)

const StyledPaginationContainer = styled(PaginationContainer)`
  margin-bottom: 0;
`

const EmptyData: React.FC = () => (
  <TableRow>
    <EmptyCell colSpan={3}>
      <Trans i18nKey={`${I18N_KEY}.noInvoices`}>You have no invoices yet</Trans>
    </EmptyCell>
  </TableRow>
)

const Invoices: React.FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { format } = useContext(TranslationContext)!

  const [page, setPage] = useState(0)

  const invoices = useSelector(dataSelector)
  const isLoading = useSelector(isLoadingSelector)
  const isLoaded = useSelector(isLoadedSelector)

  useEffect(() => {
    dispatch(getInvoices())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const loadingArray = useMemo(
    () => Array.from(Array(PAGINATION_LIMIT).keys()),
    []
  )

  const isEmpty = useMemo(() => !invoices || invoices.length === 0, [invoices])

  return (
    <>
      <StyledContent>
        <StyledTitle variant="h4">
          <Trans i18nKey={`${I18N_KEY}.title`}>Payment History</Trans>
        </StyledTitle>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.dateColumnLabel`}>Date</Trans>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.typeColumnLabel`}>Type</Trans>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.amountColumnLabel`}>
                      Amount
                    </Trans>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading &&
                isEmpty &&
                loadingArray.map(index => <LoadingRow key={index} />)}
              {isLoaded && isEmpty && <EmptyData />}
              {!isEmpty &&
                invoices?.length &&
                invoices
                  ?.slice(
                    page * PAGINATION_LIMIT,
                    (page + 1) * PAGINATION_LIMIT
                  )
                  .map((invoice, index) => {
                    const planType = t(`Enums:PlanType.${invoice.planType}`)
                    const billingDuration = t(
                      `Enums:BillingDuration.${invoice.duration}`
                    )

                    return (
                      <TableRow key={`${invoice.date}${index}`}>
                        <TableCell>
                          {format(new Date(invoice.date), DateFormats.Invoice)}
                        </TableCell>
                        <TableCell>
                          <Trans i18nKey={`${I18N_KEY}.membershipLabel`}>
                            {{ planType }} Membership ({{ billingDuration }})
                          </Trans>
                        </TableCell>
                        <TableCell>{formatCurrency(invoice.amount)}</TableCell>
                      </TableRow>
                    )
                  })}
            </TableBody>
          </Table>
        </TableContainer>
      </StyledContent>
      <StyledPaginationContainer>
        {!isEmpty && (
          <TablePagination
            page={page}
            component="div"
            count={invoices?.length || 0}
            rowsPerPageOptions={[]}
            rowsPerPage={PAGINATION_LIMIT}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </StyledPaginationContainer>
    </>
  )
}

export default Invoices
