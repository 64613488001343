import React from 'react'
import { motion } from 'framer-motion'

interface Props {
  stroke: string
  progress: number
  isNegative: boolean
}

const ProgressRing: React.FC<Props> = ({
  stroke,
  progress,
  isNegative,
  ...props
}) => {
  const positivePath =
    'M74.5 5C112.884 5 144 36.1162 144 74.5C144 112.884 112.884 144 74.5 144C36.1162 144 5 112.884 5 74.5C5 36.1162 36.1162 5 74.5 5Z'
  const negativePath =
    'M74.5 5C36.1162 5 5 36.1162 5 74.5C5 112.884 36.1162 144 74.5 144C112.884 144 144 112.884 144 74.5C144 36.1162 112.884 5 74.5 5Z'

  return (
    <motion.svg
      fill="none"
      viewBox="0 0 150 150"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <motion.path
        stroke={stroke}
        strokeWidth="10"
        strokeLinecap="round"
        initial={{ pathLength: 0 }}
        transition={{ duration: 1.5 }}
        d={isNegative ? negativePath : positivePath}
        animate={{ pathLength: progress > 1 ? 1 : progress }}
      />
    </motion.svg>
  )
}

export default React.memo(ProgressRing)
