import React, { useContext } from 'react'

import { TickProps } from 'src/utils/chartTypes'
import { AxisLabel } from 'src/components/graph/common'
import { DECIMAL_PLACES, DateFormats } from 'src/utils/constants'
import { TranslationContext } from 'src/utils/TranslationContext'

type YAxisProps = TickProps<number> & { decimals?: number }

export const RenderYAxisTick: React.FC<YAxisProps> = ({
  x,
  y,
  payload,
  textAnchor,
  decimals = DECIMAL_PLACES,
}) => (
  <AxisLabel y={y} x={x} dy={8} textAnchor={textAnchor}>
    {payload.value.toFixed(decimals)}
  </AxisLabel>
)

export const RenderXAxisTick: React.FC<TickProps<number>> = ({
  x,
  y,
  payload,
  textAnchor,
}) => {
  const { format } = useContext(TranslationContext)!

  return (
    <AxisLabel y={y} x={x} dy={16} textAnchor={textAnchor}>
      {format(payload.value, DateFormats.GraphLabel)}
    </AxisLabel>
  )
}
