import { createTheme } from '@material-ui/core/styles'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'

const fontFamily = `"IBM Plex Sans", Roboto, Helvetica, Arial, sans-serif`
const headingFamily = `"Whyte Inktrap", Roboto, Helvetica, Arial, sans-serif`

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

export const palette: PaletteOptions = {
  primary: {
    main: '#154AC6',
    light: '#618FF7',
    dark: '#030483',
    contrastText: '#EAF0FF',
  },
  secondary: {
    main: '#D9EEFD',
    light: '#ecf6fe',
    dark: '#8ecdf9',
    contrastText: '#4B87FF',
  },
  background: {
    light: '#E7E8E9',
    xlight: '#F9F9FA',
    grey: '#CFD1D3',
    default: '#fff',
    dark: '#64696F',
    black: '#121212',
    paper: '#fff',
    darkGrey: '#3c3c3c',
    darkestGrey: '#282828',
    blueHighlight: '#222b40',
    focusBlue: '#94B5FF',
  },
  disabled: {
    main: '#949494',
    light: '#f4f4f4',
  },
  success: {
    main: '#39BF69',
    light: '#53cc7f',
  },
  warning: {
    main: '#F6A609',
    xlight: '#fffaea',
  },
  error: {
    main: '#EE303D',
    light: '#FDEAEC',
  },
  text: {
    light: '#A6AAAD',
    primary: '#000',
    secondary: '#64696F',
    white: '#FFFFFF',
  },
  divider: '#ECECEC',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    info: '#F6A609',
    error: '#EE303D',
    success: '#39BF69',
  },
  type: 'light',
}

const theme = createTheme({
  palette,
  props: {
    MuiWithWidth: {
      initialWidth: 'xs',
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiTooltip: {
      enterTouchDelay: 0,
      leaveTouchDelay: 3000,
    },
    MuiFormControl: { fullWidth: true },
  },
})

theme.shadows[1] = '0px 0px 5px 0px rgba(43, 42, 98, 0.1)'
theme.shadows[2] = '0px 1px 4px rgba(37, 40, 43, 0.25)'

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontFamily,
  fontWeightBold,
  fontWeightLight,
  fontWeightMedium,
  fontWeightRegular,
  fontFamilySecondary: fontFamily,
  h1: {
    ...theme.typography.h1,
    fontFamily: headingFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(56),
    lineHeight: theme.typography.pxToRem(72),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(56),
    },
  },
  h2: {
    ...theme.typography.h2,
    letterSpacing: 0.4,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(48),
  },
  h3: {
    ...theme.typography.h3,
    fontWeight: 500,
    fontFamily: fontFamily,
    fontSize: theme.typography.pxToRem(26),
    lineHeight: theme.typography.pxToRem(40),
    [theme.breakpoints.down('xs')]: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  h4: {
    ...theme.typography.h4,
    fontFamily: fontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(32),
  },
  h5: {
    ...theme.typography.h5,
    fontFamily: fontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },
  h6: {
    ...theme.typography.h6,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
  subtitle2: {
    ...theme.typography.subtitle2,
  },
  body1: {
    ...theme.typography.body1,
    fontFamily: fontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },
  body2: {
    ...theme.typography.body2,
    fontFamily: fontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },
  button: {
    ...theme.typography.button,
    fontFamily: fontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(26),
  },
  caption: {
    ...theme.typography.caption,
    fontFamily: fontFamily,
    textTransform: 'uppercase',
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.3),
  },
  overline: {
    ...theme.typography.overline,
  },
}

// Overrides are assigned here so we can use theme.breakpoints
// Override cake ui components here based off classes api
theme.overrides = {
  MuiOutlinedInput: {
    root: {
      borderRadius: 6,
      '&$disabled .MuiOutlinedInput-notchedOutline': {
        borderColor: '#F9F9FA',
      },
    },
    adornedEnd: {
      paddingRight: 8,
    },
  },
  MuiButton: {
    root: {
      letterSpacing: 0,
      textTransform: 'none',
      padding: theme.spacing(1.5, 2),
      '&$disabled': {
        color: theme.palette.background.grey,
      },
    },
    contained: {
      color: '#fff',
      backgroundColor: '#000',
      '&:hover': {
        backgroundColor: palette.background?.dark,
        borderColor: palette.background?.dark,
      },
      '&:disabled': {
        borderColor: 'transparent',
      },
    },
    outlined: {
      padding: theme.spacing(1.5, 2),
    },
    text: {
      padding: theme.spacing(1.5, 2),
    },
    textPrimary: {
      '&:hover': {
        backgroundColor: theme.palette.primary.contrastText,
      },
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
    textSizeSmall: {
      padding: theme.spacing(1, 1.5),
      fontSize: theme.typography.pxToRem(14),
    },
  },
  MuiInputBase: {
    root: {
      '&$disabled': {
        backgroundColor: '#F9F9FA',
        color: theme.palette.text.primary,
      },
    },
  },
  MuiInputLabel: {
    root: {
      color: '#000',
      fontWeight: 'bold',
      textTransform: 'uppercase',
    },
    outlined: {
      '&$shrink': {
        transform: 'translate(19px, 12px) scale(0.75)',
      },
    },
  },
  MuiSelect: {
    icon: {
      color: theme.palette.primary.main,
      '&$disabled': {
        color: theme.palette.primary.main,
      },
    },
  },
  MuiList: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  MuiListItem: {
    root: {
      '&$selected': {
        backgroundColor: '#fff',
        color: theme.palette.primary.main,
        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.secondary.light,
        },
      },
    },
    button: {
      '&:hover': {
        backgroundColor: theme.palette.secondary.light,
      },
    },
  },
  MuiMenuItem: {
    root: {
      minHeight: 54,
      [theme.breakpoints.up('xs')]: {
        minHeight: 54,
      },
    },
  },
  MuiPaper: {
    rounded: {
      borderRadius: 6,
    },
    elevation1: {
      boxShadow: '0px 0px 5px 0px rgba(43, 42, 98, 0.1)',
    },
  },
  MuiFab: {
    root: {
      color: '#fff',
      textTransform: 'none',
      backgroundColor: '#000',
      boxShadow: '0px 0px 5px 0px rgba(43, 42, 98, 0.1)',
      '&:hover': {
        backgroundColor: theme.palette.background.dark,
      },
    },
    extended: {
      height: 'auto',
      borderRadius: 4,
      padding: theme.spacing(1.5, 2),
    },
    primary: {
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    },
  },
  MuiAppBar: {
    colorDefault: {
      backgroundColor: '#fff',
    },
  },
  MuiTooltip: {
    tooltip: {
      borderRadius: 6,
      fontWeight: 400,
      padding: theme.spacing(2),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      backgroundColor: theme.palette.background.black,
    },
    arrow: {
      color: theme.palette.background.black,
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: 'none',
      padding: theme.spacing(2, 3),
    },
  },
  MuiTableHead: {
    root: {
      borderBottom: `1px solid ${theme.palette.background.xlight}`,
    },
  },
  MuiTablePagination: {
    toolbar: {
      borderTop: `1px solid ${theme.palette.background.xlight}`,
      backgroundColor: theme.palette.background.xlight,
      paddingLeft: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    spacer: {
      display: 'none',
    },
    caption: {
      display: 'none',
    },
  },
  MuiDialog: {
    paperFullScreen: {
      minWidth: 'unset',
    },
    paperWidthSm: {
      maxWidth: 500,
      minWidth: 415,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      minWidth: 'unset',
      margin: 0,
    },
  },
  MuiDialogContent: {
    root: {
      padding: theme.spacing(3),
      '&:first-child': {
        paddingTop: theme.spacing(4),
      },
    },
  },
  MuiDialogActions: {
    root: {
      padding: theme.spacing(3),
    },
  },
  MuiSnackbarContent: {
    root: {
      flexWrap: 'nowrap',
    },
  },
  MuiFormLabel: {
    root: {
      ...theme.typography.caption,
    },
  },
}

export default theme
