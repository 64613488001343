import { motion } from 'framer-motion'
import { Form as FormikForm } from 'formik'
import styled, { css } from 'styled-components'

import { Link } from 'react-router-dom'
import MuiDivider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'

export const Container = styled(motion.div)(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    min-height: 100%;
    position: absolute;
    align-items: center;
    flex-direction: column;
    background-color: ${theme.palette.background.xlight};
  `
)

export const TitleContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: ${theme.spacing(5)}px;
    padding-bottom: ${theme.spacing(4)}px;
  `
)

export const StyledLink = styled(Link)(
  ({ theme }) => css`
    font-weight: bold;
    text-decoration: underline;
    margin-left: ${theme.spacing(0.5)}px;
  `
)

export const Form = styled.form(
  ({ theme }) => css`
    width: 465px;
    border-radius: 6px;
    background-color: #fff;
    padding: ${theme.spacing(5, 5)};
    margin: 0 auto ${theme.spacing(6)}px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      box-shadow: none;
      padding: ${theme.spacing(3, 2)};
      margin: 0 0 ${theme.spacing(14)}px;
    }
  `
)

// TODO: remove when new auth flow is completed
export const StyledFormikForm = styled(FormikForm)(
  ({ theme }) => css`
    width: 465px;
    background-color: #fff;
    padding: ${theme.spacing(5, 5)};
    margin: 0 auto ${theme.spacing(6)}px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.07);

    ${theme.breakpoints.down('xs')} {
      width: auto;
      box-shadow: none;
      padding: ${theme.spacing(3, 2)};
      margin: 0 0 ${theme.spacing(14)}px;
    }
  `
)

export const DisclaimerText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.light};

    a {
      color: ${theme.palette.text.primary};
    }
  `
)

export const OptionText = styled(Typography)(
  ({ theme }) => css`
    a {
      font-weight: bold;
      color: ${theme.palette.text.primary};
    }
  `
)

export const Divider = styled(MuiDivider)(
  ({ theme }) => css`
    height: 2px;
    margin: ${theme.spacing(4, 0)};
    background-color: ${theme.palette.background.light};
  `
)
