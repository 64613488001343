import React from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Close from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'
import CheckIcon from '@material-ui/icons/Check'
import Snackbar from '@material-ui/core/Snackbar'
import WarningIcon from '@material-ui/icons/Warning'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import { ToastType } from 'src/utils/constants'
import { closeToast, toastSelector } from 'src/store/toastSlice'

interface ToastProps {
  $type: ToastType
}

const ToastBar = styled(Snackbar)(
  ({ theme }) => css`
    width: 100%;
    max-width: 1280px;
    padding: ${theme.spacing(1)}px;
    margin-top: ${theme.spacing(7)}px;

    ${theme.breakpoints.down('xs')} {
      width: unset;
      padding: ${theme.spacing(1, 0)};
      margin-top: ${theme.spacing(9)}px;
    }
  `
)

const ToastContent = styled(SnackbarContent)(
  ({ theme }) => css`
    width: 100%;
    min-height: 60;
    border-radius: 6px;
    padding: ${theme.spacing(1, 2)};
    background-color: ${theme.palette.background.dark};
  `
)

const StyledTypography = styled(Typography)`
  margin-left: 8px;
`

const MessageContainer = styled.span<ToastProps>(
  ({ theme, $type }) => css`
    display: flex;
    align-items: center;

    svg {
      color: ${theme.palette.toast[$type]};
      margin-right: ${theme.spacing(1)}px;
    }
  `
)

const Toast: React.FC = () => {
  const { isOpen, variant, message, hideDuration } = useSelector(toastSelector)

  const dispatch = useDispatch()

  const onCloseToast = () => {
    dispatch(closeToast())
  }

  const getIcon = (variant: ToastType) => {
    switch (variant) {
      case ToastType.Error:
        return <WarningIcon />
      case ToastType.Success:
        return <CheckIcon />
      default:
        return <InfoIcon />
    }
  }

  return (
    <ToastBar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      onClose={onCloseToast}
      autoHideDuration={hideDuration}
    >
      <ToastContent
        message={
          <MessageContainer $type={variant}>
            {getIcon(variant)}
            <StyledTypography>{message}</StyledTypography>
          </MessageContainer>
        }
        action={
          <IconButton
            key="close"
            color="inherit"
            aria-label="Close"
            onClick={onCloseToast}
          >
            <Close />
          </IconButton>
        }
      />
    </ToastBar>
  )
}

export default React.memo(Toast)
