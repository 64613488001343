import React from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { motion, useCycle } from 'framer-motion'

import Typography from '@material-ui/core/Typography'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import useAppState from 'src/hooks/useAppState'
import useBreakpoints from 'src/hooks/useBreakpoints'
import BurgerIcon from 'src/modules/header/BurgerIcon'

interface Route {
  label: string
  route: string
  external?: boolean
}

interface Props {
  routes: Route[]
}

const Container = styled(motion.div)`
  margin-left: auto;
  position: relative;
`

const Nav = styled(motion.nav)<{ $isOpen: boolean }>(
  ({ theme, $isOpen }) => css`
    width: 100vw;
    bottom: -18px;
    display: flex;
    z-index: 1000;
    position: absolute;
    left: calc(-100vw + 168px);
    transform: translateY(100%);
    pointer-events: ${$isOpen ? 'all' : 'none'};
    background-color: ${theme.palette.background.black};

    .active-link {
      background-color: ${theme.palette.background.blueHighlight};

      h5 {
        color: ${theme.palette.primary.light};
      }
    }

    ${theme.breakpoints.down('xs')} {
      left: calc(-100vw + 160px);
      flex-direction: column;
    }
  `
)

const linkCSS = css`
  ${({ theme }) => `
    flex: 1;
    height: 100%;
    display: flex;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacing(1, 2)};
    p {
      transition: color 0.3s ease-out;
      color: ${theme.palette.text.white};

      &:hover {
        color: ${theme.palette.primary.light};
      }
    }
`}
`

const NavItem = styled(NavLink)<{ $isOpen: boolean }>(
  ({ $isOpen }) => css`
    pointer-events: ${$isOpen ? 'all' : 'none'};
    visibility: ${$isOpen ? 'visible' : 'hidden'};
    ${linkCSS}
  `
)

const ExternalLink = styled.a<{ $isOpen: boolean }>(
  ({ $isOpen }) => css`
    pointer-events: ${$isOpen ? 'all' : 'none'};
    visibility: ${$isOpen ? 'visible' : 'hidden'};
    ${linkCSS}
  `
)

const variants = {
  closed: {
    height: 0,
    opacity: 0,
    padding: 0,
  },
  open: ({
    isPlayer,
    isCoachView,
    isSmallScreen,
  }: {
    isPlayer: boolean
    isCoachView: boolean
    isSmallScreen: boolean
  }) => {
    const styles = {
      opacity: 1,
      padding: isSmallScreen ? 16 : 24,
    }

    if (isPlayer || isCoachView) {
      return {
        ...styles,
        height: isSmallScreen ? 200 : 90,
      }
    }

    return {
      ...styles,
      height: isSmallScreen ? 120 : 90,
    }
  },
}

const BurgerMenu: React.FC<Props> = ({ routes }) => {
  const [isBurgerOpen, toggleIsBurgerOpen] = useCycle(false, true)

  const { isSmallScreen } = useBreakpoints()
  const { isPlayer, isCoachView } = useAppState()

  const handleMenuClick = () => {
    toggleIsBurgerOpen()
  }

  const handleClickAway = () => {
    toggleIsBurgerOpen(0)
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container
        initial={false}
        transition={{ duration: 500 }}
        animate={isBurgerOpen ? 'open' : 'closed'}
      >
        <BurgerIcon burgerToggle={toggleIsBurgerOpen} />
        <Nav
          variants={variants}
          $isOpen={isBurgerOpen}
          custom={{ isPlayer, isCoachView, isSmallScreen }}
        >
          {routes.map(({ route, label, external }) =>
            external ? (
              <ExternalLink
                key={route}
                href={route}
                target="_blank"
                $isOpen={isBurgerOpen}
                rel="noopener noreferrer"
              >
                <Typography variant="h5" component="p">
                  {label}
                </Typography>
              </ExternalLink>
            ) : (
              <NavItem
                exact
                to={route}
                key={route}
                $isOpen={isBurgerOpen}
                onClick={handleMenuClick}
                activeClassName="active-link"
              >
                <Typography variant="h5" component="p">
                  {label}
                </Typography>
              </NavItem>
            )
          )}
        </Nav>
      </Container>
    </ClickAwayListener>
  )
}

export default BurgerMenu
