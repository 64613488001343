import React, { useContext } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'

import Dash from 'src/assets/svgComponents/DashIcon'
import { handleNumberFormat } from 'src/utils/helpers'
import { TranslationContext } from 'src/utils/TranslationContext'
import DifferenceIcon from 'src/assets/svgComponents/DifferenceIcon'
import { RadarData } from 'src/components/dataDisplay/RadarComparison'
import { breakpoints } from 'src/utils/constants'

const I18N_KEY = 'StrokesComparisonTable'

interface Props {
  data: RadarData[]
  benchmarkName: string
  isRoundSummary: boolean
  isCompactSummary: boolean
}

const StyledCell = styled(TableCell)(
  ({ theme }) => css`
    padding: ${theme.spacing(1.5, 2)};
    text-align: center;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: 0;
      text-align: center;
      min-width: unset;
      max-width: 100px;
    }
  `
)

const StyledTitleCell = styled(TableCell)(
  ({ theme }) => css`
    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: 0;
      min-width: unset;
      max-width: 100px;
      padding-bottom: ${theme.spacing(1)}px;
    }
  `
)

const IconText = styled(Typography).attrs({
  variant: 'caption',
  color: 'textSecondary',
})`
  display: flex;
  align-items: center;
`

const GapIcon = styled(DifferenceIcon)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(0.5)}px;
  `
)

const DashIcon = styled(Dash)(
  ({ theme }) => css`
    fill: ${theme.palette.warning.main};
    margin-right: ${theme.spacing(1)}px;
  `
)

const StrokesComparisonTable: React.FC<Props> = ({
  data,
  benchmarkName,
  isRoundSummary,
  isCompactSummary = false,
  ...props
}) => {
  const { categoryLabels } = useContext(TranslationContext)!

  return (
    <TableContainer {...props}>
      <Table aria-label="rounds table">
        <TableHead>
          <TableRow>
            <StyledTitleCell>
              <Typography variant="caption" color="textSecondary">
                <Trans i18nKey={`${I18N_KEY}.category`}>Category</Trans>
              </Typography>
            </StyledTitleCell>
            {!isCompactSummary && (
              <>
                <StyledTitleCell>
                  {isRoundSummary ? (
                    <Typography variant="caption" color="textSecondary">
                      <Trans i18nKey={`${I18N_KEY}.thisRound`}>
                        This Round
                      </Trans>
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="textSecondary">
                      <Trans i18nKey={`${I18N_KEY}.sgTotal`}>SG Total</Trans>
                    </Typography>
                  )}
                </StyledTitleCell>
                <StyledTitleCell>
                  <IconText>
                    <DashIcon />
                    {benchmarkName}
                  </IconText>
                </StyledTitleCell>
              </>
            )}
            <StyledTitleCell>
              <IconText>
                <GapIcon />
                <Trans i18nKey={`${I18N_KEY}.iconText`}>Gap</Trans>
              </IconText>
            </StyledTitleCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({ category, overall, benchmark }) => {
            const gap = (overall as number) - (benchmark as number)
            const isNegative = gap < 0

            return (
              <TableRow key={category}>
                <StyledTitleCell>
                  <Typography>{categoryLabels[category]}</Typography>
                </StyledTitleCell>
                {!isCompactSummary && (
                  <>
                    <StyledCell>
                      <Typography variant="body2">
                        {overall || overall === 0 ? (
                          handleNumberFormat(overall)
                        ) : (
                          <Box component="span" color="background.grey">
                            -
                          </Box>
                        )}
                      </Typography>
                    </StyledCell>
                    <StyledCell>
                      <Typography variant="body2">
                        {handleNumberFormat(benchmark as number)}
                      </Typography>
                    </StyledCell>
                  </>
                )}
                <StyledCell>
                  <Typography variant="body2">
                    <Box
                      component="span"
                      color={isNegative ? 'error.main' : 'success.main'}
                    >
                      {handleNumberFormat(gap, true)}
                    </Box>
                  </Typography>
                </StyledCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default StrokesComparisonTable
