import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { SummaryStatus } from 'src/store/summarySlice'

const I18N_KEY = 'PredictionError'

interface PredictionErrorProps {
  summaryStatus: SummaryStatus
}

const Card = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(16, 4)};
  `
)

const ErrorText = styled(Typography)`
  max-width: 600px;
`

const PredictionError: React.FC<PredictionErrorProps> = ({
  summaryStatus,
  ...props
}) => {
  const { t } = useTranslation()

  const errorMessage = useMemo(() => {
    switch (summaryStatus) {
      case SummaryStatus.NoFilterRounds:
        return t(
          `${I18N_KEY}.noFilterRoundsErrorMessage`,
          'You have no rounds matching this filter.'
        )
      case SummaryStatus.NoRoundsError:
        return t(
          `${I18N_KEY}.noRoundsErrorErrorMessage`,
          'Prediction Model displays your potential improvement trajectory. This is calculated using Circles advanced Artificial Intelligence. After you have entered 20 rounds, we will automatically display your Strokes Gained potential looking forward 12 weeks.'
        )
      default:
        return t(
          `${I18N_KEY}.genericError`,
          'Something went wrong trying to get your Prediction Model information.'
        )
    }
  }, [t, summaryStatus])

  return (
    <Card elevation={0} {...props}>
      {summaryStatus === SummaryStatus.NoFilterRounds ? (
        <>
          <ErrorText color="textSecondary" variant="h4" align="center">
            {errorMessage}
          </ErrorText>
          <ErrorText color="textSecondary" align="center">
            <Trans i18nKey={`${I18N_KEY}.secondaryErrorMessage`}>
              Please select another.
            </Trans>
          </ErrorText>
        </>
      ) : (
        <ErrorText color="textSecondary" align="center">
          {errorMessage}
        </ErrorText>
      )}
    </Card>
  )
}

export default PredictionError
