import { PayloadAction } from '@reduxjs/toolkit'

import { State, ErrorPayload } from 'typings/store'

export const getBasicReducers = <T>(initialState: State<T>) => ({
  requestInProgress: (state: State<T>) => {
    state.requestInProgress = true
  },
  requestFinished: (state: State<T>) => {
    state.requestInProgress = false
  },
  requestError: (state: State<T>, action: PayloadAction<ErrorPayload>) => {
    state.requestInProgress = false
    state.error = `${action.payload}`
  },
  reinitialise: () => {
    return initialState
  },
})
