import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { Plan } from 'src/models/plan'
import usePlans from 'src/hooks/usePlans'
import {
  BillingDuration,
  PlanType,
  SubscriptionStatus,
} from 'src/utils/subscriptionConstants'
import { FlowComponentProps } from 'src/components/subscriptionFlow/types'
import MembershipCard from 'src/components/subscriptionFlow/MembershipCard'
import BillingDurationSwitch from 'src/components/subscriptionFlow/BillingDurationSwitch'
import EvaluationCard from './EvaluationCard'
import { useSelector } from 'react-redux'
import { dataSelector } from 'src/store/subscriptionSlice'

const I18N_KEY = 'SubscriptionFlowMembership'

const MembershipCardsContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: ${theme.spacing(6, 0, 0)};
  `
)

const Membership: React.FC<FlowComponentProps> = ({
  data,
  onNext,
  onChange,
}) => {
  const { billingDuration } = data

  const subscriptionData = useSelector(dataSelector)

  const showEvaluationCard =
    subscriptionData?.isEvaluationPeriod ||
    (subscriptionData?.plan?.planType === PlanType.PremiumNew &&
      subscriptionData?.status === SubscriptionStatus.Trialing)

  const handleBillingDurationChange = (value: BillingDuration) =>
    onChange({
      ...data,
      billingDuration: value,
    })

  const generateSelectPlan = (plan: Plan) => () => onNext({ ...data, plan })

  const { plans } = usePlans(billingDuration)

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography align="center" variant="h2">
        <Trans i18nKey={`${I18N_KEY}.title`}>Membership plans</Trans>
      </Typography>
      <Box mt={2}>
        <BillingDurationSwitch
          onChange={handleBillingDurationChange}
          value={billingDuration}
        />
      </Box>
      <MembershipCardsContainer>
        {showEvaluationCard && <EvaluationCard />}
        {plans?.map(plan => (
          <MembershipCard
            price={plan.price}
            key={plan.planType}
            planType={plan.planType}
            billingDuration={billingDuration}
            handleSelectPlan={generateSelectPlan(plan)}
            isSelected={plan.planType === data.plan?.planType}
          />
        ))}
      </MembershipCardsContainer>
    </Box>
  )
}

export default Membership
