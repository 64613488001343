import React, { useState, MouseEvent } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Typography from '@material-ui/core/Typography'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'

import { RoundType } from 'src/utils/golfConstants'
import { PAGINATION_LIMIT } from 'src/utils/constants'
import { handleNumberFormat, isNumber } from 'src/utils/helpers'
import EmptyRounds from 'src/modules/activity/rounds/EmptyRounds'
import {
  DataRow,
  SGText,
  PaginationContainer,
} from 'src/components/dataDisplay/tableStyles'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'

const I18N_KEY = 'CategoryRoundsTable'

interface Props {
  rounds: TableData[]
}

interface TableData {
  uuid: string
  date: string
  type: RoundType
  roundTitle: string
  strokesGained: number | null | undefined
}

const TypeText = styled(Typography)`
  text-transform: capitalize;
`

const CategoryRoundsTable: React.FC<Props> = ({ rounds }) => {
  const [page, setPage] = useState(0)

  const { t } = useTranslation()

  const roundColumns = [
    t(`${I18N_KEY}.roundColumnsRound`, 'Round'),
    t(`${I18N_KEY}.roundColumnsType`, 'Type'),
    t(`${I18N_KEY}.roundColumnsDate`, 'Date'),
    t(`${I18N_KEY}.roundColumnsStrokesGained`, 'Strokes Gained'),
  ]

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  return (
    <>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="rounds table">
          <TableHead>
            <TableRow>
              {roundColumns.map(column => (
                <TableCell key={column}>
                  <Typography variant="caption" color="textSecondary">
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rounds
              .slice(
                page * PAGINATION_LIMIT,
                page * PAGINATION_LIMIT + PAGINATION_LIMIT
              )
              .map(({ uuid, date, type, roundTitle, strokesGained }) => {
                const hasStrokesGained = isNumber(strokesGained)

                return (
                  <DataRow key={uuid}>
                    <TableCell>
                      <Typography>{roundTitle}</Typography>
                    </TableCell>
                    <TableCell>
                      <TypeText>{t(`Enums:RoundType.${type}`)}</TypeText>
                    </TableCell>
                    <TableCell>
                      <Typography>{date}</Typography>
                    </TableCell>
                    <TableCell>
                      <SGText variant="body2" hasStrokes={!!strokesGained}>
                        {hasStrokesGained
                          ? handleNumberFormat(strokesGained as number)
                          : '-'}
                      </SGText>
                    </TableCell>
                  </DataRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationContainer>
        {rounds.length === 0 ? (
          <EmptyRounds />
        ) : (
          <TablePagination
            page={page}
            component="div"
            count={rounds.length}
            rowsPerPageOptions={[]}
            rowsPerPage={PAGINATION_LIMIT}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </PaginationContainer>
    </>
  )
}

export default React.memo(CategoryRoundsTable)
