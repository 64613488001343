import React, { useEffect } from 'react'
import {
  Field,
  Form as FormikForm,
  FormikProps,
  useFormikContext,
} from 'formik'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import { Button, Divider, Grid, Typography } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'

import {
  AvatarActions,
  AvatarContainer,
  PersonIcon,
  PlusSign,
  SettingsSubmitButton,
  StyledAvatar,
} from 'src/modules/common'
import {
  DateFormat,
  Gender,
  Languages,
  PlayerType,
  Unit,
} from 'src/utils/constants'
import {
  deletePlayerProfileImage,
  setPlayerProfileImage,
  UpdatePlayerRequest,
} from 'src/store/playerSlice'
import useEnum from 'src/hooks/useEnum'
import {
  ActionRow,
  FieldGroup,
  Row,
  Title,
} from 'src/modules/player-settings/common'
import useCropper from 'src/hooks/useCropper'

import CropDialog from 'src/components/feedback/CropDialog'
import { SequentialDatePicker } from 'src/components/common'
import FormTextField from 'src/components/inputs/FormTextField'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'
import FormToggleButton from 'src/components/inputs/FormToggleButton'
import BenchmarkForm from 'src/modules/player-settings/profile/BenchmarkForm'
import { Label } from '../../../components/Label'
import { formatPhoneNumber } from '../../../utils/helpers'
import Player from '../../../models/player'
import Box from '@material-ui/core/Box'

// will be released later
// import nationalities from 'src/assets/data/nationalities.json'
// import countries from 'src/assets/data/countries.json'

const I18N_KEY = 'ProfileForm'

const Form = styled(FormikForm)`
  display: block;
`

const NoteText = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    font-size: 0.875rem;
  `
)

interface ProfileFormProps {
  player: Player
  profileImageUrl: string | null
  onChange?: (change: FormikProps<UpdatePlayerRequest>) => void
}

const ProfileForm: React.FC<ProfileFormProps> = ({
  player,
  onChange,
  profileImageUrl,
}) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  const {
    previewSrc,
    selectedFile,
    cropDialogOpen,
    closeCropDialog,
    handleFileSelection,
  } = useCropper()

  const saveProfileImage = async (file: File) => {
    try {
      await dispatch(setPlayerProfileImage(file))
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.profileImageeError`, 'Could not upload image')
          )
        )
      )
    } finally {
      closeCropDialog()
    }
  }

  const handleProfileImageDelete = async () => {
    try {
      await dispatch(deletePlayerProfileImage())
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.profileImageeError`, 'Could not delete image')
          )
        )
      )
    }
  }

  const { options: genderOptions } = useEnum(Gender, 'Gender')
  const { options: unitOptions } = useEnum(Unit, 'Unit')
  const playerTypeOptions = [
    {
      label: t(`${I18N_KEY}.playerTypePro`, 'Pro'),
      value: PlayerType.Pro,
    },
    {
      label: t(`${I18N_KEY}.playerTypeAmateur`, 'Amateur'),
      value: PlayerType.Amateur,
    },
  ]

  const formik = useFormikContext<UpdatePlayerRequest>()
  const { values, dirty, isValid, isSubmitting } = formik

  useEffect(() => {
    if (onChange) {
      onChange(formik)
    }
  }, [formik, onChange, values])

  return (
    <>
      <Form>
        <AvatarContainer>
          {profileImageUrl ? (
            <StyledAvatar
              src={profileImageUrl}
              alt={t(`${I18N_KEY}.userAlt`, 'User profile')}
            />
          ) : (
            <StyledAvatar>
              <PersonIcon />
            </StyledAvatar>
          )}
          <AvatarActions>
            <Button color="primary" variant="outlined" component="label">
              <PlusSign>+</PlusSign>
              <Trans i18nKey={`${I18N_KEY}.uploadButtonLabel`}>
                Upload new picture
              </Trans>
              <input
                hidden
                type="file"
                accept="image/png, image/jpeg"
                onChange={handleFileSelection}
              />
            </Button>
            {profileImageUrl && (
              <Button
                startIcon={<DeleteIcon />}
                onClick={handleProfileImageDelete}
              >
                <Trans i18nKey={`${I18N_KEY}.deleteButtonLabel`}>Delete</Trans>
              </Button>
            )}
          </AvatarActions>
        </AvatarContainer>
        <Divider />
        <FieldGroup>
          <Title variant="h4">
            <Trans i18nKey={`${I18N_KEY}.personalInformation`}>
              Personal Information
            </Trans>
          </Title>
          <Grid container spacing={0}>
            <Row item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    fullWidth
                    name="firstName"
                    label={t(`${I18N_KEY}.firstNameLabel`, 'First name')}
                    placeholder={t(
                      `${I18N_KEY}.firstNamePlaceholder`,
                      'First name'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormTextField
                    fullWidth
                    name="lastName"
                    label={t(`${I18N_KEY}.lastNameLabel`, 'Last name')}
                    variant="outlined"
                    placeholder={t(
                      `${I18N_KEY}.lastNamePlaceholder`,
                      'Last name'
                    )}
                  />
                </Grid>
              </Grid>
            </Row>
            <Row item xs={12} sm={6}>
              <FormToggleButton
                name="gender"
                label={t(`${I18N_KEY}.genderLabel`, 'Gender')}
                options={genderOptions}
              />
            </Row>
            <Grid item xs={12} sm={6} />
            <Row item xs={12} sm={6}>
              <Typography variant="caption">
                <Trans i18nKey={`${I18N_KEY}.dateOfBirth`}>Date of birth</Trans>
              </Typography>
              <Field
                autoOk
                fullWidth
                name="dob"
                helperText=""
                disableFuture
                inputVariant="outlined"
                component={SequentialDatePicker}
                InputLabelProps={{ shrink: true }}
                InputProps={{ endAdornment: <CalendarIcon /> }}
                format={DateFormat[i18n.language as Languages].datePicker}
              />
            </Row>
            <Grid item xs={12} sm={6} />
            <Row item xs={12} sm={6}>
              <Label $hasError={false}>
                <Trans i18nKey={`${I18N_KEY}.phoneNumber`}>Phone number</Trans>
              </Label>
              <Box>{formatPhoneNumber(player.phoneNumber)}</Box>
            </Row>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12}>
              <Title variant="h4" $gutterTop={3}>
                <Trans i18nKey={`${I18N_KEY}.golfPreferencesTitle`}>
                  Golf Preferences
                </Trans>
              </Title>
            </Grid>
            {/* This feature will be released later */}
            {/* <Row item xs={6}>
            <Autocomplete
              options={nationalities}
              renderInput={(props) => <FormTextField
                {...props}
                fullWidth
                name="nationality"
                label={t(`${I18N_KEY}.nationalityLabel`, "Nationality")}
                placeholder={t(`${I18N_KEY}.nationalityPlaceHolder`, "Please select")}
              />}
            />
          </Row>
          <Grid item xs={6} /> */}
            <Row item xs={12} sm={8}>
              <BenchmarkForm />
            </Row>
            <Row item xs={12} sm={6}>
              <FormToggleButton
                name="unit"
                label={t(`${I18N_KEY}.scoringUnitsLabel`, 'Units')}
                options={unitOptions}
              />
            </Row>
            <Grid item xs={12} sm={6} />
            <Grid item xs={12} sm={6}>
              <FormToggleButton
                name="playerType"
                label={t(`${I18N_KEY}.playerTypeLabel`, 'Player Type')}
                options={playerTypeOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Row item xs={12}>
              <NoteText>
                <Trans i18nKey={`${I18N_KEY}.playerTypeNote`}>
                  This adjusts the calculations to account for more difficult
                  courses played by professional players.
                </Trans>
              </NoteText>
            </Row>
            {/* This feature will be released later */}
            {/* <Row item xs={6}>
            <Autocomplete
              options={countries}
              renderInput={(props) => <FormTextField
                {...props}
                fullWidth
                name="country"
                label={t(`${I18N_KEY}.countryLabel`, "Representing")}
                placeholder={t(`${I18N_KEY}.countryPlaceHolder`, "Please select")}
              />}
            />
          </Row>
          <Grid item xs={6} /> */}
            <ActionRow item xs={12}>
              <SettingsSubmitButton
                type="submit"
                color="primary"
                variant="contained"
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting}
              >
                <Trans i18nKey={`${I18N_KEY}.saveChangesButtonLabel`}>
                  Save Changes
                </Trans>
              </SettingsSubmitButton>
            </ActionRow>
          </Grid>
        </FieldGroup>
      </Form>
      <CropDialog
        file={selectedFile}
        open={cropDialogOpen}
        previewSrc={previewSrc}
        onClose={closeCropDialog}
        onSave={saveProfileImage}
      />
    </>
  )
}

export default ProfileForm
