import React from 'react'
import { motion } from 'framer-motion'
import styled, { css, StyledProps } from 'styled-components'

import Check from '@material-ui/icons/Check'
import Button from '@material-ui/core/Button'

import { fadeIn } from 'src/utils/animations'

interface LayoutProps {
  selected: boolean
}

export const ActiveBar = styled(motion.span)(
  ({ theme }) => css`
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 4px;
    background-color: ${theme.palette.primary.contrastText};
  `
)

export const SharedLayoutButton = styled(({ selected, ...props }) => (
  <Button {...props} />
))(
  ({ theme, selected }: StyledProps<LayoutProps>) =>
    css`
      position: relative;
      background-color: transparent;
      color: ${selected ? theme.palette.primary.main : '#000'};

      .MuiButton-label {
        z-index: 0;
      }
    `
)

export const LargeLayoutButton = styled(SharedLayoutButton)(
  ({ theme, selected }) => css`
    border: 1px solid;
    justify-content: flex-start;
    margin: ${theme.spacing(1, 0)};
    border-color: ${selected
      ? theme.palette.primary.contrastText
      : theme.palette.background.light};
    padding-top: ${theme.spacing(2.5)}px;
    padding-bottom: ${theme.spacing(2.5)}px;

    :hover {
      border-color: ${theme.palette.primary.contrastText};
    }
  `
)

export const CheckIcon = styled(Check)(
  ({ theme }) =>
    css`
      margin-left: auto;
      color: ${theme.palette.primary.main};
      font-size: ${theme.typography.pxToRem(18)};
      animation: ${fadeIn} 0.45s ease-out forwards;
    `
)
