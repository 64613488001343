import React, { ChangeEvent } from 'react'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useFormikContext } from 'formik'

import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import { spring } from 'src/utils/animations'
import { unitSelector } from 'src/store/playerSlice'
import { breakpoints, Unit, UnitLabel } from 'src/utils/constants'
import FormSelect from 'src/components/inputs/FormSelect'
import { Hole, Lie, Par, Shot } from 'src/utils/golfConstants'
import FormTextField from 'src/components/inputs/FormTextField'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface Props {
  shot: Shot
  index: number
  par: Par
  lieOptions: { label: string; value: string }[]
  isEditDisabled: boolean
  remove: <T>(index: number) => T | undefined
}

const absoluteStyles = css`
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
`

const ListItem = styled(motion.li)(
  ({ theme }) => css`
    width: 100%;
    list-style: none;
    position: relative;
    margin: ${theme.spacing(0.5, 0)};
  `
)

const LeftLabel = styled(Typography)(
  ({ theme }) => css`
    ${absoluteStyles};
    left: -30px;
    color: ${theme.palette.text.light};
    ${theme.breakpoints.down(breakpoints.mobile)} {
      left: -17px;
    }
  `
)

const LeftLine = styled.div(
  ({ theme }) => css`
    top: 46px;
    width: 1px;
    left: -28px;
    height: 40px;
    position: absolute;
    background-color: ${theme.palette.background.light};
    ${theme.breakpoints.down(breakpoints.mobile)} {
      left: -14px;
    }
  `
)

const StyledTextField = styled(FormTextField)`
  .MuiOutlinedInput-input {
    padding-right: 0;
  }
`

const DeleteShot = styled(IconButton)(
  ({ theme }) => css`
    ${absoluteStyles};
    right: -55px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      right: -44px;
    }
  `
)

const ShotEntry: React.FC<Props> = ({
  shot,
  index,
  par,
  lieOptions,
  remove,
  isEditDisabled,
  ...props
}) => {
  const { teeLayup } = useFlags()
  const unit =
    useSelector(unitSelector) === Unit.Metric
      ? UnitLabel.Metric
      : UnitLabel.Imperial
  const { values, setFieldValue } = useFormikContext<Hole>()

  // The first shot (tee shot) is always disabled
  let disabledLie
  if (teeLayup) {
    disabledLie = isEditDisabled || (index === 0 && par === Par.Three)
  } else {
    disabledLie = isEditDisabled || index === 0
  }
  const disableShot = isEditDisabled || values.shots[index]?.lie === Lie.Penalty

  const showDeleteButton = !isEditDisabled && index > 0

  const filterOptions = (
    par: Par,
    lieOptions: { label: string; value: string }[]
  ) => {
    if (teeLayup && par !== Par.Three && index === 0) {
      return lieOptions.filter(
        option => option.value === Lie.Tee || option.value === Lie.TeeLayup
      )
    }
    return lieOptions
  }

  const handleLieChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    const shotDistanceName = `shots[${index}].distance`

    // Make value 0 when Penalty is selected
    if (event.target.value === Lie.Penalty) {
      setFieldValue(shotDistanceName, 0)
    }

    // Clear 0 as a value when changing from a Penalty
    if (shot.lie === Lie.Penalty) {
      setFieldValue(shotDistanceName, '')
    }

    setFieldValue(`shots[${index}].lie`, event.target.value)
  }

  return (
    <ListItem {...props} layout exit={{ opacity: 0 }} transition={spring}>
      <LeftLabel variant="caption">{index + 1}</LeftLabel>
      <LeftLine />
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <FormSelect
            fullWidth
            options={filterOptions(par, lieOptions)}
            disabled={disabledLie}
            showErrorString={false}
            onChange={handleLieChange}
            name={`shots[${index}].lie`}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTextField
            fullWidth
            type="number"
            disabled={disableShot}
            showErrorString={false}
            inputProps={{ min: 0 }}
            name={`shots[${index}].distance`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {shot.lie === Lie.Green ? UnitLabel.Feet : unit}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      {showDeleteButton && (
        <DeleteShot
          onClick={() => {
            remove(index)
          }}
        >
          <DeleteIcon />
        </DeleteShot>
      )}
    </ListItem>
  )
}

export default React.memo(ShotEntry)
