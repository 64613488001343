import React from 'react'
import styled, { css, StyledProps } from 'styled-components'

import Typography from '@material-ui/core/Typography'
import { SharedLayoutButton } from 'src/components/layout/SharedLayoutComponents'

interface HoleTextProps {
  selected?: boolean
  completed: boolean
}

export const Cell = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1.25, 1.75)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1, 1.5)};
    }
  `
)

export const Column = styled(SharedLayoutButton)`
  padding: 0;
  min-width: unset;

  .MuiButton-label {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
  }

  &.MuiButton-root.Mui-disabled {
    color: #000;
  }
`

export const HoleText = styled(({ selected, completed, ...props }) => (
  <Typography {...props} />
))(
  ({ theme, selected, completed }: StyledProps<HoleTextProps>) => css`
    color: ${selected
      ? theme.palette.primary.main
      : completed
      ? theme.palette.text.primary
      : theme.palette.text.light};

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(14)};
      line-height: ${theme.typography.pxToRem(20)};
    }
  `
)
