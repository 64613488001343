import React, { useMemo, useState, ChangeEvent, useEffect } from 'react'
import {
  Dot,
  Area,
  XAxis,
  YAxis,
  Scatter,
  Tooltip,
  TooltipProps,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import ExpandMore from '@material-ui/icons/ExpandMore'
import { Paper, MenuItem, useTheme, Typography } from '@material-ui/core'

import {
  PositiveGradient,
  NegativeGradient,
} from 'src/components/graph/Gradient'
import { average } from 'src/utils/helpers'
import { HolesNumber } from 'src/utils/golfConstants'
import { GraphSelect } from 'src/components/graph/common'
import { getXAxisTicks } from 'src/components/graph/utils'
import TooltipContent from 'src/components/graph/score/TooltipContent'
import { RenderXAxisTick, RenderYAxisTick } from 'src/components/graph/Axis'

const I18N_KEY = 'ScoreGraph'

export interface ScoreDataItem {
  toPar: number
  average: number
  datePlayed: number
  roundTitle: string
  numberOfHoles: HolesNumber
}

interface ScoreGraphProps {
  id: string
  data: ScoreDataItem[]
  referencePoint: string
}

const Container = styled(Paper)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(3, 3, 3, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 2, 2, 0)};
    }
  `
)

const InfoContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: ${theme.spacing(4)}px;
  `
)

const GraphContainer = styled(ResponsiveContainer)`
  margin-left: -12px;
`

const ScoreGraph: React.FC<ScoreGraphProps> = ({
  id,
  data,
  referencePoint,
}) => {
  const [showFilter, setShowFilter] = useState(false)
  const [numberOfHoles, setNumberOfHoles] = useState(HolesNumber.Eighteen)

  const theme = useTheme()
  const { t } = useTranslation()

  // Set valid numberOfHoles and only enable filters when both 9 & 18 hole rounds are in the dataset
  useEffect(() => {
    const containsNine = data.some(
      item => item.numberOfHoles === HolesNumber.Nine
    )
    const containsEighteen = data.some(
      item => item.numberOfHoles === HolesNumber.Eighteen
    )

    if (containsNine && containsEighteen) {
      setShowFilter(true)
    }

    // Only 9 hole rounds exist
    if (containsNine && !containsEighteen) {
      setNumberOfHoles(HolesNumber.Nine)
    }
  }, [data])

  const { xTicks, payload, averageScore } = useMemo(() => {
    const payload = data.filter(item => numberOfHoles === item.numberOfHoles)

    const scores: number[] = []
    const dates: (number | undefined)[] = []

    payload.forEach(item => {
      scores.push(item.toPar)
      dates.push(item.datePlayed)
    })

    return {
      payload,
      xTicks: getXAxisTicks(dates),
      averageScore: average(scores).toFixed(1),
    }
  }, [data, numberOfHoles])

  const handleHolesNumberChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setNumberOfHoles(event.target.value as HolesNumber)
  }

  return (
    <Container elevation={0}>
      <InfoContainer>
        <div>
          <Typography>
            <Trans i18nKey={`${I18N_KEY}.average`}>Average</Trans>
          </Typography>
          <Typography variant="h3">{averageScore}</Typography>
        </div>
        {showFilter && (
          <GraphSelect
            value={numberOfHoles}
            IconComponent={ExpandMore}
            onChange={handleHolesNumberChange}
            MenuProps={{
              elevation: 1,
              getContentAnchorEl: null,
              anchorOrigin: { vertical: 'top', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
          >
            <MenuItem value={HolesNumber.Nine}>
              {t(`Enums:HolesNumber.${HolesNumber.Nine}`)}
            </MenuItem>
            <MenuItem value={HolesNumber.Eighteen}>
              {t(`Enums:HolesNumber.${HolesNumber.Eighteen}`)}
            </MenuItem>
          </GraphSelect>
        )}
      </InfoContainer>
      <GraphContainer width="100%" height={220}>
        <ComposedChart data={payload}>
          <defs>
            <PositiveGradient id={id} color={theme.palette.primary.light} />
            <NegativeGradient id={id} color={theme.palette.primary.light} />
          </defs>
          <YAxis
            type="number"
            dataKey="toPar"
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            domain={['dataMin', 'dataMax']}
            tick={props => <RenderYAxisTick {...props} decimals={0} />}
          />
          <XAxis
            type="number"
            ticks={xTicks}
            tickLine={false}
            axisLine={false}
            dataKey="datePlayed"
            tickFormatter={() => 'XXX 99'}
            domain={['dataMin', 'dataMax']}
            tick={props => <RenderXAxisTick {...props} />}
          />
          <ReferenceLine
            y={0}
            strokeWidth={2}
            strokeDasharray="4"
            ifOverflow="extendDomain"
            stroke={theme.palette.warning.main}
          />
          <Tooltip
            cursor={{
              strokeWidth: 2,
              stroke: theme.palette.background.grey,
            }}
            content={(props: TooltipProps<number, string>) => (
              <TooltipContent {...props} referencePoint={referencePoint} />
            )}
          />
          <Area
            dot={false}
            type="monotone"
            strokeWidth={2}
            fillOpacity={1}
            dataKey="rollingAverage"
            activeDot={false}
            animationDuration={1000}
            stroke={theme.palette.primary.light}
            fill={
              Number(averageScore) > 0
                ? `url(#positive-average-${id})`
                : `url(#negative-average-${id})`
            }
          />
          <Scatter
            dataKey="toPar"
            strokeWidth={2}
            fillOpacity={1}
            animationDuration={1000}
            fill={theme.palette.primary.light}
            stroke={theme.palette.primary.light}
            shape={props => <Dot {...props} r={4} />}
          />
        </ComposedChart>
      </GraphContainer>
    </Container>
  )
}

export default ScoreGraph
