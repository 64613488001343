declare global {
  interface Window {
    env: any
  }
}

type ApplicationConfig = {
  REACT_APP_API_URL: string
  REACT_APP_GATEWAY_URL: string
  REACT_APP_DEPLOYMENT_LOCATION: string
  REACT_APP_ENABLE_PREDICTIVE_ANALYSIS: string
  REACT_APP_PROFITWELL_KEY: string
  REACT_APP_RECAPTCHA_SITE_KEY: string
  REACT_APP_REWARDFUL_KEY: string
  REACT_APP_SALT: string
  REACT_APP_SEGMENT_KEY: string
  REACT_APP_SEGMENT_URL: string
  REACT_APP_STRIPE_KEY: string
  REACT_APP_LAUNCHDARKLY_CLIENT_ID: string
  REACT_APP_HUBSPOT_PORTAL_ID: string
}

export const config: ApplicationConfig = { ...process.env, ...window.env }
