import React, { useState } from 'react'

import { InputAdornment } from '@material-ui/core'

import HookFormTextField, {
  HookFormTextFieldProps,
} from 'src/components/inputs/HookFormTextField'
import PasswordVisibility from 'src/components/inputs/PasswordVisibility'

const HookPasswordField: React.FC<HookFormTextFieldProps> = props => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleVisibility = () => setShowPassword(!showPassword)

  return (
    <HookFormTextField
      autoComplete="new-password"
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <PasswordVisibility
              isVisible={showPassword}
              toggleVisibility={toggleVisibility}
            />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default HookPasswordField
