import { keyframes } from 'styled-components'

export const fadeSlide = keyframes`
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const grow = keyframes`
  from {
    scale: 0.7;
  }
  to {
    scale: 1;
  }
`

export const defaultTransition = { duration: 0.3 }

export const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
}

export const fadeVariants = {
  enter: { opacity: 0 },
  visible: {
    opacity: 1,
  },
  exit: { opacity: 0 },
}

export const tabVariants = {
  enter: (showTrends: boolean) => ({ opacity: 0, x: showTrends ? 50 : -50 }),
  visible: {
    x: 0,
    opacity: 1,
  },
  exit: (showTrends: boolean) => ({ opacity: 0, x: showTrends ? -50 : 50 }),
}
