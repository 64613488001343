import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { Dialog } from 'src/modules/common'
import { getReport, reportSelector } from 'src/store/reportSlice'
import useAppState from 'src/hooks/useAppState'
import { localizeRoutePath } from 'src/utils/i18n'
import { Routes } from 'src/utils/constants'
import { updateDialogVisibility } from 'src/store/roundSlice'
import GenericLoader from 'src/components/layout/GenericLoader'
import { TranslationContext } from 'src/utils/TranslationContext'
import { MonthlyReport, Report, ReportType } from 'src/utils/golfConstants'
import GeneralReportDetail from '../evaluation'
import MonthlyReportContainer from '../monthly-report/MonthlyReportContainer'

const I18N_KEY = 'ReportDetailDialog'

const ReportDetailDialog: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isCoachView, playerUuid } = useAppState()

  const { t } = useContext(TranslationContext)!

  const reportRouteMatch = useRouteMatch<{ reportUuid: string }>(
    localizeRoutePath(
      isCoachView ? Routes.CoachPlayerReportDetail : Routes.ReportDetail
    )
  )
  const reportUuid = reportRouteMatch?.params.reportUuid

  useEffect(() => {
    if (reportUuid) {
      dispatch(getReport(reportUuid))
    }
  }, [reportUuid, dispatch])

  const {
    selected: report,
    isSelectedLoading: loading,
    selectedType: type,
  } = useSelector(reportSelector)

  const closeDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: false }))
    history.push(
      isCoachView
        ? Routes.CoachPlayerReports.replace(':playerUuid', playerUuid ?? '')
        : Routes.Reports
    )
  }

  const generateReport = () => {
    let rep
    switch (type) {
      case ReportType.Evaluation:
        rep = report as Report
        return (
          <GeneralReportDetail
            report={rep}
            isCoachView={isCoachView}
            closeDialog={closeDialog}
          />
        )
      case ReportType.MonthlyReport:
        rep = report as MonthlyReport
        return (
          <MonthlyReportContainer
            startDate={rep.startDate}
            endDate={rep.endDate}
            player={rep.player}
            closeDialog={closeDialog}
            title={rep.title}
            createdAt={rep.createdAt}
          />
        )
    }
  }

  return (
    <Dialog open={!!reportUuid}>
      {!report || loading ? (
        <GenericLoader
          text={t(`${I18N_KEY}.loadingText`, 'Loading report data')}
        />
      ) : (
        generateReport()
      )}
    </Dialog>
  )
}

export default ReportDetailDialog
