import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import { Grid, Typography } from '@material-ui/core'

import CardForm from 'src/components/subscriptionFlow/CardForm'
import { BillingDuration } from 'src/utils/subscriptionConstants'
import { usePricing } from 'src/components/subscriptionFlow/usePricing'
import {
  FlowComponentProps,
  FormFields,
} from 'src/components/subscriptionFlow/types'
import BillingAddressForm from 'src/components/subscriptionFlow/BillingAddressForm'
import BillingDurationSwitch from 'src/components/subscriptionFlow/BillingDurationSwitch'
import {
  Paper,
  PaperTitle,
  HeadingContainer,
  PaymentContainer,
} from 'src/components/subscriptionFlow/payment/common'
import OrderSummary from 'src/components/subscriptionFlow/payment/OrderSummary'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { createBillingAddressSchema } from '../../../utils/validationSchemas'
import { Billing } from '../../../models/subscription'
import { useNewStripePayment } from '../useNewStripePayment'

const I18N_KEY = 'SubscriptionFlowCard'

const BillingAddressFormContainer = styled.div(
  ({ theme }) => css`
    margin-right: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} {
      margin-right: 0;
    }
  `
)

const CardFormContainer = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  `
)

const NewStripePayment: React.FC<FlowComponentProps> = ({
  data,
  onNext,
  onChange,
}) => {
  const { t } = useTranslation()
  const pricing = usePricing(data.plan)
  const {
    subTotal,
    finalPrice,
    yearlyDiscount,
    appliedPromoCode,
    promoCodeDiscount,
    clearPromoCodeDetails,
  } = pricing

  const {
    submitting,
    isPaymentValid,
    handleSubscription,
    handleBillingDurationChange,
  } = useNewStripePayment({
    onNext,
    onChange,
    data: {
      ...data,
      promoCode: appliedPromoCode ?? '',
      total: Number(finalPrice),
      subtotal: Number(subTotal),
      discount: Number(yearlyDiscount) + Number(promoCodeDiscount),
    },
  })

  const { billingDuration } = data

  const validationSchema = useMemo(() => createBillingAddressSchema(t), [t])

  const { getValues, formState, control, errors } = useForm<FormFields>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      fullName: '',
      address: '',
      city: '',
      country: {
        name: '',
        dialCode: '',
        code: '',
      },
      postcode: '',
      ...data.billing,
    },
  })

  const onFormSubmit = () => {
    const { ...billingRest } = getValues()
    const billing: Billing = {
      fullName: billingRest.fullName,
      address: billingRest.address,
      city: billingRest.city,
      country: billingRest.country,
      postcode: billingRest.postcode,
    }
    handleSubscription(
      {
        billing,
        billingDuration: data.billingDuration,
        plan: data.plan,
      },
      appliedPromoCode
    )
  }

  const onBillingDurationChange = (duration: BillingDuration) => {
    if (duration !== billingDuration) {
      handleBillingDurationChange(duration)
      clearPromoCodeDetails()
    }
  }

  return (
    <PaymentContainer>
      <HeadingContainer>
        <div>
          <Typography variant="body1">
            <Trans i18nKey={`${I18N_KEY}.checkOutLabel`}>Check out</Trans>
          </Typography>
          <Typography variant="h3">
            {t(`Enums:PlanType.${data.plan?.planType}`)}{' '}
            <Trans i18nKey={`${I18N_KEY}.membership`}>Membership</Trans>
          </Typography>
        </div>
        <BillingDurationSwitch
          value={billingDuration}
          onChange={onBillingDurationChange}
        />
      </HeadingContainer>
      <Grid container spacing={3}>
        <Grid item md={8} sm={12}>
          <Paper>
            <PaperTitle>
              <Trans i18nKey={`${I18N_KEY}.billingInformationLabel`}>
                Billing information
              </Trans>
            </PaperTitle>
            <Grid container spacing={0}>
              <Grid item md={6} sm={12}>
                <BillingAddressFormContainer>
                  <BillingAddressForm
                    control={control}
                    disabled={submitting}
                    withPaymentElement
                  />
                </BillingAddressFormContainer>
              </Grid>
              <Grid item md={6} sm={12}>
                <CardFormContainer>
                  <CardForm
                    handleChange={() => {}}
                    control={control}
                    errors={errors}
                    stripeUpgrade
                  />
                </CardFormContainer>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item md={4} sm={12}>
          <OrderSummary
            data={data}
            pricing={pricing}
            isSubmitting={submitting}
            isCheckoutDisabled={!isPaymentValid || !formState.isValid}
            onSubmit={onFormSubmit}
          />
        </Grid>
      </Grid>
    </PaymentContainer>
  )
}

export default NewStripePayment
