import React from 'react'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'

import { Notification, NotificationType } from '../../models/notification'
import Typography from '@material-ui/core/Typography'
import MUIAvatar from '@material-ui/core/Avatar'
import theme from '../../styles/theme'

const NotificationContainer = styled(Box)`
  cursor: pointer;
  padding: ${theme.spacing(1)}px;
  &:hover {
    background: ${theme.palette.background.grey};
  }
`

const Avatar = styled(MUIAvatar)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2)}px;
  `
)

const Title = styled.span`
  font-weight: 500;
  font-size: 0.9rem;
`

const Message = styled.span`
  font-size: 0.8rem;
`

interface NotificationItemProps {
  notification: Notification
  handleClick: (notification: Notification) => void
  loading?: boolean
}
const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
  handleClick,
}) => {
  const { message, attributes } = notification

  const firstName = attributes.playerFirstName
  const lastName = attributes.playerLastName

  const onBoxClick = () => {
    handleClick(notification)
  }

  const showTitle = () => {
    if (
      notification.type === NotificationType.IMPROVEMENT_OPPORTUNITY_AVAILABLE
    ) {
      return notification.title
    }
    return `${firstName} ${lastName}`
  }

  return (
    <NotificationContainer
      onClick={() => onBoxClick()}
      display="flex"
      alignItems="center"
    >
      {firstName && lastName ? (
        <Avatar>{`${(firstName as string)[0].toUpperCase()}${(
          lastName as string
        )[0].toUpperCase()}`}</Avatar>
      ) : (
        ''
      )}
      <div>
        <Box pb={1}>
          <Typography>
            <Title>{showTitle()}</Title>
          </Typography>
          <Typography>
            <Message>{message}</Message>
          </Typography>
        </Box>
      </div>
    </NotificationContainer>
  )
}

export default NotificationItem
