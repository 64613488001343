import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Container } from 'src/modules/auth/common'
import useLogin from 'src/hooks/useLogin'
import { login } from 'src/store/authSlice'
import { Routes } from 'src/utils/constants'

interface FormValues {
  providerCode: string
}
let sent = false
const SignInProvider: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoggedIn } = useLogin()
  const location = useLocation<{ from: Location }>()
  const { from } = location.state || { from: { pathname: Routes.Dashboard } }
  const { formState } = useForm<FormValues>({})
  const { isSubmitting } = formState
  const query = new URLSearchParams(useLocation().search)
  // get provider name
  const { providerName } = useParams<{ providerName: string }>()
  // get provider code
  const code = query.get('code')
  // redirect if code not present
  if (!code) {
    // todo: parameterise
    const appid = 'wxae941f9f92f18304'
    const path = window.location.pathname
    const redirectUrl = `http://drawmorecircles.com.cn${path}`
    const redirectUrlEncoded = encodeURIComponent(redirectUrl)
    const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirectUrlEncoded}&response_type=code&scope=snsapi_login#wechat_redirect`
    window.location.href = url
  }

  // If/Once user is authenticated, navigate them to route they came from or the dashboard
  useEffect(() => {
    if (isLoggedIn) {
      history.replace(from)
    } else {
      if (!sent) {
        dispatch(login({ providerCode: `${providerName}:${code}` }))
        sent = true
      }
    }
  }, [from, history, isLoggedIn, isSubmitting, providerName, code, dispatch])

  return <Container></Container>
}

export default SignInProvider
