import React, { useState, MouseEvent, useContext } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'

import {
  SGText,
  DataRow,
  TitleCell,
} from 'src/components/dataDisplay/tableStyles'
import { getRoundTitle } from 'src/utils/helpers'
import { DateFormats } from 'src/utils/constants'
import NoData from 'src/components/dataDisplay/NoData'
import { Round, RoundState } from 'src/utils/golfConstants'
import { TranslationContext } from 'src/utils/TranslationContext'
import { EndCell, IconButton, StyledCell } from 'src/modules/activity/common'

import TeeGolfBall from 'src/assets/svgComponents/TeeGolfBall'

const I18N_KEY = 'RoundsRow'

interface Props {
  round: Round
  handleDeleteClick: (e: MouseEvent<HTMLLIElement>) => void
  handleRoundClick: (e: MouseEvent<HTMLTableRowElement>) => void
  handleDuplicateClick: (e: MouseEvent<HTMLLIElement>) => Promise<void>
}

interface IconProps {
  $draft: boolean
  $isSimulator: boolean
}

const IconContainer = styled.div<IconProps>(
  ({ theme, $draft, $isSimulator }) => css`
    width: 35px;
    height: 35px;
    display: flex;
    min-width: 35px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: ${$draft
      ? theme.palette.background.light
      : $isSimulator
      ? '#000'
      : theme.palette.primary.light};

    svg {
      width: 90%;
      margin-top: ${theme.spacing(0.5)}px;
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

const TextContainer = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  `
)

const RoundText = styled(Typography)(
  ({ theme }) => css`
    text-transform: capitalize;

    span {
      color: ${theme.palette.error.main};
    }
  `
)

const RoundsRow: React.FC<Props> = ({
  round,
  handleRoundClick,
  handleDeleteClick,
  handleDuplicateClick,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { format } = useContext(TranslationContext)!

  const { uuid, state, summary, datePlayed, isSimulated } = round
  const isDraft = state === RoundState.Incomplete
  const isSimulator = isSimulated === true

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <DataRow id={uuid} onClick={handleRoundClick} {...props}>
        <TitleCell>
          <Box display="flex" alignItems="center">
            <IconContainer $draft={isDraft} $isSimulator={isSimulator}>
              <TeeGolfBall />
            </IconContainer>
            <TextContainer>
              <RoundText variant="body2">
                {getRoundTitle(round)}
                {isDraft && (
                  <span>
                    {' '}
                    • <Trans i18nKey={`${I18N_KEY}.draft`}>Draft</Trans>
                  </span>
                )}
              </RoundText>
              <Typography variant="caption" color="textSecondary">
                {format(new Date(datePlayed), DateFormats.TableRow)}
              </Typography>
            </TextContainer>
          </Box>
        </TitleCell>
        <StyledCell align="center">
          <Typography>
            {summary?.strokesGained?.drives?.overall || <NoData />}
          </Typography>
        </StyledCell>
        <StyledCell align="center">
          <Typography>
            {summary?.strokesGained?.approach?.overall || <NoData />}
          </Typography>
        </StyledCell>
        <StyledCell align="center">
          <Typography>
            {summary?.strokesGained?.short?.overall || <NoData />}
          </Typography>
        </StyledCell>
        <StyledCell align="center">
          <Typography>
            {summary?.strokesGained?.putt?.overall || <NoData />}
          </Typography>
        </StyledCell>
        <StyledCell align="center">
          <SGText hasStrokes={!isDraft}>
            {isDraft ? <NoData /> : summary?.strokesGained?.total?.overall}
          </SGText>
        </StyledCell>
        <StyledCell align="center">
          <SGText hasStrokes={!isDraft}>
            {isDraft ? <NoData /> : summary?.score.total}
          </SGText>
        </StyledCell>
        <EndCell align="center">
          <IconButton
            size="small"
            color="primary"
            onClick={openMenu}
            aria-label="Options"
          >
            <MoreIcon />
          </IconButton>
        </EndCell>
      </DataRow>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={e => {
            handleDuplicateClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.duplicate`}>Duplicate</Trans>
        </MenuItem>
        <MenuItem
          onClick={e => {
            handleDeleteClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.delete`}>Delete</Trans>
        </MenuItem>
      </Menu>
    </>
  )
}

export default RoundsRow
