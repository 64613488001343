import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import { Category, CategoryType } from 'src/utils/golfConstants'
import SubCategoryRow from 'src/modules/rounds/summary/SubCategoryRow'
import { TableContainer } from 'src/components/dataDisplay/tableStyles'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'

const I18N_KEY = 'RoundsSummarySubCategoryTable'

interface Props {
  categories: Category[]
}

const StyledTableContainer = styled(TableContainer)`
  margin: ${({ theme }) => theme.spacing(2, 0, 6)};
`

const StyledTitle = styled(ReferencedTitle)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

type CategoryTableData = Omit<Record<CategoryType, Category[]>, 'all'>

const Categories: React.FC<Props> = ({ categories }) => {
  const { t } = useTranslation()
  const categoryData = useMemo(() => {
    const data: CategoryTableData = {
      drives: [],
      approach: [],
      short: [],
      putt: [],
    }

    categories.forEach(category => {
      data[category.type as keyof typeof data].push(category)
    })

    return data
  }, [categories])

  return (
    <>
      <StyledTitle title={t(`${I18N_KEY}.title`, 'All Categories')} />
      <StyledTableContainer>
        <Table aria-label="rounds table">
          <TableBody>
            {Object.keys(categoryData).map(key => (
              <SubCategoryRow
                key={key}
                category={key as CategoryType}
                subCategories={categoryData[key as keyof typeof categoryData]}
              />
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  )
}

export default React.memo(Categories)
