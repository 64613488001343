import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { deleteIO } from 'src/store/summarySlice'
import { MetricId } from 'src/utils/golfConstants'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { TranslationContext } from 'src/utils/TranslationContext'
import { openToast, getErrorToast, getSuccessToast } from 'src/store/toastSlice'

const I18N_KEY = 'OpportunityDelete'

interface Props {
  open: boolean
  metricId: MetricId
  handleClose: () => void
}

const DeleteButton = styled(LoaderButton)(
  ({ theme }) => css`
    width: 90px;
    color: ${theme.palette.error.main};

    &:hover {
      background-color: ${theme.palette.error.light};
    }
  `
)

const OpportunityDelete: React.FC<Props> = ({
  open,
  metricId,
  handleClose,
}) => {
  const [inProgress, setInProgress] = useState(false)

  const { t } = useTranslation()
  const { metricLabels } = useContext(TranslationContext)!

  const dispatch = useDispatch()

  const name = metricLabels[metricId]

  const deleteOpportunity = async () => {
    setInProgress(true)
    try {
      await dispatch(deleteIO(metricId))
      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.successToast`, {
              name,
              defaultValue: `{{ name }} was successfully removed as an opportunity`,
            })
          )
        )
      )
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.errorToast`, 'Could not remove opportunity')
          )
        )
      )
    }
    setInProgress(false)
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <Typography gutterBottom variant="h4" id="form-dialog-title">
          <Trans i18nKey={`${I18N_KEY}.title`}>
            Remove this Improvement Opportunity?
          </Trans>
        </Typography>
        <Typography color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.description`}>
            You will be able to add {{ name }} again from the detail view.
          </Trans>
        </Typography>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans i18nKey={`${I18N_KEY}.cancel`}>Cancel</Trans>
        </Button>
        <DeleteButton loading={inProgress} onClick={deleteOpportunity}>
          <Trans i18nKey={`${I18N_KEY}.remove`}>Remove</Trans>
        </DeleteButton>
      </DialogActions>
    </Dialog>
  )
}

export default OpportunityDelete
