import React from 'react'
import { Trans } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DeleteButton from 'src/components/inputs/DeleteButton'

const I18N_KEY = 'CancelMembershipConfirmDialog'

interface Props {
  open: boolean
  inProgress: boolean
  handleClose: () => void
  handleAction: () => Promise<void>
}

const CancelMembershipConfirmDialog: React.FC<Props> = ({
  open,
  inProgress,
  handleClose,
  handleAction,
}) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogContent>
      <Typography gutterBottom variant="h4" id="form-dialog-title">
        <Trans i18nKey={`${I18N_KEY}.dialogTitle`}>
          Confirm membership cancellation
        </Trans>
      </Typography>
      <Typography color="textSecondary">
        <Trans i18nKey={`${I18N_KEY}.dialogContent`}>
          Are you sure you want to cancel your membership?
        </Trans>
      </Typography>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={handleClose} disabled={inProgress}>
        <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Don’t cancel</Trans>
      </Button>
      <DeleteButton $width={186} loading={inProgress} onClick={handleAction}>
        <Trans i18nKey={`${I18N_KEY}.deleteButtonLabel`}>
          Confirm Cancellation
        </Trans>
      </DeleteButton>
    </DialogActions>
  </Dialog>
)

export default CancelMembershipConfirmDialog
