import React, { Dispatch, useContext, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'

import {
  MainContentBackground,
  XsPaddingZeroContainer,
} from 'src/modules/common'
import {
  getErrorCode,
  getErrorStatus,
  getPayloadFromResponse,
  getRoundTitle,
  getSinglePayloadFromResponse,
  isForbiddenOrUnauthorised,
} from 'src/utils/helpers'
import GenericLoader from 'src/components/layout/GenericLoader'
import { DateFormats, Routes } from 'src/utils/constants'
import { api, setAuthTokenHeader } from 'src/utils/api'
import { TranslationContext } from 'src/utils/TranslationContext'
import { useRouteMatch } from 'react-router-dom'
import { Round } from 'src/utils/golfConstants'
import { localizeRoutePath } from 'src/utils/i18n'
import RoundSummary from './summary'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import { useDispatch } from 'react-redux'
import { updateBenchmarkData, updatePlayer } from 'src/store/playerSlice'
import Player from 'src/models/player'

const I18N_KEY = 'Rounds'

const HeaderContainer = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.spacing(4)}px;
    padding-bottom: ${theme.spacing(4)}px;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2, 2, 4, 2)};
    }
  `
)

const Container = styled.div(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(0, 0, 10)}em;
  `
)

const getBenchmarkListReports = async (
  dispatch: Dispatch<any>,
  token: string
) => {
  setAuthTokenHeader(token)
  const response = await api.get(`api/partner/v1/partner/benchmark/list`)
  const list = getPayloadFromResponse(response)
  dispatch(updateBenchmarkData({ list, inProgress: false }))
}

const getPlayerData = async (token: string, uuid: string) => {
  const endpoint = `api/partner/v1/player/legacy/${uuid}`
  setAuthTokenHeader(token)
  return await api.get(endpoint)
}

const getRoundData = async (token: string, uuid: string) => {
  const endpoint = `api/partner/v1/round/${uuid}`
  setAuthTokenHeader(token)
  return await api.get(endpoint)
}

const handleError = (error: Error, dispatch: Dispatch<any>) => {
  if (isForbiddenOrUnauthorised(error)) {
    console.error('Unauthorized')
  }
  const errorMsg = getErrorCode(error) || getErrorStatus(error)
  dispatch(openToast(getErrorToast(`Unauthorized ${errorMsg}`)))
}

/*
 * Duplicate of rounds summary for Partner API implementation
 * TODO: Refactor to remove duplication and allow partner API to use existing code
 * The duplication allows up to easily remove some UI elements and make our own request
 * to the backend partner's API endpoint
 */
const RoundReport: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { format } = useContext(TranslationContext)!

  const [roundData, setRoundData] = useState<Round>()
  const [isLoading, setIsLoading] = useState(false)

  const partnerToken =
    new URLSearchParams(window.location.search).get('partner') || ''

  const reportRouteMatch = useRouteMatch<{
    playerUuid: string
    roundUuid: string
  }>(localizeRoutePath(Routes.PartnerPlayerRoundDetail))

  const playerUuid = reportRouteMatch?.params.playerUuid
  const reportUuid = reportRouteMatch?.params.roundUuid

  useEffect(() => {
    if (playerUuid && reportUuid) {
      setIsLoading(true)
      getBenchmarkListReports(dispatch, partnerToken)
        .then()
        .catch(error => {
          handleError(error, dispatch)
        })
      // Load player then round data
      getPlayerData(partnerToken, playerUuid)
        .then(playerRes => {
          const player: Player = getSinglePayloadFromResponse(playerRes)
          dispatch(updatePlayer(player))
          // dispatch(updatePlayerBenchmark(player.benchmarkId))
        })
        .catch(error => {
          handleError(error, dispatch)
        })
      getRoundData(partnerToken, reportUuid)
        .then(roundRes => {
          const data = getSinglePayloadFromResponse(roundRes)
          // const player: Player = data.playerData
          // dispatch(updatePlayer(player))
          // dispatch(updatePlayerBenchmark(player.benchmarkId))
          setRoundData(data)
          setIsLoading(false)
        })
        .catch(error => {
          setIsLoading(false)
          handleError(error, dispatch)
        })
    }
  }, [dispatch, partnerToken, playerUuid, reportUuid])

  return (
    <>
      {isLoading ? (
        <GenericLoader
          text={t(`${I18N_KEY}.loadingText`, 'Getting round data')}
        />
      ) : (
        <>
          <AppBar elevation={0} color="default" position="relative">
            <XsPaddingZeroContainer>
              <HeaderContainer>
                {roundData ? (
                  <>
                    <Typography variant="h1">
                      {getRoundTitle(roundData!)}
                    </Typography>
                    <Box mt={-2}>
                      <Typography>
                        {roundData &&
                          format(
                            new Date(roundData.datePlayed),
                            DateFormats.WeekDayMonthDay
                          )}
                      </Typography>
                    </Box>
                  </>
                ) : null}
              </HeaderContainer>
            </XsPaddingZeroContainer>
          </AppBar>
          <MainContentBackground>
            {/* className round_report used for server-side report rendering - DO NOT REMOVE */}
            <Container className="round_report">
              {roundData ? <RoundSummary roundData={roundData} /> : null}
            </Container>
          </MainContentBackground>
        </>
      )}
    </>
  )
}

export default RoundReport
