import React from 'react'
import { Trans } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import DeleteButton from 'src/components/inputs/DeleteButton'

const I18N_KEY = 'RemovePlayerDialog'

interface Props {
  open: boolean
  inProgress: boolean
  handleClose: () => void
  handleAction: () => Promise<void>
}

const RemovePlayerDialog: React.FC<Props> = ({
  open,
  inProgress,
  handleClose,
  handleAction,
}) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogContent>
      <Typography gutterBottom variant="h4" id="form-dialog-title">
        <Trans i18nKey={`${I18N_KEY}.title`}>
          Remove player from dashboard?
        </Trans>
      </Typography>
      <Typography color="textSecondary">
        <Trans i18nKey={`${I18N_KEY}.description`}>
          This will remove your access to this player&apos;s dashboard. Their
          data will be retained on their account. To reconnect with this player
          you&apos;ll need to add them again using their email.
        </Trans>
      </Typography>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={handleClose}>
        <Trans i18nKey={`${I18N_KEY}.cancel`}>Cancel</Trans>
      </Button>
      <DeleteButton $width={145} loading={inProgress} onClick={handleAction}>
        <Trans i18nKey={`${I18N_KEY}.remove`}>Remove Player</Trans>
      </DeleteButton>
    </DialogActions>
  </Dialog>
)

export default RemovePlayerDialog
