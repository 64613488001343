import React, { useMemo, useState, ChangeEvent } from 'react'
import {
  Dot,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  TooltipProps,
  ReferenceLine,
  ResponsiveContainer,
} from 'recharts'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import ExpandMore from '@material-ui/icons/ExpandMore'
import { Box, Paper, useTheme, MenuItem, Typography } from '@material-ui/core'

import useBenchmark from 'src/hooks/useBenchmark'
import { GraphSelect } from 'src/components/graph/common'
import { RenderYAxisTick } from 'src/components/graph/Axis'
import XAxisTick from 'src/components/graph/forecast/XAxisTick'
import TooltipContent from 'src/components/graph/forecast/TooltipContent'
import { CategoryMetricIds, ForecastItem } from 'src/utils/golfConstants'

const I18N_KEY = 'ForecastGraph'

type ForecastBenchmarkInfo = Record<CategoryMetricIds, number | undefined>

interface ForecastGraphProps {
  showFilter?: boolean
  forecast: ForecastItem[]
  defaultMetric?: CategoryMetricIds
  benchmarkInfo: ForecastBenchmarkInfo
}

const Container = styled(Paper)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(3, 3, 3, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 2, 2, 0)};
    }
  `
)

const ErrorContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 3)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const ForecastGraph: React.FC<ForecastGraphProps> = ({
  forecast,
  benchmarkInfo,
  showFilter = true,
  defaultMetric = CategoryMetricIds.Total,
}) => {
  const [metric, setMetric] = useState<CategoryMetricIds>(defaultMetric)

  const theme = useTheme()
  const { t } = useTranslation()
  const { benchmarkName, referencePoint } = useBenchmark()

  const benchmarkValue = benchmarkInfo[metric]

  const data = useMemo(
    () =>
      forecast.map(({ stats, ...item }) => ({ value: stats[metric], ...item })),
    [metric, forecast]
  )

  // Can have the case where we don't get provided values for a particular category
  const dataIsNull = useMemo(
    () => data.some(({ value }) => value === null),
    [data]
  )

  const ticks = useMemo(() => data.map(({ week }) => week), [data])

  const handleHolesNumberChange = (
    event: ChangeEvent<{ name?: string | undefined; value: unknown }>
  ) => {
    setMetric(event.target.value as CategoryMetricIds)
  }

  return (
    <Container elevation={0}>
      <Box display="flex" ml={3} mb={2}>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.predicting`}>Predicting:</Trans>
        </Typography>
        {showFilter ? (
          <GraphSelect
            value={metric}
            IconComponent={ExpandMore}
            onChange={handleHolesNumberChange}
            MenuProps={{
              elevation: 1,
              getContentAnchorEl: null,
              anchorOrigin: { vertical: 'top', horizontal: 'left' },
              transformOrigin: { vertical: 'top', horizontal: 'left' },
            }}
          >
            {Object.values(CategoryMetricIds).map(value => (
              <MenuItem key={value} value={value}>
                {t(`Enums:MetricLabels.${value}`)}
              </MenuItem>
            ))}
          </GraphSelect>
        ) : (
          <Box ml={0.5}>
            <Typography variant="body2">
              {t(`Enums:MetricLabels.${metric}`)}
            </Typography>
          </Box>
        )}
      </Box>
      {dataIsNull ? (
        <ErrorContainer>
          <Typography>
            <Trans i18nKey={`${I18N_KEY}.error`}>
              We don&apos;t have enough data for this category to generate the
              Prediction Model. Please select another category.
            </Trans>
          </Typography>
        </ErrorContainer>
      ) : (
        <ResponsiveContainer width="100%" height={260}>
          <AreaChart data={data}>
            <YAxis
              type="number"
              axisLine={false}
              tickLine={false}
              domain={['dataMin', 'dataMax']}
              tick={props => <RenderYAxisTick {...props} />}
            />
            <XAxis
              type="number"
              ticks={ticks}
              dataKey="week"
              tickLine={false}
              axisLine={false}
              domain={['dataMin', 'dataMax']}
              tick={props => <XAxisTick {...props} />}
            />
            <ReferenceLine
              strokeWidth={2}
              y={benchmarkValue}
              ifOverflow="extendDomain"
              stroke={theme.palette.warning.main}
            />
            <ReferenceLine
              y={0}
              strokeWidth={2}
              strokeDasharray="4"
              ifOverflow="extendDomain"
              stroke={theme.palette.warning.main}
            />
            <Tooltip
              cursor={{
                strokeWidth: 2,
                stroke: theme.palette.background.grey,
              }}
              content={(props: TooltipProps<number, string>) => (
                <TooltipContent
                  {...props}
                  benchmarkName={benchmarkName}
                  benchmarkValue={benchmarkValue}
                  referencePoint={referencePoint}
                />
              )}
            />
            <ReferenceLine
              x={1}
              strokeWidth={2}
              strokeDasharray="4"
              ifOverflow="extendDomain"
              stroke={theme.palette.background.dark}
            />
            <Area
              fill="#fff"
              dataKey="value"
              type="monotone"
              strokeWidth={2}
              fillOpacity={0}
              activeDot={false}
              animationDuration={1000}
              stroke={theme.palette.primary.light}
              dot={props => (
                <Dot
                  {...props}
                  r={2}
                  fillOpacity={1}
                  fill={theme.palette.primary.light}
                />
              )}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </Container>
  )
}

export default ForecastGraph
