import React from 'react'
import { useTranslation } from 'react-i18next'

import { TickProps } from 'src/utils/chartTypes'
import useBreakpoints from 'src/hooks/useBreakpoints'
import { AxisLabel } from 'src/components/graph/common'

const I18N_KEY = 'ForecastXAxisTick'

const XAxisTick: React.FC<TickProps<number>> = ({
  x,
  y,
  textAnchor,
  payload: { value },
}) => {
  const { isDesktop } = useBreakpoints()
  const { t } = useTranslation()

  const getText = () => {
    switch (value) {
      case 0:
        return t(`${I18N_KEY}.current`, 'Current') as string
      case 1:
        return isDesktop
          ? (t(`${I18N_KEY}.weekOne`, 'Week 1') as string)
          : (t(`${I18N_KEY}.w1`, 'W1') as string)
      default:
        return value
    }
  }

  return (
    <AxisLabel y={y} x={x} dy={16} textAnchor={textAnchor}>
      {getText()}
    </AxisLabel>
  )
}

export default XAxisTick
