import React, { useEffect, useContext } from 'react'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import {
  getSummary,
  SummaryStatus,
  summaryStatusSelector,
  metricPerformanceIndicatorsSelector,
} from 'src/store/summarySlice'
import {
  userPlanSelector,
  userIsTrialingSelector,
  hasActiveSubscription,
} from 'src/store/subscriptionSlice'
import { getGreeting } from 'src/utils/helpers'
import useAppState from 'src/hooks/useAppState'
import Score from 'src/modules/player-dash/Score'
import { MetricId } from 'src/utils/golfConstants'
import {
  getRoundCount,
  getRoundLastUpdated,
  totalRoundsSelector,
} from 'src/store/roundSlice'
import useBreakpoints from 'src/hooks/useBreakpoints'
import useRoundFilters from 'src/hooks/useRoundFilters'
import ProgressDonut from 'src/modules/player-dash/donut'
import { playerNameSelector } from 'src/store/playerSlice'
import Statistics from 'src/modules/player-dash/Statistics'
import Performance from 'src/modules/player-dash/performance'
import StrokesGained from 'src/modules/player-dash/strokesGained'
import { TranslationContext } from 'src/utils/TranslationContext'
import { breakpoints } from 'src/utils/constants'
import DashboardFilters from '../header/DashboardFilters'
import InsightDialogButton from '../insight/generation/InsightDialogButton'
import {
  ForecastStatus,
  forecastStatusSelector,
  getForecast,
} from 'src/store/forecastSlice'

const Container = styled.div`
  width: 100%;
  display: flex;
  min-height: 100%;
  position: absolute;
  flex-direction: column;
`

const DashHero = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.background.black};
  `
)

const HeroContent = styled(MuiContainer)<{ $addPagePadding: boolean }>(
  ({ theme, $addPagePadding }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: ${theme.spacing(12)}px;
    padding-top: ${theme.spacing($addPagePadding ? 20 : 12)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      flex-direction: column-reverse;
      padding-bottom: ${theme.spacing(5)}px;
      padding-top: ${theme.spacing($addPagePadding ? 14 : 6)}px;
      align-items: center;
    }
  `
)

const HeroText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      text-align: center;
      margin: ${theme.spacing(2, 0, 0)};
    }
  `
)

const Content = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(6, 0, 20)};
    background-color: ${theme.palette.background.xlight};
  `
)

const ContentContainer = styled(MuiContainer)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding-left: 0;
      padding-right: 0;
    }
  `
)

const ButtonContainer = styled.div`
  margin-top: 24px;
  width: 250px;
`

const Dashboard: React.FC = () => {
  const { isCoach, isPlayer } = useAppState()
  const { timeFilter } = useRoundFilters()
  const { isDesktop } = useBreakpoints()
  const totalRounds = useSelector(totalRoundsSelector)
  const { firstName } = useSelector(playerNameSelector)
  const userNotSubscribed = !useSelector(userPlanSelector)
  const isPlayerTrialing = useSelector(userIsTrialingSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const forecastStatus = useSelector(forecastStatusSelector)
  const { greetingLabels } = useContext(TranslationContext)!
  const performanceIndicators = useSelector(
    metricPerformanceIndicatorsSelector(MetricId.All, false)
  )

  const addPagePadding = isPlayer && (userNotSubscribed || isPlayerTrialing)

  const greeting = greetingLabels[getGreeting()]

  const summaryStatus = useSelector(summaryStatusSelector)

  const summaryIsValid = summaryStatus === SummaryStatus.Valid
  const isLoadingSummary = summaryStatus === SummaryStatus.Loading

  const dispatch = useDispatch()

  useEffect(() => {
    if (isCoach) {
      dispatch(getRoundCount())
      dispatch(getRoundLastUpdated())
    }
    if (summaryStatus === SummaryStatus.UnInitialised) {
      dispatch(getSummary())
    }
  }, [isCoach, summaryStatus, dispatch])

  useEffect(() => {
    if (
      forecastStatus === ForecastStatus.UnInitialised &&
      isPlayerPremium &&
      totalRounds >= 20
    ) {
      dispatch(getForecast())
    }
  }, [forecastStatus, isPlayerPremium, totalRounds, dispatch])

  return (
    <Container>
      <DashHero>
        <HeroContent $addPagePadding={addPagePadding}>
          <Box>
            <HeroText variant="h1">
              {greeting} {firstName}
            </HeroText>
            {isDesktop && <DashboardFilters />}
            {totalRounds >= 5 ? (
              <ButtonContainer>
                <InsightDialogButton />
              </ButtonContainer>
            ) : null}
          </Box>
          <ProgressDonut loading={isLoadingSummary} valid={summaryIsValid} />
        </HeroContent>
      </DashHero>
      {(isLoadingSummary || summaryIsValid) && (
        <Performance timeFilter={timeFilter} loading={isLoadingSummary} />
      )}
      <Content>
        <ContentContainer>
          <Score summaryStatus={summaryStatus} />
          <StrokesGained summaryStatus={summaryStatus} />
          <Statistics
            summaryStatus={summaryStatus}
            performanceIndicators={performanceIndicators}
          />
        </ContentContainer>
      </Content>
    </Container>
  )
}

export default Dashboard
