import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { api } from 'src/utils/api'
import { Plan } from 'src/models/plan'
import { plansSelector } from 'src/store/plansSlice'
import { openToast, getErrorToast } from 'src/store/toastSlice'
import { BillingDuration } from 'src/utils/subscriptionConstants'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'

const I18N_KEY = 'SubscriptionFlowCard'

type PromoCodeDetails = {
  code: string
  totalDiscount: number
  totalPrice: number
  totalWithDiscount: number
}

type PromoCodeResponse = {
  active: boolean
  code: string
  percentOff: number
  promotionCodeId: string
  totalDiscount: number
  totalPrice: number
  totalWithDiscount: number
}

export const usePricing = (plan: Plan | null) => {
  const [promoCodeDetails, setPromoCodeDetails] =
    useState<PromoCodeDetails | null>(null)
  const [isValidatingPromoCode, setIsValidatingPromoCode] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const plans = useSelector(plansSelector)
  const relatedPlan = plans?.find(
    item => item.planType === plan?.planType && item.duration !== plan?.duration
  )

  const isQuarterlyBilling = plan?.duration === BillingDuration.Quarterly

  const clearPromoCodeDetails = () => setPromoCodeDetails(null)

  const planPrice = plan?.price ?? 0

  // For annual plans billing we need to check the pricing of the
  // related monthly plan to get the annual plan discount amount
  const subtotal = isQuarterlyBilling
    ? (plan?.price as number)
    : (relatedPlan?.price as number) * 4

  const yearlyPriceDiscount = isQuarterlyBilling ? 0 : subtotal - planPrice

  const finalPrice = promoCodeDetails?.totalWithDiscount ?? planPrice

  const validatePromoCode = async (code: string) => {
    setIsValidatingPromoCode(true)
    try {
      const response = await api.get(
        `/promotioncode/validate/code/${code}/plan/${plan?.planStripeUuid}`
      )
      const data = getSinglePayloadFromResponse(response) as PromoCodeResponse

      if (data.active) {
        setPromoCodeDetails({
          code: data.code,
          totalPrice: data.totalPrice,
          totalDiscount: data.totalDiscount,
          totalWithDiscount: data.totalWithDiscount,
        })
      } else {
        dispatch(
          openToast(
            getErrorToast(
              t(
                `${I18N_KEY}.invalidPromoCode`,
                'Promo code is invalid or has expired'
              )
            )
          )
        )
      }
    } catch (e) {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.errorPromoCode`,
              'Error checking promo code, please try again'
            )
          )
        )
      )
    } finally {
      setIsValidatingPromoCode(false)
    }
  }

  return {
    validatePromoCode,
    clearPromoCodeDetails,
    isValidatingPromoCode,
    subTotal: subtotal,
    planPrice,
    finalPrice,
    appliedPromoCode: promoCodeDetails?.code,
    yearlyDiscount: yearlyPriceDiscount,
    promoCodeDiscount: promoCodeDetails?.totalDiscount ?? 0,
  }
}

export type PricingDetails = ReturnType<typeof usePricing>
