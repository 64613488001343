import React, { ChangeEvent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { Trans } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import { Input } from '@material-ui/core'

const I18N_KEY = 'ReportCommentDialog'

const TextContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1)}px;
    background: ${theme.palette.background.xlight};
    border: 1px solid #e7e8e9;
    width: 536px;
    max-width: 100%;
    margin-bottom: ${theme.spacing(-2)}px;
  `
)

interface ReportCommentDialogProps extends DialogProps {
  title: string
  comment?: string
  onCancel: () => void
  onSave: (comment: string) => Promise<boolean>
}

const ReportCommentDialog: React.FC<ReportCommentDialogProps> = ({
  title,
  open,
  comment,
  onCancel,
  onSave,
  ...props
}) => {
  const [saving, setSaving] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(comment || '')
  }, [comment, open])

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value)
  }

  const confirmHandler = async () => {
    setSaving(true)
    await onSave(value)
    setSaving(false)
  }

  return (
    <Dialog open={open} onClose={onCancel} {...props}>
      <DialogContent>
        <Typography variant="h4" component="h2" gutterBottom>
          {title}
        </Typography>
        <TextContainer>
          <Input
            name="comment"
            type="text"
            multiline
            rowsMax={8}
            rows={8}
            fullWidth
            onChange={onChange}
            value={value}
            disableUnderline
          />
        </TextContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} disabled={saving}>
          <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Cancel</Trans>
        </Button>
        <Button
          onClick={confirmHandler}
          variant="contained"
          color="primary"
          disabled={saving || value === ''}
        >
          <Trans i18nKey={`${I18N_KEY}.saveButtonLabel`}>Save</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReportCommentDialog
