import React from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import { userSelector } from 'src/store/userSlice'
import { coachPlayerOverviewSelector } from 'src/store/coachOverviewSlice'
import useAppState from 'src/hooks/useAppState'
import styled, { css } from 'styled-components'
import { Button, Paper, Typography } from '@material-ui/core'
import { breakpoints } from 'src/utils/constants'
import { XsPaddingZeroContainer } from 'src/modules/common'
import ArrowDotIllustration from 'src/assets/svgComponents/ArrowDotIllustration'

const I18N_KEY = 'CourseDownload'

interface Props {
  handleClose: () => void
}

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    margin: auto;
    margin-top: ${theme.spacing(4)}px;
    text-align: center;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    ${theme.breakpoints.down('xs')} {
      margin: 0;
      padding: ${theme.spacing(4, 2)};
    }
  `
)

const SectionTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)}px;
  `
)

const HeroText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.dark};
    width: 500px;
    margin: auto;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      text-align: center;
      margin: ${theme.spacing(2, 0, 0)};
    }
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(12)}px;
  `
)

const CourseStrategyDownload: React.FC<Props> = ({ handleClose }) => {
  const { isCoachView, playerUuid } = useAppState()
  const currentUser = useSelector(userSelector) // Could be the coach or player
  const coachPlayer = useSelector(coachPlayerOverviewSelector(playerUuid || '')) // Could be undefined when the player is logged in

  const email = isCoachView ? coachPlayer.email : currentUser.email

  return (
    <XsPaddingZeroContainer>
      <StyledPaper elevation={0}>
        <ArrowDotIllustration rotation="-90deg" scale="0.75" />
        <SectionTitle variant="h4">
          <Trans i18nKey={`${I18N_KEY}.downloadTitle`}>
            Your strategy will download
          </Trans>
        </SectionTitle>
        <Trans i18nKey={`${I18N_KEY}.downloadMessage`}>
          <HeroText>
            Check your download folder, or you can access this document from
            your email, {{ email }}
          </HeroText>
        </Trans>
        <ButtonContainer>
          <Button color="primary" onClick={handleClose}>
            <Trans i18nKey={`${I18N_KEY}.close`}>Close</Trans>
          </Button>
        </ButtonContainer>
      </StyledPaper>
    </XsPaddingZeroContainer>
  )
}

export default CourseStrategyDownload
