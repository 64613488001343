import React from 'react'
import { RouteProps, Route, Redirect } from 'react-router-dom'

import { Routes } from 'src/utils/constants'
import { RefreshStatus } from 'src/store/authSlice'
import LoadingSession from 'src/components/layout/SessionLoading'
import { localizeRoutePath } from 'src/utils/i18n'
import useLogin from 'src/hooks/useLogin'

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { isLoggedIn, refreshStatus } = useLogin()
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: localizeRoutePath(Routes.SignIn) as string,
                state: { from: location },
              }}
            />
          )
        }

        return refreshStatus === RefreshStatus.Initialising ? (
          <LoadingSession />
        ) : (
          children
        )
      }}
    />
  )
}

export default PrivateRoute
