import React from 'react'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { ReactComponent as LeftBrackets } from 'src/assets/images/LeftBrackets.svg'
import { ReactComponent as RightBrackets } from 'src/assets/images/RightBrackets.svg'

interface Props {
  text: string
}

const Container = styled.span`
  display: flex;
  width: fit-content;
  align-items: center;
`

const Text = styled(Typography)(
  ({ theme }) => css`
    margin-top: 2px;
    color: ${theme.palette.primary.main};
  `
)

const FocusLabel: React.FC<Props> = ({ text, ...props }) => {
  return (
    <Container {...props}>
      <LeftBrackets />
      <Text variant="caption">{text}</Text>
      <RightBrackets />
    </Container>
  )
}

export default FocusLabel
