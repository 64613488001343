import React from 'react'
import ReactDOM from 'react-dom'
import styled, { css, ThemeProvider } from 'styled-components'
import { Provider as ReduxProvider } from 'react-redux'

import { CssBaseline } from '@material-ui/core'
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles'

import { config } from './configLoader'

import store from 'src/store'
import App from 'src/modules/App'
import theme from 'src/styles/theme'
import * as serviceWorker from './serviceWorker'
import GlobalStyles from 'src/styles/GlobalStyles'

import history from 'src/utils/history'
import LanguageProvider from 'src/utils/LanguageProvider'
import { Languages, Routes } from 'src/utils/constants'
import TranslationProvider from 'src/utils/TranslationContext'
import { LocalizedRouter } from 'src/components/i18n/LocalizedRouter'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import LocalizedSwitch from './components/i18n/LocalizedSwitch'
import { Route } from 'react-router-dom'
import RoundReport from './modules/partner/rounds'
import Header from './modules/header'

const SITE_KEY = config.REACT_APP_RECAPTCHA_SITE_KEY as string

// *** Duplicated code (TODO: refactor): Start ***
// Load player summary report separate from other routes to exclude loading of stripe snippet
// Note: Duplicated in <App />
const Main = styled.main<{ $margin: number }>(
  ({ $margin }) => css`
    flex: 1;
    padding: 0;
    position: relative;
    margin-top: ${$margin}px;

    /* So items don't hide behind fixed/sticky header when using scroll into view */
    button,
    .MuiBox-root,
    .MuiPaper-root,
    .MuiContainer-root {
      scroll-margin-top: ${$margin}px;
    }
  `
)

const Layout = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
// *** Duplicated code : End ***

ReactDOM.render(
  <ReduxProvider store={store}>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <>
            <GlobalStyles />
            <CssBaseline />
            <LocalizedRouter history={history}>
              <LanguageProvider>
                <TranslationProvider>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={SITE_KEY}
                    language={Languages.English}
                    useRecaptchaNet
                    useEnterprise={false}
                    scriptProps={{
                      async: false,
                      defer: false,
                      appendTo: 'head',
                      nonce: undefined,
                    }}
                  >
                    {history.location.pathname.includes('/partner/player/') ? (
                      <Layout>
                        {/* Load player summary report separate from other routes to exclude loading of stripe snippet 
                            Note: Duplicated in <App />: Start */}
                        <Header
                          isCoach={false}
                          isPlayer
                          isLoggedIn={false}
                          isCoachView={false}
                          completedProfile={false}
                        />
                        <Main id="main-pass" $margin={74}>
                          <LocalizedSwitch>
                            <Route path={Routes.PartnerPlayerRoundDetail}>
                              <RoundReport />
                            </Route>
                          </LocalizedSwitch>
                        </Main>
                        {/* Duplcated code: End */}
                      </Layout>
                    ) : (
                      <App />
                    )}
                  </GoogleReCaptchaProvider>
                </TranslationProvider>
              </LanguageProvider>
            </LocalizedRouter>
          </>
        </StylesProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  </ReduxProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
