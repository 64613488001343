import React, { useContext, useEffect, useMemo } from 'react'
import { Trans } from 'react-i18next'
import DateFnsUtils from '@date-io/date-fns'
import { loadStripe } from '@stripe/stripe-js'
import styled, { css } from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'
import { useDispatch, useSelector } from 'react-redux'
import { Route, useLocation, useRouteMatch } from 'react-router-dom'
import { useFlags, withLDProvider } from 'launchdarkly-react-client-sdk'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import {
  COACH_VIEW_HEADER_HEIGHT,
  floatingActionsRoutes,
  HEADER_HEIGHT,
  isChina,
  Routes,
  UPGRADE_QUERY_PARAM_NAME,
} from 'src/utils/constants'
import NotFound from 'src/modules/404'
import Header from 'src/modules/header'
import useLogin from 'src/hooks/useLogin'
import usePlans from 'src/hooks/usePlans'
import useQuery from 'src/hooks/useQuery'
import Activity from 'src/modules/activity'
import SignIn from 'src/modules/auth/SignIn'
import RoundsDialog from 'src/modules/rounds'
import Categories from 'src/modules/categories'
import useAppState from 'src/hooks/useAppState'
import Register from 'src/modules/auth/Register'
import PracticeDialog from 'src/modules/practice'
import Toast from 'src/components/feedback/Toast'
import { localizeRoutePath } from 'src/utils/i18n'
import CoachDashboard from 'src/modules/coach-dash'
import { RefreshStatus } from 'src/store/authSlice'
import PlayerDashboard from 'src/modules/player-dash'
import PracticeDashboard from 'src/modules/practice-dash'
import CoachSettings from 'src/modules/coach-settings'
import VerifyEmail from 'src/modules/auth/VerifyEmail'
import PlayerSettings from 'src/modules/player-settings'
import SkipLink from 'src/components/navigation/SkipLink'
import ResetPassword from 'src/modules/auth/ResetPassword'
import SignInProvider from 'src/modules/auth/SignInProvider'
import ForgotPassword from 'src/modules/auth/ForgotPassword'
import SubscriptionFlow from 'src/components/subscriptionFlow'
import ScrollToTop from 'src/components/navigation/ScrollToTop'
import VerifyDialog from 'src/components/feedback/VerifyDialog'
import RegisterProvider from 'src/modules/auth/RegisterProvider'
import PrivateRoute from 'src/components/navigation/PrivateRoute'
import LocalizedSwitch from 'src/components/i18n/LocalizedSwitch'
import { TranslationContext } from 'src/utils/TranslationContext'
import FloatingActions from 'src/components/inputs/FloatingActions'
import { playerSelector, updatePlayerUuid } from 'src/store/playerSlice'
import ChinaPaymentVerification from 'src/components/subscriptionFlow/ChinaPaymentVerification'
import { config } from '../configLoader'
import CourseStrategy from 'src/modules/course-strategy'
import AddCourseStrategyDialog from 'src/modules/course-strategy/courseStrategyDetail'
import InsightComponent from './insight'
import ImprovementOpportunities from './insight/generation'
import DiscussionDocumentGeneration from './reports/discussion-document/generation'

const I18N_KEY = 'App'

interface MatchParams {
  lang: string
  playerUuid?: string
}

const stripePromise = loadStripe(config.REACT_APP_STRIPE_KEY!)

const Main = styled.main<{ $margin: number }>(
  ({ $margin }) => css`
    flex: 1;
    padding: 0;
    position: relative;
    margin-top: ${$margin}px;

    /* So items don't hide behind fixed/sticky header when using scroll into view */
    button,
    .MuiBox-root,
    .MuiPaper-root,
    .MuiContainer-root {
      scroll-margin-top: ${$margin}px;
    }
  `
)

const Layout = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`

const App: React.FC = () => {
  usePlans()
  const query = useQuery()
  const dispatch = useDispatch()
  const { stripeUpgrade } = useFlags()
  const { pathname } = useLocation()
  const { uuid } = useSelector(playerSelector)
  const { locale } = useContext(TranslationContext)!
  const { isCoach, isPlayer, isCoachView, completedProfile } = useAppState()
  const { courseStrategy, courseStrategyChina } = useFlags()
  const courseStrategyFeatureEnabled = isChina
    ? courseStrategyChina
    : courseStrategy

  const match = useRouteMatch<MatchParams>('/:lang/player/:playerUuid')
  const playerUuid = match?.params.playerUuid || uuid
  const { isLoggedIn, refreshStatus, refreshSession } = useLogin()

  const refreshValid = refreshStatus === RefreshStatus.Valid
  const refreshInitialising = refreshStatus === RefreshStatus.Initialising

  const showVerifyingUpgrade = useMemo(() => {
    return (
      isChina &&
      query.has('out_trade_no') &&
      query.get('method') === 'alipay.trade.page.pay.return'
    )
  }, [query])

  const showUpgrade = useMemo(
    () => query.has(UPGRADE_QUERY_PARAM_NAME) && !showVerifyingUpgrade,
    [query, showVerifyingUpgrade]
  )

  const headerMargin =
    isLoggedIn && refreshInitialising
      ? 0
      : isCoachView
      ? COACH_VIEW_HEADER_HEIGHT
      : HEADER_HEIGHT

  const showFloatingActions = useMemo(() => {
    // Only show for coaches when they are viewing a player
    if (isCoach && !isCoachView) {
      return false
    }

    // Only pass uuid for when in coach view
    return floatingActionsRoutes(isPlayer ? undefined : uuid).some(route => {
      const localizedRoute = localizeRoutePath(route)

      return localizedRoute === pathname || pathname.includes(Routes.Categories)
    })
  }, [uuid, pathname, isCoach, isPlayer, isCoachView])

  useEffect(() => {
    if (playerUuid) {
      dispatch(updatePlayerUuid({ uuid: playerUuid }))
    }
    refreshSession()
  }, []) /* eslint-disable-line react-hooks/exhaustive-deps */

  const playerRoutes = useMemo(
    () => [
      <PrivateRoute
        key={Routes.PracticeDashboard}
        path={Routes.PracticeDashboard}
      >
        <PracticeDashboard />
      </PrivateRoute>,
      <PrivateRoute key={Routes.Activity} path={Routes.Activity}>
        <Activity />
      </PrivateRoute>,
      <PrivateRoute key={Routes.Insight} path={Routes.Insight}>
        <InsightComponent />
      </PrivateRoute>,
      <PrivateRoute key={Routes.Categories} path={Routes.Categories}>
        <Categories isLoggedIn={isLoggedIn} refreshStatus={refreshStatus} />
      </PrivateRoute>,
    ],
    [isLoggedIn, refreshStatus]
  )

  const coachRoutes = [
    <PrivateRoute
      exact
      key={Routes.CoachPlayerDash}
      path={Routes.CoachPlayerDash}
    >
      <PlayerDashboard />
    </PrivateRoute>,
    <PrivateRoute
      key={Routes.CoachPlayerPracticeDash}
      path={Routes.CoachPlayerPracticeDash}
    >
      <PracticeDashboard />,
    </PrivateRoute>,
    <PrivateRoute
      key={Routes.CoachPlayerActivity}
      path={Routes.CoachPlayerActivity}
    >
      <Activity />
    </PrivateRoute>,
    <PrivateRoute
      key={Routes.CoachPlayerInsight}
      path={Routes.CoachPlayerInsight}
    >
      <InsightComponent />
    </PrivateRoute>,
    <PrivateRoute
      key={Routes.CoachPlayerCategories}
      path={Routes.CoachPlayerCategories}
    >
      <Categories isLoggedIn={isLoggedIn} refreshStatus={refreshStatus} />
    </PrivateRoute>,
  ]

  const content = (
    <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
      <>
        <ScrollToTop />
        <SkipLink href="#main-pass">
          <Trans i18nKey={`${I18N_KEY}.skipLinkText`}>
            Skip to main content
          </Trans>
        </SkipLink>
        <Layout>
          {(!refreshInitialising || !isLoggedIn) && (
            <Header
              isCoach={isCoach}
              isPlayer={isPlayer}
              isLoggedIn={isLoggedIn}
              isCoachView={isCoachView}
              completedProfile={completedProfile}
            />
          )}
          <Main id="main-pass" $margin={headerMargin}>
            <LocalizedSwitch>
              <PrivateRoute exact path={Routes.Dashboard}>
                {isPlayer ? <PlayerDashboard /> : <CoachDashboard />}
              </PrivateRoute>
              <PrivateRoute path={Routes.Settings}>
                {isPlayer ? <PlayerSettings /> : <CoachSettings />}
              </PrivateRoute>
              <Route exact path={Routes.RegisterProvider}>
                <RegisterProvider />
              </Route>
              <Route exact path={Routes.Register}>
                <Register />
              </Route>
              {isChina && (
                <Route exact path={Routes.CLPGASignup}>
                  <Register requiresPayment />
                </Route>
              )}
              <Route exact path={Routes.SignInProvider}>
                <SignInProvider />
              </Route>
              <Route exact path={Routes.SignIn}>
                <SignIn />
              </Route>
              <Route path={Routes.VerifyEmail}>
                <VerifyEmail />
              </Route>
              <Route path={Routes.ForgotPassword}>
                <ForgotPassword />
              </Route>
              <Route path={Routes.ResetPassword}>
                <ResetPassword />
              </Route>
              {isPlayer ? playerRoutes : coachRoutes}
              {isPlayer && courseStrategyFeatureEnabled && (
                <PrivateRoute
                  key={Routes.CourseStrategy}
                  path={Routes.CourseStrategy}
                >
                  <CourseStrategy />
                </PrivateRoute>
              )}
              {!isPlayer && courseStrategyFeatureEnabled && (
                <PrivateRoute
                  key={Routes.CoachPlayerCourseStrategy}
                  path={Routes.CoachPlayerCourseStrategy}
                >
                  <CourseStrategy />
                </PrivateRoute>
              )}
              <Route
                path="*"
                render={() => (
                  <NotFound
                    isLoggedIn={isLoggedIn}
                    refreshStatus={refreshStatus}
                  />
                )}
              />
            </LocalizedSwitch>
          </Main>
        </Layout>
        <Toast />
        {isLoggedIn && (
          <>
            <RoundsDialog />
            <AddCourseStrategyDialog />
            <PracticeDialog />
            <VerifyDialog />
            <DiscussionDocumentGeneration />
            <ImprovementOpportunities />
            <SubscriptionFlow open={showUpgrade} />
            {isChina && !refreshInitialising && (
              <ChinaPaymentVerification open={showVerifyingUpgrade} />
            )}
          </>
        )}
        {showFloatingActions && refreshValid && <FloatingActions />}
      </>
    </MuiPickersUtilsProvider>
  )

  return stripeUpgrade ? (
    content
  ) : (
    <Elements stripe={stripePromise}>{content}</Elements>
  )
}

export default withLDProvider({
  clientSideID: config.REACT_APP_LAUNCHDARKLY_CLIENT_ID!,
})(App)
