import { useState, useContext, useCallback, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import {
  saveIO,
  savedIOSelector,
  dismissSuggestedIO,
} from 'src/store/summarySlice'
import { MetricId, FocusAreaIO } from 'src/utils/golfConstants'
import { TranslationContext } from 'src/utils/TranslationContext'
import { openToast, getErrorToast, getSuccessToast } from 'src/store/toastSlice'

const I18N_KEY = 'useIOManagement'

const useIO = (metricId: MetricId) => {
  const [swapDialogOpen, setSwapDialogOpen] = useState(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { metricLabels } = useContext(TranslationContext)!
  const savedOpportunities = useSelector(savedIOSelector) as FocusAreaIO[]

  const metricName = metricLabels[metricId]

  const openDeleteDialog = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation()
      setDeleteDialogOpen(true)
    },
    []
  )

  const closeDeleteDialog = useCallback(() => {
    setDeleteDialogOpen(false)
  }, [])

  const closeSwapDialog = useCallback(() => {
    setSwapDialogOpen(false)
  }, [])

  const onSaveOpportunity = useCallback(async () => {
    // Player can only have 3 opportunities
    if (savedOpportunities.length < 3) {
      try {
        await dispatch(saveIO(metricId))
        dispatch(
          openToast(
            getSuccessToast(
              t(`${I18N_KEY}.successToast`, {
                metricName,
                defaultValue:
                  '{{ metricName }} has been saved as an opportunity',
              })
            )
          )
        )
      } catch {
        dispatch(
          openToast(
            getErrorToast(
              t(`${I18N_KEY}.errorToast`, {
                metricName,
                defaultValue:
                  'Could not save {{ metricName }} as an opportunity',
              })
            )
          )
        )
      }
    } else {
      setSwapDialogOpen(true)
    }
  }, [t, dispatch, metricId, metricName, savedOpportunities.length])

  const handleDismissIO = useCallback(async () => {
    await dispatch(dismissSuggestedIO(metricId))
  }, [dispatch, metricId])

  return {
    metricName,
    swapDialogOpen,
    handleDismissIO,
    closeSwapDialog,
    deleteDialogOpen,
    openDeleteDialog,
    closeDeleteDialog,
    onSaveOpportunity,
    savedOpportunities,
  }
}

export default useIO
