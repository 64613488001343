import React from 'react'

const CalendarIcon: React.FC = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#A6AAAD"
      fillRule="evenodd"
      d="M3 1a1 1 0 012 0v1h6V1a1 1 0 112 0v1h2a1 1 0 011 1v2H0V3a1 1 0 011-1h2V1zm13 5H0v9a1 1 0 001 1h14a1 1 0 001-1V6z"
      clipRule="evenodd"
    />
  </svg>
)

export default React.memo(CalendarIcon)
