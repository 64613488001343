import React, { useContext } from 'react'
import {
  Strength,
  strengthGraphDataSelector,
  summaryFocusAreasSelector,
  SummaryStatus,
} from '../../store/summarySlice'
import { useSelector } from 'react-redux'
import StrengthGraph from './StrengthGraph'
import { Grid } from '@material-ui/core'
import { ResponsiveGridxs2 } from '../common'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import ReferencedTitle from '../../components/dataDisplay/ReferencedTitle'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Skeleton from '../../components/dataDisplay/Skeleton'
import { TranslationContext } from '../../utils/TranslationContext'

interface Props {
  summaryStatus: SummaryStatus
}

const Container = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(6, 0, 0)};
  `
)

const HeadingContainer = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const StyledReferencedTitle = styled(ReferencedTitle)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const Card = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(16, 4)};
  `
)

const ErrorText = styled(Typography)`
  max-width: 600px;
`

const I18N_KEY = 'Strengths'

const Strengths: React.FC<Props> = ({ summaryStatus }) => {
  const { t } = useTranslation()
  const focusAreas = useSelector(summaryFocusAreasSelector)
  const { metricLabels } = useContext(TranslationContext)!
  const data: Strength[][] = useSelector(
    strengthGraphDataSelector(
      focusAreas.slice(focusAreas.length - 2, focusAreas.length)
    )
  )

  const formatStrengthTitle = (strength: Strength) => {
    return metricLabels[strength.metricId]
  }

  const getContentWithData = () => {
    switch (summaryStatus) {
      case SummaryStatus.Loading:
        return (
          <Skeleton variant="rect" width="100%" height={350} animation="wave" />
        )
      case SummaryStatus.Valid:
        return (
          <ResponsiveGridxs2 container spacing={2}>
            {data.map(
              strength =>
                strength[0] && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                    key={strength[0].metricId}
                  >
                    <StrengthGraph
                      title={formatStrengthTitle(strength[0])}
                      key={strength[0].metricId}
                      strength={strength}
                      currentValue={
                        focusAreas.find(
                          area => area.metricId === strength[0].metricId
                        )!.difference
                      }
                      isActive={summaryStatus === SummaryStatus.Valid}
                    />
                  </Grid>
                )
            )}
          </ResponsiveGridxs2>
        )
      case SummaryStatus.NoFilterRounds:
        return (
          <Card elevation={0}>
            <>
              <ErrorText color="textSecondary" variant="h4" align="center">
                <Trans i18nKey={`${I18N_KEY}.noFilterRoundsErrorMessage`}>
                  You have no rounds matching this filter.
                </Trans>
              </ErrorText>
              <ErrorText color="textSecondary" align="center">
                <Trans i18nKey={`${I18N_KEY}.secondaryErrorMessage`}>
                  Please select another.
                </Trans>
              </ErrorText>
            </>
          </Card>
        )
    }
  }

  const getContent = () => {
    if (summaryStatus === SummaryStatus.NoRoundsError) {
      return (
        <Card elevation={0}>
          <ErrorText color="textSecondary" align="center">
            <Trans i18nKey={`${I18N_KEY}.noRoundsErrorErrorMessage`}>
              Once you’ve entered round data this area will show you how you are
              performing using the latest golf analysis method, strokes gained.
            </Trans>
          </ErrorText>
        </Card>
      )
    }
    if (data.every(array => array.length === 0)) {
      return (
        <Skeleton variant="rect" width="100%" height={350} animation="wave" />
      )
    }
    return getContentWithData()
  }

  return (
    <Container>
      <HeadingContainer>
        <StyledReferencedTitle
          title={t(`${I18N_KEY}.title`, 'Strengths')}
          tooltipText={t(`${I18N_KEY}.tooltip`, 'Add tooltip text')}
        />
      </HeadingContainer>
      {getContent()}
    </Container>
  )
}

export default Strengths
