import React from 'react'
import { Trans } from 'react-i18next'
import { TooltipProps } from 'recharts'

import { Box } from '@material-ui/core'

import {
  DashIcon,
  ScratchIcon,
  TooltipText,
  TooltipTitle,
  DifferenceIcon,
  DifferenceText,
  TooltipContainer,
} from 'src/components/graph/common'
import { DECIMAL_PLACES } from 'src/utils/constants'
import { getAbsolutelyFixedNumber } from 'src/utils/helpers'

const I18N_KEY = 'ForecastTooltip'

interface TooltipContentProps extends TooltipProps<number, string> {
  benchmarkName: string
  referencePoint: string
  benchmarkValue: number | undefined
}

const TooltipContent: React.FC<TooltipContentProps> = ({
  payload,
  benchmarkName,
  benchmarkValue,
  referencePoint,
}) => {
  const data = payload && payload[0]?.payload

  const prediction = data && Number(data.value.toFixed(2))

  const isGreater = prediction > (benchmarkValue as number)
  const absDifference = getAbsolutelyFixedNumber(
    prediction - (benchmarkValue as number)
  )

  const weekNumber = data?.week || 0

  return (
    <TooltipContainer>
      <Box px={2}>
        <TooltipTitle>
          {weekNumber === 0 ? (
            <Trans i18nKey={`${I18N_KEY}.current`}>Current</Trans>
          ) : (
            <Trans i18nKey={`${I18N_KEY}.week`}>Week {{ weekNumber }}</Trans>
          )}
        </TooltipTitle>
      </Box>
      <Box
        px={2}
        pt={1}
        mt={1}
        display="flex"
        borderTop="1px solid"
        borderColor="#282828"
        justifyContent="space-between"
      >
        <TooltipText variant="h6">
          <ScratchIcon />
          {referencePoint}
        </TooltipText>
        <TooltipText $isValue>0.00</TooltipText>
      </Box>
      <Box px={2} display="flex" justifyContent="space-between">
        <TooltipText>
          <DashIcon />
          <Trans i18nKey={`${I18N_KEY}.prediction`}>Prediction</Trans>
        </TooltipText>
        <TooltipText $isValue>{prediction}</TooltipText>
      </Box>
      <Box px={2} display="flex" justifyContent="space-between">
        <TooltipText>
          <DashIcon $isScratch />
          {benchmarkName}
        </TooltipText>
        <TooltipText>{benchmarkValue?.toFixed(DECIMAL_PLACES)}</TooltipText>
      </Box>
      <Box px={2} display="flex" justifyContent="space-between">
        <TooltipText>
          <DifferenceIcon />
          <Trans i18nKey={`${I18N_KEY}.toBenchmark`}>
            To {{ benchmarkName }}
          </Trans>
        </TooltipText>
        <DifferenceText $isGreater={isGreater}>{absDifference}</DifferenceText>
      </Box>
    </TooltipContainer>
  )
}

export default TooltipContent
