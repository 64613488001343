import React from 'react'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip'

const Title = styled(Typography)(
  ({ theme }) => css`
    font-weight: 600;
    color: ${theme.palette.text.white};
  `
)

const Description = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.text.white};
    font-size: ${theme.typography.pxToRem(14)};
    line-height: ${theme.typography.pxToRem(20)};
  `
)

type Props = TooltipProps & { description: string }

const Tooltip: React.FC<Props> = ({ title, description, ...props }) => (
  <MuiTooltip
    {...props}
    title={
      <>
        <Title variant="h5" gutterBottom>
          {title}
        </Title>
        <Description>{description}</Description>
      </>
    }
  />
)

export default Tooltip
