import {
  IO,
  CategoryType,
  RootCategory,
  RootStrokesGained,
} from 'src/utils/golfConstants'
import { getRoundTitle, getStrokesForOpportunity } from 'src/utils/helpers'
import { SummaryRound } from './summarySlice'

export function extractRadarData(
  strokesGained: RootStrokesGained | null | undefined
) {
  return [
    {
      reference: 0,
      category: CategoryType.Drives,
      overall: strokesGained?.drives?.overall,
      benchmark: strokesGained?.drives?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Approach,
      overall: strokesGained?.approach?.overall,
      benchmark: strokesGained?.approach?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Short,
      overall: strokesGained?.short?.overall,
      benchmark: strokesGained?.short?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Putt,
      overall: strokesGained?.putt?.overall,
      benchmark: strokesGained?.putt?.benchmark,
    },
  ]
}

export function extractIOPotential(
  savedMetrics: IO[] | undefined,
  categories: RootCategory[] | undefined,
  strokesGained: RootStrokesGained | null | undefined
) {
  return (
    savedMetrics
      ?.map(
        ({ metricId }) =>
          getStrokesForOpportunity(
            metricId,
            categories as RootCategory[],
            strokesGained || null
          ) || 0
      )
      .reduce((acc, cur) => acc + cur, 0) || 0
  )
}

export function extractScoreInfo(rounds: SummaryRound[] | undefined) {
  return (rounds || []).map(round => {
    const { summary, datePlayed, numberOfHoles } = round
    const score = summary!.score

    return {
      numberOfHoles,
      toPar: score.toPar,
      average: score.average,
      roundTitle: getRoundTitle(round),
      datePlayed: new Date(datePlayed).getTime(),
      rollingAverage: score.rollingAverage,
    }
  })
}
