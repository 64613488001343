import React from 'react'
import {
  Route,
  Redirect,
  LinkProps,
  useHistory,
  useRouteMatch,
} from 'react-router-dom'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'

import {
  SettingsMenu,
  SettingsButton,
  SettingsContainer,
} from 'src/modules/common'
import { localizeRoutePath } from 'src/utils/i18n'
import Profile from 'src/modules/coach-settings/profile'
import Account from 'src/modules/player-settings/account'
import { CoachSettingsRoutes, Routes } from 'src/utils/constants'
import LocalizedSwitch from 'src/components/i18n/LocalizedSwitch'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'

const Page = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.background.xlight};
  `
)

const DEFAULT_PATH = CoachSettingsRoutes.Profile

const MenuButton: React.FC<LinkProps> = ({ children, ...props }) => {
  const { to } = props
  const match = useRouteMatch({
    path: localizeRoutePath(to as string),
    strict: true,
    sensitive: true,
  })
  const isExact = match?.isExact
  return (
    <SettingsButton {...props}>
      {children}
      {isExact && <ActiveBar layoutId="profile-menu" />}
    </SettingsButton>
  )
}

const settingRouteEntries = Object.entries(CoachSettingsRoutes)

const CoachSettings: React.FC = () => {
  const history = useHistory()

  const handleMenuItemClick = (path: string): void => {
    history.push(localizeRoutePath(path) as string)
  }

  return (
    <Page>
      <SettingsContainer>
        <AnimateSharedLayout>
          <SettingsMenu>
            {settingRouteEntries.map(([key, path]) => {
              const fullPath = `${Routes.Settings}${path}`
              return (
                <MenuButton
                  key={fullPath}
                  to={fullPath}
                  onClick={() => handleMenuItemClick(fullPath)}
                >
                  <Trans i18nKey={`Enums:SettingsRoutes.${key}`} />
                </MenuButton>
              )
            })}
          </SettingsMenu>
        </AnimateSharedLayout>
        <LocalizedSwitch>
          <Route path={`${Routes.Settings}${CoachSettingsRoutes.Profile}`}>
            <Profile />
          </Route>
          <Route path={`${Routes.Settings}${CoachSettingsRoutes.Account}`}>
            <Account />
          </Route>
          <Route path={`${Routes.Settings}`}>
            <Redirect to={`${Routes.Settings}${DEFAULT_PATH}`} />
          </Route>
        </LocalizedSwitch>
      </SettingsContainer>
    </Page>
  )
}

export default CoachSettings
