import React, { ChangeEvent, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'

import Box from '@material-ui/core/Box'
import Add from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import useDebounce from 'react-use/lib/useDebounce'

import Player from 'src/modules/coach-dash/player'
import Pagination from 'src/components/dataDisplay/Pagination'
import SearchTextField from 'src/components/inputs/SearchTextField'
import {
  getCoachPlayers,
  requestInProgress,
  coachPlayersCount,
  removePlayerCoachConnection,
  unregisteredPlayersSelector,
  coachOverviewLoadingSelector,
  coachPlayersSelector,
} from 'src/store/coachOverviewSlice'
import { COACH_PAGINATION_LIMIT } from 'src/utils/constants'
import InviteDialog from 'src/modules/coach-dash/InviteDialog'
import EmptyMessage from 'src/components/dataDisplay/EmptyMessage'
import {
  CoachInvitationItems,
  CoachInvitations,
} from 'src/models/coach-player-overview'
import PlayerLoading from 'src/modules/coach-dash/player/PlayerLoading'
import RemovePlayerDialog from 'src/modules/coach-dash/RemovePlayerDialog'
import UnregisteredPlayer from 'src/modules/coach-dash/UnregisteredPlayer'
import { getSuccessToast, getErrorToast, openToast } from 'src/store/toastSlice'

const I18N_KEY = 'Players'

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(1)}px;
  `
)

const Players: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [deleteInProgress, setDeleteInProgress] = useState(false)
  const [deleteUuid, setDeleteUuid] = useState<string | null>(null)

  const dispatch = useDispatch()
  const { t } = useTranslation()

  const [inviteDialogOpenState, setInviteDialogOpenState] = useState(false)

  const playersOverviewItems = useSelector(coachPlayersSelector)
  const players: CoachInvitations[] = Object.keys(playersOverviewItems)
    .map(uuid => {
      const player: any = playersOverviewItems[uuid]
      return {
        email: player.email,
        uuid: uuid,
        inviteUuid: player.inviteUuid,
        firstName: player.firstName,
        lastName: player.lastName,
        accepted: player.accepted,
      }
    })
    .filter(player => player.email !== undefined)

  const unregisteredPlayers = useSelector(
    unregisteredPlayersSelector
  ) as CoachInvitationItems
  const unregisteredPlayersKeys = Object.keys(unregisteredPlayers)

  const hasPlayers = !!players?.length
  const hasUnregisteredPlayers = unregisteredPlayersKeys.length > 0

  const playersCount = useSelector(coachPlayersCount)
  const { loaded, requestInProgress: inProgress } = useSelector(
    coachOverviewLoadingSelector
  )
  const isLoading = !loaded || inProgress

  const [searchInput, setSearchInput] = useState('')
  const handleChangeSearchInput = (value: string = '') => {
    if (value !== searchInput) {
      dispatch(requestInProgress())
      setSearchInput(value)
    }
  }
  const onChangeSearch = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeSearchInput(e.target.value)
  }
  useDebounce(
    () => {
      dispatch(getCoachPlayers(0, searchInput))
      // First page should be 1
      setCurrentPage(1)
    },
    500,
    [searchInput, dispatch]
  )

  const openDeleteDialog = (uuid: string) => {
    setDeleteUuid(uuid)
  }

  const closeDeleteDialog = () => {
    setDeleteUuid(null)
  }

  const onPageChange = (event: ChangeEvent<unknown>, newPage: number) => {
    dispatch(
      getCoachPlayers((newPage - 1) * COACH_PAGINATION_LIMIT, searchInput)
    )
    setCurrentPage(newPage)
    window.scrollTo(0, 0)
  }

  const handleAddPlayer = () => setInviteDialogOpenState(true)
  const handleInviteSuccess = () => {
    setInviteDialogOpenState(false)
    dispatch(
      openToast(getSuccessToast(t(`${I18N_KEY}.inviteSent`, 'Invite sent')))
    )
    dispatch(getCoachPlayers())
  }
  const handleInviteError = () => {
    dispatch(
      openToast(
        getErrorToast(t(`${I18N_KEY}.inviteError`, 'Error sending invite'))
      )
    )
  }
  const handleInviteClose = () => setInviteDialogOpenState(false)

  const handlePlayerRemoveRequest = async () => {
    setDeleteInProgress(true)
    try {
      await dispatch(removePlayerCoachConnection(deleteUuid as string))
      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.removeSuccess`, 'Player has been removed')
          )
        )
      )
      dispatch(getCoachPlayers(0, searchInput))
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(t(`${I18N_KEY}.removeError`, 'Error removing player'))
        )
      )
    } finally {
      setDeleteInProgress(false)
      closeDeleteDialog()
    }
  }

  return (
    <>
      <div>
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="h3">
            <Trans i18nKey={`${I18N_KEY}.title`}>Your Players</Trans>
          </Typography>
          <Button
            color="primary"
            startIcon={<Add fontSize="small" />}
            onClick={handleAddPlayer}
          >
            <Trans i18nKey={`${I18N_KEY}.addPlayerButton`}>Add Player</Trans>
          </Button>
        </Box>
        <SearchTextField
          placeholder={t(`${I18N_KEY}.searchPlayers`, 'Search players')}
          cleanAriaLabel={t(`${I18N_KEY}.clearSearch`, 'Clear search')}
          onCleanSearch={() => handleChangeSearchInput()}
          onChange={onChangeSearch}
          value={searchInput}
        />
        {isLoading ? (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <PlayerLoading />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <PlayerLoading />
            </Grid>
          </Grid>
        ) : hasPlayers || hasUnregisteredPlayers ? (
          <>
            <Grid container spacing={2}>
              {unregisteredPlayersKeys.map(email => (
                <Grid key={email} item xs={12} sm={12} md={6}>
                  <UnregisteredPlayer
                    {...unregisteredPlayers[email]}
                    openDeleteDialog={openDeleteDialog}
                  />
                </Grid>
              ))}
              {players.map((player: CoachInvitations) => (
                <Grid key={player.uuid} item xs={12} sm={12} md={6}>
                  <Player
                    playerFirstName={player.firstName || ''}
                    playerLastName={player.lastName || ''}
                    playerUuid={player.uuid || ''}
                    playerAccepted={player.accepted}
                    openDeleteDialog={openDeleteDialog}
                  />
                </Grid>
              ))}
            </Grid>
            {playersCount > COACH_PAGINATION_LIMIT && (
              <Box display="flex" justifyContent="center" mt={6}>
                <Pagination
                  page={currentPage}
                  count={playersCount}
                  onPageChange={onPageChange}
                  paginationLimit={COACH_PAGINATION_LIMIT}
                />
              </Box>
            )}
          </>
        ) : searchInput ? (
          <EmptyMessage
            title={t(`${I18N_KEY}.noResults`, 'No Results')}
            description={t(`${I18N_KEY}.tryNewSearch`, 'Try a new search')}
          />
        ) : (
          <p>
            <Trans i18nKey={`${I18N_KEY}.noPlayersConnected`}>
              No players connected
            </Trans>
          </p>
        )}
      </div>
      {inviteDialogOpenState && (
        <InviteDialog
          open={inviteDialogOpenState}
          onClose={handleInviteClose}
          onCancel={handleInviteClose}
          onSuccess={handleInviteSuccess}
          onError={handleInviteError}
        />
      )}
      <RemovePlayerDialog
        open={!!deleteUuid}
        inProgress={deleteInProgress}
        handleClose={closeDeleteDialog}
        handleAction={handlePlayerRemoveRequest}
      />
    </>
  )
}

export default Players
