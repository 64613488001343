import { AppThunk } from 'src/store'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './rootReducer'

import { api } from 'src/utils/api'
import { ForecastItem } from 'src/utils/golfConstants'
import {
  authRequestFail,
  getPayloadFromResponse,
  getUserInfo,
  isForbiddenOrUnauthorised,
} from 'src/utils/helpers'
import { getErrorToast, openToast } from './toastSlice'
import {
  summaryRoundsSelector,
  summaryStrokesGainedSelector,
} from './summarySlice'

interface InitialState {
  forecastsStatus: ForecastStatus
  forecasts: ForecastItem[]
}

export enum ForecastStatus {
  UnInitialised = 'uninitialised',
  Valid = 'valid',
  Error = 'error',
  Loading = 'loading',
}

const initialState: InitialState = {
  forecastsStatus: ForecastStatus.UnInitialised,
  forecasts: [],
}

const { actions, reducer } = createSlice({
  name: 'forecast',
  initialState,
  reducers: {
    updateStatus: (
      state,
      action: PayloadAction<{ status: ForecastStatus }>
    ) => {
      state.forecastsStatus = action.payload.status
    },
    updateForecasts: (
      state,
      action: PayloadAction<{ forecasts: ForecastItem[] }>
    ) => {
      const { forecasts } = action.payload
      state.forecasts = forecasts
    },
    reinitialiseForecasts: () => {
      return initialState
    },
  },
})

export default reducer
export const { updateStatus, updateForecasts, reinitialiseForecasts } = actions

// Selectors
export const forecastSelector = (state: RootState) => state.forecast.forecasts

export const forecastStatusSelector = (state: RootState) =>
  state.forecast.forecastsStatus

export const forecastDataSelector = createSelector(
  summaryRoundsSelector,
  forecastSelector,
  summaryStrokesGainedSelector,
  (rounds, forecast, strokesGained) => {
    const latestRound = rounds[rounds.length - 1]
    const roundStrokesGained = latestRound?.summary?.strokesGained

    const forecastData = [
      {
        week: 0,
        date: latestRound?.datePlayed,
        stats: {
          M02: roundStrokesGained?.total.average || null,
          M03: roundStrokesGained?.drives.average || null,
          M04: roundStrokesGained?.approach.average || null,
          M05: roundStrokesGained?.short.average || null,
          M06: roundStrokesGained?.putt.average || null,
        },
      },
      ...forecast,
    ]

    const benchmarkInfo = {
      M02: strokesGained?.total.benchmark,
      M03: strokesGained?.drives.benchmark,
      M04: strokesGained?.approach.benchmark,
      M05: strokesGained?.short.benchmark,
      M06: strokesGained?.putt.benchmark,
    }

    return {
      benchmarkInfo,
      forecast: forecastData,
    }
  }
)

// Action Creators
export const getForecast = (): AppThunk => async (dispatch, getState) => {
  dispatch(updateStatus({ status: ForecastStatus.Loading }))
  const { isPlayer, playerUuid } = getUserInfo(getState())
  const endpoint = isPlayer
    ? 'forecast'
    : `overview/player/${playerUuid}/forecast`

  try {
    const response = await api.get(endpoint)
    const forecasts: ForecastItem[] = getPayloadFromResponse(response)
    dispatch(updateForecasts({ forecasts }))
    dispatch(updateStatus({ status: ForecastStatus.Valid }))
  } catch (error: any) {
    dispatch(updateStatus({ status: ForecastStatus.Error }))
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    } else {
      dispatch(openToast(getErrorToast('Could not load forecasts')))
    }
  }
}

export const resetForecast = (): AppThunk => async dispatch => {
  try {
    dispatch(reinitialiseForecasts())
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}
