import React from 'react'

import Typography from '@material-ui/core/Typography'

import { parsePar } from 'src/utils/helpers'
import { Hole } from 'src/utils/golfConstants'
import { Cell, Column, HoleText } from 'src/modules/rounds/table/common'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'

interface Props {
  holes: Hole[]
  currentHole: number
  mappingArray: number[]
  getHoleClickHandler: (index: number) => () => void
}

const HoleList: React.FC<Props> = ({
  holes,
  currentHole,
  mappingArray,
  getHoleClickHandler,
}) => (
  <>
    {mappingArray.map(index => {
      const selected = currentHole === index
      const completed = index <= holes.length - 1

      return (
        <Column
          key={`hole-${index}`}
          disabled={holes.length < index}
          onClick={getHoleClickHandler(index)}
        >
          <Cell>
            <Typography
              variant={selected ? 'body2' : 'body1'}
              color={selected ? 'primary' : 'textPrimary'}
            >
              {index + 1}
            </Typography>
          </Cell>
          <Cell>
            <HoleText selected={selected} completed={completed}>
              {parsePar(holes[index]?.par) || '4'}
            </HoleText>
          </Cell>
          <Cell>
            <HoleText selected={selected} completed={completed}>
              {holes[index]?.shots.length || '•'}
            </HoleText>
          </Cell>
          {selected && <ActiveBar layoutId="shots-table" />}
        </Column>
      )
    })}
  </>
)

export default React.memo(HoleList)
