import React, { useState } from 'react'
import { countries as defaultCountries, Country } from '../../models/countries'
import { MenuItem, Select } from '@material-ui/core'
import styled from 'styled-components'
import { Control, Controller } from 'react-hook-form'
import { isChina } from 'src/utils/constants'

interface CountrySelectorProps {
  control: Control
  countries?: Country[]
  defaultValue?: string
}

const StyledSelect = styled(Select)`
  margin-right: 12px;
`

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  control,
  countries = defaultCountries,
  defaultValue,
}) => {
  const [dialCode, setDialCode] = useState<string>(isChina ? '+86' : '+64')

  const handleChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setDialCode(event.target.value as string)
  }

  return (
    <Controller
      name="dialCode"
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, ...props }) => (
        <StyledSelect
          variant="outlined"
          labelId="country-selector-label"
          id="demo-simple-select-autowidth"
          onChange={event => {
            handleChange(event)
            onChange(event.target.value)
          }}
          autoWidth
          label="Country"
          renderValue={() => <span>{dialCode}</span>}
          {...props}
        >
          {countries?.map(country => (
            <MenuItem key={country.code} value={country.dialCode}>
              {country.name} ({country.dialCode})
            </MenuItem>
          ))}
        </StyledSelect>
      )}
    />
  )
}
