import React from 'react'
import styled, { css, StyledProps } from 'styled-components'
import Paper from '@material-ui/core/Paper'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import MuiTableContainer from '@material-ui/core/TableContainer'
import MuiTableCell from '@material-ui/core/TableCell'

interface TextProps {
  hasStrokes: boolean
}

export const TableContainer = styled(({ ...props }) => (
  <MuiTableContainer {...props} component={Paper} elevation={0} />
))(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing(2, 0, 0)};
  `
)

export const PaginationContainer = styled(({ ...props }) => <div {...props} />)(
  ({ theme }) => css`
    width: 100%;
    margin: ${theme.spacing(2, 0, 16)};
  `
)

export const DataRow = styled(TableRow)`
  transition: box-shadow 0.3s ease-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px rgba(43, 42, 98, 0.1);
  }
`

export const SGText = styled(({ hasStrokes, ...props }) => (
  <Typography {...props} />
))(
  ({ theme, hasStrokes }: StyledProps<TextProps>) => css`
    color: ${hasStrokes
      ? theme.palette.text.primary
      : theme.palette.background.grey};
  `
)

export const TableCell = styled(({ ...props }) => <MuiTableCell {...props} />)(
  ({ theme }) => css`
    min-width: 200px;
    ${theme.breakpoints.down('sm')} {
      min-width: 100px;
      padding: ${theme.spacing(3, 2)};
      font-size: ${theme.typography.pxToRem(16)};
      line-height: ${theme.typography.pxToRem(26)};
    }
  `
)

export const TitleCell = styled(({ ...props }) => <MuiTableCell {...props} />)(
  ({ theme }) => css`
    min-width: 220px;
    ${theme.breakpoints.down('sm')} {
      min-width: 120px;
      padding: ${theme.spacing(2, 1.5)};
      font-size: ${theme.typography.pxToRem(14)};
      line-height: ${theme.typography.pxToRem(20)};
    }
  `
)
