import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { api } from 'src/utils/api'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'

const I18N_KEY = 'useResendEmailVerification'

const useResendEmailVerification = (email: string) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const resendVerificationEmail = async () => {
    try {
      await api.post('user/verification/resend', { email })
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.verificationSuccessMessage`,
              'Verification link resent'
            )
          )
        )
      )
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.verificationErrorMessage`,
              'Could not resend verification link'
            )
          )
        )
      )
    }
  }

  return { resendVerificationEmail }
}

export default useResendEmailVerification
