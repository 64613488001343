import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Help from '@material-ui/icons/Help'
import { Box, Tooltip, Typography } from '@material-ui/core'

import useBenchmark from 'src/hooks/useBenchmark'

const I18N_KEY = 'ReferencedTitle'

interface Props {
  title: string
  tooltipText?: string
}

const HelpIcon = styled(Help)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(0.5)}px;
    fill: ${theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(22)};
  `
)

const ReferencedTitle: React.FC<Props> = ({ title, tooltipText, ...props }) => {
  const { referencePoint } = useBenchmark()

  return (
    <div {...props}>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Typography variant="h3">{title}</Typography>
        {tooltipText && (
          <Tooltip arrow enterDelay={700} title={tooltipText}>
            <HelpIcon />
          </Tooltip>
        )}
      </Box>
      <Typography variant="caption" component="p" color="textSecondary">
        <Trans i18nKey={`${I18N_KEY}.vs`}>VS {{ referencePoint }} (0.0)</Trans>
      </Typography>
    </div>
  )
}

export default React.memo(ReferencedTitle)
