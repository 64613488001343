import React, { MouseEvent, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core'

import {
  deletePractice,
  getPractices,
  practicesCountSelector,
  practicesSelector,
  PracticeStatus,
  practiceStatusSelector,
  setSelectedPracticeByUuid,
} from 'src/store/practiceSlice'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import {
  PaginationContainer,
  TableContainer,
} from 'src/components/dataDisplay/tableStyles'
import useAppState from 'src/hooks/useAppState'
import LoadingRow from 'src/modules/activity/LoadingRow'
import { MembershipLevel, PAGINATION_LIMIT, Routes } from 'src/utils/constants'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import UpgradePanel from 'src/components/membership/UpgradePanel'
import PracticeRow from 'src/modules/activity/practice/PracticeRow'
import EmptyPractice from 'src/modules/activity/practice/EmptyPractice'
import ActionConfirmDialog from 'src/modules/activity/DeleteConfirmDialog'
import TablePaginationActions from 'src/components/dataDisplay/TablePaginationActions'
import { PlanType } from 'src/utils/subscriptionConstants'

const I18N_KEY = 'PracticeTable'

const PracticeTable: React.FC = () => {
  const [page, setPage] = useState(0)
  const [deleteUuid, setDeleteUuid] = useState('')
  const [isDeleting, setIsDeleting] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isCoachView, playerUuid } = useAppState()

  const practices = useSelector(practicesSelector)
  const totalPractices = useSelector(practicesCountSelector)
  const practiceStatus = useSelector(practiceStatusSelector)
  const practiceIsLoading = practiceStatus === PracticeStatus.Loading

  const isPlayerPremium = useSelector(
    hasActiveSubscription(MembershipLevel.Basic)
  )
  const isPlayerTrialing = useSelector(userIsTrialingSelector)

  const noPracticesEntered = !practiceIsLoading && practices.length === 0

  const loadingArray = useMemo(
    () =>
      Array.from(
        Array(
          totalPractices > 0 && totalPractices < PAGINATION_LIMIT
            ? totalPractices
            : PAGINATION_LIMIT
        ).keys()
      ),
    [totalPractices]
  )

  const handleChangePage = async (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    dispatch(getPractices(newPage * PAGINATION_LIMIT))
    setPage(newPage)
  }

  const handlePracticeClick = (event: MouseEvent<HTMLTableRowElement>) => {
    const uuid = event.currentTarget.id
    dispatch(setSelectedPracticeByUuid({ uuid }))
    const route = isCoachView
      ? Routes.CoachPlayerPracticeDetail
      : Routes.PracticeDetail
    history.push(
      route
        .replace(':playerUuid', playerUuid ?? '')
        .replace(':practiceUuid', uuid)
    )
  }

  const closeDialog = () => {
    setDeleteUuid('')
  }

  const generateDeleteClick =
    (uuid: string) => (event: MouseEvent<HTMLLIElement>) => {
      event.stopPropagation()
      setDeleteUuid(uuid)
    }

  const handleDelete = async () => {
    try {
      setIsDeleting(true)
      await dispatch(deletePractice(deleteUuid))
      setIsDeleting(false)
    } catch {
      dispatch(
        openToast(
          getErrorToast(t(`${I18N_KEY}.deleteToast`, 'Could not delete round'))
        )
      )
    }
    setDeleteUuid('')
  }

  return isPlayerPremium || isPlayerTrialing ? (
    <>
      {!noPracticesEntered && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="caption" color="textSecondary">
                    <Trans i18nKey={`${I18N_KEY}.practice`}>Practice</Trans>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {practiceIsLoading
                ? loadingArray.map(index => <LoadingRow key={index} />)
                : practices.map(practice => (
                    <PracticeRow
                      key={practice.uuid}
                      practice={practice}
                      handlePracticeClick={handlePracticeClick}
                      handleDeleteClick={generateDeleteClick(practice.uuid)}
                    />
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <PaginationContainer>
        {noPracticesEntered ? (
          <EmptyPractice status={practiceStatus} />
        ) : (
          <TablePagination
            page={page}
            component="div"
            count={totalPractices}
            rowsPerPageOptions={[]}
            rowsPerPage={PAGINATION_LIMIT}
            onPageChange={handleChangePage}
            ActionsComponent={TablePaginationActions}
          />
        )}
      </PaginationContainer>

      <ActionConfirmDialog
        open={!!deleteUuid}
        inProgress={isDeleting}
        handleClose={closeDialog}
        handleAction={handleDelete}
        title={t(
          `${I18N_KEY}.dialogTitle`,
          'Are you sure you want to delete this practice?'
        )}
        description={t(
          `${I18N_KEY}.dialogDescription`,
          'This action can’t be undone.'
        )}
        deleteText={t(`${I18N_KEY}.dialogDeleteText`, 'Delete Practice')}
      />
    </>
  ) : (
    <Box mt={2}>
      <UpgradePanel planType={PlanType.Train}>
        <Trans i18nKey={`${I18N_KEY}.upgradeDescription`}>
          Track your practice done at the range or on course against intelligent
          practice targets relating to your goal.
        </Trans>
      </UpgradePanel>
    </Box>
  )
}

export default PracticeTable
