import React from 'react'
import styled, { css } from 'styled-components'

import { useTheme } from '@material-ui/core'

import Ring from 'src/assets/svgComponents/Ring'
import Goal from 'src/assets/svgComponents/GoalIcon'
import ProgressRing from 'src/components/dataDisplay/ProgressRing'

interface Props {
  progress: number
  showGoalIcon?: boolean
  backgroundRingOpacity?: number
}

interface RingProps {
  $opacity: number
  $progress: number
}

const ringStyles = css`
  left: 0;
  top: 50%;
  width: 100%;
  position: absolute;
  transform: translateY(-50%);
`

export const BackgroundRing = styled(Ring)<RingProps>(
  ({ $opacity, $progress }) => css`
    ${ringStyles}
    opacity: ${$progress > 1 ? 1 : $opacity};
  `
)

const StyledProgressRing = styled(ProgressRing)`
  ${ringStyles}
`

export const GoalIcon = styled(Goal)`
  top: -5px;
  left: 50%;
  width: 12%;
  position: absolute;
  transform: translateX(-50%);
`

const ProgressDonut: React.FC<Props> = ({
  progress,
  showGoalIcon = false,
  backgroundRingOpacity = 0.4,
}) => {
  const theme = useTheme()

  let simplifiedProgress = progress

  const donutColors = {
    badge: '#F6A609',
    progress: theme.palette.primary.light,
    background: theme.palette.primary.main,
  }

  if (progress > 1) {
    simplifiedProgress = progress - 1
    donutColors.progress = theme.palette.success.main
    donutColors.background = theme.palette.primary.light
  }

  if (progress < 0) {
    simplifiedProgress = progress * -1
    donutColors.progress = theme.palette.error.main
    donutColors.badge = theme.palette.background.grey
  }

  return (
    <>
      <BackgroundRing
        $progress={progress}
        $opacity={backgroundRingOpacity}
        stroke={donutColors.background}
      />
      <StyledProgressRing
        isNegative={progress < 0}
        progress={simplifiedProgress}
        stroke={donutColors.progress}
      />
      {showGoalIcon && <GoalIcon color={donutColors.badge} />}
    </>
  )
}

export default ProgressDonut
