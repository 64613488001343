import React from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Box, Typography } from '@material-ui/core'

import ScoreGraph from 'src/components/graph/score'
import { reportScoreSelector } from 'src/store/reportSlice'

const I18N_KEY = 'ReportScore'

const TitleContainer = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

interface ScoreProps {
  referencePoint: string
}

const Score: React.FC<ScoreProps> = ({ referencePoint }) => {
  const data = useSelector(reportScoreSelector)

  return (
    <Box mb={8}>
      <TitleContainer>
        <Typography variant="h3">
          <Trans i18nKey={`${I18N_KEY}.score`}>Score to Par</Trans>
        </Typography>
        <Typography component="p" variant="caption" color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.vsPar`}>vs Par</Trans>
        </Typography>
      </TitleContainer>
      <ScoreGraph
        data={data}
        id="report-score"
        referencePoint={referencePoint}
      />
    </Box>
  )
}

export default Score
