import React from 'react'

import Button, { ButtonProps } from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

interface Props extends ButtonProps {
  loading: boolean
}

const LoaderButton: React.FC<Props> = ({
  loading,
  endIcon,
  children,
  disabled,
  startIcon,
  ...props
}) => (
  <Button
    {...props}
    disabled={loading || disabled}
    endIcon={loading ? undefined : endIcon}
    startIcon={loading ? undefined : startIcon}
  >
    {loading ? <CircularProgress size={26} color="inherit" /> : children}
  </Button>
)

export default React.memo(LoaderButton)
