import { SubscriptionFlowData } from './types'
import { useEffect, useState } from 'react'
import { api } from '../../utils/api'
import { NewSubscriptionResponseV2 } from '../../models/subscription'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { getErrorToast, openToast } from '../../store/toastSlice'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { getSinglePayloadFromResponse } from '../../utils/helpers'
import { getSubscriptionInfo } from '../../store/subscriptionSlice'
import { getInvoices } from '../../store/invoiceSlice'
import { StripePaymentElementChangeEvent } from '@stripe/stripe-js/types/stripe-js/elements/payment'
import { BillingDuration } from '../../utils/subscriptionConstants'
import { plansSelector } from '../../store/plansSlice'
import { SetupIntentResult } from '@stripe/stripe-js'

enum StripeLoggingType {
  INFO,
  WARN,
  ERROR,
}

export interface NewStripePaymentData extends SubscriptionFlowData {
  total: number
  discount: number
  subtotal: number
  promoCode: string
}

type UsePaymentProps = {
  data: NewStripePaymentData
  onNext: (data?: SubscriptionFlowData) => void
  onChange: (data: SubscriptionFlowData) => void
}

const I18N_KEY = 'SubscriptionFlowCard'

export const useNewStripePayment = ({
  data,
  onNext,
  onChange,
}: UsePaymentProps) => {
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const plans = useSelector(plansSelector)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isPaymentValid, setIsPaymentValid] = useState(false)

  useEffect(() => {
    if (elements) {
      const paymentElement = elements.getElement('payment')
      if (paymentElement) {
        paymentElement.on(
          'change',
          (event: StripePaymentElementChangeEvent) => {
            setIsPaymentValid(event.complete)
          }
        )
      }
    }
  }, [elements])

  const handleSubscription = async (
    data: SubscriptionFlowData,
    promotionCode?: string
  ) => {
    let subscriptionId = ''
    try {
      if (submitting || !stripe || !elements) {
        return
      }

      setSubmitting(true)

      if (!data.plan) {
        setSubmitting(false)
        return
      }

      const setupIntentResponse = await api.post('subscription/v2/setupIntent')

      const clientSecret = getSinglePayloadFromResponse(setupIntentResponse)

      await elements.submit()

      const result: SetupIntentResult = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: 'https://drawmorecircles.com',
          payment_method_data: {
            billing_details: {
              address: {
                postal_code: data.billing?.postcode,
                country: data.billing?.country.code,
              },
            },
          },
        },
        redirect: 'if_required',
      })

      if (!result.error) {
        await api.put(
          `subscription/v2/defaultPaymentMethod/${
            result.setupIntent.payment_method as string
          }`
        )
      } else {
        await api.post('subscription/v2/logging', {
          message: result.error.message,
          type: StripeLoggingType.ERROR,
        })
        setSubmitting(false)
        return
      }

      const subscriptionResponse = await api.post('subscription/v2', {
        planType: data.plan.planType,
        billingDuration: data.billingDuration,
        billing: data.billing,
        promotionCode,
      })

      const tailoredResponse: NewSubscriptionResponseV2 =
        getSinglePayloadFromResponse(subscriptionResponse)

      subscriptionId = tailoredResponse.subscriptionId

      if (tailoredResponse.isNewSubscription) {
        await api.post('subscription/v2/logging', {
          message: `Subscription created ${subscriptionId}, confirming PaymentIntent`,
          type: StripeLoggingType.INFO,
        })
        const { error } = await stripe.confirmPayment({
          clientSecret: tailoredResponse.clientSecret,
          confirmParams: {
            payment_method_data: {
              billing_details: {
                address: {
                  postal_code: data.billing?.postcode,
                  country: data.billing?.country.code,
                },
              },
            },
            return_url: 'https://drawmorecircles.com',
          },
          redirect: 'if_required',
        })

        if (error) {
          setErrorMessage(error.message!)
          setSubmitting(false)
          await api.post('subscription/v2/logging', {
            message: error.message,
            type: StripeLoggingType.ERROR,
          })
          if (subscriptionId) {
            await api.delete(`subscription/v2/${subscriptionId}`)
          }
        } else {
          await api.post('subscription/v2/logging', {
            message: `PaymentIntent for ${subscriptionId} confirmed`,
            type: StripeLoggingType.INFO,
          })
        }
      } else {
        await api.post('subscription/v2/logging', {
          message: `Subscription ${subscriptionId} upgraded, no need to confirm PaymentIntent`,
          type: StripeLoggingType.INFO,
        })
      }

      dispatch(getInvoices())
      dispatch(getSubscriptionInfo())

      if (onNext) {
        onNext()
      }
    } catch (error: any) {
      await api.post('subscription/v2/logging', {
        message: error.message,
        type: StripeLoggingType.ERROR,
      })
      if (subscriptionId) {
        await api.delete(`subscription/v2/${subscriptionId}`)
      }
      const code = error.message
      let message: string
      const errorKey = `Enums:SubscriptionError.${code}`
      if (i18n.exists(errorKey)) {
        message = t(errorKey)
      } else {
        message = t(
          `${I18N_KEY}.createSubscriptionErrorMessage`,
          'Error creating payment'
        )
      }
      dispatch(openToast(getErrorToast(message)))
    } finally {
      setSubmitting(false)
    }
  }

  const handleBillingDurationChange = (billingDuration: BillingDuration) => {
    const currentPlan = data.plan
    const updatedPlan =
      plans?.find(
        item =>
          item.planType === currentPlan?.planType &&
          item.duration !== currentPlan?.duration
      ) || null

    onChange({
      ...data,
      billingDuration,
      plan: updatedPlan,
    })
  }

  return {
    elements,
    stripe,
    submitting,
    isPaymentValid,
    handleSubscription,
    handleBillingDurationChange,
    errorMessage,
  }
}
