import React from 'react'
import styled, { css } from 'styled-components'
import { DialogProps } from 'material-ui'
import {
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
} from '@material-ui/core'

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperWidthSm {
    width: 100%;
    max-width: 650px;
  }
`

const DialogContent = styled(MuiDialogContent)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: ${theme.spacing(5)}px;

    &.MuiDialogContent-root:first-child {
      padding-top: ${theme.spacing(5)}px;
    }
  `
)

const CustomDialog: React.FC<DialogProps> = ({ open, ...props }) => {
  return (
    <Dialog open={open}>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  )
}

export default CustomDialog
