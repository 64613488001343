import React from 'react'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

import useBenchmark from 'src/hooks/useBenchmark'
import { MetricId } from 'src/utils/golfConstants'
import ComposedGraph from 'src/components/graph/composed'
import Legend from 'src/components/graph/composed/Legend'
import { SummarySingleItem } from 'src/store/summarySlice'
import StrokesProgressLabel from 'src/components/dataDisplay/StrokesProgressLabel'

interface CategoryGraphProps {
  metricId: MetricId
  width: string | number
  height: string | number
  payload: SummarySingleItem
}

const Container = styled(Paper)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(5, 0, 0)};
  `
)

const HeaderContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${theme.breakpoints.up(750)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

const LegendContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    margin: ${theme.spacing(3, 0, 0)};

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(3, 2, 0)};
    }
  `
)

const CategoryGraph: React.FC<CategoryGraphProps> = ({
  width,
  height,
  payload,
  metricId,
}) => {
  const dataExists = payload.sgTotal !== null
  const { benchmarkId, ...benchmarkInfo } = useBenchmark()

  return (
    <Container elevation={0}>
      <HeaderContent>
        <Box mb={2} pl={5}>
          <StrokesProgressLabel
            dataExists={dataExists}
            sgValue={payload.sgTotal}
            progress={payload.progress}
          />
        </Box>
      </HeaderContent>
      <ComposedGraph
        width={width}
        id={metricId}
        height={height}
        payload={payload}
        dataExists={dataExists}
        {...benchmarkInfo}
      />
      <LegendContainer>
        <Legend />
      </LegendContainer>
    </Container>
  )
}

export default CategoryGraph
