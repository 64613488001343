import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Add from '@material-ui/icons/Add'
import { Box, Button } from '@material-ui/core'

import { hasActiveSubscription } from 'src/store/subscriptionSlice'
import UpgradeDialog from 'src/components/membership/UpgradeDialog'
import ReportCommentDialog from 'src/modules/reports/ReportDetail/ReportCommentDialog'

const I18N_KEY = 'ReportComment'

const Container = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(2)}px;
    background: ${theme.palette.background.paper};
    border-radius: 6px;
  `
)

const CommentText = styled.div`
  white-space: pre-line;
`

const AddButton = styled(Button)(
  ({ theme }) => css`
    .MuiButton-iconSizeMedium > *:first-child {
      font-size: 1rem;
    }
    svg {
      fill: ${theme.palette.primary.light};
    }
  `
)

interface ReportCommentProps {
  comment?: string
  className?: string
  dialogTitle: string
  isCoachView: boolean
  addButtonLabel: string
  isCreatedByUser: boolean
  onChangeComment: (comment: string) => Promise<boolean>
}

const ReportComment: React.FC<ReportCommentProps> = ({
  comment,
  className,
  dialogTitle,
  isCoachView,
  addButtonLabel,
  isCreatedByUser,
  onChangeComment,
}) => {
  const [open, setOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  const playerIsPremium = useSelector(hasActiveSubscription())

  const canAddComment = isCoachView && isCreatedByUser && playerIsPremium

  const openCommentDialog = () => {
    if (canAddComment) {
      setOpen(true)
    } else {
      setUpgradeDialogOpen(true)
    }
  }

  const saveCommentHandler = async (comment: string) => {
    const saved = await onChangeComment(comment)
    if (saved) {
      setOpen(false)
    }
    return saved
  }

  const deleteCommentHandler = async () => {
    if (canAddComment) {
      setDeleting(true)
      await onChangeComment('')
      setDeleting(false)
    } else {
      setUpgradeDialogOpen(true)
    }
  }

  const closeUpgradeDialog = () => {
    setUpgradeDialogOpen(false)
  }

  return (
    <Container className={className}>
      {comment ? (
        <>
          <CommentText>{comment}</CommentText>
          {canAddComment && (
            <Box
              display="flex"
              justifyContent="space-between"
              margin={-2}
              mt={2}
            >
              <Button
                color="primary"
                disabled={deleting}
                onClick={openCommentDialog}
              >
                <Trans i18nKey={`${I18N_KEY}.editButtonLabel`}>Edit</Trans>
              </Button>
              <Button onClick={deleteCommentHandler} disabled={deleting}>
                <Trans i18nKey={`${I18N_KEY}.deleteButtonLabel`}>Delete</Trans>
              </Button>
            </Box>
          )}
        </>
      ) : canAddComment ? (
        <AddButton startIcon={<Add />} onClick={openCommentDialog}>
          {addButtonLabel}
        </AddButton>
      ) : (
        <CommentText>
          <Trans i18nKey={`${I18N_KEY}.noComment`}>No comments added</Trans>
        </CommentText>
      )}
      <ReportCommentDialog
        title={dialogTitle}
        open={open}
        onCancel={() => setOpen(false)}
        onSave={saveCommentHandler}
        comment={comment}
      />
      <UpgradeDialog
        open={upgradeDialogOpen}
        onClose={closeUpgradeDialog}
        onUpgradeOpen={closeUpgradeDialog}
      />
    </Container>
  )
}

export default ReportComment
