import styled, { css } from 'styled-components'
import Link from 'src/components/navigation/Link'

export const LinkButton = styled(Link)(
  ({ theme }) => css`
    display: flex;
    font-weight: 500;
    border-radius: 6px;
    align-items: center;
    margin: ${theme.spacing(3, 0, 0)};
    padding: ${theme.spacing(1.5, 2)};
    color: ${theme.palette.background.paper};
    transition: background-color 0.3s ease-out;
    background-color: ${theme.palette.primary.main};

    svg {
      transition: color 0.3s ease-out;
      margin-right: ${theme.spacing(1)}px;
      color: ${theme.palette.primary.light};
    }

    &:hover {
      color: ${theme.palette.background.paper};
      background-color: ${theme.palette.primary.light};

      svg {
        color: ${theme.palette.background.paper};
      }
    }
  `
)

export default LinkButton
