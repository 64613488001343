import React from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Controller, Control } from 'react-hook-form'

import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'

import {
  AutocompletePaper,
  SubscriptionTextField,
  SubscriptionFieldLabel,
  SubscriptionHookedTextField,
} from 'src/components/subscriptionFlow/common'
import { countries, Country } from 'src/models/countries'
import { ErrorMessage } from 'src/components/inputs/FormTextField'

const I18N_KEY = 'SubscriptionFlowBillingAddress'

const StyledShortFormTextField = styled(SubscriptionHookedTextField)`
  ${ErrorMessage} {
    bottom: -29px;
  }
`

const StyledSubscriptionFieldLabel = styled(SubscriptionFieldLabel)<{
  withPaymentElement: boolean
}>(
  ({ theme, withPaymentElement }) => css`
    font-family: ${withPaymentElement ? 'Arial' : theme.typography.fontFamily};
    display: block;
    margin: 24px 0 8px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 600;
  `
)

interface Props {
  control: Control
  disabled: boolean
  withPaymentElement: boolean
}

const BillingAddressForm: React.FC<Props> = ({
  control,
  disabled,
  withPaymentElement,
}) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StyledSubscriptionFieldLabel
          htmlFor="billing-form-full-name"
          withPaymentElement={withPaymentElement}
        >
          {t(`${I18N_KEY}.fullNameLabel`, 'Full name')}
        </StyledSubscriptionFieldLabel>
        <SubscriptionHookedTextField
          name="fullName"
          id="billing-form-full-name"
          fullWidth
          disabled={disabled}
          placeholder={t(`${I18N_KEY}.fullNamePlaceholder`, 'Enter name')}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledSubscriptionFieldLabel
          htmlFor="billing-form-street-address"
          withPaymentElement={withPaymentElement}
        >
          {t(`${I18N_KEY}.addressLabel`, 'Street Address')}
        </StyledSubscriptionFieldLabel>
        <SubscriptionHookedTextField
          name="address"
          id="billing-form-street-address"
          autoComplete="street-address"
          fullWidth
          disabled={disabled}
          placeholder={t(`${I18N_KEY}.streetAddressPlaceholder`, 'Address')}
          control={control}
        />
      </Grid>
      <Grid item xs={7}>
        <StyledSubscriptionFieldLabel
          htmlFor="billing-form-city"
          withPaymentElement={withPaymentElement}
        >
          {t(`${I18N_KEY}.cityLabel`, 'City')}
        </StyledSubscriptionFieldLabel>
        <SubscriptionHookedTextField
          name="city"
          id="billing-form-city"
          autoComplete="address-level2"
          fullWidth
          disabled={disabled}
          placeholder={t(`${I18N_KEY}.cityPlaceholder`, 'City')}
          control={control}
        />
      </Grid>
      <Grid item xs={5}>
        <StyledSubscriptionFieldLabel
          htmlFor="billing-form-post-code"
          withPaymentElement={withPaymentElement}
        >
          {t(`${I18N_KEY}.postcodeLabel`, 'Post/ZIP code')}
        </StyledSubscriptionFieldLabel>
        <StyledShortFormTextField
          name="postcode"
          id="billing-form-post-code"
          autoComplete="postal-code"
          fullWidth
          disabled={disabled}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        <StyledSubscriptionFieldLabel
          htmlFor="billing-form-country"
          withPaymentElement={withPaymentElement}
        >
          {t(`${I18N_KEY}.countryLabel`, 'Country')}
        </StyledSubscriptionFieldLabel>
        <Controller
          render={props => (
            <Autocomplete
              {...props}
              value={props.value || null}
              disabled={disabled}
              PaperComponent={AutocompletePaper}
              options={countries}
              getOptionLabel={(country: Country) => country.name}
              renderInput={params => (
                <SubscriptionTextField
                  {...params}
                  name="country"
                  variant="outlined"
                  disabled={disabled}
                  placeholder={t(
                    `${I18N_KEY}.countryPlaceholder`,
                    'Please select'
                  )}
                  inputProps={{
                    ...params.inputProps,
                    id: 'billing-form-country',
                    autoComplete: 'country-name',
                  }}
                  onChange={evt => {
                    const country: Country | undefined = countries.find(
                      (country: Country) =>
                        country.name.toLowerCase() ===
                        evt.target.value.toLowerCase()
                    )
                    if (country) {
                      props.onChange(country)
                    }
                  }}
                />
              )}
              onChange={(e, value) => {
                props.onChange(value)
              }}
            />
          )}
          name="country"
          control={control}
        />
      </Grid>
    </Grid>
  )
}

export default BillingAddressForm
