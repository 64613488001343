import React from 'react'
import { useField } from 'formik'
import styled, { css } from 'styled-components'

import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@material-ui/lab/ToggleButtonGroup'
import InputLabel from '@material-ui/core/InputLabel'
import ToggleButton from '@material-ui/lab/ToggleButton'

interface ExtraProps {
  name: string
  label?: string
  options: Options
  disabled?: boolean
}

interface Option {
  label: string
  value: string
}

type Options = Option[]

type Props = ToggleButtonGroupProps & ExtraProps

const ButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => css`
    width: 100%;
    border: none;
    padding: 4px;
    border-radius: 6px;
    background-color: #f5f5f5;
    margin: ${theme.spacing(1, 0, 0)};
  `
)

const Label = styled(InputLabel)(
  ({ theme }) => css`
    font-weight: ${theme.typography.fontWeightMedium};
  `
)

const Button = styled(ToggleButton)(
  ({ theme }) => css`
    flex: 1;
    border: none;
    text-transform: none;
    background-color: #f5f5f5;
    border-radius: 4px !important;
    color: ${theme.palette.text.primary};
    padding: 11px ${theme.spacing(2.5)}px;
    transition: color 0.3s ease-out, background-color 0.3s ease-out,
      box-shadow 0.3s ease-out;

    &:hover {
      background-color: #f5f5f5;
      color: ${theme.palette.primary.main};
    }

    &.MuiToggleButton-root {
      letter-spacing: 0;
    }

    &.MuiToggleButton-root.Mui-selected {
      color: ${theme.palette.primary.main};
      box-shadow: 0px 0px 15px rgba(43, 42, 98, 0.1);
      background-color: ${theme.palette.background.paper};
    }
  `
)

const FormToggleButton: React.FC<Props> = ({
  name,
  label,
  options,
  className,
  disabled = false,
  ...props
}) => {
  const [field, , helpers] = useField({
    name,
  })

  const { setValue } = helpers
  const { onBlur, onChange, value: selectedVal, ...restField } = field

  const handleSelection = (
    event: React.MouseEvent<HTMLElement>,
    selection: string
  ) => {
    if (selection) {
      setValue(selection)
    }
  }

  return (
    <div className={className}>
      {label && <Label shrink>{label}</Label>}
      <ButtonGroup
        exclusive
        value={selectedVal}
        onChange={handleSelection}
        {...props}
        {...restField}
      >
        {options.map(({ label, value }) => (
          <Button key={value} value={value} disabled={disabled}>
            {label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

export default React.memo(FormToggleButton)
