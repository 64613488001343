import React from 'react'
import { useDispatch } from 'react-redux'

import styled, { css } from 'styled-components'
import { Box, Button, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { Trans } from 'react-i18next'
import { TemplateLink } from 'src/utils/constants'
import { updateDialogVisibility } from 'src/store/courseStrategySlice'

const I18N_KEY = 'EmptyCourseStrategies'

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    padding: ${theme.spacing(8, 0)};
  `
)

const AddButton = styled(Button)`
  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 1rem;
  }
`

const EmptyCourseStrategies: React.FC = () => {
  const dispatch = useDispatch()

  const openCourseStrategyDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: true }))
  }

  return (
    <Container>
      <Typography>
        <Trans i18nKey={`${I18N_KEY}.mainDescription`}>
          Find the optimal way for you to navigate the course on your next
          round.
        </Trans>
      </Typography>
      <Box pt={3} pb={5}>
        <AddButton
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={openCourseStrategyDialog}
        >
          <Trans i18nKey={`${I18N_KEY}.addButtonLabel`}>Add Strategy</Trans>
        </AddButton>
      </Box>
      <Typography variant="h6" color="textSecondary">
        <Trans i18nKey={`${I18N_KEY}.description`}>
          Want to understand how this works?
        </Trans>
      </Typography>
      <a target="_blank" rel="noopener noreferrer" href={TemplateLink.Articles}>
        <Typography variant="h6" color="primary">
          <Trans i18nKey={`${I18N_KEY}.templateButtonLabel`}>
            Course Strategy overview
          </Trans>
        </Typography>
      </a>
    </Container>
  )
}

export default EmptyCourseStrategies
