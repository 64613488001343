import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import MuiContainer from '@material-ui/core/Container'

import { TimeFilter } from 'src/utils/constants'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import { suggestedIOSelector } from 'src/store/summarySlice'
import TextSummary from 'src/modules/player-dash/performance/TextSummary'

import { ReactComponent as FadingDotIllustration } from 'src/assets/images/fadingDotIllustration.svg'

interface Props {
  loading: boolean
  timeFilter: TimeFilter
}

interface ContainerProps {
  $hasSuggestions?: boolean
}

const Container = styled.div<ContainerProps>(
  ({ theme }) => css`
    width: 100%;
    z-index: 0;
    display: flex;
    position: relative;
    background-color: #000;
    flex-direction: column;
    justify-content: center;
    padding: ${theme.spacing(8, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(5, 0)};

      > .MuiContainer-root {
        padding-left: ${theme.spacing(2)}px;
        padding-right: ${theme.spacing(2)}px;
      }
    }
  `
)

const Illustration = styled(FadingDotIllustration)(
  ({ theme }) => css`
    top: 0;
    right: 0;
    z-index: 1;
    width: 35%;
    height: 100%;
    position: absolute;

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

const LoadingContainer = styled.div(
  ({ theme }) => css`
    margin: 0;
    width: 65%;
    max-width: 700px;

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `
)

const StyledSkeleton = styled(Skeleton)(
  ({ theme }) => css`
    background-color: ${theme.palette.text.secondary};
  `
)

const Performance: React.FC<Props> = ({ loading, timeFilter }) => {
  const suggestIOs = useSelector(suggestedIOSelector)
  const suggestionsLength = suggestIOs?.length
  const hasSuggestions = !!suggestionsLength

  return (
    <Container>
      {!hasSuggestions && <Illustration />}
      <MuiContainer>
        {loading ? (
          <LoadingContainer>
            <StyledSkeleton height={80} width="100%" variant="text" />
            <StyledSkeleton height={26} width="100%" variant="text" />
            <Box display="flex" mt={-2}>
              <Box mr={2}>
                <StyledSkeleton height={155} width={250} />
              </Box>
              <StyledSkeleton height={155} width={250} />
            </Box>
          </LoadingContainer>
        ) : (
          <TextSummary loading={loading} timeFilter={timeFilter} />
        )}
      </MuiContainer>
    </Container>
  )
}

export default Performance
