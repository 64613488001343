export const getXAxisTicks = (data: (number | undefined)[]): number[] => {
  const MAX_NUMBER_OF_TICKS = 5

  // calculate the number of xAxis ticks based on the data
  if (data && data.length) {
    let min = data[0] as number
    let max = min

    data.forEach(datePlayed => {
      min = Math.min(datePlayed as number, min)
      max = Math.max(datePlayed as number, max)
    })

    const gap = max - min
    const MILLISECONDS_IN_A_DAY = 86400000
    const numberOfDays = Math.round(gap / MILLISECONDS_IN_A_DAY)

    if (numberOfDays <= 2) {
      return [min, max]
    } else if (numberOfDays > 2) {
      const numberOfTicks = Math.min(MAX_NUMBER_OF_TICKS, numberOfDays)
      const offset = gap / numberOfTicks
      return [
        min,
        ...Array.from(
          { length: numberOfTicks - 1 },
          (_, i) => min + offset * (i + 1)
        ),
        max,
      ]
    }
    return [min]
  }
  return []
}
