import React, { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { Check as CheckIcon } from '@material-ui/icons'
import { Box, CircularProgress, Grid, Button } from '@material-ui/core'

import { fadeIn } from 'src/utils/animations'
import {
  SubscriptionFieldLabel,
  SubscriptionTextField,
} from 'src/components/subscriptionFlow/common'
import { BillingDuration } from 'src/utils/subscriptionConstants'
import {
  Paper,
  PaperTitle,
} from 'src/components/subscriptionFlow/payment/common'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { SubscriptionFlowData } from 'src/components/subscriptionFlow/types'
import { PricingDetails } from 'src/components/subscriptionFlow/usePricing'
import { formatCurrencyFull, formatCurrency } from 'src/utils/helpers'

const SubtotalGrid = styled(Grid)(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(14)};
  `
)

const TotalGrid = styled(Grid)(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(18)};
    font-weight: ${theme.typography.fontWeightBold};
    margin-top: ${theme.spacing(2)}px;
  `
)

const NoteText = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.background.grey};
    font-size: 0.875rem;
    padding-top: ${theme.spacing(3)}px;
  `
)

const StyledLoaderButton = styled(LoaderButton)`
  width: 100%;

  &.MuiButton-contained.Mui-disabled {
    color: #fff;
  }
`

const PromoCodeButton = styled(Button)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(2)}px;
    color: ${theme.palette.text.white};
    font-size: ${theme.typography.pxToRem(16)};
    line-height: ${theme.typography.pxToRem(18)};

    &.MuiButton-contained.Mui-disabled {
      color: #fff;
    }
  `
)

const SuccessIcon = styled(CheckIcon)(
  ({ theme }) => css`
    color: ${theme.palette.success.main};
    animation: ${fadeIn} 0.3s ease-in;
  `
)

const MoneyGrid = styled(Grid)`
  text-align: right;
`

const DiscountGrid = styled(MoneyGrid)(
  ({ theme }) => css`
    color: ${theme.palette.success.main};
  `
)

const I18N_KEY = 'SubscriptionFlowCard'

type OrderSummaryProps = {
  data: SubscriptionFlowData
  pricing: PricingDetails
  disablePromoCodes?: boolean
  isSubmitting: boolean
  isCheckoutDisabled: boolean
  onSubmit: () => void
  className?: string
}

const OrderSummary = ({
  data,
  pricing,
  disablePromoCodes,
  isSubmitting,
  isCheckoutDisabled,
  onSubmit,
  className,
}: OrderSummaryProps) => {
  const [promoCode, setPromoCode] = useState('')

  const { t } = useTranslation()
  const { billingDuration } = data

  const billingDurationLabel = useMemo(
    () =>
      billingDuration === BillingDuration.Annually
        ? t(`${I18N_KEY}.year`, 'year')
        : t(`${I18N_KEY}.quarter`, 'quarter'),
    [billingDuration, t]
  )

  const {
    isValidatingPromoCode,
    appliedPromoCode,
    promoCodeDiscount,
    finalPrice,
    subTotal,
    yearlyDiscount,
    validatePromoCode,
  } = pricing

  const checkPromoCode = () => validatePromoCode(promoCode)

  const checkPromoCodeOnEnter = (evt: React.KeyboardEvent) => {
    if (evt.key === 'Enter') {
      checkPromoCode()
    }
  }

  return (
    <Paper className={className}>
      <PaperTitle>
        <Trans i18nKey={`${I18N_KEY}.orderSummaryLabel`}>Order summary</Trans>
      </PaperTitle>
      {!disablePromoCodes && (
        <>
          <Box mt={2}>
            <SubscriptionFieldLabel htmlFor="promo-code">
              <Trans i18nKey={`${I18N_KEY}.promoCodeLabel`}>Promo Code</Trans>
            </SubscriptionFieldLabel>
            <SubscriptionTextField
              name="promoCode"
              id="promo-code"
              autoComplete="off"
              variant="outlined"
              fullWidth
              disabled={isSubmitting || isValidatingPromoCode}
              value={promoCode}
              onChange={evt => setPromoCode(evt.target.value)}
              onKeyUp={checkPromoCodeOnEnter}
              placeholder="XXXXX"
              InputProps={{
                endAdornment: isValidatingPromoCode ? (
                  <Box display="flex" mr={0.5}>
                    <CircularProgress thickness={6} size={20} />
                  </Box>
                ) : appliedPromoCode && appliedPromoCode === promoCode ? (
                  <Box display="flex" mr={0.5}>
                    <SuccessIcon />
                  </Box>
                ) : null,
              }}
            />
            <PromoCodeButton
              color="primary"
              variant="contained"
              onClick={checkPromoCode}
              disabled={isSubmitting || isValidatingPromoCode || !promoCode}
            >
              Apply
            </PromoCodeButton>
          </Box>
        </>
      )}
      <Box mt={4}>
        <SubtotalGrid container>
          {subTotal !== finalPrice && (
            <>
              <Grid item xs={8}>
                <Trans i18nKey={`${I18N_KEY}.costSubTotalLabel`}>
                  Sub-Total:
                </Trans>
              </Grid>
              <MoneyGrid item xs={4}>
                {formatCurrency(subTotal)}
              </MoneyGrid>
            </>
          )}
          {billingDuration === BillingDuration.Annually &&
            Number(yearlyDiscount) > 0 && (
              <>
                <Grid item xs={8}>
                  <Trans i18nKey={`${I18N_KEY}.costDiscount`}>
                    Yearly plan discount:
                  </Trans>
                </Grid>
                <DiscountGrid item xs={4}>
                  -{formatCurrency(yearlyDiscount)}
                </DiscountGrid>
              </>
            )}
          {appliedPromoCode && (
            <>
              <Grid item xs={8}>
                <Trans i18nKey={`${I18N_KEY}.promoCodeDiscountLabel`}>
                  Promo code {{ code: appliedPromoCode }}:
                </Trans>
              </Grid>
              <DiscountGrid item xs={4}>
                -{formatCurrency(promoCodeDiscount)}
              </DiscountGrid>
            </>
          )}
        </SubtotalGrid>
        <TotalGrid container>
          <Grid item xs={6}>
            <Trans i18nKey={`${I18N_KEY}.costTotal`}>Total:</Trans>
          </Grid>
          <MoneyGrid item xs={6}>
            {formatCurrencyFull(finalPrice)}
          </MoneyGrid>
        </TotalGrid>
      </Box>
      <NoteText>
        <Trans
          i18nKey={`${I18N_KEY}.subtotalNote`}
          values={{
            finalPrice: formatCurrency(finalPrice, true),
            billingDurationLabel,
          }}
        >
          All prices are in USD and all sales are final. Prices include local
          tax rates. You will be charged {{ finalPrice }} every{' '}
          {{ billingDurationLabel }} while this subscription is active. Cancel
          any time.
        </Trans>
      </NoteText>
      <Box mt={5}>
        <StyledLoaderButton
          variant="contained"
          color="primary"
          disabled={isCheckoutDisabled}
          loading={isSubmitting}
          onClick={onSubmit}
        >
          <Trans i18nKey={`${I18N_KEY}.subscribeButtonLabel`}>
            Subscribe &amp; Checkout
          </Trans>
        </StyledLoaderButton>
      </Box>
    </Paper>
  )
}

export default OrderSummary
