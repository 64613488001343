import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, useLocation } from 'react-router-dom'

import NotFound from 'src/modules/404'
import history from 'src/utils/history'
import {
  getCategories,
  getSubcategories,
  generateCategoryPath,
} from 'src/modules/categories/routesConfig'
import useAppState from 'src/hooks/useAppState'
import { RefreshStatus } from 'src/store/authSlice'
import Category from 'src/modules/categories/Category'
import { playerSelector } from 'src/store/playerSlice'
import { CategoryRoutes, Routes } from 'src/utils/constants'
import LocalizedSwitch from 'src/components/i18n/LocalizedSwitch'
import { localizeRoutePath } from 'src/utils/i18n'

interface Props {
  isLoggedIn: boolean
  refreshStatus: RefreshStatus
}

const Categories: React.FC<Props> = props => {
  const { pathname } = useLocation()
  const { isCoach, isPlayer } = useAppState()
  const { uuid } = useSelector(playerSelector)

  // Provide player's uuid when in Coach View
  const categories = getCategories(isCoach ? uuid : undefined)
  const subcategories = getSubcategories(isCoach ? uuid : undefined)

  useEffect(() => {
    if (isPlayer && pathname === Routes.Categories) {
      history.push(generateCategoryPath(CategoryRoutes.All))
    }

    if (
      isCoach &&
      pathname === localizeRoutePath(`/player/${uuid}/categories`)
    ) {
      history.push(generateCategoryPath(CategoryRoutes.All, uuid))
    }
  }, [uuid, isCoach, isPlayer, pathname])

  return (
    <LocalizedSwitch>
      {categories.map(({ path, props: categoryProps }) => (
        <Route key={path} path={path} exact>
          <Category {...categoryProps} />
        </Route>
      ))}
      {subcategories.map(({ path, props: subcategoryProps }) => (
        <Route key={path} path={path} exact>
          <Category {...subcategoryProps} />
        </Route>
      ))}
      <Route path="/*">
        <NotFound {...props} />
      </Route>
    </LocalizedSwitch>
  )
}

export default Categories
