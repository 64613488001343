import React from 'react'
import { Trans } from 'react-i18next'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DeleteButton from 'src/components/inputs/DeleteButton'
// import CommonLink from 'src/components/navigation/Link'

const I18N_KEY = 'AccountCloseConfirmDialog'

// Will be released later with the notifications feature
// const Link = styled(CommonLink)`
//   font-weight: 600;
// `

interface Props {
  open: boolean
  inProgress: boolean
  handleClose: () => void
  handleAction: () => Promise<void>
}

const AccountCloseConfirmDialog: React.FC<Props> = ({
  open,
  inProgress,
  handleClose,
  handleAction,
}) => (
  <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogContent>
      <Typography gutterBottom variant="h4" id="form-dialog-title">
        <Trans i18nKey={`${I18N_KEY}.dialogTitle`}>
          Confirm account deletion
        </Trans>
      </Typography>
      <Typography color="textSecondary">
        {/* Will be released with the notifications feature */}
        {/* <Trans i18nKey={`${I18N_KEY}.dialogContent`}>
          If you’d like to reduce your email notifications you can{' '}
          <Link to={`${Routes.Settings}${PlayerSettingsRoutes.Notifications}`}>
            disable them here
          </Link>
          .<br />
          Be advised, account deletion is final. There will be no way to restore
          your account.
        </Trans> */}
        <Trans i18nKey={`${I18N_KEY}.dialogContent`}>
          Be advised, account deletion is final. There will be no way to restore
          your account.
        </Trans>
      </Typography>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={handleClose}>
        <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Don’t delete</Trans>
      </Button>
      <DeleteButton $width={156} loading={inProgress} onClick={handleAction}>
        <Trans i18nKey={`${I18N_KEY}.deleteButtonLabel`}>
          Confirm Deletion
        </Trans>
      </DeleteButton>
    </DialogActions>
  </Dialog>
)

export default AccountCloseConfirmDialog
