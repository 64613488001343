import { useContext } from 'react'

import { Gender } from 'src/utils/constants'
import { Report } from 'src/utils/golfConstants'
import { TranslationContext } from 'src/utils/TranslationContext'

const useReportDetail = (report?: Report) => {
  const { getBenchmarkName, getReferencePoint } =
    useContext(TranslationContext)!

  const { benchmarkId, playerType } = report?.data.player || {}
  const benchmarkName = getBenchmarkName(benchmarkId)

  const { firstName, lastName, gender, email } = report?.player || {}

  const name = `${firstName} ${lastName}`

  const isMale = gender === Gender.Male

  const referencePoint = getReferencePoint(benchmarkId, isMale)

  const coachUuid = report?.coach?.uuid
  const coachProfileImageUrl = report?.coach?.profileImageUrl
  const coachName = `${report?.coach?.firstName} ${report?.coach?.lastName}`

  return {
    name,
    email,
    coachUuid,
    coachName,
    playerType,
    benchmarkId,
    benchmarkName,
    referencePoint,
    coachProfileImageUrl,
    generationType: report?.generationType,
  }
}

export default useReportDetail
