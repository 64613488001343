import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { FormikProps, useFormikContext } from 'formik'

import Grid from '@material-ui/core/Grid'

import {
  Row,
  Form,
  Title,
  ActionRow,
  FieldGroup,
} from 'src/modules/player-settings/common'
import { SettingsSubmitButton } from 'src/modules/common'
import FormPasswordField from 'src/components/inputs/FormPasswordField'
import { PasswordFormFields } from 'src/modules/player-settings/account'

const I18N_KEY = 'ChangePasswordForm'

interface Props {
  onChange?: (change: FormikProps<PasswordFormFields>) => void
}

const ChangePasswordForm: React.FC<Props> = ({ onChange }) => {
  const { t } = useTranslation()

  const formik = useFormikContext<PasswordFormFields>()
  const { values, dirty, isValid, isSubmitting } = formik

  useEffect(() => {
    if (onChange) {
      onChange(formik)
    }
  }, [formik, onChange, values])

  return (
    <Form>
      <FieldGroup>
        <Title variant="h4">
          <Trans i18nKey={`${I18N_KEY}.title`}>Change Password</Trans>
        </Title>
        <Grid container spacing={0}>
          <Row item xs={12}>
            <FormPasswordField
              fullWidth
              name="currentPassword"
              label={t(`${I18N_KEY}.currentPasswordLabel`, 'Current Password')}
              placeholder={t(`${I18N_KEY}.currentPasswordPlaceholder`, '')}
              type="password"
            />
          </Row>
          <Grid item xs={6} />
          <Row item xs={12}>
            <FormPasswordField
              fullWidth
              name="newPassword"
              label={t(`${I18N_KEY}.newPasswordLabel`, 'New Password')}
              placeholder={t(`${I18N_KEY}.newPasswordPlaceholder`, '')}
              type="password"
            />
          </Row>
          <Grid item xs={6} />
          <Row item xs={12}>
            <FormPasswordField
              fullWidth
              name="confirmPassword"
              label={t(
                `${I18N_KEY}.confirmPasswordLabel`,
                'Re-type New Password'
              )}
              placeholder={t(`${I18N_KEY}.confirmPasswordPlaceholder`, '')}
              type="password"
            />
          </Row>
          <Grid item xs={6} />
          <ActionRow item xs={12}>
            <SettingsSubmitButton
              type="submit"
              color="primary"
              variant="contained"
              disabled={!dirty || !isValid}
              loading={isSubmitting}
            >
              <Trans i18nKey={`${I18N_KEY}.saveChangesButtonLabel`}>
                Save Changes
              </Trans>
            </SettingsSubmitButton>
          </ActionRow>
        </Grid>
      </FieldGroup>
    </Form>
  )
}

export default ChangePasswordForm
