import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { api, Errors } from 'src/utils/api'
import {
  authRequestFail,
  isForbiddenOrUnauthorised,
  getErrorStatus,
  getErrorCode,
  getPayloadFromResponse,
} from 'src/utils/helpers'
import { Invoice } from 'src/models/invoice'
import { State } from 'typings/store'
import { RootState } from 'src/store/rootReducer'
import { getBasicReducers } from 'src/utils/store'
import { AppThunk } from '.'

type InvoicesState = State<Invoice[]>

const initialState: InvoicesState = {
  data: null,
  requestInProgress: false,
  loaded: false,
  error: null,
}

const { actions, reducer } = createSlice({
  name: 'invoices',
  initialState: initialState,
  reducers: {
    ...getBasicReducers<Invoice[]>(initialState),
    loaded: (state: InvoicesState, action: PayloadAction<Invoice[]>) => {
      state.data = action.payload
      state.error = null
      state.requestInProgress = false
      state.loaded = true
    },
  },
})

export default reducer

// Actions

export const {
  loaded,
  reinitialise,
  requestInProgress,
  requestFinished,
  requestError,
} = actions

export const getInvoices = (): AppThunk => async dispatch => {
  dispatch(requestInProgress())
  try {
    const response = await api.get('subscription/invoice')
    const invoices: Invoice[] = getPayloadFromResponse(response) as Invoice[]
    dispatch(loaded(invoices))
  } catch (error: any) {
    const code = getErrorCode(error) || getErrorStatus(error)
    if (code.toString() === Errors.NotFound) {
      dispatch(loaded([]))
      return
    }
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
    dispatch(requestError(code))
  }
}

// Selectors

export const invoicesSelector = (state: RootState): InvoicesState =>
  state.invoices

export const dataSelector = createSelector(
  invoicesSelector,
  state => state.data
)

export const isLoading = createSelector(
  invoicesSelector,
  (state): boolean => state.requestInProgress
)

export const isLoaded = createSelector(
  invoicesSelector,
  (state): boolean => state.loaded
)

export const getError = createSelector(
  invoicesSelector,
  (state): string | null => state.error
)

export const hasError = createSelector(
  getError,
  (error: any): boolean => !!(error && error !== Errors.NotFound)
)
