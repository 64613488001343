import React from 'react'
import styled, { css } from 'styled-components'
import { Control, Controller, FieldErrors } from 'react-hook-form'

import { TextField, FormControl, TextFieldProps } from '@material-ui/core'
import { Label } from '../Label'

interface ExtraProps {
  name: string
  control: Control
  errors?: FieldErrors
  defaultValue?: string
}

export type HookFormTextFieldProps = TextFieldProps & ExtraProps

interface ErrorProps {
  $active: boolean
}

const StyledContainer = styled(FormControl)`
  position: relative;
`

const ErrorMessage = styled.span<ErrorProps>(
  ({ theme, $active }) => css`
    left: 0;
    font-size: 12px;
    position: absolute;
    opacity: ${$active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${$active ? -24 : -10}px;
    color: ${theme.palette.error.main};
  `
)

const HookFormTextField: React.FC<HookFormTextFieldProps> = ({
  name,
  label,
  errors,
  control,
  className,
  defaultValue = '',
  variant = 'outlined',
  onChange: customOnChange,
  ...props
}) => {
  const fieldError = errors && errors[name]
  const hasError = !!fieldError

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ onChange, ...renderProps }) => (
        <StyledContainer className={className}>
          {label && (
            <Label $hasError={hasError} htmlFor={name}>
              {label}
            </Label>
          )}
          <TextField
            id={name}
            error={hasError}
            variant={variant}
            onChange={customOnChange || onChange}
            InputLabelProps={{ shrink: true }}
            {...renderProps}
            {...props}
          />
          <ErrorMessage $active={hasError}>{fieldError?.message}</ErrorMessage>
        </StyledContainer>
      )}
    />
  )
}

export default React.memo(HookFormTextField)
