import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'

const I18N_KEY = 'EmptyReports'

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    padding: ${theme.spacing(8, 0)};
  `
)

const EmptyReports: React.FC = () => {
  return (
    <Container>
      <Typography>
        <Trans i18nKey={`${I18N_KEY}.emptyMessage`}>
          There are no reports available
        </Trans>
      </Typography>
    </Container>
  )
}

export default EmptyReports
