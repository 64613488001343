import { config } from '../configLoader'
import hmacSHA512 from 'crypto-js/hmac-sha512'

const SALT = config.REACT_APP_SALT

export const hashPassword = (password?: string) => {
  if (password && SALT) {
    const encryptedPassword = hmacSHA512(password, SALT)
    return String(encryptedPassword)
  }

  return password
}
