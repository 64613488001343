import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import history, { originalPush } from 'src/utils/history'

import { Languages, DefaultLanguage } from 'src/utils/constants'
import {
  setLanguage as setLanguageAction,
  userLanguageSelector,
} from 'src/store/userSlice'
import { isLoggedInSelector } from 'src/store/authSlice'

interface UrlParams {
  lang: Languages
}

type LanguageLabel = { [key in Languages]?: string }
export type LanguageOption = {
  label: string
  value: Languages
}

interface Language {
  language: Languages
  setLanguage: (language: Languages) => void
  labels: LanguageLabel
  options: LanguageOption[]
}

const locals = Object.values(Languages)

export const LanguageContext = React.createContext<Language>({
  language: DefaultLanguage,
  setLanguage: () => {},
  labels: {},
  options: [],
})

const LanguageProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation()
  const userIsLoggedIn = useSelector(isLoggedInSelector)
  const userLanguage = useSelector(userLanguageSelector)
  const { lang: langUrlParam } = useParams<UrlParams>()

  const setLanguage = (language: Languages) => {
    if (userIsLoggedIn) {
      dispatch(setLanguageAction(language))
    } else {
      i18n.changeLanguage(language)
    }
  }

  useEffect(() => {
    if (userLanguage && i18n.language !== userLanguage) {
      i18n.changeLanguage(userLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLanguage])

  useEffect(() => {
    i18n.changeLanguage(langUrlParam || DefaultLanguage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      langUrlParam !== i18n.language &&
      locals.includes(langUrlParam as Languages)
    ) {
      const pathname = history.location.pathname.replace(
        `/${langUrlParam}`,
        `/${i18n.language}`
      )
      originalPush({
        ...history.location,
        pathname,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  const labels: LanguageLabel = useMemo(
    () =>
      locals.reduce(
        (lbls: LanguageLabel, local: Languages) => ({
          ...lbls,
          [local]: t(`Enums:Language.${local}`),
        }),
        {}
      ),
    [t]
  )
  const options: LanguageOption[] = useMemo(
    () =>
      locals.reduce(
        (opts: LanguageOption[], local: Languages): LanguageOption[] => {
          return [
            ...opts,
            {
              label: labels[local]!,
              value: local,
            },
          ]
        },
        []
      ),
    [labels]
  )

  return (
    <LanguageContext.Provider
      value={{
        setLanguage,
        language: i18n.language as Languages,
        labels,
        options,
      }}
    >
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
