import React from 'react'

import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

const LoadingRow: React.FC = () => (
  <TableRow>
    <TableCell>
      <Box display="flex" alignItems="center">
        <Skeleton variant="circle" width={35} height={35} />
        <Box ml={3}>
          <Skeleton height={20} width={150} />
          <Skeleton height={15} width={150} />
        </Box>
      </Box>
    </TableCell>
  </TableRow>
)

export default React.memo(LoadingRow)
