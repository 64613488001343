import React, { useMemo } from 'react'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import { TableCell } from 'src/components/dataDisplay/tableStyles'
import TableContainer from '@material-ui/core/TableContainer'
import Typography from '@material-ui/core/Typography'

import {
  MetricId,
  PerformanceIndicator,
  CategoryType,
  CATEGORY_TYPE_MAP,
} from 'src/utils/golfConstants'
import PerformanceIndicatoryCategory from './PerformanceIndicatoryCategory'
import { usePerformanceIndicatorColumns } from 'src/hooks/usePerformanceIndicatorColumns'

const I18N_KEY = 'PerformanceIndicatorCategory'

interface Props {
  isOpen?: boolean
  showCategoryHeader?: boolean
  isSingleRound?: boolean
  performanceIndicators: PerformanceIndicator[]
}

const PerformanceIndicatorTable: React.FC<Props> = ({
  isOpen = false,
  showCategoryHeader = true,
  isSingleRound = false,
  performanceIndicators,
  ...props
}) => {
  const columns = usePerformanceIndicatorColumns(I18N_KEY, isSingleRound)
  const categorisedPerformanceIndicator = useMemo(() => {
    const data: Record<CategoryType, PerformanceIndicator[]> = {
      [CategoryType.All]: [],
      [CategoryType.Drives]: [],
      [CategoryType.Approach]: [],
      [CategoryType.Short]: [],
      [CategoryType.Putt]: [],
    }

    performanceIndicators.forEach(performanceIndicator => {
      const metricIdKey = Object.keys(MetricId).find(
        key =>
          MetricId[key as keyof typeof MetricId] ===
          performanceIndicator.category
      )

      if (metricIdKey) {
        const category = metricIdKey.toLowerCase() as unknown as CategoryType
        if (
          !data[category].some(
            ind => ind.metricId === performanceIndicator.metricId
          )
        ) {
          data[category].push(performanceIndicator)
        }
      } else if (performanceIndicator?.subcategories.length > 0) {
        performanceIndicator.subcategories.forEach(subCategoryId => {
          // eslint-disable-next-line prettier/prettier
          const parentCategory = CATEGORY_TYPE_MAP[subCategoryId as keyof typeof CATEGORY_TYPE_MAP]
          if (parentCategory) {
            if (
              !data[parentCategory].some(
                ind => ind.metricId === performanceIndicator.metricId
              )
            ) {
              data[parentCategory].push(performanceIndicator)
            }
          }
        })
      }
    })

    return data
  }, [performanceIndicators])

  const categoriesWithData = Object.values(
    categorisedPerformanceIndicator
  ).reduce(
    (count, indicators) => (indicators.length > 0 ? count + 1 : count),
    0
  )

  return (
    <TableContainer elevation={0} component={Paper} {...props}>
      <Table>
        <TableBody>
          {!showCategoryHeader ? (
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  style={index === 0 ? { minWidth: '450px' } : {}}
                  key={column}
                >
                  <Typography variant="caption" color="textSecondary">
                    {column}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          ) : null}
          {Object.entries(categorisedPerformanceIndicator).map(
            ([category, indicators]) => (
              <PerformanceIndicatoryCategory
                key={category}
                isOpen={isOpen}
                showCategoryHeader={showCategoryHeader}
                isSingleCategory={categoriesWithData === 1}
                isSingleRound={isSingleRound}
                category={category}
                indicators={indicators}
              />
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PerformanceIndicatorTable
