import React, { useMemo, useContext } from 'react'
import {
  Dot,
  Radar,
  PolarGrid,
  RadarChart,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from 'recharts'
import styled from 'styled-components'

import { useTheme } from '@material-ui/core'

import { renderAxisTicks } from 'src/components/graph/radar/Axis'
import { TranslationContext } from 'src/utils/TranslationContext'
import { RadarData } from 'src/components/dataDisplay/RadarComparison'

interface Props {
  data: RadarData[]
  width: string | number
  height: string | number
}

const StyledChart = styled(RadarChart)`
  .recharts-polar-grid-concentric {
    display: none;
  }
`

const RadarGraph: React.FC<Props> = ({ data, width, height, ...props }) => {
  const theme = useTheme()
  const { categoryLabels } = useContext(TranslationContext)!

  const payload = useMemo(
    () => data.map(item => ({ ...item, label: categoryLabels[item.category] })),
    [data, categoryLabels]
  )

  return (
    <ResponsiveContainer width={width} height={height} {...props}>
      <StyledChart data={payload}>
        <PolarGrid stroke={theme.palette.background.light} strokeWidth={2} />
        <PolarAngleAxis dataKey="label" />
        <PolarRadiusAxis
          angle={90}
          domain={[-2, 'auto']}
          tick={renderAxisTicks}
        />
        <Radar
          fill="none"
          strokeWidth={2}
          dataKey="reference"
          strokeDasharray="2 2"
          stroke={theme.palette.warning.main}
        />
        <Radar
          fill={theme.palette.warning.main}
          strokeWidth={2}
          dataKey="benchmark"
          fillOpacity={0.05}
          stroke={theme.palette.warning.main}
        />
        <Radar
          strokeWidth={2}
          dataKey="overall"
          fillOpacity={0.2}
          fill={theme.palette.primary.light}
          stroke={theme.palette.primary.light}
          dot={<Dot r={2} fill={theme.palette.primary.light} />}
        />
      </StyledChart>
    </ResponsiveContainer>
  )
}

export default RadarGraph
