import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'
import ArrowBack from '@material-ui/icons/KeyboardBackspace'

import { Routes } from 'src/utils/constants'
import { RefreshStatus } from 'src/store/authSlice'
import LoadingSession from 'src/components/layout/SessionLoading'

import { grow } from 'src/utils/animations'
import { LinkButton } from 'src/components/navigation/LinkButton'

import { ReactComponent as OutOfBoundsIllustration } from 'src/assets/images/outOfBounds.svg'

const I18N_KEY = 'NotFound'

interface Props {
  isLoggedIn: boolean
  refreshStatus: RefreshStatus
}

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    min-height: 100%;
    position: absolute;
    background-color: ${theme.palette.background.black};
  `
)

const Content = styled(MuiContainer)`
  flex: 1;
  display: flex;
  margin-top: -70px;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  animation: ${grow} 0.3s ease-out forwards;
`

const OutOfBounds = styled(OutOfBoundsIllustration)(
  ({ theme }) => css`
    margin: ${theme.spacing(6, 0)};
  `
)

const Text = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};
  `
)

const NotFound: React.FC<Props> = ({ isLoggedIn, refreshStatus }) => {
  const { t } = useTranslation()
  const linkText = useMemo(() => {
    return isLoggedIn
      ? t(`${I18N_KEY}.dashboardLinkText`, 'Back to Dashboard')
      : t(`${I18N_KEY}.homeLinkText`, 'Back Home')
  }, [t, isLoggedIn])
  return isLoggedIn && refreshStatus === RefreshStatus.Initialising ? (
    <LoadingSession />
  ) : (
    <Container>
      <Content>
        <OutOfBounds />
        <Text variant="h1">
          <Trans i18nKey={`${I18N_KEY}.title`}>You’re out of bounds</Trans>
        </Text>
        <Text>
          <Trans i18nKey={`${I18N_KEY}.description`}>
            Sorry, the page you’re looking for doesn’t exist.
          </Trans>
        </Text>
        <LinkButton to={Routes.Dashboard}>
          <ArrowBack />
          {linkText}
        </LinkButton>
      </Content>
    </Container>
  )
}

export default NotFound
