import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  getErrorCode,
  getErrorStatus,
  authRequestFail,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import {
  Billing,
  CardDetails,
  Subscription,
  SubscriptionInfo,
  NewSubscriptionPayload,
} from 'src/models/subscription'
import { AppThunk } from 'src/store'
import { State } from 'typings/store'
import { api, Errors } from 'src/utils/api'
import { RootState } from 'src/store/rootReducer'
import { getBasicReducers } from 'src/utils/store'
import { getInvoices } from 'src/store/invoiceSlice'
import { playerSubsriptionSelector } from 'src/store/playerSlice'
import { PlanType, SubscriptionStatus } from 'src/utils/subscriptionConstants'
import { countries, Country } from 'src/models/countries'
import { MembershipLevel } from 'src/utils/constants'

type SubscriptionState = State<SubscriptionInfo>

const initialState: SubscriptionState = {
  data: null,
  requestInProgress: false,
  loaded: false,
  error: null,
}

const SLICE_NAME = 'subscription'

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...getBasicReducers(initialState),
    loaded: (
      state: SubscriptionState,
      action: PayloadAction<SubscriptionInfo>
    ) => {
      state.data = action.payload
      state.error = null
      state.requestInProgress = false
      state.loaded = true
    },
    updated: (
      state: SubscriptionState,
      action: PayloadAction<Subscription>
    ) => {
      const data: SubscriptionInfo = Object.assign(
        {},
        state.data,
        action.payload
      )
      state.requestInProgress = false
      state.error = null
      state.data = data
    },
    canceled: (state: SubscriptionState) => {
      if (!state.data) {
        throw new Error('No subscription data on changing status')
      }
      state.data.status = SubscriptionStatus.Canceled
    },
    billingUpdated: (
      state: SubscriptionState,
      action: PayloadAction<Billing>
    ) => {
      if (!state.data) {
        throw new Error('No subscription data on billing update')
      }
      state.data.billing = action.payload
    },
  },
})

export default reducer

export const {
  loaded,
  updated,
  canceled,
  billingUpdated,
  reinitialise,
  requestInProgress,
  requestFinished,
  requestError,
} = actions

// SELECTORS
export const subscriptionSelector = (state: RootState): SubscriptionState =>
  state.subscription as unknown as SubscriptionState

export const dataSelector = createSelector(
  subscriptionSelector,
  (state: SubscriptionState) => state.data
)

export const isLoaded = createSelector(
  subscriptionSelector,
  (state): boolean => state?.loaded || state?.error === Errors.NotFound
)

export const userIsTrialingSelector = createSelector(
  dataSelector,
  playerSubsriptionSelector,
  (data, playerSubscription) => {
    const planType = data?.plan?.planType || playerSubscription.planType
    const status = data?.status || playerSubscription.status

    return (
      (planType === PlanType.PremiumNew || planType === PlanType.Play) &&
      status === SubscriptionStatus.Trialing
    )
  }
)

export const hasActiveSubscription = (
  membershipLevel: MembershipLevel = MembershipLevel.Premium,
  includeTrialSubscriptionCheck: boolean = true
) =>
  createSelector(
    dataSelector,
    playerSubsriptionSelector,
    (data, playerSubscription): boolean => {
      try {
        const planType = data?.plan?.planType || playerSubscription.planType
        if (!planType) {
          throw new Error('Invalid plan type for membership')
        }
        const subscriptionStatus = data?.status || playerSubscription.status

        if (
          includeTrialSubscriptionCheck &&
          subscriptionStatus === SubscriptionStatus.Trialing
        ) {
          const trialRoundsLimit =
            (playerSubscription.details?.rounds as number) < 5
          const validTrialPlans = [PlanType.PremiumNew, PlanType.Play]
          const planValid = validTrialPlans.includes(planType)
          return trialRoundsLimit && planValid
        } else if (subscriptionStatus === SubscriptionStatus.Active) {
          const premiumPlans = [
            PlanType.Premium,
            PlanType.PremiumNew,
            PlanType.PremiumPlus,
            PlanType.Play,
            PlanType.Consult,
          ]

          switch (membershipLevel) {
            case MembershipLevel.Basic:
              return [
                ...premiumPlans,
                PlanType.Lite,
                PlanType.Train,
                PlanType.Insight,
              ].includes(planType)
            case MembershipLevel.Premium:
              return [...premiumPlans, PlanType.Lite, PlanType.Train].includes(
                planType
              )
            case MembershipLevel.CourseStrategy:
              return premiumPlans.includes(planType)
            default:
              return false
          }
        }
        return false
      } catch (error) {
        console.error('Subscription Error', error)
        return false
      }
    }
  )

export const subscriptionCancelsAt = createSelector(
  dataSelector,
  (data): string | null => data?.cancelsAt || null
)
export const subscriptionIsCancelled = createSelector(
  subscriptionCancelsAt,
  (date): boolean => !!date
)

export const billingAddress = createSelector(
  dataSelector,
  (data): Billing | null => data?.billing || null
)

export const cardSelector = createSelector(
  dataSelector,
  (data): CardDetails | null | undefined => data?.card
)

export const cardLastFourDigits = createSelector(
  cardSelector,
  card => card?.lastFour
)

export const userPlanSelector = createSelector(
  subscriptionSelector,
  subscription => subscription.data?.plan
)

export const getSubscriptionInfo = (): AppThunk => async dispatch => {
  dispatch(requestInProgress())
  try {
    const response = await api.get('subscription/info')
    const subscriptionInfo: SubscriptionInfo =
      getSinglePayloadFromResponse(response)

    if (subscriptionInfo?.billing?.country?.code) {
      // Rematch country from country code
      const { billing, ...restSubscriptionInfo } = subscriptionInfo
      const matchedCountry = countries.find(
        (country: Country) =>
          country.code === subscriptionInfo.billing.country.code
      )
      if (!matchedCountry) {
        throw new Error(
          `Unable to match Subscription Info country, subscription uuid: ${subscriptionInfo.subscriptionUuid}`
        )
      }
      const { country, ...restBilling } = billing
      const updatedSubscriptionInfo: SubscriptionInfo = {
        billing: {
          country: matchedCountry,
          ...restBilling,
        },
        ...restSubscriptionInfo,
      }
      dispatch(loaded(updatedSubscriptionInfo))
    } else {
      dispatch(loaded(subscriptionInfo))
    }
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
    dispatch(requestError(getErrorStatus(error)))
  }
}

export const createSubscription =
  (payload: NewSubscriptionPayload, playerUuid?: string): AppThunk =>
  async dispatch => {
    dispatch(requestInProgress())
    const endpoint = playerUuid
      ? `overview/player/${playerUuid}/subscription`
      : 'subscription'
    try {
      const response = await api.post(endpoint, payload)
      dispatch(updated(getSinglePayloadFromResponse(response) as Subscription))
      dispatch(getInvoices())
      await dispatch(getSubscriptionInfo())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      const errorMsg = getErrorCode(error) || getErrorStatus(error)
      dispatch(requestError(errorMsg))
      throw new Error(errorMsg)
    }
  }

export const cancelSubscription =
  (): AppThunk => async (dispatch, getState) => {
    dispatch(requestInProgress())
    try {
      await api.delete('subscription')
      // dispatch(getSubscriptionInfo())
      const data = dataSelector(getState())
      dispatch(
        updated({
          ...data,
          cancelsAt: new Date().toISOString(),
        } as SubscriptionInfo)
      )
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
    }
  }

export const updateBilling =
  (billing: Billing): AppThunk =>
  async dispatch => {
    dispatch(requestInProgress())
    try {
      const response = await api.put('subscription/billing', billing)
      dispatch(
        billingUpdated(
          (getSinglePayloadFromResponse(response) as Billing) || billing
        )
      )
      dispatch(getSubscriptionInfo())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
    }
  }

export const deleteCard = (): AppThunk => async dispatch => {
  dispatch(requestInProgress())
  try {
    await api.delete('subscription/card')
    dispatch(getSubscriptionInfo())
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
    dispatch(requestError(getErrorStatus(error)))
    throw error
  }
}

interface AddCardPayload {
  paymentToken: string
}

export const addCard =
  (payload: AddCardPayload): AppThunk =>
  async dispatch => {
    dispatch(requestInProgress())
    try {
      await api.put('subscription/card', payload)
      dispatch(getSubscriptionInfo())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
      throw error
    }
  }
