import { AppThunk } from './index'
import { api } from '../utils/api'
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ErrorPayload } from '../../typings/store'
import {
  authRequestFail,
  getErrorStatus,
  getUserInfo,
  isForbiddenOrUnauthorised,
} from '../utils/helpers'
import { BenchmarkId } from '../utils/golfConstants'
import { RootState } from './rootReducer'
import { getErrorToast, openToast } from './toastSlice'
import { TimeFilter, TypeFilter, WhereFilter } from '../utils/constants'

export interface CustomTimeRange {
  startDate: Date
  endDate: Date
}

export interface GenerateIOPayload {
  time: TimeFilter
  type: TypeFilter
  where: WhereFilter
  customTimeRange: CustomTimeRange | undefined
}

export interface IOValue {
  metricId: string
  difference: number
}

export interface IOData {
  savedMetrics: IOValue[]
  suggestedMetrics: IOValue[]
  list: IOValue[]
}

interface IoState {
  data: IOData | undefined
  requestInProgress: boolean
  isOpen: boolean
  error: string | null
}

const initialState: IoState = {
  data: undefined,
  requestInProgress: false,
  isOpen: false,
  error: null,
}

const SLICE_NAME = 'io'

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateData: (state, action: PayloadAction<IOData>) => {
      state.data = action.payload
    },
    requestInProgress: state => {
      state.requestInProgress = true
    },
    requestFinished: state => {
      state.requestInProgress = false
    },
    requestError: (state, action: PayloadAction<ErrorPayload>) => {
      return {
        ...state,
        requestInProgress: false,
        error: action.payload,
      }
    },
    updateDialogVisibility: (
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ) => {
      const { isOpen } = action.payload

      state.isOpen = isOpen
    },
  },
})

export const {
  updateData,
  requestInProgress,
  requestFinished,
  requestError,
  updateDialogVisibility,
} = actions

export default reducer

export const ioState = (state: RootState) => state[SLICE_NAME]

export const requestIsInProgress = createSelector(
  ioState,
  (state: IoState): boolean => state.requestInProgress
)

export const requestErrorSelector = createSelector(
  ioState,
  (state: IoState): string | null => state.error
)

export const isOpenSelector = createSelector(
  ioState,
  (state: IoState): boolean => state.isOpen
)

export const generateIO =
  (payload: GenerateIOPayload, benchmark: BenchmarkId): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { isPlayer, playerUuid } = getUserInfo(state)
    dispatch(requestInProgress())

    const endpoint = isPlayer
      ? '/io/generate'
      : `/overview/player/${playerUuid}/io/generate`

    try {
      await api.post(`${endpoint}/${benchmark.valueOf()}`, {
        ...payload,
      })
      dispatch(requestFinished())
      dispatch(updateDialogVisibility({ isOpen: false }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(
        openToast(
          getErrorToast(
            'You have no rounds matching this filter. A minimum of 1 round is required to generate Improvement Opportunities. Please select a different filter'
          )
        )
      )
      dispatch(requestError(getErrorStatus(error)))
      dispatch(updateDialogVisibility({ isOpen: true }))
    }
  }
