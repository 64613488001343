import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Form, Formik, FormikConfig } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { playerSelector } from '../../../store/playerSlice'
import {
  generateIO,
  GenerateIOPayload,
  isOpenSelector,
  requestIsInProgress,
  updateDialogVisibility,
} from '../../../store/ioSlice'
import { CustomTimeRange } from '../../../components/inputs/CustomDatePicker'
import {
  BenchmarkId,
  FEMALE_PRO_BENCHMARK_SELECTION,
  FEMALE_SCRATCH_BENCHMARKS,
  MALE_PRO_BENCHMARK_SELECTION,
  MALE_SCRATCH_BENCHMARKS,
} from '../../../utils/golfConstants'
import AppBar from '@material-ui/core/AppBar'
import { Dialog, Toolbar, XsPaddingZeroContainer } from '../../common'
import IconButton from '@material-ui/core/IconButton'
import Close from '@material-ui/icons/Close'
import styled, { css } from 'styled-components'
import {
  breakpoints,
  Routes,
  TimeFilter,
  TypeFilter,
  WhereFilter,
} from '../../../utils/constants'
import MuiContainer from '@material-ui/core/Container'
import LoaderButton from '../../../components/inputs/LoaderButton'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import useAppState from '../../../hooks/useAppState'
import { getSummary, updateFilters } from '../../../store/summarySlice'
import Grid from '@material-ui/core/Grid'
import FormSelect from '../../../components/inputs/FormSelect'
import RoundSelectionFilter from '../../../components/filter/RoundSelectionFilter'

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(5)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(5, 2, 0)};
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 0, 0)};
    }
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(6)}px;
    width: 350px;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
)

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    width: 650px;
    padding: ${theme.spacing(6, 4)};
    margin: ${theme.spacing(4, 0, 0)};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    ${theme.breakpoints.down('xs')} {
      margin: 0;
      padding: ${theme.spacing(4, 2)};
    }
  `
)

export const MainContentBackground = styled.div(
  ({ theme }) => css`
    flex: 1;
    width: 100%;
    background-color: ${theme.palette.background.xlight};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(2, 0)};
    }
  `
)

const SectionTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1)}px;
  `
)

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(56)};
    line-height: ${theme.typography.pxToRem(72)};

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(40)};
      line-height: ${theme.typography.pxToRem(48)};
      margin-bottom: ${theme.spacing(2)}px;
    }
  `
)

const TextContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

const StyledGrid = styled(Grid)(
  ({ theme }) => css`
    padding: ${theme.spacing(2)}px;
  `
)

const StyledFormSelect = styled(FormSelect)`
  margin-top: 6px;
`

interface Filters {
  type: TypeFilter
  time: TimeFilter
  where: WhereFilter
  benchmark: BenchmarkId
  customTimeRange: CustomTimeRange | undefined
}

const I18N_KEY = 'InsightGeneration'

const ImprovementOpportunities: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { gender } = useSelector(playerSelector)
  const history = useHistory()
  const { isCoachView, playerUuid } = useAppState()

  const { benchmarkId } = useSelector(playerSelector)

  const requestInProgress = useSelector(requestIsInProgress)

  const isOpen = useSelector(isOpenSelector)

  const closeDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: false }))
  }

  const formikOptions: FormikConfig<Filters> = {
    initialValues: {
      type: TypeFilter.Both,
      time: TimeFilter.LastFive,
      where: WhereFilter.Outdoor,
      benchmark: benchmarkId,
      customTimeRange: undefined,
    },
    enableReinitialize: true,
    onSubmit: async ({ type, time, where, benchmark, customTimeRange }) => {
      // Remove random id created for animation
      const payload: GenerateIOPayload = {
        type,
        time,
        where,
        customTimeRange,
      }

      await dispatch(generateIO(payload, benchmark))
      await dispatch(
        updateFilters({
          timeFilter: time,
          typeFilter: type,
          whereFilter: where,
        })
      )
      dispatch(getSummary())
      history.push(
        isCoachView
          ? Routes.CoachPlayerInsight.replace(':playerUuid', playerUuid ?? '')
          : Routes.Insight
      )
    },
  }

  const showBenchmarkOptions = () => {
    const benchmarks = []
    if (gender === 'male') {
      benchmarks.push(...MALE_SCRATCH_BENCHMARKS)
      benchmarks.push(...MALE_PRO_BENCHMARK_SELECTION)
    } else {
      benchmarks.push(...FEMALE_SCRATCH_BENCHMARKS)
      benchmarks.push(...FEMALE_PRO_BENCHMARK_SELECTION)
    }
    return Object.values(benchmarks).map(benchmark => {
      return {
        label: t(`Enums:Benchmark.${benchmark}`),
        value: benchmark,
      }
    })
  }

  return (
    <Dialog open={isOpen}>
      <AppBar elevation={0} color="default" position="relative">
        <XsPaddingZeroContainer>
          <Toolbar>
            <IconButton onClick={closeDialog}>
              <Close />
            </IconButton>
          </Toolbar>
          <TextContainer>
            <DialogTitle variant="h1">
              <Trans i18nKey={`${I18N_KEY}.title`}>Generate Insight</Trans>
            </DialogTitle>
          </TextContainer>
        </XsPaddingZeroContainer>
      </AppBar>
      <MainContentBackground>
        <Container>
          <StyledPaper elevation={0}>
            <SectionTitle variant="h4">
              <Trans i18nKey={`${I18N_KEY}.filters`}>
                Select following values
              </Trans>
            </SectionTitle>
            <Formik {...formikOptions}>
              {() => (
                <Form>
                  <Grid>
                    <RoundSelectionFilter />
                    <StyledGrid item xs={12} sm={6}>
                      <Typography variant="caption">
                        <Trans i18nKey={`${I18N_KEY}.benchmark`}>
                          Benchmark
                        </Trans>
                      </Typography>
                      <StyledFormSelect
                        fullWidth
                        options={showBenchmarkOptions()}
                        showErrorString={false}
                        name="benchmark"
                      />
                    </StyledGrid>
                  </Grid>
                  <ButtonContainer>
                    <LoaderButton
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                      loading={requestInProgress}
                    >
                      <Trans i18nKey={`${I18N_KEY}.generate`}>Generate</Trans>
                    </LoaderButton>
                  </ButtonContainer>
                </Form>
              )}
            </Formik>
          </StyledPaper>
        </Container>
      </MainContentBackground>
    </Dialog>
  )
}

export default ImprovementOpportunities
