import Player from 'src/models/player'
import { Summary } from 'src/models/summary'
import { Subscription } from 'src/models/subscription'
import { IO, FocusAreaIO } from 'src/utils/golfConstants'

export interface CoachPlayerOverview {
  email: string
  player: Player
  inviteUuid: string
  summary: Summary | null
  timeline: TimelineItem[]
  status: CoachPlayerStatus
  subscription: Subscription | null
  io: ImprovementOpportunities | null
}

export enum CoachPlayerStatus {
  Accepted = 'accepted',
  PlayerHasNoRounds = 'player_has_no_rounds',
  PlayerInvitationNotAccepted = 'player_invitation_not_accepted',
}

export interface TimelineItem {
  date: Date
  addedRound: boolean
  addedPractice: boolean
}

interface ImprovementOpportunities {
  savedMetrics: IO[]
  list: FocusAreaIO[]
  suggestedMetrics: IO[]
}

export interface CoachOverviewData {
  totalPlayers: number
  players: CoachPlayerOverviewItems
  unregisteredPlayers: CoachInvitationItems
}

export interface CoachPlayerOverviewItems {
  [uuid: string]: Partial<CoachPlayerOverview> | null
}

export interface CoachInvitationItems {
  [email: string]: CoachInvitationItem
}

export interface CoachInvitations {
  email: string
  inviteUuid: string
  uuid: string | null
  firstName: string | null
  lastName: string | null
  accepted: boolean
}

export interface CoachInvitationItem {
  email: string
  inviteUuid: string
}
