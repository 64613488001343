import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  authRequestFail,
  isForbiddenOrUnauthorised,
  getPayloadFromResponse,
  getErrorStatus,
} from 'src/utils/helpers'

import { AppThunk } from 'src/store'
import { api, Errors } from 'src/utils/api'

import { State } from 'typings/store'
import { RootState } from 'src/store/rootReducer'
import { getBasicReducers } from 'src/utils/store'
import { Invitation } from 'src/models/invitation'

type InvitationsState = State<Invitation[]>

const initialState: InvitationsState = {
  data: null,
  requestInProgress: false,
  loaded: false,
  error: null,
}

const SLICE_NAME = 'invitation'

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...getBasicReducers(initialState),
    loaded: (state: InvitationsState, action: PayloadAction<Invitation[]>) => {
      state.data = action.payload
      state.error = null
      state.requestInProgress = false
      state.loaded = true
    },
    updated: (state: InvitationsState, action: PayloadAction<Invitation>) => {
      const data: Invitation[] = Object.assign({}, state.data, action.payload)
      state.requestInProgress = false
      state.error = null
      state.data = data
    },
  },
})

export default reducer

export const {
  loaded,
  updated,
  reinitialise,
  requestInProgress,
  requestFinished,
  requestError,
} = actions

// SELECTORS
export const invitationSelector = (state: RootState): InvitationsState =>
  state.invitation as unknown as InvitationsState

export const dataSelector = createSelector(
  invitationSelector,
  (state: InvitationsState): null | Invitation[] => state.data
)

export const isLoaded = createSelector(
  invitationSelector,
  (state): boolean => state?.loaded || state?.error === Errors.NotFound
)

export const openInvitationsSelector = createSelector(
  dataSelector,
  data => data && data.filter(invite => !invite.accepted)
)

// ACTIONS

export const getInvitations = (): AppThunk => async dispatch => {
  dispatch(requestInProgress())
  try {
    const response = await api.get('coach-player')
    const subscriptionInfo: Invitation[] = getPayloadFromResponse(response)
    dispatch(loaded(subscriptionInfo))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
    dispatch(requestError(getErrorStatus(error)))
  }
}

export const invite =
  (email: string, recaptchaToken: string): AppThunk =>
  async dispatch => {
    try {
      await api.post('coach-player/invite', { email, recaptchaToken })
      dispatch(getInvitations())
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
    }
  }

export const acceptInvite =
  (invitationUuid: string): AppThunk =>
  async dispatch => {
    await api.post('coach-player/accept', {
      invitationUuid,
    })
    dispatch(getInvitations())
  }

export const declineInvite =
  (invitationUuid: string): AppThunk =>
  async dispatch => {
    await api.post('coach-player/remove', {
      invitationUuid,
    })
    dispatch(getInvitations())
  }
