import React from 'react'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

interface Props {
  items: Array<{
    title: string
    value: string | number
    type?: ScoreCardItemType
  }>
}

export enum ScoreCardItemType {
  Default,
  Positive,
  Negative,
}

const colors = {
  [ScoreCardItemType.Default]: '',
  [ScoreCardItemType.Positive]: 'success.main',
  [ScoreCardItemType.Negative]: 'error.main',
}

const Item = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(3, 3, 3, 0)};
    &:first-child {
      padding-left: ${theme.spacing(3)}px;
    }
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(0, 3, 3, 3)};
      &:first-child {
        padding-top: ${theme.spacing(3)}px;
      }
    }
  `
)

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      text-align: left;
    }
  `
)

const ScorePaper = styled(Paper)(
  ({ theme }) => css`
    z-index: 1;
    width: fit-content;
    background-color: ${theme.palette.background.xlight};

    ${theme.breakpoints.down('sm')} {
      width: 48%;
    }
  `
)

const ScoreCard: React.FC<Props> = ({ items }) => (
  <ScorePaper elevation={0}>
    <Container>
      {items.map((item, index) => (
        <Item key={index}>
          <Typography>{item.title}</Typography>
          <Typography variant="h4" component="p">
            <Box
              component="span"
              color={colors[item.type ?? ScoreCardItemType.Default]}
            >
              {item.value || ''}
            </Box>
          </Typography>
        </Item>
      ))}
    </Container>
  </ScorePaper>
)

export default React.memo(ScoreCard)
