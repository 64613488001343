import React, { useState, useContext } from 'react'
import styled, { css, StyledProps } from 'styled-components'

import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import { isNumber, handleNumberFormat } from 'src/utils/helpers'
import { TranslationContext } from 'src/utils/TranslationContext'
import { MetricId, Category, CategoryType } from 'src/utils/golfConstants'

interface Props {
  category: CategoryType
  subCategories: Category[]
}

interface CollapseProps {
  open?: boolean
}

const StyledRow = styled(({ open, ...props }) => <TableRow {...props} />)(
  ({ theme, open = true }: StyledProps<CollapseProps>) => css`
    border-bottom: ${open ? '2px solid' : 'none'};
    border-color: ${theme.palette.background.xlight};
  `
)

const MoreIcon = styled(({ open, ...props }) => (
  <KeyboardArrowDownIcon {...props} />
))(
  ({ theme, open }: StyledProps<CollapseProps>) => css`
    transition: transform 0.2s ease-out;
    color: ${theme.palette.primary.main};
    font-size: ${theme.typography.pxToRem(20)};
    transform: rotate(${open ? '180deg' : '0'});
  `
)

const DataCell = styled(TableCell)(
  ({ theme }) => css`
    padding-top: 0;
    padding-bottom: 0;
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 0, 1, 1)};
    }
  `
)

const StyledTableCell = styled(TableCell)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 0, 1, 1)};
    }
  `
)

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      font-size: ${theme.typography.pxToRem(14)};
    }
  `
)

const SubCategoryRow: React.FC<Props> = ({ category, subCategories }) => {
  const [open, setOpen] = useState(true)
  const { metricLabels, categoryLabels } = useContext(TranslationContext)!

  const toggleOpen = () => {
    setOpen(!open)
  }

  return (
    <>
      <StyledRow open>
        <StyledTableCell component="th" scope="row">
          <Typography variant="caption" color="textSecondary">
            {categoryLabels[category]}
          </Typography>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row" align="right">
          <IconButton
            color="primary"
            onClick={toggleOpen}
            aria-label={`expand ${category} row`}
          >
            <MoreIcon open={open} />
          </IconButton>
        </StyledTableCell>
      </StyledRow>
      <StyledRow open={open}>
        <DataCell colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {subCategories.map(({ metricId, strokesGained }) => {
              return metricId === 'M135' || metricId === 'M136' ? (
                ''
              ) : (
                <Box
                  py={2}
                  pr={2}
                  key={metricId}
                  display="flex"
                  justifyContent="space-between"
                >
                  <StyledTypography>
                    {metricLabels[metricId as MetricId]}
                  </StyledTypography>
                  <StyledTypography>
                    {isNumber(strokesGained) ? (
                      <>
                        <Box component="span" fontWeight={500}>
                          {handleNumberFormat(strokesGained)}
                        </Box>
                        SG
                      </>
                    ) : (
                      <Box component="span" color="text.secondary">
                        -
                      </Box>
                    )}
                  </StyledTypography>
                </Box>
              )
            })}
          </Collapse>
        </DataCell>
      </StyledRow>
    </>
  )
}

export default React.memo(SubCategoryRow)
