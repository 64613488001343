import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { api } from 'src/utils/api'
import { ChinaPaymentMethod } from 'src/components/subscriptionFlow/types'
import { getSinglePayloadFromResponse } from 'src/utils/helpers'
import { getPlayer } from 'src/store/playerSlice'
import useAppState from 'src/hooks/useAppState'
import { getSubscriptionInfo } from 'src/store/subscriptionSlice'

export enum TransactionStatus {
  Completed = 'completed',
  InProgress = 'inProgress',
  Failed = 'failed',
}

type UseVerifyPaymentData = {
  paymentMethod: ChinaPaymentMethod
}

const POLLING_INTERVAL = 2000 // milliseconds
const POLLING_TIMEOUT = 30000 // max time before we give up
const FORCE_REFRESH_THRESHOLD = 4 // every n-th request is a force refresh

export const useVerifyPayment = ({ paymentMethod }: UseVerifyPaymentData) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { playerUuid } = useAppState()

  const isPolling = useRef(false)
  const pollStartTime = useRef(0)
  const requestCounter = useRef(0)

  const [isError, setIsError] = useState(false)
  const [isVerified, setIsVerified] = useState(false)

  const close = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    stopPollingTransactionStatus()
    history.push({
      ...location,
      search: '',
    })
  }

  const checkTimeout = () => {
    if (Date.now() > pollStartTime.current + POLLING_TIMEOUT) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      stopPollingTransactionStatus()
      setIsError(true)
      return true
    }
    return false
  }

  const getLastTransactionStatus = async () => {
    try {
      const response = await api.get(
        `subscription/${paymentMethod}/last-transaction/status`
      )
      const status: TransactionStatus = getSinglePayloadFromResponse(response)
      if (status === TransactionStatus.Completed) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        handleSuccess()
      } else {
        checkTimeout()
      }
    } catch (e) {
      checkTimeout()
    }
  }

  const requestLastTransactionRefresh = async () => {
    try {
      await api.post(`subscription/${paymentMethod}/last-transaction/refresh`)
    } catch (e) {
      // We don't handle errors individually
    }
  }

  const sendNextRequest = () => {
    requestCounter.current += 1
    if ((requestCounter.current - 1) % FORCE_REFRESH_THRESHOLD === 0) {
      return requestLastTransactionRefresh()
    }
    return getLastTransactionStatus()
  }

  const poll = () => {
    if (isPolling.current) {
      setTimeout(async () => {
        await sendNextRequest()
        poll()
      }, POLLING_INTERVAL)
    }
  }

  const startPollingTransactionStatus = async () => {
    isPolling.current = true
    requestCounter.current = 0
    pollStartTime.current = Date.now()
    await sendNextRequest()
    poll()
  }

  const stopPollingTransactionStatus = () => {
    isPolling.current = false
  }

  const handleSuccess = () => {
    stopPollingTransactionStatus()
    setIsVerified(true)

    // Refresh the player data so their subscription info is up to date
    if (playerUuid) {
      dispatch(getPlayer(playerUuid))
    } else {
      dispatch(getPlayer())
      dispatch(getSubscriptionInfo())
    }
  }

  const startPolling = () => {
    // Reset error state and start polling again
    setIsError(false)
    startPollingTransactionStatus()
  }

  return {
    startPolling,
    stopPolling: stopPollingTransactionStatus,
    isError,
    isVerified,
    close,
  }
}
