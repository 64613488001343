import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

export const useEnum = <T extends { [key: string]: any }>(
  obj: T,
  i18nKey: string
) => {
  const { t } = useTranslation()
  const values = useMemo(() => Object.values(obj), [obj])
  const labels: { [k: string]: string } = useMemo(
    () =>
      values.reduce(
        (labels, value) => ({
          ...labels,
          [value as string]: t(`Enums:${i18nKey}.${value}`),
        }),
        {}
      ),
    [t, values, i18nKey]
  )
  const options = useMemo(
    () =>
      values.map(value => ({
        label: labels[value as string],
        value: value as string,
      })),
    [labels, values]
  )
  return {
    labels,
    options,
  }
}

export default useEnum
