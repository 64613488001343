import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  authRequestFail,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { Coach } from 'src/models/coach'
import { RootState } from 'src/store/rootReducer'
import { Gender, Unit } from 'src/utils/constants'
import { Permission } from '../models/permission'

export type UpdateCoachRequest = Pick<
  Coach,
  'dob' | 'lastName' | 'firstName' | 'gender'
>

interface InitialState extends Coach {
  inProgress: boolean
}

const initialState: InitialState = {
  uuid: '',
  userUuid: '',
  dob: null,
  lastName: '',
  firstName: '',
  inProgress: false,
  unit: Unit.Metric,
  gender: Gender.Male,
  profileImageUrl: null,
  player: [],
  roles: [],
  isAdmin: false,
}

const { actions, reducer } = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    updateProgress: (
      state,
      action: PayloadAction<Pick<InitialState, 'inProgress'>>
    ) => {
      state.inProgress = action.payload.inProgress
    },
    updateCoach: (state, action: PayloadAction<Coach>) => ({
      inProgress: false,
      ...action.payload,
    }),
    updateCoachProfileImage: (
      state,
      action: PayloadAction<{ profileImageUrl: string | null }>
    ) => {
      state.profileImageUrl = action.payload.profileImageUrl
    },
    reinitialiseUser: () => initialState,
  },
})

export default reducer
export const {
  updateCoach,
  updateProgress,
  reinitialiseUser,
  updateCoachProfileImage,
} = actions

// Selectors
export const coachSelector = (state: RootState) => state.coach

export const coachNameSelector = createSelector(
  coachSelector,
  ({ lastName, firstName }) => ({ lastName, firstName })
)

export const coachRolesSelector = createSelector(
  coachSelector,
  ({ roles }) => roles
)

export const coachAdminSelector = createSelector(
  coachSelector,
  ({ isAdmin }) => ({ isAdmin })
)

export const coachPermissionsSelector = createSelector(
  coachSelector,
  ({ roles }) => {
    const permissions: Permission[] = []
    roles?.forEach(role => {
      permissions.push(...role.permissions)
    })
    return permissions
  }
)

// Action Creators
export const getCoach = (): AppThunk => async dispatch => {
  try {
    const response = await api.get('coach')
    const data: Coach = getSinglePayloadFromResponse(response)

    dispatch(updateCoach(data))
  } catch (error: any) {
    dispatch(updateProgress({ inProgress: false }))
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}

export const updateCoachProfile =
  (payload: UpdateCoachRequest): AppThunk =>
  async dispatch => {
    dispatch(updateProgress({ inProgress: true }))

    try {
      const response = await api.put('coach', payload)
      const coach: Coach = getSinglePayloadFromResponse(response)

      dispatch(updateCoach(coach))
      return coach
    } catch (error: any) {
      dispatch(updateProgress({ inProgress: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Unable to update profile')
    }
  }

export const setCoachProfileImage =
  (file: File): AppThunk =>
  async dispatch => {
    const data = new FormData()
    data.append('file', file)
    try {
      const response = await api.post('coach/image', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      const profileImageUrl =
        getSinglePayloadFromResponse(response).profileImageUrl

      dispatch(updateCoachProfileImage({ profileImageUrl }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error('Unable to upload image')
      }
    }
  }

export const deleteCoachProfileImage = (): AppThunk => async dispatch => {
  try {
    await api.delete('coach/image')

    dispatch(updateCoachProfileImage({ profileImageUrl: null }))
  } catch (error: any) {
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    } else {
      throw new Error('Unable to delete image')
    }
  }
}
