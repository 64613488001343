import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  authRequestFail,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { User } from 'src/models/user'
import { hashPassword } from 'src/utils/crypto'
import { RootState } from 'src/store/rootReducer'
import { coachSelector, coachNameSelector } from 'src/store/coachSlice'
import { ErrorNames, Languages, UserType } from 'src/utils/constants'
import { playerSelector, playerNameSelector } from 'src/store/playerSlice'

interface InitialState extends User {
  inProgress: boolean
}

export interface UpdateUserPasswordRequest {
  newPassword: string
  currentPassword: string
}

const initialState: InitialState = {
  uuid: '',
  email: '',
  createdAt: '',
  deletedAt: '',
  updatedAt: '',
  metadata: null,
  inProgress: false,
  userVerified: false,
}

const { actions, reducer } = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateProgress: (
      state,
      action: PayloadAction<Pick<InitialState, 'inProgress'>>
    ) => {
      state.inProgress = action.payload.inProgress
    },
    updateUser: (state, action: PayloadAction<User>) => ({
      inProgress: false,
      ...action.payload,
    }),
    reinitialiseUser: () => initialState,
  },
})

export default reducer
export const { updateUser, updateProgress, reinitialiseUser } = actions

// Action Creators
export const getUser = (): AppThunk => async dispatch => {
  try {
    const response = await api.get('user')
    const data: User = getSinglePayloadFromResponse(response)
    dispatch(updateUser(data))
  } catch (error: any) {
    dispatch(updateProgress({ inProgress: false }))
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
  }
}

export const updateUserPassword =
  ({ newPassword, currentPassword }: UpdateUserPasswordRequest): AppThunk =>
  async dispatch => {
    dispatch(updateProgress({ inProgress: true }))

    try {
      await api.put('user/password', {
        newPassword: hashPassword(newPassword),
        currentPassword: hashPassword(currentPassword),
      })
    } catch (error: any) {
      const actionError = new Error('Unable to update the password')
      actionError.name =
        error.response?.status === 403 ? ErrorNames.IncorrectPassword : ''
      throw actionError
    } finally {
      dispatch(updateProgress({ inProgress: false }))
    }
  }

export const closeAccount = (): AppThunk => async dispatch => {
  dispatch(updateProgress({ inProgress: true }))

  try {
    await api.delete('user')
    dispatch(updateProgress({ inProgress: false }))
  } catch (error: any) {
    dispatch(updateProgress({ inProgress: false }))
    if (isForbiddenOrUnauthorised(error)) {
      authRequestFail(dispatch)
    }
    throw new Error('Unable to close account')
  }
}

export const setLanguage =
  (language: Languages): AppThunk =>
  async dispatch => {
    dispatch(updateProgress({ inProgress: true }))
    try {
      const response = await api.put('user/language', { language })
      dispatch(updateUser(getSinglePayloadFromResponse(response)))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error('Unable to set language')
    } finally {
      dispatch(updateProgress({ inProgress: false }))
    }
  }

// Selectors
export const userSelector = (state: RootState) => state.user

export const userTypeSelector = createSelector(
  userSelector,
  ({ metadata }) => metadata?.userType
)

export const userLanguageSelector = createSelector(
  userSelector,
  ({ metadata }) => metadata?.language
)

export const nameSelector = createSelector(
  userTypeSelector,
  coachNameSelector,
  playerNameSelector,
  (userType, coachName, playerName) =>
    userType === UserType.Player ? playerName : coachName
)

export const emailSelector = createSelector(userSelector, ({ email }) => email)

export const profileImageUrlSelector = createSelector(
  userTypeSelector,
  playerSelector,
  coachSelector,
  (userType, player, coach) =>
    userType === UserType.Player
      ? player?.profileImageUrl
      : coach?.profileImageUrl
)
