import React, { useCallback, useContext } from 'react'

import Select from 'src/components/inputs/Select'
import { LanguageContext } from 'src/utils/LanguageProvider'

export const LanguageSelector: React.FC = props => {
  const { language, setLanguage, options } = useContext(LanguageContext)

  const handleLangChange = useCallback(
    event => {
      const {
        target: { value },
      } = event
      setLanguage(value)
    },
    [setLanguage]
  )

  return (
    <Select
      name="lang"
      {...props}
      options={options}
      onChange={handleLangChange}
      value={language}
    />
  )
}

export default LanguageSelector
