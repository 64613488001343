import React from 'react'
import { Field, useField } from 'formik'

import { Autocomplete as FMUIAutocomplete } from 'formik-material-ui-lab'
import TextField from '@material-ui/core/TextField'
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import LoopIcon from '@material-ui/icons/Loop'

import {
  Label,
  ExtraProps,
  ErrorMessage,
} from 'src/components/inputs/FormTextField'
import { TextFieldProps } from 'material-ui'
import styled, { css, keyframes } from 'styled-components'

type BaseProps = TextFieldProps & ExtraProps

interface AutocompleteExtraProps extends BaseProps {
  label?: string
  options: any[]
  getOptionLabel?: (e: any) => string
  onChangedInput?: (e: string) => void
  loading?: boolean
}

const StyledContainer = styled.div(
  ({ theme }) => css`
    position: relative;

    input::placeholder {
      color: ${theme.palette.background.grey};
    }
  `
)

const StyledLabelContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: flex-start;
`

const rotationAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledLoopIcon = styled(LoopIcon)<{ loading: boolean | undefined }>`
  height: 1rem;
  width: 1rem;
  animation-name: ${rotationAnimation};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin-left: 0.25rem;
  display: ${({ loading }) => (loading ? 'block' : 'none')};
`

const Autocomplete: React.FC<AutocompleteExtraProps> = ({
  label,
  name,
  showErrorString = true,
  ...props
}) => {
  const field = useField(name)
  const meta = field[1]
  const placeholder = props.placeholder || ''

  const hasError = meta.touched && !!meta.error
  const { onChangedInput, loading, ...fieldProps } = props

  return (
    <StyledContainer>
      {label && (
        <StyledLabelContainer>
          <Label variant="caption" color={hasError ? 'error' : 'textPrimary'}>
            {label}
          </Label>
          <StyledLoopIcon fontSize="small" loading={loading} />
        </StyledLabelContainer>
      )}
      <Field
        name={name}
        component={FMUIAutocomplete}
        {...fieldProps}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            variant="outlined"
            error={hasError}
            placeholder={placeholder}
            onChange={e =>
              props.onChangedInput ? props.onChangedInput(e.target.value) : null
            }
          />
        )}
      />
      {showErrorString && (
        <ErrorMessage active={hasError}>{meta.error}</ErrorMessage>
      )}
    </StyledContainer>
  )
}

export default Autocomplete
