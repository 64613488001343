import React, { useContext } from 'react'
import {
  FadingDotIllustration,
  Toolbar,
  XsPaddingZeroContainer,
} from '../common'
import { AppBar, Avatar, IconButton } from '@material-ui/core'
import Close from '@material-ui/icons/Close'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { DateFormats } from '../../utils/constants'
import { Trans } from 'react-i18next'
import ReportDonut from './ReportDetail/ReportDonut'
import styled, { css } from 'styled-components'
import { TranslationContext } from '../../utils/TranslationContext'
import SmallLogo from '../../assets/svgComponents/SmallLogo'
import { ReportGeneration } from '../../utils/golfConstants'

const HeaderAppBar = styled(AppBar)<{ darkMode: boolean | undefined }>(
  ({ darkMode }) => css`
    background-color: ${darkMode ? '#070707' : '#fff'};
    color: ${!darkMode ? '#070707' : '#fff'};
  `
)

const HeaderContainer = styled(Box)(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(4)}px;
    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      padding: ${theme.spacing(2, 2, 4, 2)};
    }
  `
)

const Illustration = styled(FadingDotIllustration)(
  ({ theme }) => css`
    position: absolute;
    height: 100%;
    top: 10px;
    right: 0;
    z-index: -1;
    g {
      fill: ${theme.palette.background.grey};
    }
  `
)

const ProgressDonutContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    min-width: 300px;
    align-items: center;
    justify-content: center;

    p {
      color: ${theme.palette.text.primary};
    }
  `
)

const StyledAvatar = styled(Avatar)(
  ({ theme }) => css`
    width: 50px;
    height: 50px;
    background-color: #000;
    margin-right: ${theme.spacing(2)}px;

    svg {
      width: 28px;
    }
  `
)

const StyledIconButton = styled(IconButton)<{ darkMode: boolean | undefined }>(
  ({ darkMode }) => css`
    svg {
      color: ${!darkMode ? '#070707' : '#fff'};
    }
  `
)

interface ReportHeaderProps {
  closeDialog: () => void
  title: string
  createdAt: Date
  name: string
  email: string
  playerType: string
  generationType: ReportGeneration
  progressDonutNeeded?: boolean
  coachProfileImageUrl?: string
  coachName?: string
  darkMode?: boolean
}

const I18N_KEY = 'ReportDetailDialog'

const ReportHeader: React.FC<ReportHeaderProps> = ({
  closeDialog,
  title,
  createdAt,
  name,
  email,
  playerType,
  generationType,
  progressDonutNeeded,
  coachProfileImageUrl,
  coachName,
  darkMode,
}) => {
  const { format, t } = useContext(TranslationContext)!

  const isManualGeneration = generationType === ReportGeneration.Manual

  return (
    <HeaderAppBar
      elevation={0}
      color="default"
      position="relative"
      darkMode={darkMode}
    >
      <Illustration />
      <XsPaddingZeroContainer>
        <Toolbar>
          <StyledIconButton onClick={closeDialog} darkMode={darkMode}>
            <Close />
          </StyledIconButton>
        </Toolbar>
        <HeaderContainer display="flex" flexDirection="row">
          <Box flex={1}>
            <Typography variant="h1" component="h2">
              {title}
            </Typography>
            <Box mb={3}>
              <Typography>
                {format(new Date(createdAt), DateFormats.Report)}
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography>
                <Trans i18nKey={`${I18N_KEY}.player`}>
                  <strong>Player:</strong> {{ name }}
                </Trans>
              </Typography>
              <Typography>
                <Trans i18nKey={`${I18N_KEY}.email`}>
                  <strong>Email:</strong> {{ email }}
                </Trans>
              </Typography>
              <Typography>
                <Trans i18nKey={`${I18N_KEY}.type`}>
                  <strong>Type:</strong> {{ playerType }}
                </Trans>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              {isManualGeneration ? (
                coachProfileImageUrl && (
                  <StyledAvatar
                    src={coachProfileImageUrl}
                    alt={t(`${I18N_KEY}.coachProfile`, 'Coach profile')}
                  />
                )
              ) : (
                <StyledAvatar>
                  <SmallLogo />
                </StyledAvatar>
              )}
              <Typography>
                <Trans i18nKey={`${I18N_KEY}.createdBy`}>
                  {/* Since "Circles" is a name, we don't need to translate it */}
                  <strong>
                    Created by{' '}
                    {{
                      createdBy: isManualGeneration ? coachName : 'Circles',
                    }}
                  </strong>
                </Trans>
              </Typography>
            </Box>
          </Box>
          {progressDonutNeeded ? (
            <ProgressDonutContainer>
              <ReportDonut />
            </ProgressDonutContainer>
          ) : (
            ''
          )}
        </HeaderContainer>
      </XsPaddingZeroContainer>
    </HeaderAppBar>
  )
}

export default ReportHeader
