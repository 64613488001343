import React from 'react'
import { Trans } from 'react-i18next'
import { t } from 'i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MUIAvatar from '@material-ui/core/Avatar'

import CloseIcon from '@material-ui/icons/Close'
import MuiCheckIcon from '@material-ui/icons/Check'

import { Invitation } from 'src/models/invitation'
import LoaderButton from 'src/components/inputs/LoaderButton'

const I18N_KEY = 'Invite'

const Avatar = styled(MUIAvatar)(
  ({ theme }) => css`
    margin-right: ${theme.spacing(2)}px;
  `
)

const Name = styled.span`
  font-weight: 500;
`

const CheckIcon = styled(MuiCheckIcon)(
  ({ theme }) => css`
    color: ${theme.palette.primary.light};
    transition: color 0.3s ease-out;
  `
)

const DeclineButton = styled(Button)(
  ({ theme }) => css`
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: ${theme.spacing(1)}px;
    color: ${theme.palette.error.main};
    background-color: ${theme.palette.error.light};

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  `
)

const StyledLoaderButton = styled(LoaderButton)(
  ({ theme }) => css`
    &:hover ${CheckIcon} {
      color: ${theme.palette.background.paper};
    }
  `
)

interface InviteItemProps {
  invite: Invitation
  onAccept: (uuid: string) => void
  onDecline: (uuid: string) => void
  loading?: boolean
}
const InviteItem: React.FC<InviteItemProps> = ({
  invite,
  loading,
  onAccept,
  onDecline,
}) => {
  const { name, uuid, owner } = invite
  const [firstName, lastName] = name.split(' ')
  const lastNameInitial = `${lastName[0].toUpperCase()}`
  const userType = t(`Enums:UserType.${owner}`).toLowerCase()

  const handleAccept = () => onAccept(uuid)
  const handleDecline = () => onDecline(uuid)

  return (
    <Box display="flex" alignItems="center">
      <Avatar>{`${firstName[0].toUpperCase()}${lastNameInitial}`}</Avatar>
      <div>
        <Box pb={1}>
          <Trans i18nKey={`${I18N_KEY}.invitationText`}>
            <Name>
              {{ firstName }} {{ lastNameInitial }}.
            </Name>{' '}
            wants to connect as your {{ userType }}
          </Trans>
        </Box>
        <Box display="flex">
          <DeclineButton
            disabled={!!loading}
            variant="contained"
            onClick={handleDecline}
          >
            <CloseIcon />
          </DeclineButton>
          <StyledLoaderButton
            loading={!!loading}
            color="primary"
            variant="contained"
            startIcon={<CheckIcon />}
            onClick={handleAccept}
          >
            <Trans i18nKey={`${I18N_KEY}.acceptButtonLabel`}>Accept</Trans>
          </StyledLoaderButton>
        </Box>
      </div>
    </Box>
  )
}

export default InviteItem
