import React, { useState } from 'react'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'

import { FormValues } from 'src/modules/auth/ResetPassword'
import { Label } from 'src/components/inputs/FormTextField'
import LoaderButton from 'src/components/inputs/LoaderButton'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import PasswordVisibility from 'src/components/inputs/PasswordVisibility'
import { Form, Container, TitleContainer } from 'src/modules/auth/common'

const I18N_KEY = 'ResetPassword'

interface Props {
  control: Control
  isDirty: boolean
  isValid: boolean
  isSubmitting: boolean
  errors: FieldErrors<FormValues>
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>
}

const ForgotPasswordForm: React.FC<Props> = ({
  errors,
  control,
  isDirty,
  isValid,
  onSubmit,
  isSubmitting,
}) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  }
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)
  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm)
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transitionDuration: '0.4s' }}
    >
      <TitleContainer>
        <Typography variant="h1" align="center">
          <Trans i18nKey={`${I18N_KEY}.title`}>Reset password</Trans>
        </Typography>
      </TitleContainer>
      <Form>
        <Box mb={4}>
          <Label variant="caption">
            {t(`${I18N_KEY}.passwordLabel`, 'New Password')}
          </Label>
          <HookFormTextField
            fullWidth
            errors={errors}
            control={control}
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PasswordVisibility
                    isVisible={showPassword}
                    toggleVisibility={togglePasswordVisibility}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box mb={4}>
          <Label variant="caption">
            {t(`${I18N_KEY}.passwordConfirmLabel`, 'Re-type New Password')}
          </Label>
          <HookFormTextField
            fullWidth
            errors={errors}
            control={control}
            name="passwordConfirm"
            type={showPasswordConfirm ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PasswordVisibility
                    isVisible={showPasswordConfirm}
                    toggleVisibility={togglePasswordConfirmVisibility}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Controller
          name="token"
          control={control}
          render={props => <input type="hidden" {...props} />}
        />
        <LoaderButton
          fullWidth
          type="submit"
          onClick={onSubmit}
          variant="contained"
          loading={isSubmitting}
          disabled={!isDirty || !isValid}
        >
          <Trans i18nKey={`${I18N_KEY}.setNewPasswordButtonLabel`}>
            Set new Password
          </Trans>
        </LoaderButton>
      </Form>
    </Container>
  )
}

export default ForgotPasswordForm
