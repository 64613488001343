import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import React, { useState, useMemo, useContext } from 'react'

import { config } from '../../../configLoader'

import {
  userPlanSelector,
  cancelSubscription,
  subscriptionCancelsAt,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import {
  Title,
  Container,
  CenteredRow,
  RemoveButton,
} from 'src/modules/player-settings/common'
import { DateFormats } from 'src/utils/constants'
import useUpgradeFlow from 'src/hooks/useUpgradeFlow'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { TranslationContext } from 'src/utils/TranslationContext'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'
import CancelMembershipConfirmDialog from 'src/modules/player-settings/billing/CancelMembershipConfirmDialog'

const I18N_KEY = 'MembershipInfo'

const EndDate = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(14)};
  `
)

const StyledCenteredRow = styled(CenteredRow)`
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
`

const StyledButton = styled(LoaderButton)`
  margin-right: 12px;
`

const MembershipInfo: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { format } = useContext(TranslationContext)!
  const [inProgress, setInProgress] = useState(false)
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const plan = useSelector(userPlanSelector)
  const isTrialing = useSelector(userIsTrialingSelector)
  const cancelsAt = useSelector(subscriptionCancelsAt)
  const { open } = useUpgradeFlow()

  const hasSubscription = !!plan
  const planType = t(`Enums:PlanType.${plan?.planType}`)

  const handleCancelButtonClick = () => {
    setConfirmDialogOpen(true)
  }

  const handleClose = () => {
    setConfirmDialogOpen(false)
  }

  const handleCancelSubscription = async () => {
    try {
      await dispatch(cancelSubscription())
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.cancelMembershipSuccess`,
              'Your premium membership has been cancelled'
            ) as string
          )
        )
      )
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(
              `${I18N_KEY}.cancelMembershipError`,
              'Error cancelling your membership'
            ) as string
          )
        )
      )
    }
  }

  const handleAction = async () => {
    setInProgress(true)
    if (config.REACT_APP_PROFITWELL_KEY) {
      ;(window as any)
        .profitwell('init_cancellation_flow')
        .then(async (result: any) => {
          // This means the customer either aborted the flow (i.e.
          // they clicked on "never mind, I don't want to cancel"), or
          // accepted a salvage attempt or salvage offer.
          // Thus, do nothing since they won't cancel.
          if (result.status === 'retained' || result.status === 'aborted') {
            return
          }

          // At this point, the customer ended deciding to cancel (i.e.
          // they rejected the salvage attempts and the salvage offer).
          // It could also be the case the widget couldn't be shown properly for
          // some reason (for which case, `result.status` will be 'error'), but that
          // shouldn't stop them from cancelling.
          handleCancelSubscription()
        })
    } else {
      handleCancelSubscription()
    }
    setInProgress(false)
    handleClose()
  }

  const cancelsAtDate = useMemo(
    () => cancelsAt && new Date(cancelsAt),
    [cancelsAt]
  )

  return (
    <>
      <Title variant="h4">
        <Trans i18nKey={`${I18N_KEY}.title`}>Membership</Trans>
      </Title>
      <Container container spacing={1}>
        <StyledCenteredRow item xs={12} sm={4}>
          {isTrialing ? (
            <Trans i18nKey={`${I18N_KEY}.evaluation`}>Premium Evaluation</Trans>
          ) : hasSubscription ? (
            <Trans i18nKey={`${I18N_KEY}.membership`}>
              {{ planType }} Membership
            </Trans>
          ) : (
            <Trans i18nKey={`${I18N_KEY}.membershipSelected`}>
              No membership selected
            </Trans>
          )}
          {cancelsAtDate && (
            <EndDate>
              <Trans
                i18nKey={`${I18N_KEY}.endsMessage`}
                values={{
                  cancelsAt: format(
                    cancelsAtDate || new Date(),
                    DateFormats.TableRow
                  ),
                }}
              >
                Ends: {{ cancelsAt }}
              </Trans>
            </EndDate>
          )}
        </StyledCenteredRow>
        <CenteredRow item xs={12} sm={12} md={7}>
          <StyledButton
            onClick={open}
            color="primary"
            variant="contained"
            loading={inProgress}
          >
            {plan ? (
              <Trans i18nKey={`${I18N_KEY}.changeMembership`}>
                Change membership
              </Trans>
            ) : (
              <Trans i18nKey={`${I18N_KEY}.upgrade`}>Upgrade</Trans>
            )}
          </StyledButton>
          {!isTrialing && !cancelsAt && !!plan && (
            <RemoveButton
              loading={inProgress}
              onClick={handleCancelButtonClick}
            >
              <Trans i18nKey={`${I18N_KEY}.cancelLabel`}>
                Cancel membership
              </Trans>
            </RemoveButton>
          )}
        </CenteredRow>
      </Container>
      <CancelMembershipConfirmDialog
        inProgress={inProgress}
        handleClose={handleClose}
        handleAction={handleAction}
        open={confirmDialogOpen}
      />
    </>
  )
}

export default MembershipInfo
