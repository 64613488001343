import React, { useContext } from 'react'
import { getDayOfYear } from 'date-fns'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'

import Box from '@material-ui/core/Box'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import {
  Languages,
  DateFormats,
  DAY_IN_MILLISECONDS,
} from 'src/utils/constants'
import TeeGolfBall from 'src/assets/svgComponents/TeeGolfBall'
import { TimelineItem } from 'src/models/coach-player-overview'
import { TranslationContext } from 'src/utils/TranslationContext'

import { ReactComponent as MultiActivity } from 'src/assets/images/MultiActivity.svg'
import { ReactComponent as CalendarCheckIcon } from 'src/assets/images/CalendarCheck.svg'

const I18N_KEY = 'ActivityTimeline'

interface Props {
  timeline: TimelineItem[] | undefined
  generateNavigateToPlayer: (toActivity?: boolean) => () => void
}

interface IconProps {
  $hasActivity: boolean
}

const Container = styled.div(
  ({ theme }) =>
    css`
      min-height: 185px;
      padding: ${theme.spacing(3)}px;

      ${theme.breakpoints.down('xs')} {
        min-height: unset;
      }
    `
)

const Descriptions = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      position: relative;
      flex-direction: column;

      ${theme.breakpoints.down('xs')} {
        flex-direction: row;
        justify-content: space-between;
      }
    `
)

const Button = styled(MuiButton)(
  ({ theme }) => css`
    top: 0;
    right: 0;
    position: absolute;
    background-color: ${theme.palette.background.xlight};

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `
)

const ActivityContainer = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: ${theme.spacing(3, 0, 0)};

      ${theme.breakpoints.down('xs')} {
        margin: ${theme.spacing(1.5, 0, 0)};
      }
    `
)

const IconContainer = styled.div<IconProps>(
  ({ theme, $hasActivity }) => css`
    width: 35px;
    height: 35px;
    display: flex;
    border: 1px solid;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-color: ${$hasActivity
      ? theme.palette.primary.light
      : theme.palette.background.grey};
    background-color: ${$hasActivity ? theme.palette.primary.light : '#fff'};

    /* Custom breakpoint just for this icon */
    ${theme.breakpoints.down(345)} {
      width: 30px;
      height: 30px;
    }
  `
)

const PracticeIcon = styled(CalendarCheckIcon)`
  width: 16px;
`

const RoundsIcon = styled(TeeGolfBall)`
  width: 16px;
  margin-top: 4px;
`

const MultiAcitvityIcon = styled(MultiActivity)`
  margin-top: 4px;
`

const today = new Date()

export const lastWeek = [
  new Date(today.getTime() - 6 * DAY_IN_MILLISECONDS),
  new Date(today.getTime() - 5 * DAY_IN_MILLISECONDS),
  new Date(today.getTime() - 4 * DAY_IN_MILLISECONDS),
  new Date(today.getTime() - 3 * DAY_IN_MILLISECONDS),
  new Date(today.getTime() - 2 * DAY_IN_MILLISECONDS),
  new Date(today.getTime() - 1 * DAY_IN_MILLISECONDS),
  today,
]

const ActivityTimeline: React.FC<Props> = ({
  timeline,
  generateNavigateToPlayer,
}) => {
  const { i18n } = useTranslation()
  const { format } = useContext(TranslationContext)!

  const getIcon = (addedRound?: boolean, addedPractice?: boolean) => {
    if (addedRound && addedPractice) {
      // TODO: Update to new icon once Charles has made
      return <MultiAcitvityIcon />
    } else if (addedPractice) {
      return <PracticeIcon />
    } else if (addedRound) {
      return <RoundsIcon />
    }
  }

  return (
    <Container>
      <Descriptions>
        <Typography variant="body2">
          <Trans i18nKey={`${I18N_KEY}.roundsTraining`}>
            Rounds & Training
          </Trans>
        </Typography>
        <Typography color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.last7Days`}>Last 7 days</Trans>
        </Typography>
        <Button
          size="small"
          color="primary"
          onClick={generateNavigateToPlayer(true)}
        >
          <Trans i18nKey={`${I18N_KEY}.activity`}>Activity</Trans>
        </Button>
      </Descriptions>
      <ActivityContainer>
        {lastWeek.map(weekday => {
          const dayOfYear = getDayOfYear(weekday)
          const activity = timeline?.find(
            ({ date }) => dayOfYear === getDayOfYear(new Date(date))
          )
          const hasActivity = !!activity

          const dayOfWeek = format(weekday, DateFormats.DayOfWeek)
          const formattedDay =
            i18n.language === Languages.English ? dayOfWeek[0] : dayOfWeek

          return (
            <Box
              display="flex"
              key={dayOfYear}
              alignItems="center"
              flexDirection="column"
              color="background.grey"
            >
              {formattedDay}
              <IconContainer $hasActivity={hasActivity}>
                {getIcon(activity?.addedRound, activity?.addedPractice)}
              </IconContainer>
            </Box>
          )
        })}
      </ActivityContainer>
    </Container>
  )
}

export default ActivityTimeline
