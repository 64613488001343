import React from 'react'
import styled, { css } from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import DialogContent from '@material-ui/core/DialogContent'
import { motion, AnimatePresence } from 'framer-motion'

import SmallLogo from 'src/assets/svgComponents/SmallLogo'
import { fadeVariants, defaultTransition } from 'src/utils/animations'
import FullScreenDialog from 'src/components/feedback/FullScreenDialog'

const Dialog = styled(FullScreenDialog)(
  ({ theme }) => css`
    .MuiDialog-paper {
      background-color: ${theme.palette.background.black};
      color: ${theme.palette.text.white};
    }

    .MuiDialogContent-root {
      padding-top: 0;
    }
  `
)

const DialogHeader = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    padding: ${theme.spacing(2, 6)};
    justify-content: space-between;
    width: 100%;

    svg {
      display: block;
    }

    @media (max-width: 1248px) {
      max-width: 1152px;
      padding-left: 0;
      padding-right: 0;
      margin: auto;
    }

    @media (max-width: 1200px) {
      padding: ${theme.spacing(2, 3)};
      max-width: 100%;
    }
  `
)

const NavButton = styled(IconButton)`
  position: relative;
  left: -12px;
`

const RightAlignedLogo = styled(SmallLogo)`
  width: 24px;
`

type UpgradeDialogProps = {
  open: boolean
  children: React.ReactNode
  navButton: React.ReactNode
  onNavButtonClick: () => void
}

const UpgradeDialog = ({
  open,
  children,
  navButton,
  onNavButtonClick,
}: UpgradeDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogHeader>
        <NavButton color="secondary" onClick={onNavButtonClick}>
          {navButton}
        </NavButton>
        <RightAlignedLogo />
      </DialogHeader>
      <DialogContent>
        <AnimatePresence>
          <motion.div
            exit="exit"
            initial="enter"
            animate="visible"
            variants={fadeVariants}
            transition={defaultTransition}
          >
            {children}
          </motion.div>
        </AnimatePresence>
      </DialogContent>
    </Dialog>
  )
}

export default UpgradeDialog
