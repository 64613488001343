import React from 'react'

import Box, { BoxProps } from '@material-ui/core/Box'

const NoData: React.FC<BoxProps> = props => (
  <Box {...props} component="span" color="background.grey">
    N/A
  </Box>
)

export default NoData
