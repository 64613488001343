import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans } from 'react-i18next'

import Typography from '@material-ui/core/Typography'

import { playerSelector } from 'src/store/playerSlice'
import BenchmarkSelection from 'src/components/feedback/BenchmarkSelection'
import RadarComparison from 'src/components/dataDisplay/RadarComparison'
import { breakpoints } from 'src/utils/constants'
import useBenchmark from 'src/hooks/useBenchmark'
import { CategoryType, FocusArea, Round } from 'src/utils/golfConstants'
import Dash from 'src/assets/svgComponents/DashIcon'
import { CircleIcon } from 'src/components/graph/common'

const I18N_KEY = 'EntryComplete'

interface Props {
  roundUuid: string
  recommendedBenchmarkId: string
  roundData: Round | null
}

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto ${theme.spacing(3)}px;
  `
)

const Comparison = styled(RadarComparison)(
  ({ theme }) => css`
    width: 70%;
    margin: ${theme.spacing(2, 0, 6)};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      width: 90%;
      margin: ${theme.spacing(2, 0, 4)};
    }
  `
)

const LegendContainer = styled.div(
  ({ theme }) => css`
    width: 50%;
    display: flex;
    justify-content: space-around;
    margin-top: 2em;
    margin-left: -2em;

    ${theme.breakpoints.down(1000)} {
      margin-left: ${theme.spacing(-6)}px;
    }
  `
)

const IconText = styled(Typography).attrs({
  variant: 'caption',
  color: 'textSecondary',
})`
  display: flex;
  align-items: center;
`

const DashIcon = styled(Dash)(
  ({ theme }) => css`
    fill: ${theme.palette.warning.main};
    margin-right: ${theme.spacing(1)}px;
  `
)

const calculateFocusAreaTotal = (focusAreas: FocusArea[]): number => {
  const totalFocusArea = focusAreas.reduce(
    (total, focusArea) => total + focusArea.difference,
    0
  )

  if (totalFocusArea < 0) {
    return Math.abs(totalFocusArea)
  }

  return totalFocusArea
}

const formatFocusAreaText = (focusAreaText: string): string => {
  let formattedText = focusAreaText
  formattedText = formattedText.replaceAll(/SG: /g, '')
  formattedText = formattedText.replaceAll(/from/g, '')
  formattedText = formattedText.replaceAll(/ - /g, ':')
  formattedText = formattedText.replaceAll(/ yards/gi, 'y')
  formattedText = formattedText.replaceAll(/ feet/gi, 'ft')
  formattedText = formattedText.replaceAll(/</g, '\t&lt;')
  formattedText = formattedText.replaceAll(/>/g, '\t&gt;')
  return formattedText
}

const EntryComplete: React.FC<Props> = ({
  roundUuid,
  recommendedBenchmarkId,
  roundData,
}) => {
  const { benchmarkSelected } = useSelector(playerSelector)
  const { benchmarkName } = useBenchmark()

  const [dialogOpen, setDialogOpen] = useState(!benchmarkSelected)

  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const strokesGained = roundData?.summary?.strokesGained
  const totalStrokesGainedScore = strokesGained?.total.overall
  const radarData = [
    {
      reference: 0,
      category: CategoryType.Drives,
      overall: strokesGained?.drives?.overall,
      benchmark: strokesGained?.drives?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Approach,
      overall: strokesGained?.approach?.overall,
      benchmark: strokesGained?.approach?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Short,
      overall: strokesGained?.short?.overall,
      benchmark: strokesGained?.short?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Putt,
      overall: strokesGained?.putt?.overall,
      benchmark: strokesGained?.putt?.benchmark,
    },
  ]

  const totalFocusAreas = roundData?.summary?.focusAreas || []
  const focusAreas = totalFocusAreas
    .slice(0, 3)
    .filter(focusArea => focusArea.difference < 0)
  const focusAreaCount = focusAreas.length
  const totalFocusArea = calculateFocusAreaTotal(focusAreas).toFixed(2)
  const focusAreasText =
    focusAreas.length > 0
      ? formatFocusAreaText(
          focusAreas.map(focusArea => focusArea.metricName).join(', ')
        )
      : 'none'

  const strongestAreas = totalFocusAreas.slice(-2).reverse()
  const strongestAreasText =
    strongestAreas.length > 0
      ? formatFocusAreaText(
          strongestAreas.map(focusArea => focusArea.metricName).join(' and ')
        )
      : 'none'

  return (
    <Container>
      <Typography
        color="textPrimary"
        variant="h2"
        align="center"
        style={{ fontWeight: 'normal' }}
      >
        <Trans i18nKey={`${I18N_KEY}.roundScore`}>
          You scored a total of
          <strong> {{ totalStrokesGainedScore }}SG </strong>this round.
        </Trans>
      </Typography>
      <div
        style={{
          width: '70%',
          textAlign: 'center',
          paddingTop: '2em',
          paddingBottom: '2em',
        }}
      >
        <Typography
          color="textPrimary"
          variant="h4"
          align="center"
          style={{ fontWeight: 'normal' }}
        >
          <Trans i18nKey={`${I18N_KEY}.roundSummaryStrengths`} shouldUnescape>
            Your top two strengths this round were{' '}
            <strong>{{ strongestAreasText }}</strong>.
          </Trans>
        </Typography>
        <br />
        <Typography
          color="textPrimary"
          variant="h4"
          align="center"
          style={{ fontWeight: 'normal' }}
        >
          <Trans
            i18nKey={`${I18N_KEY}.roundSummaryWeaknesses`}
            values={{
              totalFocusArea,
              benchmarkName,
              focusAreaCount,
              focusAreasText,
            }}
            shouldUnescape
          >
            Potential to improve by <strong>{{ totalFocusArea }}</strong>{' '}
            strokes per round by improving to{' '}
            <strong> {{ benchmarkName }}</strong> in {{ focusAreaCount }} areas:{' '}
            <strong>{{ focusAreasText }}</strong>.
          </Trans>
        </Typography>
      </div>
      <>
        <LegendContainer>
          <IconText>
            <DashIcon />
            {benchmarkName}
          </IconText>
          <IconText>
            <CircleIcon />
            YOU
          </IconText>
        </LegendContainer>
        <Comparison
          isCompactSummary
          data={radarData}
          benchmarkName={benchmarkName}
          isRoundSummary={false}
        />
      </>
      <BenchmarkSelection
        open={dialogOpen}
        roundUuid={roundUuid}
        handleClose={handleDialogClose}
        recommendedBenchmark={recommendedBenchmarkId}
      />
    </Container>
  )
}

export default EntryComplete
