import React from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { emailSelector } from 'src/store/userSlice'
import { isChina } from 'src/utils/constants'

const I18N_KEY = 'SubscriptionFlowSuccess'

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    color: ${theme.palette.text.white};
    padding-top: ${theme.spacing(17.5)}px;
  `
)

interface Props {
  onNext: () => void
}

const Success: React.FC<Props> = ({ onNext }) => {
  const email = useSelector(emailSelector)
  return (
    <Container>
      <Typography variant="h1">
        <Trans i18nKey={`${I18N_KEY}.title`}>Success!</Trans>
      </Typography>
      <Typography variant="body1">
        <Trans i18nKey={`${I18N_KEY}.description`}>
          You now have premium access to the most elite golf training platform.
        </Trans>
      </Typography>
      {!isChina && (
        <Typography variant="body2">
          <Trans i18nKey={`${I18N_KEY}.receiptNote`} values={{ email }}>
            We sent a receipt of purchase to {{ email }}
          </Trans>
        </Typography>
      )}
      <Box mt={4}>
        <Button variant="contained" color="primary" onClick={() => onNext()}>
          <Trans i18nKey={`${I18N_KEY}.dashboardButtonLabel`}>
            Continue to Dashboard
          </Trans>
        </Button>
      </Box>
    </Container>
  )
}

export default Success
