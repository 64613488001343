import React from 'react'
import { useField } from 'formik'
import styled, { css, StyledProps } from 'styled-components'

import Typography from '@material-ui/core/Typography'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'

export type FormTextFieldProps = TextFieldProps & ExtraProps

export interface ExtraProps {
  name: string
  showErrorString?: boolean
}

interface ErrorProps {
  active: boolean
}

const StyledContainer = styled.div(
  ({ theme }) => css`
    position: relative;

    input::placeholder {
      color: ${theme.palette.background.grey};
    }
  `
)

export const Label = styled(Typography)(
  ({ theme }) => css`
    display: block;
    margin: ${theme.spacing(0, 0, 1)};
  `
)

export const ErrorMessage = styled(({ active, ...props }) => (
  <span {...props} />
))(
  ({ theme, active }: StyledProps<ErrorProps>) => css`
    left: 0;
    font-size: 12px;
    line-height: 1.1em;
    position: absolute;
    opacity: ${active ? 1 : 0};
    transition: all 0.3s ease-out;
    bottom: ${active ? -23 : -10}px;
    color: ${theme.palette.error.main};
  `
)

const FormTextField: React.FC<FormTextFieldProps> = ({
  name,
  label,
  className,
  showErrorString = true,
  ...props
}) => {
  const [field, meta] = useField(name)

  const { value } = field
  const hasError = meta.touched && !!meta.error

  return (
    <StyledContainer className={className}>
      {label && (
        <Label variant="caption" color={hasError ? 'error' : 'textPrimary'}>
          {label}
        </Label>
      )}
      <TextField
        {...field}
        {...props}
        error={hasError}
        variant="outlined"
        value={value || ''}
        InputLabelProps={{ shrink: true }}
      />
      {showErrorString && (
        <ErrorMessage active={hasError}>{meta.error}</ErrorMessage>
      )}
    </StyledContainer>
  )
}

export default React.memo(FormTextField)
