import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'

import useBenchmark from 'src/hooks/useBenchmark'
import {
  DashIcon,
  CircleIcon,
  ScratchIcon,
  PracticeCircleIcon,
} from 'src/components/graph/common'

const I18N_KEY = 'GraphComposedLegend'

interface Props {
  isPracticeDashboard?: boolean
}

const LegendContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;

    ${theme.breakpoints.down(740)} {
      padding-left: ${theme.spacing(4.5)}px;
    }

    ${theme.breakpoints.down('xs')} {
      padding-left: ${theme.spacing(2)}px;
    }
  `
)

const LegendText = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(0, 2, 1, 0)};
    }
  `
)

const Legend: React.FC<Props> = ({ isPracticeDashboard }) => {
  const { benchmarkName, referencePoint } = useBenchmark()

  return (
    <LegendContainer>
      <LegendText variant="caption" color="textSecondary">
        <DashIcon $isScratch />
        {benchmarkName}
      </LegendText>
      <LegendText variant="caption" color="textSecondary">
        <ScratchIcon />
        {referencePoint} (0.0)
      </LegendText>
      <LegendText variant="caption" color="textSecondary">
        <DashIcon />
        <Trans i18nKey={`${I18N_KEY}.rollingAverage`}>Rolling Average</Trans>
      </LegendText>
      {isPracticeDashboard ? (
        <>
          <LegendText variant="caption" color="textSecondary">
            <CircleIcon />
            <Trans i18nKey={`${I18N_KEY}.rounds`}>Rounds</Trans>
          </LegendText>
          <LegendText variant="caption" color="textSecondary">
            <PracticeCircleIcon />
            <Trans i18nKey={`${I18N_KEY}.practice`}>Practice</Trans>
          </LegendText>
        </>
      ) : (
        <LegendText variant="caption" color="textSecondary">
          <CircleIcon />
          <Trans i18nKey={`${I18N_KEY}.you`}>You</Trans>
        </LegendText>
      )}
    </LegendContainer>
  )
}

export default Legend
