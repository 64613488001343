import React from 'react'

interface Props {
  color?: string
}

const GoalIcon: React.FC<Props> = ({ color = '#F6A609', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 11 14"
    {...props}
  >
    <path
      fill="#FBF3E4"
      stroke={color}
      strokeWidth="1.233"
      d="M10.383.617v6.716c0 1.045-.585 1.993-1.592 2.955-.835.798-1.882 1.533-2.964 2.293l-.327.23-.327-.23c-1.082-.76-2.129-1.495-2.964-2.293C1.202 9.326.617 8.378.617 7.333V.617h9.766z"
    />
    <path
      fill={color}
      d="M8.942 2.158v4.667c0 1.649-1.802 2.8-3.392 3.966-1.59-1.166-3.392-2.317-3.392-3.966V2.158h6.784z"
    />
  </svg>
)

export default React.memo(GoalIcon)
