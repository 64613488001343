import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useLocation } from 'react-router-dom'

import { Box, Typography } from '@material-ui/core'

import {
  Form,
  Container,
  StyledLink,
  TitleContainer,
} from 'src/modules/auth/common'
import useLogin from 'src/hooks/useLogin'
import { login } from 'src/store/authSlice'
import { LoginError, Routes } from 'src/utils/constants'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { createSignInSchema } from 'src/utils/validationSchemas'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import HookPasswordField from 'src/components/inputs/HookPasswordField'
import useResendEmailVerification from 'src/hooks/useResendEmailVerification'
import { userSelector } from 'src/store/userSlice'
import { useLDClient } from 'launchdarkly-react-client-sdk'

const I18N_KEY = 'SignIn'

interface FormValues {
  email: string
  password: string
}

const ErrorBox = styled.div<{ $error: boolean }>(
  ({ theme, $error }) => css`
    display: flex;
    overflow: hidden;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    height: ${$error ? '65px' : 0};
    color: ${theme.palette.error.main};
    margin: ${$error ? theme.spacing(0, 0, 4) : 0};
    background-color: ${theme.palette.error.light};
    transition: height 0.3s ease-out, margin 0.3s ease-out;
  `
)

const LockedBox = styled.div<{ $locked: boolean }>(
  ({ theme, $locked }) => css`
    display: flex;
    overflow: hidden;
    border-radius: 6px;
    align-items: center;
    flex-direction: column;
    background-color: #f5f5f5;
    height: ${$locked ? '92px' : 0};
    padding: ${$locked ? theme.spacing(1.5) : 0}px;
    margin: ${$locked ? theme.spacing(0, 0, 4) : 0};
    transition: height 0.3s ease-out, margin 0.3s ease-out;

    a,
    button {
      padding: 0;
      border: none;
      cursor: pointer;
      font-weight: 500;
      white-space: nowrap;
      background: transparent;
      text-decoration: underline;
      font-family: ${theme.typography.fontFamily};

      @media (hover: hover) {
        :hover {
          color: ${theme.palette.primary.main};
        }
      }
    }
  `
)

const PasswordField = styled(HookPasswordField)(
  ({ theme }) => css`
    margin: ${theme.spacing(4, 0)};
  `
)

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, transitionDuration: '0.4s' },
}

const SignIn: React.FC = () => {
  const [locked, setLocked] = useState(false)
  const [error, setError] = useState<string>('')

  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isLoggedIn } = useLogin()
  const location = useLocation<{ from: Location }>()

  const { from } = location.state || { from: { pathname: Routes.Dashboard } }

  const validationSchema = useMemo(() => createSignInSchema(t), [t])

  // NOTE: Identify user for Launch Darkly
  const user = useSelector(userSelector)
  const ldClient = useLDClient()
  if (user?.uuid) {
    try {
      ldClient?.identify({
        key: user.uuid,
        email: user.email,
      })
    } catch (error) {
      console.error('LaunchDarkly error', error)
    }
  }

  const { errors, control, formState, getValues, handleSubmit } =
    useForm<FormValues>({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        email: '',
        password: '',
      },
    })
  const { isDirty, isValid, isSubmitting } = formState

  const { resendVerificationEmail } = useResendEmailVerification(
    getValues('email')
  )

  //   TODO: Add proper submit handler
  const onSubmit = handleSubmit(async values => {
    try {
      await dispatch(login(values))
    } catch (error: any) {
      if (error.message === LoginError.UserLocked) {
        setError('')
        setLocked(true)
        resendVerificationEmail()
      } else {
        setLocked(false)
        setError(error.message)
      }
    }
  })

  // If/Once user is authenticated, navigate them to route they came from or the dashboard
  useEffect(() => {
    if (isLoggedIn) {
      history.replace(from)
    }
  }, [from, history, isLoggedIn])

  return (
    <Container {...motionProps}>
      <TitleContainer>
        <Typography variant="h1" align="center">
          <Trans i18nKey={`${I18N_KEY}.title`}>Sign in</Trans>
        </Typography>
        <Box mt={1} component="span" display="flex" justifyContent="center">
          <Typography color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.noAccount`}>
              Don&apos;t have an account?
            </Trans>
          </Typography>
          <StyledLink to={Routes.Register}>
            <Trans i18nKey={`${I18N_KEY}.createAccount`}>Create account</Trans>
          </StyledLink>
        </Box>
      </TitleContainer>
      <Form onSubmit={onSubmit}>
        <ErrorBox $error={!!error}>
          {!!error && (
            <Typography variant="body2">
              {t(`Enums:LoginError.${error}`, '')}
            </Typography>
          )}
        </ErrorBox>
        <LockedBox $locked={locked}>
          <Typography variant="body2" color="error" align="center">
            <Trans i18nKey={`${I18N_KEY}.lockedVerify`}>
              Verify your email to continue
            </Trans>
          </Typography>
          <Typography component="p" variant="h6" align="center">
            <Trans i18nKey={`${I18N_KEY}.notReceived`}>
              If you didn&apos;t receive the email{' '}
              <button type="button" onClick={resendVerificationEmail}>
                Resend
              </button>{' '}
              or{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="mailto:support@drawmorecircles.com"
              >
                Contact support
              </a>
            </Trans>
          </Typography>
        </LockedBox>
        <HookFormTextField
          name="email"
          type="email"
          errors={errors}
          control={control}
          label={t(`${I18N_KEY}.emailLabel`, 'Email')}
          placeholder={t(`${I18N_KEY}.emailPlaceholder`, 'Your email')}
        />
        <PasswordField
          name="password"
          errors={errors}
          control={control}
          label={t(`${I18N_KEY}.passwordLabel`, 'Password')}
          placeholder={t(`${I18N_KEY}.passwordPlaceholder`, '8+ characters')}
        />
        <LoaderButton
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}
          disabled={!isDirty || !isValid}
        >
          <Trans i18nKey={`${I18N_KEY}.signInButtonLabel`}>Sign in</Trans>
        </LoaderButton>
        <Box mt={4} display="flex" justifyContent="center">
          <StyledLink to={Routes.ForgotPassword}>
            <Trans i18nKey={`${I18N_KEY}.forgotPass`}>Forgot password</Trans>
          </StyledLink>
        </Box>
      </Form>
    </Container>
  )
}

export default SignIn
