import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimatePresence } from 'framer-motion'
import { yupResolver } from '@hookform/resolvers/yup'

import { useAppDispatch } from 'src/store'
import { resetPassword } from 'src/store/authSlice'
import ResetPasswordForm from 'src/modules/auth/ResetPasswordForm'
import { createResetPasswordSchema } from 'src/utils/validationSchemas'
import { PASSWORD_RESET_TOKEN_PARAM_NAME, Routes } from 'src/utils/constants'
import { openToast, getSuccessToast, getErrorToast } from 'src/store/toastSlice'

const I18N_KEY = 'ResetPassword'

export interface FormValues {
  newPassword: string
  passwordConfirm: string
  token: string
}

const ResetPassword: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const token =
    new URLSearchParams(window.location.search).get(
      PASSWORD_RESET_TOKEN_PARAM_NAME
    ) || ''
  const history = useHistory()

  const validationSchema = useMemo(() => createResetPasswordSchema(t), [t])

  const { formState, control, handleSubmit } = useForm<FormValues>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      token,
      newPassword: '',
      passwordConfirm: '',
    },
  })

  const handleFormSubmission = handleSubmit(
    async ({ token, newPassword }: FormValues) => {
      try {
        await dispatch(resetPassword({ token, newPassword }))
        dispatch(
          openToast(
            getSuccessToast(
              t(
                `${I18N_KEY}.passwordResetSuccess`,
                'Your password has been reset'
              )
            )
          )
        )
        history.push(Routes.SignIn)
      } catch (error: any) {
        dispatch(
          openToast(
            getErrorToast(
              t(
                `${I18N_KEY}.passwordResetError`,
                'An error occurred. Please request a new password reset link'
              )
            )
          )
        )
      }
    }
  )

  if (!token) {
    dispatch(
      openToast(
        getErrorToast(
          t(`${I18N_KEY}.missingToken`, 'Invalid password reset token')
        )
      )
    )
    history.push(Routes.SignIn)
    return null
  }

  return (
    <AnimatePresence>
      <ResetPasswordForm
        key="reset-password-form"
        onSubmit={handleFormSubmission}
        control={control}
        {...formState}
      />
    </AnimatePresence>
  )
}

export default ResetPassword
