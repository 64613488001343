import React from 'react'

import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'

const SlideTransition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />
  }
)

SlideTransition.displayName = 'SlideTransition'

export default SlideTransition
