import React, { useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import Cropper, { ReactCropperElement } from 'react-cropper'

import {
  Button,
  Dialog,
  Typography,
  DialogContent,
  DialogActions,
} from '@material-ui/core'

import LoaderButton from 'src/components/inputs/LoaderButton'

import 'cropperjs/dist/cropper.css'

const I18N_KEY = 'CropDialog'

interface CropDialogProps {
  open: boolean
  file: File | null
  onClose: () => void
  onSave: (file: File) => Promise<void>
  previewSrc: string | ArrayBuffer | null
}

const StyledCropper = styled(Cropper)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-width: 290px;
    max-width: 500px;
    min-height: 290px;
    max-height: 500px;
    border-radius: 6px;

    .cropper-crop-box {
      border-radius: 50%;
      border: 2px solid ${theme.palette.primary.main};
    }

    .cropper-view-box {
      outline: 0;
      border-radius: 50%;
      box-shadow: 0 0 0 1px ${theme.palette.primary.main};
    }
  `
)

const StyledLoaderButton = styled(LoaderButton)`
  width: 64px;
`

const CropDialog: React.FC<CropDialogProps> = ({
  file,
  open,
  onSave,
  onClose,
  previewSrc,
}) => {
  const [saving, setSaving] = useState(false)
  const [croppedFile, setCroppedFile] = useState<File | null>(file)

  const cropperRef = useRef<ReactCropperElement>(null)

  const handleCrop = () => {
    if (file) {
      cropperRef.current?.cropper.getCroppedCanvas().toBlob(blob => {
        const newFile = blob
          ? new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            })
          : null

        setCroppedFile(newFile)
      }, file.type)
    }
  }

  const handleSaveImage = async () => {
    if (croppedFile) {
      setSaving(true)
      await onSave(croppedFile as File)
      setSaving(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="crop-dialog-title">
      <DialogContent>
        <Typography gutterBottom variant="h4" id="crop-dialog-title">
          <Trans i18nKey={`${I18N_KEY}.title`}>Save image</Trans>
        </Typography>
        <StyledCropper
          guides
          center
          movable
          autoCrop
          viewMode={3}
          cropBoxMovable
          dragMode="move"
          ref={cropperRef}
          autoCropArea={1}
          highlight={false}
          crop={handleCrop}
          minCropBoxWidth={1}
          minCropBoxHeight={1}
          cropend={handleCrop}
          cropmove={handleCrop}
          initialAspectRatio={1}
          cropBoxResizable={false}
          src={previewSrc as string}
          toggleDragModeOnDblclick={false}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans i18nKey={`${I18N_KEY}.close`}>Close</Trans>
        </Button>
        <StyledLoaderButton
          color="primary"
          loading={saving}
          variant="contained"
          onClick={handleSaveImage}
        >
          <Trans i18nKey={`${I18N_KEY}.save`}>Save</Trans>
        </StyledLoaderButton>
      </DialogActions>
    </Dialog>
  )
}

export default CropDialog
