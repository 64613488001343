import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useBenchmark from './useBenchmark'

export const usePerformanceIndicatorColumns = (
  I18N_KEY: string,
  isSingleRound: boolean
) => {
  const { t } = useTranslation()
  const { benchmarkName } = useBenchmark()

  const columns = useMemo(
    () => [
      t(`${I18N_KEY}.performanceIndicators`, 'Performance Indicators'),
      isSingleRound
        ? t(`${I18N_KEY}.round`, 'This Round')
        : t(`${I18N_KEY}.rounds`, 'Rounds'),
      benchmarkName as string,
      t(`${I18N_KEY}.gap`, 'Gap'),
    ],
    [t, I18N_KEY, isSingleRound, benchmarkName]
  )

  return columns
}
