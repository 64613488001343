import React from 'react'
import { LinkProps, Link as ReactRouterLink } from 'react-router-dom'
import { localizeLocationDescriptor } from 'src/utils/i18n'
import { useTranslation } from 'react-i18next'
import { Languages } from 'src/utils/constants'

export const Link: React.FC<LinkProps> = ({ to, ...rest }) => {
  const { i18n } = useTranslation()
  return (
    <ReactRouterLink
      {...rest}
      to={localizeLocationDescriptor(to, i18n.language as Languages)}
    />
  )
}

export default Link
