import React from 'react'
import { useLocation } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { motion, AnimateSharedLayout } from 'framer-motion'

import Typography from '@material-ui/core/Typography'

import { NavLink } from 'src/components/navigation/NavLink'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'
import { Routes } from '../../utils/constants'

interface Route {
  label: string
  route: string
  external?: boolean
}

interface Props {
  routes: Route[]
}

const Nav = styled.nav(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-left: ${theme.spacing(4)}px;

    .active-link {
      p {
        color: ${theme.palette.primary.light};
      }
    }
  `
)

const linkCSS = css`
  ${({ theme }) => `
  display: block;
  position: relative;
  padding: ${theme.spacing(1, 2)};
  margin-right: ${theme.spacing(4)}px;

  p {
    transition: color 0.3s ease-out;
    color: ${theme.palette.text.white};

    &:hover {
      color: ${theme.palette.primary.light};
    }
  }
`}
`

const NavItem = styled(NavLink)`
  ${linkCSS}
`

const ExternalLink = styled.a`
  ${linkCSS}
`

const StyledActiveBar = styled(ActiveBar)(
  ({ theme }) => css`
    background-color: ${theme.palette.background.blueHighlight};
  `
)

const DesktopNavigation: React.FC<Props> = ({ routes }) => {
  const { pathname } = useLocation()

  const applicationNav = (route: string, label: string) => {
    if (route.includes(Routes.Activity)) {
      return (
        <NavItem
          to={route}
          isActive={() => pathname.includes(Routes.Activity)}
          activeClassName="active-link"
        >
          <Typography variant="h5" component="p">
            {label}
          </Typography>
          {pathname.includes(Routes.Activity) && (
            <StyledActiveBar layoutId="nav-item" />
          )}
        </NavItem>
      )
    } else if (route.includes(Routes.Insight)) {
      return (
        <NavItem
          to={route}
          isActive={() => pathname.includes(Routes.Insight)}
          activeClassName="active-link"
        >
          <Typography variant="h5" component="p">
            {label}
          </Typography>
          {pathname.includes(Routes.Insight) && (
            <StyledActiveBar layoutId="nav-item" />
          )}
        </NavItem>
      )
    } else {
      return (
        <NavItem to={route} exact activeClassName="active-link">
          <Typography variant="h5" component="p">
            {label}
          </Typography>
          {pathname === route && <StyledActiveBar layoutId="nav-item" />}
        </NavItem>
      )
    }
  }

  return (
    <AnimateSharedLayout>
      <Nav>
        {routes.map(({ route, label, external }, index) => (
          <motion.div
            key={route}
            animate={{ y: 0, opacity: 1, rotateX: 0 }}
            initial={{ y: -15, opacity: 0, rotateX: -40 }}
            transition={{ delay: 0.2 * index, duration: 0.4 }}
          >
            {external ? (
              <ExternalLink
                target="_blank"
                rel="noopener noreferrer"
                href={route}
              >
                <Typography variant="h5" component="p">
                  {label}
                </Typography>
              </ExternalLink>
            ) : (
              applicationNav(route, label)
            )}
          </motion.div>
        ))}
      </Nav>
    </AnimateSharedLayout>
  )
}

export default DesktopNavigation
