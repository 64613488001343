import React from 'react'
import { useSelector } from 'react-redux'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Box, Typography } from '@material-ui/core'

import useBenchmark from 'src/hooks/useBenchmark'
import ScoreGraph from 'src/components/graph/score'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import CategoryError from 'src/modules/categories/CategoryError'
import { summaryScoreSelector, SummaryStatus } from 'src/store/summarySlice'

const I18N_KEY = 'Score'

interface ScoreProps {
  summaryStatus: SummaryStatus
}

const TitleContainer = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const Score: React.FC<ScoreProps> = ({ summaryStatus }) => {
  const { referencePoint } = useBenchmark()
  const data = useSelector(summaryScoreSelector)

  const getContent = () => {
    switch (summaryStatus) {
      case SummaryStatus.Loading:
        return (
          <Skeleton variant="rect" width="100%" height={350} animation="wave" />
        )
      case SummaryStatus.Valid:
        if (data) {
          return (
            <ScoreGraph
              data={data}
              id="player-dash-score"
              referencePoint={referencePoint}
            />
          )
        }
        return (
          <Skeleton variant="rect" width="100%" height={350} animation="wave" />
        )
      default:
        return <CategoryError summaryStatus={summaryStatus} />
    }
  }

  return (
    <Box mb={8}>
      <TitleContainer>
        <Typography variant="h3">
          <Trans i18nKey={`${I18N_KEY}.score`}>Score to Par</Trans>
        </Typography>
        <Typography component="p" variant="caption" color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.vsPar`}>vs Par</Trans>
        </Typography>
      </TitleContainer>
      {getContent()}
    </Box>
  )
}

export default Score
