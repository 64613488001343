import React, { useState, MouseEvent } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Menu from '@material-ui/core/Menu'
import MuiPaper from '@material-ui/core/Paper'
import MuiMenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import MuiIconButton from '@material-ui/core/IconButton'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import useBreakpoints from 'src/hooks/useBreakpoints'
import UnconnectedPlayer from 'src/modules/coach-dash/player/UnconnectedPlayer'

const I18N_KEY = 'UnregisteredPlayer'

interface Props {
  email: string
  inviteUuid: string
  openDeleteDialog: (uuid: string) => void
}

const Paper = styled(MuiPaper)`
  position: relative;
`

const IconButton = styled(MuiIconButton)`
  top: 8px;
  right: 16px;
  position: absolute;
`

const MenuItem = styled(MuiMenuItem)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
  `
)

const CardHeader = styled.div(
  ({ theme }) =>
    css`
      display: flex;
      height: 22.45em;
      align-items: center;
      flex-direction: column;
      padding: ${theme.spacing(6, 3, 4)};
    `
)

const Avatar = styled.div(
  ({ theme }) =>
    css`
      width: 120px;
      height: 120px;
      border-radius: 50%;
      margin: ${theme.spacing(0, 0, 2.5)};
      border: 2px dashed ${theme.palette.background.grey};
    `
)

const UnregisteredPlayer: React.FC<Props> = ({
  email,
  inviteUuid,
  openDeleteDialog,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { isSmallScreen } = useBreakpoints()

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const handleRemovePlayer = () => {
    openDeleteDialog(inviteUuid)
    closeMenu()
  }

  return (
    <Paper elevation={0}>
      <IconButton onClick={openMenu}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleRemovePlayer}>
          <Trans i18nKey={`${I18N_KEY}.removePlayer`}>Remove Player</Trans>
        </MenuItem>
      </Menu>
      <CardHeader>
        <Avatar />
        <Typography variant="body2">{email}</Typography>
        <Typography color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.pending`}>
            Pending Account Creation
          </Trans>
        </Typography>
      </CardHeader>
      {!isSmallScreen && <UnconnectedPlayer />}
    </Paper>
  )
}

export default UnregisteredPlayer
