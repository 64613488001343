import { useMediaQuery, useTheme } from '@material-ui/core'

const useBreakpoints = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up(900))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return { theme, isDesktop, useMediaQuery, isSmallScreen }
}

export default useBreakpoints
