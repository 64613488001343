import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import { SummaryStatus } from 'src/store/summarySlice'

const I18N_KEY = 'ImprovementError'

interface Props {
  hasAddedFiveRounds: boolean
  summaryStatus: SummaryStatus
}

const Card = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(16, 4)};
  `
)

const ErrorText = styled(Typography)`
  max-width: 600px;
`

const ImprovementError: React.FC<Props> = ({
  summaryStatus,
  hasAddedFiveRounds,
}) => {
  const errorMessage = useMemo(() => {
    switch (summaryStatus) {
      case SummaryStatus.NoFilterRounds:
        return (
          <Trans i18nKey={`${I18N_KEY}.noFilterRoundsErrorMessage`}>
            You have no rounds matching this filter.
          </Trans>
        )
      case SummaryStatus.NoRoundsError:
        return (
          <Trans i18nKey={`${I18N_KEY}.noRoundsErrorErrorMessage`}>
            Once you’ve entered round data this area will show you how you are
            performing using the latest golf analysis method, strokes gained.
          </Trans>
        )
      case SummaryStatus.Error:
        return (
          <Trans i18nKey={`${I18N_KEY}.genericError`}>
            Something went wrong trying to get your Improvement Opportunities
            information.
          </Trans>
        )
      default:
        return hasAddedFiveRounds ? (
          <Trans i18nKey={`${I18N_KEY}.moreThanFiveRounds`}>
            Improvement Opportunities will be suggested every five rounds.
            Alternatively, you can add these from the shot category detail view.
          </Trans>
        ) : (
          <Trans i18nKey={`${I18N_KEY}.lessThanFiveRounds`}>
            Improvement Opportunities are the areas of your game that, if
            improved, will have the biggest impact on your score. After you have
            entered 5 rounds we’ll automatically find improvement opportunities
            for you.
          </Trans>
        )
    }
  }, [summaryStatus, hasAddedFiveRounds])

  return (
    <Card elevation={0}>
      {summaryStatus === SummaryStatus.NoFilterRounds ? (
        <>
          <ErrorText color="textSecondary" variant="h4" align="center">
            {errorMessage}
          </ErrorText>
          <ErrorText color="textSecondary" align="center">
            <Trans i18nKey={`${I18N_KEY}.secondaryErrorMessage`}>
              Please select another.
            </Trans>
          </ErrorText>
        </>
      ) : (
        <ErrorText color="textSecondary" align="center">
          {errorMessage}
        </ErrorText>
      )}
    </Card>
  )
}

export default ImprovementError
