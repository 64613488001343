import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import MuiContainer from '@material-ui/core/Container'
import { breakpoints } from 'src/utils/constants'
import { useTranslation } from 'react-i18next'
import RadarComparison from 'src/components/dataDisplay/RadarComparison'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import SubCategoryTable from 'src/modules/rounds/summary/SubCategoryTable'
import { CategoryType, perShotMetrics, Round } from 'src/utils/golfConstants'
import ScoreDetails from './ScoreDetails'
import Statistics from './Statistics'
import useBenchmark from 'src/hooks/useBenchmark'

const I18N_KEY = 'RoundsSummary'

interface Props {
  roundData: Round
}

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    padding-top: ${theme.spacing(5)}px;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${theme.spacing(5, 2, 0)};
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 0, 0)};
    }
  `
)

const StyledTitle = styled(ReferencedTitle)(
  ({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const Comparison = styled(RadarComparison)(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0, 6)};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      margin: ${theme.spacing(2, 0, 4)};
    }
  `
)

/*
 * Duplicate of rounds summary for Partner API implementation
 * TODO: Refactor to remove duplication and allow partner API to use existing code
 * The duplication allows up to easily remove some UI elements and make our own request
 * to the backend partner's API endpoint
 */
const RoundSummary: React.FC<Props> = ({ roundData }) => {
  const { t } = useTranslation()

  const { benchmarkName } = useBenchmark()

  const strokesGained = roundData?.summary?.strokesGained
  const radarData = [
    {
      reference: 0,
      category: CategoryType.Drives,
      overall: strokesGained?.drives?.overall,
      benchmark: strokesGained?.drives?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Approach,
      overall: strokesGained?.approach?.overall,
      benchmark: strokesGained?.approach?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Short,
      overall: strokesGained?.short?.overall,
      benchmark: strokesGained?.short?.benchmark,
    },
    {
      reference: 0,
      category: CategoryType.Putt,
      overall: strokesGained?.putt?.overall,
      benchmark: strokesGained?.putt?.benchmark,
    },
  ]

  const performanceIndicators = useMemo(
    () =>
      roundData?.summary?.performanceIndicators.filter(
        ({ metricId }) => !perShotMetrics.includes(metricId)
      ),
    [roundData]
  )

  return (
    <>
      <ScoreDetails
        benchmarkName={benchmarkName}
        score={roundData?.summary?.score}
        strokesGained={roundData?.summary?.strokesGained?.total}
      />
      <Container>
        <StyledTitle title={t(`${I18N_KEY}.title`, 'Comparison To Goal')} />
        <Comparison
          isRoundSummary
          data={radarData}
          benchmarkName={benchmarkName}
        />
        <SubCategoryTable categories={roundData?.summary?.categories || []} />
        <Statistics performanceIndicators={performanceIndicators || []} />
      </Container>
    </>
  )
}

export default RoundSummary
