// Time to live: 3 days in milliseconds
const TTL = 259200000

export const setItemWithExpiry = <T>(
  key: string,
  value: T,
  ttl: number = TTL
) => {
  const now = new Date()

  const item = {
    value,
    expiry: now.getTime() + ttl,
  }

  localStorage.setItem(key, JSON.stringify(item))
}

export const getItemWithExpiry = (key: string) => {
  const jsonString = localStorage.getItem(key)

  if (!jsonString) {
    return null
  }
  const now = new Date()
  const item = JSON.parse(jsonString)

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }

  return item.value
}

export const clearLocal = () => {
  localStorage.clear()
}
