import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import {
  authRequestFail,
  authRequestSuccess,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { hashPassword } from 'src/utils/crypto'
import { RootState } from 'src/store/rootReducer'
import { getRoundCount, getRoundLastUpdated } from 'src/store/roundSlice'
import { getItemWithExpiry } from 'src/utils/localStorage'
import { getCoach, coachSelector } from 'src/store/coachSlice'
import { getCoachPlayers } from 'src/store/coachOverviewSlice'
import { getUser, userTypeSelector } from 'src/store/userSlice'
import { getSubscriptionInfo } from 'src/store/subscriptionSlice'
import { getPlayer, playerSelector } from 'src/store/playerSlice'
import {
  StorageKeys,
  LoginError,
  UserType,
  DEPLOYMENT_LOCATION,
  isChina,
} from 'src/utils/constants'
import { PlanType, SubscriptionStatus } from 'src/utils/subscriptionConstants'
import { identifyUser } from 'src/utils/analytics'
import { getPractices } from './practiceSlice'
import { getInvitations } from './invitationSlice'
import { getCourseStrategiesCompletedCount } from './courseStrategySlice'
import { Plan } from 'src/models/plan'
import { planSelector } from './planSlice'
import { ChinaPaymentMethod } from 'src/components/subscriptionFlow/types'
import { getSummary } from './summarySlice'

interface InitialState {
  token: string
  refreshToken: string
  showVerifyDialog: boolean
  refreshStatus: RefreshStatus
  requiresImmediatePayment: boolean // TODO: Temporary for china 1 month experiment
}

export enum RefreshStatus {
  Valid = 'valid',
  Expired = 'expired',
  Initialising = 'initialising',
}

const planTypeMap = (plan?: PlanType): string => {
  const plans = {
    free: 'Free',
    lite: 'Benchmark',
    premium: 'Premium',
    premiumnew: 'Pro',
    premiumplus: 'Elite',
    insight: 'Insight',
    train: 'Train',
    play: 'Play',
    consult: 'Consult',
  }

  return plans[plan as keyof typeof plans] || 'Evaluate'
}

type TokenPayload = Pick<InitialState, 'token' | 'refreshToken'>

type RefreshPayload = Pick<InitialState, 'refreshStatus'>

interface LoginRequest {
  email?: string
  password?: string
  providerCode?: string
}

const initialState: InitialState = {
  showVerifyDialog: false,
  refreshStatus: RefreshStatus.Initialising,
  token: getItemWithExpiry(StorageKeys.Token) || '',
  refreshToken: getItemWithExpiry(StorageKeys.RefreshToken) || '',
  requiresImmediatePayment: false,
}

// getting user data for analytics
const collectUserData = async (currentState: RootState, isPlayer: boolean) => {
  const {
    user: { email, metadata },
    player,
    round,
    subscription,
    invitation,
    coach,
    practice,
    courseStrategy,
  } = currentState

  const plan = subscription.data?.plan
  const { uuid, firstName, lastName, dob, gender, phoneNumber } = isPlayer
    ? playerSelector(currentState)
    : coachSelector(currentState)

  let dataToTrack: any = {
    uuid,
    email,
    gender,
    lastName,
    firstName,
    phoneNumber,
    userType: metadata?.userType,
    language: metadata?.language,
    dob: String(dob).substr(0, 10), // to remove timestamp from ISO date string
    accountType: plan
      ? subscription.data?.isEvaluationPeriod
        ? 'evaluation'
        : plan.planType
      : PlanType.Free,
    products: [
      {
        category: 'Subscriptions',
        product_id: subscription.data?.plan?.planStripeUuid,
        name: `${planTypeMap(subscription.data?.plan?.planType)} (${
          subscription.data?.plan?.duration
        })`,
      },
    ],
    subscriptionProduct: subscription.data?.plan?.planStripeUuid,
    subscriptionStatus: subscription.data?.status,
    isTrial: subscription.data?.status === SubscriptionStatus.Trialing,
    lineOfBusiness: DEPLOYMENT_LOCATION,
  }
  if (isPlayer) {
    const { playerType, benchmarkId } = player
    const coaches = (invitation.data || []).map(coach => ({ name: coach.name }))
    dataToTrack = {
      ...dataToTrack,
      coaches,
      coachesConnectedCount: coaches.length,
      playerType,
      benchmarkId,
      roundCount: round.totalRounds,
      country: subscription.data?.billing?.country,
      paymentType: subscription.data?.plan?.duration,
      practiceLogCount: practice.totalSubmitted,
      strategyCount: courseStrategy.totalCourseStrategiesCompleted,
    }
  } else {
    // User is not a player
    if (metadata?.userType === 'coach') {
      const { player } = coach
      if (player) {
        const players = player.map((playerObj: any) => ({
          name: `${playerObj?.first_name} ${playerObj?.last_name}`,
          email: playerObj?.email,
        }))
        if (players) {
          dataToTrack = {
            ...dataToTrack,
            players,
            playersConnectedCount: players.length,
          }
        }
      }
    }
  }

  // Frontend tracking required to group events by user
  identifyUser(uuid, dataToTrack)
  // Track on backend incase a user has a browser that blocks segment
  try {
    // eslint-disable-next-line no-console
    // console.log('SEGMENT: Sending payload to backend', dataToTrack.uuid)
    await api.post('user/track', dataToTrack)
  } catch (error: any) {
    console.error('SEGMENT: error', error)
  }
}

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateRefreshStatus: (state, action: PayloadAction<RefreshPayload>) => {
      const { refreshStatus } = action.payload

      state.refreshStatus = refreshStatus
    },
    addAuthTokens: (state, action: PayloadAction<TokenPayload>) => {
      const { token, refreshToken } = action.payload

      state.token = token
      state.refreshToken = refreshToken
      state.refreshStatus = RefreshStatus.Valid
    },
    updateVerifyDialog: (
      state,
      action: PayloadAction<Pick<InitialState, 'showVerifyDialog'>>
    ) => {
      state.showVerifyDialog = action.payload.showVerifyDialog
    },
    updateRequiresImmediatePayment: (
      state,
      action: PayloadAction<Pick<InitialState, 'requiresImmediatePayment'>>
    ) => {
      state.requiresImmediatePayment = action.payload.requiresImmediatePayment
    },
    reinitialiseAuth: () => ({
      token: '',
      refreshToken: '',
      isFirstLogin: false,
      showVerifyDialog: false,
      refreshStatus: RefreshStatus.Expired,
      requiresImmediatePayment: false,
    }),
  },
})

export const {
  addAuthTokens,
  reinitialiseAuth,
  updateVerifyDialog,
  updateRefreshStatus,
  updateRequiresImmediatePayment,
} = actions
export default reducer

// Selectors
const authSelector = (state: RootState) => state.auth

export const isLoggedInSelector = createSelector(
  authSelector,
  auth => !!auth.token
)

export const refreshStatusSelector = createSelector(
  authSelector,
  auth => auth.refreshStatus
)

export const authTokensSelector = createSelector(
  authSelector,
  ({ token, refreshToken }) => ({ token, refreshToken })
)

export const verifyDialogSelector = createSelector(
  authSelector,
  ({ showVerifyDialog }) => showVerifyDialog
)

export const immediatePaymentSelector = createSelector(
  authSelector,
  ({ requiresImmediatePayment }) => requiresImmediatePayment
)

// Action Creators
export const login =
  (
    { password, ...values }: LoginRequest,
    showVerifyDialog: boolean = true
  ): AppThunk =>
  async (dispatch, getState) => {
    try {
      const response = await api.post('user/login', {
        ...values,
        password: hashPassword(password),
      })
      const { token, refreshToken } = getSinglePayloadFromResponse(response)
      authRequestSuccess(token, refreshToken)

      const requiresPayment = immediatePaymentSelector(getState())
      const oneMonthPlan: Plan | null = planSelector(getState())

      if (isChina && requiresPayment && oneMonthPlan) {
        const goToPaymentPortal = async () => {
          let url
          try {
            const response = await api.get(
              `subscription/${ChinaPaymentMethod.Alipay}/url/${oneMonthPlan.planStripeUuid}`
            )
            url = getSinglePayloadFromResponse(response)
            window.location.href = url
          } catch (e) {
            console.error('Failed to redirect to Alipay', e)
          }
        }
        goToPaymentPortal()
      } else {
        // Fetch User before setting tokens as
        // we navigate to dashboard once those are set
        await dispatch(getUser())
        const userType = userTypeSelector(getState())
        const isPlayer = userType === UserType.Player

        if (isPlayer) {
          await Promise.all([
            dispatch(getPlayer()),
            dispatch(getRoundCount()),
            dispatch(getRoundLastUpdated()),
            dispatch(getSubscriptionInfo()),
            dispatch(getSummary()),
            dispatch(getInvitations()),
            dispatch(getCourseStrategiesCompletedCount()),
          ])
        } else {
          await Promise.all([dispatch(getCoach()), dispatch(getCoachPlayers())])
        }

        dispatch(addAuthTokens({ token, refreshToken }))

        await dispatch(getPractices())
        const updatedState = getState()

        if (showVerifyDialog && !updatedState.user.userVerified) {
          dispatch(updateVerifyDialog({ showVerifyDialog: true }))
        }

        // identify users in Segment when they log in
        await collectUserData(updatedState, isPlayer)
        try {
          ;(window as any).profitwell('user_email', updatedState.user.email)
        } catch {
          console.error('Profitwell error updating user email')
        }
      }
    } catch (error: any) {
      const status = error.response?.status

      if (status === 400 || status === 404) {
        throw new Error(LoginError.IncorrectDetails)
      } else if (
        status === 403 &&
        error.response?.data?.message === LoginError.UserLocked
      ) {
        throw new Error(LoginError.UserLocked)
      } else {
        throw new Error(LoginError.default)
      }
    }
  }

export const requestRefreshSession =
  (storedRefreshToken: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(updateRefreshStatus({ refreshStatus: RefreshStatus.Initialising }))

    try {
      const response = await api.post('user/refresh', {
        refreshToken: storedRefreshToken,
      })
      const { token, refreshToken } = getSinglePayloadFromResponse(response)
      authRequestSuccess(token, refreshToken)

      // Fetch User before setting tokens as
      // we navigate to dashboard once those are set
      await dispatch(getUser())
      const state = getState()
      const { uuid } = playerSelector(state)
      const userType = userTypeSelector(state)
      const isPlayer = userType === UserType.Player

      if (isPlayer) {
        await Promise.all([
          dispatch(getPlayer()),
          dispatch(getRoundCount()),
          dispatch(getRoundLastUpdated()),
          dispatch(getSubscriptionInfo()),
          dispatch(getSummary()),
          dispatch(getInvitations()),
          dispatch(getCourseStrategiesCompletedCount()),
        ])
      } else {
        const requests = [dispatch(getCoach())]
        if (uuid) {
          requests.push(dispatch(getPlayer(uuid)))
        }
        await Promise.all(requests)
      }

      dispatch(addAuthTokens({ token, refreshToken }))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
    }
  }

export const logout = (): AppThunk => async dispatch => {
  try {
    await api.post('user/logout')
  } catch (error: any) {
    console.warn(error)
  } finally {
    authRequestFail(dispatch)
  }
}

interface ResetPasswordRequestBody {
  email: string
}
export const requestResetPassword =
  (body: ResetPasswordRequestBody): AppThunk =>
  async () =>
    api.post('user/password/reset', body)

interface ResetPasswordBody {
  token: string
  newPassword: string
}
export const resetPassword =
  ({ token, newPassword }: ResetPasswordBody): AppThunk =>
  async () => {
    api.put('user/password/reset', {
      token,
      newPassword: hashPassword(newPassword),
    })
  }
