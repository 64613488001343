import React, { MouseEvent, useMemo, useState } from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CheckIcon from '@material-ui/icons/Check'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import {
  CategoryType,
  improvementOpportunities,
  MetricId,
} from 'src/utils/golfConstants'
import useIO from 'src/hooks/useIO'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
  userPlanSelector,
} from 'src/store/subscriptionSlice'
import useAppState from 'src/hooks/useAppState'
import { breakpoints, TimeFilter } from 'src/utils/constants'
import useBreakpoints from 'src/hooks/useBreakpoints'
import useRoundFilters from 'src/hooks/useRoundFilters'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import CategoryError from 'src/modules/categories/CategoryError'
import DetailedGraph from 'src/modules/categories/DetailedGraph'
import UpgradeDialog from 'src/components/membership/UpgradeDialog'
import OpportunitySwap from 'src/components/feedback/OpportunitySwap'
import OpportunityDelete from 'src/components/feedback/OpportunityDelete'
import { SummarySingleItem, SummaryStatus } from 'src/store/summarySlice'
import CategoryFilter from './CategoryFilter'

const I18N_KEY = 'CategoryHeader'

interface Props {
  isValid: boolean
  isLoading: boolean
  metricId: MetricId
  category: CategoryType
  data: SummarySingleItem
  summaryStatus: SummaryStatus
}

const Header = styled.div<{
  $addPagePadding: boolean
  $addCustomFilterPadding: boolean
}>(
  ({ theme, $addPagePadding, $addCustomFilterPadding }) => css`
    padding: ${$addPagePadding
      ? theme.spacing(16, 0, 4)
      : theme.spacing(10, 0, 4)};
    background-color: ${theme.palette.background.paper};

    ${theme.breakpoints.down(breakpoints.mobile)} {
      padding: ${$addPagePadding
        ? `${
            $addCustomFilterPadding
              ? theme.spacing(25, 0, 4)
              : theme.spacing(20, 0, 4)
          }`
        : `${
            $addCustomFilterPadding
              ? theme.spacing(19, 0, 4)
              : theme.spacing(14, 0, 4)
          }`};
    }

    ${theme.breakpoints.down('xs')} {
      padding: ${$addPagePadding
        ? `${
            $addCustomFilterPadding
              ? theme.spacing(21, 0, 4)
              : theme.spacing(16, 0, 4)
          }`
        : `${
            $addCustomFilterPadding
              ? theme.spacing(15, 0, 4)
              : theme.spacing(10, 0, 4)
          }`};
    }
  `
)

const TitleContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
    }
  `
)

const CategoryHeader: React.FC<Props> = ({
  data,
  isValid,
  category,
  metricId,
  isLoading,
  summaryStatus,
}) => {
  const {
    metricName,
    swapDialogOpen,
    closeSwapDialog,
    deleteDialogOpen,
    openDeleteDialog,
    closeDeleteDialog,
    onSaveOpportunity,
    savedOpportunities,
  } = useIO(metricId)
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false)

  const { isPlayer } = useAppState()
  const { isDesktop, isSmallScreen } = useBreakpoints()

  const userNotSubscribed = !useSelector(userPlanSelector)
  const playerIsTrialing = useSelector(userIsTrialingSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())

  const addPagePadding = isPlayer && (userNotSubscribed || playerIsTrialing)

  const handleUpgradeDialogClose = () => setUpgradeDialogOpen(false)

  const handleSaveOpportunityRequest = () => {
    if (isPlayerPremium) {
      onSaveOpportunity()
    } else {
      setUpgradeDialogOpen(true)
    }
  }

  const handleDeleteClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isPlayerPremium) {
      openDeleteDialog(event)
    } else {
      setUpgradeDialogOpen(true)
    }
  }

  const { timeFilter } = useRoundFilters(category)

  const isOpportunity = useMemo(
    () => improvementOpportunities.includes(metricId),
    [metricId]
  )

  const isSavedOpportunity = useMemo(
    () =>
      !!savedOpportunities.find(
        opportunity => opportunity?.metricId === metricId
      ),
    [metricId, savedOpportunities]
  )

  return (
    <>
      <Header
        $addPagePadding={addPagePadding}
        $addCustomFilterPadding={timeFilter === TimeFilter.Custom}
      >
        <Container>
          <TitleContainer>
            <Typography variant="h1">{metricName}</Typography>
            {isPlayer && isSavedOpportunity ? (
              <Button
                color="primary"
                onClick={handleDeleteClick}
                startIcon={<CheckCircleIcon />}
              >
                <Trans i18nKey={`${I18N_KEY}.savedAsOpportunityButtonLabel`}>
                  Saved as Opportunity
                </Trans>
              </Button>
            ) : (
              isPlayer &&
              isOpportunity && (
                <Button
                  color="primary"
                  startIcon={<CheckIcon />}
                  onClick={handleSaveOpportunityRequest}
                >
                  <Trans i18nKey={`${I18N_KEY}.saveAsOpportunityButtonLabel`}>
                    Save as Opportunity
                  </Trans>
                </Button>
              )
            )}
          </TitleContainer>
          <CategoryFilter
            category={category}
            isLoading={isLoading}
            isDesktop={isDesktop}
          />
        </Container>
        <Divider />
        {isLoading ? (
          <Container>
            <Skeleton
              width="100%"
              height={350}
              variant="rect"
              animation="wave"
            />
          </Container>
        ) : isValid ? (
          <Container>
            <Box ml={-4}>
              <DetailedGraph
                width="100%"
                payload={data}
                metricId={metricId}
                height={isSmallScreen ? 225 : 325}
              />
            </Box>
          </Container>
        ) : (
          <CategoryError summaryStatus={summaryStatus} />
        )}
        <OpportunitySwap
          open={swapDialogOpen}
          metricId={metricId}
          handleClose={closeSwapDialog}
          opportunities={savedOpportunities}
        />
        <OpportunityDelete
          metricId={metricId}
          open={deleteDialogOpen}
          handleClose={closeDeleteDialog}
        />
      </Header>
      <UpgradeDialog
        open={upgradeDialogOpen}
        onClose={handleUpgradeDialogClose}
        onUpgradeOpen={handleUpgradeDialogClose}
      />
    </>
  )
}

export default React.memo(CategoryHeader)
