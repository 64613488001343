import React from 'react'
import Box from '@material-ui/core/Box'
import { Trans, useTranslation } from 'react-i18next'

import { DashIcon } from 'src/components/graph/common'
import Skeleton from 'src/components/dataDisplay/Skeleton'

import { HeadingContainer } from 'src/components/subscriptionFlow/payment/common'
import { Grid, Typography } from '@material-ui/core'
import { PracticeResult } from 'src/models/practice-summary'
import styled, { css } from 'styled-components'
import PracticeResultGraph from './PracticeResultGraph'
import ErrorCard from 'src/components/dataDisplay/ErrorCard'

const I18N_KEY = 'PracticeResults'

interface Props {
  isLoading: boolean
  practiceResults: PracticeResult[]
}

const LegendContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: ${theme.spacing(1, 0, 0, 0)};

    ${theme.breakpoints.down(740)} {
      padding-left: ${theme.spacing(4.5)}px;
    }

    ${theme.breakpoints.down('xs')} {
      padding-left: ${theme.spacing(2)}px;
    }
  `
)

const LegendText = styled(Typography)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(0, 2, 1, 0)};
    }
  `
)

const PracticeResults: React.FC<Props> = ({ isLoading, practiceResults }) => {
  const { t } = useTranslation()

  return (
    <Box mb={8}>
      <HeadingContainer>
        <Box display="flex" flexDirection="column" justifyContent="flex-start">
          <Typography variant="h3">
            {t(`${I18N_KEY}.practiceResults`, 'Practice Results')}
          </Typography>
          <LegendContainer>
            <LegendText variant="caption" color="textSecondary">
              <DashIcon />
              <Trans i18nKey={`${I18N_KEY}.rounds`}>Rounds</Trans>
            </LegendText>
            <LegendText variant="caption" color="textSecondary">
              <DashIcon $isPracticeSession />
              <Trans i18nKey={`${I18N_KEY}.practice`}>Practice Sessions</Trans>
            </LegendText>
          </LegendContainer>
        </Box>
      </HeadingContainer>
      <Grid container component="div" spacing={4}>
        {isLoading ? (
          Array.from({ length: 3 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={`skeleton-${index}`}>
              <Skeleton
                variant="rect"
                width="100%"
                height={350}
                animation="wave"
              />
            </Grid>
          ))
        ) : practiceResults.length === 0 ? (
          <Grid item xs={12}>
            <ErrorCard
              errorMessage={t(
                `${I18N_KEY}.noPracticeSessionsError`,
                'No practice sessions match your selected filters'
              )}
            />
          </Grid>
        ) : (
          practiceResults.map(
            (practiceResult: PracticeResult, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={`practice-result-${index}`}>
                <PracticeResultGraph payload={practiceResult} />
              </Grid>
            )
          )
        )}
      </Grid>
    </Box>
  )
}

export default PracticeResults
