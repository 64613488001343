import React, { Dispatch, SetStateAction, useMemo } from 'react'
import * as Yup from 'yup'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, FormikConfig, Field } from 'formik'
import { useTranslation, Trans } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import {
  putRound,
  createRound,
  selectedRoundSelector,
} from 'src/store/roundSlice'
import useEnum from 'src/hooks/useEnum'
import { dateTransform } from 'src/utils/helpers'
import useBreakpoints from 'src/hooks/useBreakpoints'
import { Languages, DateFormat } from 'src/utils/constants'
import LoaderButton from 'src/components/inputs/LoaderButton'
import FormTextField from 'src/components/inputs/FormTextField'
import { openToast, getErrorToast } from 'src/store/toastSlice'
import CalendarIcon from 'src/assets/svgComponents/CalendarIcon'
import { RoundType, HolesNumber, WhereType } from 'src/utils/golfConstants'
import { ArrowForward, FormDatePicker } from 'src/components/common'
import FormToggleButton from 'src/components/inputs/FormToggleButton'
import { ResponsiveGridxs2, XsPaddingZeroContainer } from 'src/modules/common'
import { useFlags } from 'launchdarkly-react-client-sdk'

const I18N_KEY = 'RoundDetails'

interface Props {
  isEditDisabled?: boolean
  setCurrentStep: Dispatch<SetStateAction<number>>
}

export interface FormValues {
  datePlayed: Date
  tourName: string
  courseName: string
  roundNumber: string
  roundType: RoundType
  tournamentName: string
  numberOfHoles: HolesNumber
  whereType: WhereType
}

const StyledPaper = styled(Paper)(
  ({ theme }) => css`
    width: 650px;
    padding: ${theme.spacing(6, 4)};
    margin: ${theme.spacing(4, 0, 0)};

    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }

    ${theme.breakpoints.down('xs')} {
      margin: 0;
      padding: ${theme.spacing(4, 2)};
    }
  `
)

const FormNumberInput = styled(FormTextField)`
  .MuiOutlinedInput-root {
    width: 40%;
  }
`

const SectionTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(1)}px;
  `
)

const TypeTitle = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)}px;
  `
)

const ButtonContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacing(6)}px;
    width: 250px;
    ${theme.breakpoints.down('xs')} {
      width: 100%;
    }
  `
)

const PracticeFields: React.FC<{ isEditDisabled: boolean }> = ({
  isEditDisabled,
}) => {
  const { t } = useTranslation()
  return (
    <Grid item xs={12} sm={9}>
      <Box mt={3}>
        <TypeTitle variant="h4">
          <Trans i18nKey={`${I18N_KEY}.practiceFieldsTitle`}>
            Course Information
          </Trans>
        </TypeTitle>
        <FormTextField
          fullWidth
          name="courseName"
          disabled={isEditDisabled}
          label={t(`${I18N_KEY}.courseNameLabel`, 'Course name')}
        />
      </Box>
    </Grid>
  )
}

const TournamentFields: React.FC<{ isEditDisabled: boolean }> = ({
  isEditDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Grid item xs={12} sm={9}>
        <Box mt={3}>
          <TypeTitle variant="h4">
            <Trans i18nKey={`${I18N_KEY}.tournamentFieldsTitle`}>
              Tournament Information
            </Trans>
          </TypeTitle>
          <FormTextField
            fullWidth
            name="tourName"
            disabled={isEditDisabled}
            label={t(`${I18N_KEY}.tourNameLabel`, 'Tour name')}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={9}>
        <FormTextField
          fullWidth
          name="tournamentName"
          disabled={isEditDisabled}
          label={t(`${I18N_KEY}.tournamentNameLabel`, 'Tournament name')}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <FormNumberInput
          fullWidth
          type="number"
          name="roundNumber"
          disabled={isEditDisabled}
          inputProps={{ min: '0' }}
          label={t(`${I18N_KEY}.roundNumberLabel`, 'Round #')}
        />
      </Grid>
    </>
  )
}

const date = new Date()

const CreateRound: React.FC<Props> = ({
  setCurrentStep,
  isEditDisabled = false,
}) => {
  const { t, i18n } = useTranslation()
  const { isSmallScreen } = useBreakpoints()
  const round = useSelector(selectedRoundSelector)
  const { teeLayup } = useFlags()

  const uuid = round?.uuid

  const tourName = round?.tourName || ''
  const courseName = round?.courseName || ''
  const datePlayed = round?.datePlayed || date
  const roundNumber = round?.roundNumber || ''
  const tournamentName = round?.tournamentName || ''
  const roundType = round?.roundType || RoundType.Practice
  const numberOfHoles = round?.numberOfHoles || HolesNumber.Nine
  const whereType = round?.isSimulated
    ? WhereType.Simulator
    : WhereType.Outdoor || WhereType.Outdoor

  const dispatch = useDispatch()

  const { options: roundOptions } = useEnum(RoundType, 'RoundType')
  const { options: holesOptions } = useEnum(HolesNumber, 'HolesNumber')
  const { options: whereOptions } = useEnum(WhereType, 'WhereFilter')

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        datePlayed: Yup.date()
          .transform(dateTransform)
          .required(
            t(
              `${I18N_KEY}.birthDateRequiredErrorMessage`,
              'Please enter a valid birth date'
            )
          ),
        roundType: Yup.string().required(
          t(
            `${I18N_KEY}.roundTypeRequiredErrorMessage`,
            'Please select the round type'
          )
        ),
        whereType: Yup.string().required(
          t(
            `${I18N_KEY}.whereTypeRequiredErrorMessage`,
            'Please select the location type'
          )
        ),
        numberOfHoles: Yup.string().required(
          t(
            `${I18N_KEY}.numberOfHolesRequiredErrorMessage`,
            'Please select the number of holes'
          )
        ),
        courseName: Yup.string().when('roundType', {
          is: RoundType.Practice,
          then: Yup.string().required(
            t(
              `${I18N_KEY}.courseNameRequiredErrorMessage`,
              'Please specify the course name'
            )
          ),
        }),
        tourName: Yup.string().when('roundType', {
          is: RoundType.Tournament,
          then: Yup.string().required(
            t(
              `${I18N_KEY}.tourNameRequiredErrorMessage`,
              'Please specify the tour name'
            )
          ),
        }),
        tournamentName: Yup.string().when('roundType', {
          is: RoundType.Tournament,
          then: Yup.string().required(
            t(
              `${I18N_KEY}.tournamentNameRequiredErrorMessage`,
              'Please specify the tournament name'
            )
          ),
        }),
        roundNumber: Yup.string().when('roundType', {
          is: RoundType.Tournament,
          then: Yup.string().required(
            t(
              `${I18N_KEY}.roundNumberRequiredErrorMessage`,
              'Please specify the round number'
            )
          ),
        }),
      }),
    [t]
  )

  const formikOptions: FormikConfig<FormValues> = {
    initialValues: {
      tourName,
      roundType,
      courseName,
      datePlayed,
      roundNumber,
      numberOfHoles,
      tournamentName,
      whereType,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async ({
      tourName,
      roundType,
      courseName,
      roundNumber,
      tournamentName,
      whereType,
      ...restValues
    }) => {
      // Go to shot entry if edit disabled without saving anything
      if (isEditDisabled) {
        setCurrentStep(1)
      }

      const isSimulated = whereType === WhereType.Simulator

      let payload

      if (roundType === RoundType.Practice) {
        payload = {
          ...restValues,
          roundType,
          courseName,
          isSimulated,
        }
      } else {
        payload = {
          tourName,
          roundType,
          roundNumber,
          tournamentName,
          ...restValues,
          isSimulated,
        }
      }

      try {
        if (uuid) {
          await dispatch(putRound(uuid, payload, teeLayup))
        } else {
          await dispatch(createRound(payload))
        }
        setCurrentStep(1)
      } catch (error: any) {
        dispatch(openToast(getErrorToast(error.message)))
      }
    },
  }

  return (
    <XsPaddingZeroContainer>
      <StyledPaper elevation={0}>
        <SectionTitle variant="h4">
          <Trans i18nKey={`${I18N_KEY}.roundInfo`}>Round Information</Trans>
        </SectionTitle>
        <Formik {...formikOptions}>
          {({ values, isSubmitting }) => (
            <Form>
              <ResponsiveGridxs2 container spacing={isSmallScreen ? 2 : 4}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="caption">
                    <Trans i18nKey={`${I18N_KEY}.dateLabel`}>Date</Trans>
                  </Typography>
                  <Field
                    autoOk
                    fullWidth
                    helperText=""
                    name="datePlayed"
                    inputVariant="outlined"
                    disabled={isEditDisabled}
                    component={FormDatePicker}
                    views={undefined}
                    openTo={undefined}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ endAdornment: <CalendarIcon /> }}
                    format={DateFormat[i18n.language as Languages].datePicker}
                  />
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <FormToggleButton
                    name="roundType"
                    options={roundOptions}
                    disabled={isEditDisabled}
                    label={t(`${I18N_KEY}.roundTypeLabel`, 'Round type')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <FormToggleButton
                    name="whereType"
                    options={whereOptions}
                    disabled={isEditDisabled}
                    label={t(`${I18N_KEY}.whereTypeLabel`, 'Where type')}
                  />
                </Grid>
                <Grid item xs={12} sm={6} />
                <Grid item xs={12} sm={6}>
                  <FormToggleButton
                    name="numberOfHoles"
                    disabled={isEditDisabled}
                    label={t(
                      `${I18N_KEY}.numberOfHolesLabel`,
                      'Number of holes'
                    )}
                    options={holesOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={6} />
                {values.roundType === RoundType.Practice ? (
                  <PracticeFields isEditDisabled={isEditDisabled} />
                ) : (
                  <TournamentFields isEditDisabled={isEditDisabled} />
                )}
              </ResponsiveGridxs2>
              <ButtonContainer>
                <LoaderButton
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isSubmitting}
                  endIcon={<ArrowForward />}
                >
                  <Trans i18nKey={`${I18N_KEY}.submitButtonLabel`}>
                    Continue to Shot Entry
                  </Trans>
                </LoaderButton>
              </ButtonContainer>
            </Form>
          )}
        </Formik>
      </StyledPaper>
    </XsPaddingZeroContainer>
  )
}

export default CreateRound
