import React, { useState, MouseEvent } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import useIO from 'src/hooks/useIO'
import history from 'src/utils/history'
import useAppState from 'src/hooks/useAppState'
import useBenchmark from 'src/hooks/useBenchmark'
import { OpportunityItem } from 'src/store/summarySlice'
import ComposedGraph from 'src/components/graph/composed'
import OpportunityDelete from 'src/components/feedback/OpportunityDelete'
import StrokesProgressLabel from 'src/components/dataDisplay/StrokesProgressLabel'

const I18N_KEY = 'OpportunityGraph'

interface OpportunityGraphProps extends OpportunityItem {
  isPlayerPremium: boolean
  handleUpgrade: () => void
}

const Container = styled(Paper)(
  ({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(3, 3, 3, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 2, 2, 0)};
    }
  `
)

const HeaderContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${theme.spacing(4.5)}px;

    ${theme.breakpoints.down('xs')} {
      padding-left: ${theme.spacing(2)}px;
    }
  `
)

const HeaderAction = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin-left: auto;
    margin-top: ${theme.spacing(2)}px;
    background-color: ${theme.palette.background.xlight};
  `
)

const OpportunityGraph: React.FC<OpportunityGraphProps> = ({
  path,
  data,
  sgTotal,
  progress,
  metricId,
  benchmark,
  handleUpgrade,
  isPlayerPremium,
}) => {
  const [isActive, setIsActive] = useState(false)

  const { isPlayer } = useAppState()
  const { metricName, deleteDialogOpen, openDeleteDialog, closeDeleteDialog } =
    useIO(metricId)
  const { benchmarkId, ...benchmarkInfo } = useBenchmark()

  const dataExists = sgTotal !== null
  const composedGraphPayload = { data, benchmark, progress, sgTotal }

  const handleActive = () => {
    setIsActive(true)
  }

  const handleInActive = () => {
    setIsActive(false)
  }

  const handleDetailsClick = () => {
    if (dataExists) {
      history.push(path)
    }
  }

  const handleCheckIconClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (isPlayerPremium) {
      openDeleteDialog(event)
    } else {
      handleUpgrade()
    }
  }

  return (
    <>
      <Container
        elevation={0}
        onMouseEnter={handleActive}
        onMouseLeave={handleInActive}
      >
        <HeaderContent>
          <HeaderAction>
            <Typography>{metricName}</Typography>
            {isPlayer && (
              <IconButton color="primary" onClick={handleCheckIconClick}>
                <CheckCircleIcon />
              </IconButton>
            )}
          </HeaderAction>
          <StrokesProgressLabel
            sgValue={sgTotal}
            progress={progress}
            dataExists={dataExists}
          />
        </HeaderContent>
        <ComposedGraph
          width="100%"
          height={225}
          active={isActive}
          id={`io-${metricId}`}
          dataExists={dataExists}
          payload={composedGraphPayload}
          {...benchmarkInfo}
        />
        {dataExists && (
          <Button size="small" color="primary" onClick={handleDetailsClick}>
            <Trans i18nKey={`${I18N_KEY}.detailedView`}>Detailed view</Trans>
          </Button>
        )}
      </Container>
      <OpportunityDelete
        metricId={metricId}
        open={deleteDialogOpen}
        handleClose={closeDeleteDialog}
      />
    </>
  )
}

export default OpportunityGraph
