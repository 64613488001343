import React from 'react'
import styled, { css } from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import illustration from 'src/assets/images/report-back.svg'
import Box from '@material-ui/core/Box'
import { hasActiveSubscription } from '../../../store/subscriptionSlice'
import {
  FocusArea,
  MonthlyReport,
  PerformanceIndicator,
} from '../../../models/monthly-report'
import DisplayCard from '../../../components/dataDisplay/DisplayCard'
import Player from '../../../models/player'
import ReportHeader from '../ReportHeader'
import { ReportGeneration } from '../../../utils/golfConstants'

const ReportContainer = styled(Box)(
  ({ theme }) => css`
    padding-left: 16.5%;
    padding-right: 16.5%;
    ${theme.breakpoints.down('xs')} {
      padding-left: 0;
      padding-right: 0;
    }
    background-color: #070707;
    display: grid;
    grid-template-columns: 3fr 1fr;
  `
)

const Illustration = styled.img.attrs({
  src: illustration,
  alt: 'Report Logo',
})`
  position: relative;
  top: 40%;
  right: 30%;
  scale: 1.5;
`

const Container = styled.div<{ isFirst?: boolean }>(
  ({ isFirst }) => css`
    display: grid;
    grid-template-columns: 1fr;
    margin: ${isFirst ? '0 5px 15px 5px;' : '45px 5px 15px 5px;'};
  `
)

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Title = styled(Typography)`
  grid-column: 1 / -1;
  font-weight: 500;
  font-size: 26px;
  line-height: 40px;
  color: #ffffff;
`

const Highlight = styled.span`
  color: #618ff7;
`

interface MetricResult {
  currentMonthValue: number
  pastMonthValue: number
  valueComparison: number
}

interface FocusAreaResult {
  metricId: string
  metricName: string
  currentValue: number
  valueComparison: number
}

interface Props {
  currentMonthReport: MonthlyReport
  lastMonthReport: MonthlyReport
  player: Player & { email: string }
  closeDialog: () => void
  title: string
  createdAt: Date
}

const formatFocusAreaText = (focusAreaText: string): string => {
  let formattedText = focusAreaText
  formattedText = formattedText.replace(/SG: /g, '')
  formattedText = formattedText.replace(/from/g, '')
  formattedText = formattedText.replace(/ - /g, ':')
  formattedText = formattedText.replace(/ yards/gi, 'y')
  formattedText = formattedText.replace(/ feet/gi, 'ft')
  formattedText = formattedText.replace(/</, ' under ')
  formattedText = formattedText.replace(/>/, ' over ')

  return formattedText
}

const I18N_KEY = 'MonthlyReport'

const MonthlyReportComponent: React.FC<Props> = ({
  currentMonthReport,
  lastMonthReport,
  player,
  closeDialog,
  title,
  createdAt,
}) => {
  const { t } = useTranslation()
  const playerIsPremium = useSelector(hasActiveSubscription())
  const name = `${player.firstName} ${player.lastName}`
  const email = player.email
  const getPerformanceIndicatorMetric = (
    data: MonthlyReport,
    metricKey: string
  ) => {
    return data.summary.performanceIndicators.find(
      (indicator: PerformanceIndicator) => indicator.metricId === metricKey
    )
  }

  const getMetricResults = (metricKey: string): MetricResult => {
    const currentMonthIndicator = getPerformanceIndicatorMetric(
      currentMonthReport!,
      metricKey
    )
    const pastMonthIndicator = getPerformanceIndicatorMetric(
      lastMonthReport!,
      metricKey
    )
    const currentMonthValue: number = currentMonthIndicator
      ? currentMonthIndicator.value!
      : 0
    const pastMonthValue: number = pastMonthIndicator
      ? pastMonthIndicator.value!
      : 0

    return {
      currentMonthValue,
      pastMonthValue,
      valueComparison: currentMonthValue - pastMonthValue,
    }
  }

  const createComparativeMetricCard = (
    metricKey: string,
    metricTitle: string,
    inverseComparison: boolean
  ) => {
    const metric = getMetricResults(metricKey)
    return (
      <DisplayCard
        data={metric.currentMonthValue}
        dataComparison={metric.valueComparison}
        title={metricTitle}
        isInverseComparison={inverseComparison}
      />
    )
  }

  const getFocusAreaComparisons = (): FocusAreaResult[] => {
    const focusAreasMap: Map<string, FocusAreaResult> = new Map<
      string,
      FocusAreaResult
    >()
    currentMonthReport!.summary.focusAreas.every(focusArea => {
      const pastMonthArea = lastMonthReport!.summary.focusAreas.find(
        area => focusArea.metricId === area.metricId
      )
      if (!pastMonthArea) {
        return true
      }
      const valueComparison = focusArea.difference - pastMonthArea.difference
      const focusAreaResult: FocusAreaResult = {
        metricId: focusArea.metricId,
        metricName: focusArea.metricName,
        currentValue: focusArea.difference,
        valueComparison,
      }
      focusAreasMap.set(focusArea.metricId, focusAreaResult)
      return true
    })

    const sortedMap = new Map(
      Array.from(focusAreasMap.entries()).sort(
        (a, b) => b[1].valueComparison - a[1].valueComparison
      )
    )

    return Array.from(sortedMap.values()).slice(0, 3)
  }

  const createComparativeFocusAreaCard = (
    metricId: string,
    title?: string,
    isSGCard?: boolean
  ) => {
    const focusArea: FocusAreaResult | undefined =
      getFocusAreaComparisons().find(
        focusArea => focusArea.metricId === metricId
      )
    if (title) {
      return focusArea ? (
        <DisplayCard
          data={focusArea.currentValue}
          dataComparison={focusArea.valueComparison}
          title={title}
          subtitle={focusArea.metricName}
          isSGCard={isSGCard}
        />
      ) : (
        ''
      )
    }
    return focusArea ? (
      <DisplayCard
        data={focusArea.currentValue}
        dataComparison={focusArea.valueComparison}
        title={focusArea.metricName}
      />
    ) : (
      ''
    )
  }

  const showBiggestImprovement = () => {
    const biggestImprovement = getFocusAreaComparisons()[0]
    return createComparativeFocusAreaCard(
      biggestImprovement.metricId,
      t(`${I18N_KEY}.biggestImprovement`, 'Biggest Improvement'),
      true
    )
  }

  const filterOpportunities = () => {
    const opportunities: FocusArea[] = []
    let opportunitiesCount = 0

    currentMonthReport!.summary.focusAreas.every(focusArea => {
      if (opportunitiesCount === 3) {
        return false
      }
      if (focusArea.difference < 0) {
        opportunities.push(focusArea)
        opportunitiesCount += 1
      }
      return true
    })
    return opportunities
  }

  const getTotalImprovements = () => {
    return getFocusAreaComparisons()
      .reduce(
        (accumulator: number, value: FocusAreaResult) =>
          accumulator + value.valueComparison,
        0
      )
      .toFixed(2)
  }

  const getImprovementsNames = () => {
    return getFocusAreaComparisons().map((improvement: FocusAreaResult) =>
      formatFocusAreaText(improvement.metricName)
    )
  }

  const getSummedUpOpportunitiesSG = () => {
    return Math.abs(
      filterOpportunities().reduce(
        (accumulator, opportunity) => accumulator + opportunity.difference,
        0
      )
    )
  }

  const showOpportunities = () => {
    const opportunities = filterOpportunities()
    return opportunities.map(opportunity => (
      <DisplayCard
        key={opportunity!.metricId}
        data={Math.abs(opportunity!.difference)}
        title={`${t(`${I18N_KEY}.opportunity`, 'Opportunity')} ${
          opportunities.findIndex(op => op.metricId === opportunity.metricId) +
          1
        }`}
        subtitle={formatFocusAreaText(opportunity!.metricName)}
        isSGCard
      />
    ))
  }

  const showBiggestStrength = () => {
    const biggestStrength =
      currentMonthReport!.summary.focusAreas[
        currentMonthReport!.summary.focusAreas.length - 1
      ]
    return (
      <DisplayCard
        data={biggestStrength.difference}
        title={t(`${I18N_KEY}.biggestStrength`, 'Biggest Strength')}
        subtitle={biggestStrength.metricName}
        isSGCard
      />
    )
  }

  const metrics = filterOpportunities().map(
    opportunity => ` ${formatFocusAreaText(opportunity.metricName)}`
  )

  const strokesToSave = getSummedUpOpportunitiesSG().toFixed(2)

  const now = new Date()
  now.setMonth(now.getMonth() - 1)
  const month = now.toLocaleString('default', { month: 'long' })

  const score = getTotalImprovements()
  const improvements = getImprovementsNames().join(', ')

  return (
    <>
      <ReportHeader
        closeDialog={closeDialog}
        title={title}
        createdAt={createdAt}
        name={name}
        email={email}
        playerType={t(`Enums:PlayerType.${player.playerType}`)}
        generationType={ReportGeneration.Automatic}
        darkMode
      />
      <ReportContainer>
        <div>
          <Container isFirst>
            <Title>
              <Trans i18nKey={`${I18N_KEY}.improvements`}>
                Congratulations! You have improved by{' '}
                <strong>{{ score }}</strong> in: {{ improvements }}
              </Trans>
            </Title>
          </Container>
          <CardsContainer>
            {showBiggestImprovement()}
            {showBiggestStrength()}
          </CardsContainer>
          <CardsContainer>
            {createComparativeMetricCard(
              'M01',
              t(`${I18N_KEY}.scoreToPar`, 'Score to Par'),
              true
            )}
            {createComparativeMetricCard(
              'M25',
              t(`${I18N_KEY}.pars`, 'Pars'),
              false
            )}
          </CardsContainer>
          <CardsContainer>
            {createComparativeMetricCard(
              'M24',
              t(`${I18N_KEY}.birdiesOrBetter`, 'Birdies or better'),
              false
            )}
            {createComparativeMetricCard(
              'M26',
              t(`${I18N_KEY}.bogeysOrWorse`, 'Bogeys or worse'),
              true
            )}
          </CardsContainer>
          <Container>
            <Title>
              <Trans i18nKey={`${I18N_KEY}.usage`}>
                We can see your usage is increasing in{' '}
                <Highlight>{{ month }}</Highlight>:
              </Trans>
            </Title>
          </Container>
          <CardsContainer>
            <DisplayCard
              data={currentMonthReport!.rounds}
              dataComparison={
                currentMonthReport!.rounds - lastMonthReport!.rounds
              }
              title={t(`${I18N_KEY}.newRounds`, 'New Rounds')}
            />
            {playerIsPremium ? (
              <>
                <DisplayCard
                  data={currentMonthReport!.practices}
                  dataComparison={
                    currentMonthReport!.practices - lastMonthReport!.practices
                  }
                  title={t(`${I18N_KEY}.newPractices`, 'New Practices')}
                />
                <DisplayCard
                  data={currentMonthReport!.courseStrategies}
                  dataComparison={
                    currentMonthReport!.courseStrategies -
                    lastMonthReport!.courseStrategies
                  }
                  title={t(
                    `${I18N_KEY}.newCourseStrategies`,
                    'New Course Strategies'
                  )}
                />
              </>
            ) : (
              ''
            )}
          </CardsContainer>
          <Container>
            <Title>
              <Trans i18nKey={`${I18N_KEY}.focusAreas`}>
                By focusing on {{ metrics }} you could save {{ strokesToSave }}{' '}
                strokes gained per round.
              </Trans>
            </Title>
          </Container>
          <CardsContainer>{showOpportunities()}</CardsContainer>
          <Container>
            <Title>
              <Trans i18nKey={`${I18N_KEY}.finalMessage`}>
                To continue your progress, consider logging more practice
                sessions and applying course strategies.
              </Trans>
            </Title>
          </Container>
        </div>
        <div>
          <Illustration />
        </div>
      </ReportContainer>
    </>
  )
}

export default MonthlyReportComponent
