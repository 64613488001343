import { SummaryGraphPoint } from 'src/store/summarySlice'
import {
  MetricId,
  CategoryType,
  CATEGORY_TYPE_MAP,
  PARENT_CATEGORY_TYPE_MAP,
} from 'src/utils/golfConstants'

export const CategoryTypeFromMetricId = (metricId: MetricId): CategoryType => {
  return (
    CATEGORY_TYPE_MAP[metricId as keyof typeof CATEGORY_TYPE_MAP] ||
    PARENT_CATEGORY_TYPE_MAP[
      metricId as keyof typeof PARENT_CATEGORY_TYPE_MAP
    ] ||
    CategoryType.All
  )
}

export const fillMixedGraphPoints = (
  roundGraphPoints: SummaryGraphPoint[],
  practiceGraphPoints: SummaryGraphPoint[]
): SummaryGraphPoint[] => {
  const practicePoints = practiceGraphPoints.sort(
    (a, b) => a.datePlayed - b.datePlayed
  )

  const roundPoints = roundGraphPoints.sort(
    (a, b) => a.datePlayed - b.datePlayed
  )

  const firstPracticePoint =
    practicePoints.find(point => point.practiceRollingAverage !== null) ??
    practicePoints[0]
  const lastPracticePoint =
    [...practicePoints]
      .reverse()
      .find(point => point.practiceRollingAverage !== null) ??
    practicePoints[practicePoints.length - 1]

  const firstRoundPoint =
    roundPoints.find(point => point.rollingAverage !== null) ?? roundPoints[0]
  const lastRoundPoint =
    [...roundPoints].reverse().find(point => point.rollingAverage !== null) ??
    roundPoints[roundPoints.length - 1]

  if (firstRoundPoint && firstPracticePoint) {
    firstPracticePoint.rollingAverage = firstRoundPoint.rollingAverage
    firstRoundPoint.practiceRollingAverage =
      firstPracticePoint.practiceRollingAverage
  }
  if (lastRoundPoint && lastPracticePoint) {
    lastPracticePoint.rollingAverage = lastRoundPoint.rollingAverage
    lastRoundPoint.practiceRollingAverage =
      lastPracticePoint.practiceRollingAverage
  }

  return [...practicePoints, ...roundPoints].sort(
    (a, b) => a.datePlayed - b.datePlayed
  )
}
