import React, { useEffect, useState, useContext } from 'react'
import styled, { css } from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Close from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import {
  playerSelector,
  getBenchmarkList,
  updatePlayerBenchmark,
} from 'src/store/playerSlice'
import { getRound } from 'src/store/roundSlice'
import Goal from 'src/assets/svgComponents/GoalIcon'
import {
  ActiveBar,
  CheckIcon,
  LargeLayoutButton,
} from 'src/components/layout/SharedLayoutComponents'
import { BenchmarkId } from 'src/utils/golfConstants'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { TranslationContext } from 'src/utils/TranslationContext'
import { openToast, getErrorToast, getSuccessToast } from 'src/store/toastSlice'
import { useFlags } from 'launchdarkly-react-client-sdk'

const I18N_KEY = 'BenchmarkSelection'

interface Props {
  open: boolean
  roundUuid?: string
  handleClose: (selectedBenchmark: string) => void
  recommendedBenchmark?: string
}

const ConfirmButton = styled(LoaderButton)`
  width: 90px;
`

const GoalIcon = styled(Goal)`
  width: 30px;
`

const StyledSkeleton = styled(Skeleton)(
  ({ theme }) =>
    css`
      margin: ${theme.spacing(1, 0)};
    `
)

const LoadingSkeletons = () => (
  <>
    <StyledSkeleton width="100%" height={66} variant="rect" animation="wave" />
    <StyledSkeleton width="100%" height={66} variant="rect" animation="wave" />
    <StyledSkeleton width="100%" height={66} variant="rect" animation="wave" />
  </>
)

const BenchmarkSelection: React.FC<Props> = ({
  open,
  roundUuid,
  handleClose,
  recommendedBenchmark,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { teeLayup } = useFlags()
  const { getBenchmarkName } = useContext(TranslationContext)!

  const { inProgress, benchmarkId, benchmarkData, benchmarkSelected } =
    useSelector(playerSelector)

  const [selectedBenchmark, setSelectedBenchmark] = useState(benchmarkId)
  useEffect(() => {
    setSelectedBenchmark(benchmarkId)
  }, [benchmarkId])

  const { inProgress: benchmarksInProgress, list: benchmarks } = benchmarkData

  const getBenchmarkSelectionHandler = (benchmark: BenchmarkId) => () => {
    setSelectedBenchmark(benchmark)
  }

  const setBenchmark = async () => {
    // Selected benchmark is already set
    if (selectedBenchmark === benchmarkId && benchmarkSelected) {
      handleClose(selectedBenchmark)
      return
    }

    try {
      await dispatch(updatePlayerBenchmark(selectedBenchmark))
      // Get new round summary if user is in the round dialog
      if (roundUuid) {
        await dispatch(getRound(roundUuid, teeLayup))
      }

      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.successToast`, 'Your goal was successfully set')
          )
        )
      )
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.errorToast`, 'Unable to update benchmark')
          )
        )
      )
    }
    handleClose(selectedBenchmark)
  }

  useEffect(() => {
    dispatch(getBenchmarkList())
  }, [dispatch])

  return (
    <Dialog
      open={open}
      onClose={() => handleClose(selectedBenchmark)}
      aria-labelledby="dialog-title"
    >
      <DialogContent>
        <Box display="flex" justifyContent="space-between">
          <GoalIcon />
          <IconButton onClick={() => handleClose(selectedBenchmark)}>
            <Close />
          </IconButton>
        </Box>
        <Typography gutterBottom variant="h3" id="dialog-title">
          <Trans i18nKey={`${I18N_KEY}.setYourGoal`}>Set your goal</Trans>
        </Typography>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.setReason`}>
            Set a benchmark that you can track your progress against.
          </Trans>
        </Typography>
        <Box mt={2}>
          <AnimateSharedLayout>
            {benchmarks.map(({ benchmarkId }) => {
              const name = getBenchmarkName(benchmarkId)
              const isSelected = selectedBenchmark === benchmarkId
              const isRecommended = benchmarkId === recommendedBenchmark

              return (
                <LargeLayoutButton
                  fullWidth
                  disableRipple
                  color="primary"
                  key={benchmarkId}
                  selected={isSelected}
                  onClick={getBenchmarkSelectionHandler(benchmarkId)}
                >
                  {name}
                  {isRecommended && (
                    <Box ml={1}>
                      <Typography color="primary" variant="caption">
                        <Trans i18nKey={`${I18N_KEY}.suggestedGoal`}>
                          Suggested Goal
                        </Trans>
                      </Typography>
                    </Box>
                  )}
                  {isSelected && (
                    <>
                      <CheckIcon />
                      <ActiveBar layoutId="benchmark-selection" />
                    </>
                  )}
                </LargeLayoutButton>
              )
            })}
          </AnimateSharedLayout>
          {benchmarksInProgress && <LoadingSkeletons />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(selectedBenchmark)}>
          <Trans i18nKey={`${I18N_KEY}.cancel`}>Cancel</Trans>
        </Button>
        <ConfirmButton
          color="primary"
          variant="contained"
          loading={inProgress}
          onClick={setBenchmark}
          disabled={!selectedBenchmark}
        >
          <Trans i18nKey={`${I18N_KEY}.confirm`}>Confirm</Trans>
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  )
}

export default BenchmarkSelection
