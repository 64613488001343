export enum RoleType {
  Admin = 'admin',
  Player = 'player',
  Coach = 'coach',
  Partner = 'partner',
}

export enum FeatureType {
  DiscussionDocument = 'DiscussionDocument',
}
