import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import MuiAvatar from '@material-ui/core/Avatar'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import Player from 'src/models/player'
import { Languages } from 'src/utils/constants'
import { TranslationContext } from 'src/utils/TranslationContext'
import { Progress, BenchmarkDetails } from 'src/store/summarySlice'
import { CoachPlayerStatus } from 'src/models/coach-player-overview'
import ProgressDonut from 'src/components/dataDisplay/ProgressDonut'
import StrokesProgressLabel from 'src/components/dataDisplay/StrokesProgressLabel'

import { ReactComponent as ProfileIcon } from 'src/assets/images/profile.svg'

const I18N_KEY = 'PlayerCardHeader'

interface Props {
  email: string | undefined
  isAcceptedPlayer: boolean
  player: Player | undefined
  progressDonut: Progress | undefined
  status: CoachPlayerStatus | undefined
  benchmarkDetails: BenchmarkDetails | undefined
  generateNavigateToPlayer: (toActivity?: boolean) => () => void
}

interface HeaderProps {
  $isAcceptedPlayer: boolean
}

const PlayerDonut = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  align-content: center;
  justify-content: center;
`

const Avatar = styled(MuiAvatar)(
  ({ theme }) => css`
    background-color: #e2f1fc;

    h2 {
      font-weight: 500;
      color: ${theme.palette.primary.light};
    }

    ${theme.breakpoints.down('xs')} {
      h2 {
        font-size: ${theme.typography.pxToRem(20)};
        line-height: ${theme.typography.pxToRem(32)};
      }
    }
  `
)

const CardHeader = styled.div<HeaderProps>(
  ({ theme, $isAcceptedPlayer }) =>
    css`
      display: flex;
      min-height: 358px;
      align-items: center;
      flex-direction: column;
      padding: ${$isAcceptedPlayer
        ? theme.spacing(6, 3, 3)
        : theme.spacing(6, 3, 4)};

      ${PlayerDonut} {
        width: ${$isAcceptedPlayer ? 120 : 84}px;
        height: ${$isAcceptedPlayer ? 120 : 84}px;
        margin: ${$isAcceptedPlayer
          ? theme.spacing(0, 0, 1)
          : theme.spacing(0, 0, 2.5)};
      }

      ${Avatar} {
        width: ${$isAcceptedPlayer ? 70 : 100}%;
        height: ${$isAcceptedPlayer ? 70 : 100}%;
      }

      ${theme.breakpoints.down('xs')} {
        min-height: unset;
        flex-direction: row;
        padding: ${theme.spacing(3)}px;

        ${PlayerDonut} {
          width: ${$isAcceptedPlayer ? 84 : 60}px;
          height: ${$isAcceptedPlayer ? 84 : 60}px;
          margin: ${$isAcceptedPlayer
            ? theme.spacing(0, 2, 0, 0)
            : theme.spacing(0, 3, 0, 0)};
        }
      }
    `
)

const PlayerContent = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;

    ${theme.breakpoints.down('xs')} {
      align-items: flex-start;
    }
  `
)

const ProgressLabel = styled(StrokesProgressLabel)`
  margin: 0;
`

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0, 0)};
    background-color: ${theme.palette.background.xlight};

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `
)

const PlayerCardHeader: React.FC<Props> = ({
  email,
  player,
  status,
  progressDonut,
  benchmarkDetails,
  isAcceptedPlayer,
  generateNavigateToPlayer,
}) => {
  const { t, i18n } = useTranslation()

  const isEnglish = i18n.language === Languages.English

  const lastName = player?.lastName
  const firstName = player?.firstName
  const hasName = !!firstName && !!lastName
  const { getBenchmarkName } = useContext(TranslationContext)!

  const toBenchmark = progressDonut?.toBenchmark as number
  const currentBenchmark = getBenchmarkName(benchmarkDetails?.current.id)
  const avatarLetters =
    firstName && lastName
      ? firstName[0].toUpperCase() + lastName[0].toUpperCase()
      : ''

  const getDescriptionText = () => {
    if (toBenchmark === 0) {
      return (
        <Trans i18nKey={`${I18N_KEY}.playingAt`}>
          at {{ currentBenchmark }}
        </Trans>
      )
    } else if (toBenchmark > 0) {
      return (
        <Trans i18nKey={`${I18N_KEY}.playingAbove`}>
          above {{ currentBenchmark }}
        </Trans>
      )
    } else {
      return (
        <Trans i18nKey={`${I18N_KEY}.playingBetween`}>
          below {{ currentBenchmark }}
        </Trans>
      )
    }
  }

  const getStatusDetails = () => {
    switch (status) {
      case CoachPlayerStatus.Accepted:
        return (
          <>
            <ProgressLabel
              dataExists
              sgValue={progressDonut?.toBenchmark}
              progress={progressDonut?.progressValue}
            />
            <Typography color="textSecondary">
              {getDescriptionText()}
            </Typography>
          </>
        )
      case CoachPlayerStatus.PlayerHasNoRounds:
        return (
          <Typography color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.noRounds`}>No Rounds Entered</Trans>
          </Typography>
        )
      case CoachPlayerStatus.PlayerInvitationNotAccepted:
      default:
        return (
          <Typography color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.pending`}>Pending Confirmation</Trans>
          </Typography>
        )
    }
  }

  return (
    <CardHeader $isAcceptedPlayer={isAcceptedPlayer}>
      <PlayerDonut>
        {player?.profileImageUrl ? (
          <Avatar
            src={player.profileImageUrl}
            alt={t(`${I18N_KEY}.playerAlt`, 'Player profile')}
          />
        ) : (
          <Avatar>
            {isEnglish ? (
              <Typography variant="h2">{avatarLetters}</Typography>
            ) : (
              <ProfileIcon />
            )}
          </Avatar>
        )}
        {isAcceptedPlayer && (
          <ProgressDonut
            showGoalIcon
            backgroundRingOpacity={0.1}
            progress={progressDonut?.progressValue as number}
          />
        )}
      </PlayerDonut>
      <PlayerContent>
        <Typography variant="body2">
          {hasName ? `${firstName} ${lastName}` : email}
        </Typography>
        {getStatusDetails()}
      </PlayerContent>
      {(status === CoachPlayerStatus.Accepted ||
        status === CoachPlayerStatus.PlayerHasNoRounds) && (
        <Button
          size="small"
          color="primary"
          onClick={generateNavigateToPlayer()}
        >
          <Trans i18nKey={`${I18N_KEY}.playerDash`}>Player Dashboard</Trans>
        </Button>
      )}
    </CardHeader>
  )
}

export default PlayerCardHeader
