import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Help from '@material-ui/icons/Help'
import Tooltip from '@material-ui/core/Tooltip'

const I18N_KEY = 'HelpTooltip'

const HelpIcon = styled(Help)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(0.5)}px;
    color: ${theme.palette.primary.light};
    font-size: ${theme.typography.pxToRem(16)};
  `
)

const HelpTooltip: React.FC = () => {
  const { t } = useTranslation()

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        t(
          `${I18N_KEY}.practiceGoalTooltip`,
          'This practice goal is an intelligent target we’ve created for you. It combines: Your goal, your prior performance and a reasonable improvement target.'
        ) as string
      }
    >
      <HelpIcon />
    </Tooltip>
  )
}

export default HelpTooltip
