import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'

const I18N_KEY = 'PracticeError'

interface Props {
  handleClose: () => void
}

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${theme.spacing(12, 0, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(9, 0, 0)};
    }
  `
)

const IconContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: ${theme.spacing(0, 0, 6)};

    svg {
      font-size: ${theme.typography.pxToRem(60)};
      color: ${theme.palette.background.focusBlue};
    }
  `
)

const NormalText = styled(Typography)(
  ({ theme }) => css`
    font-weight: 400;
    margin: ${theme.spacing(2, 0, 6)};
  `
)

const Button = styled(MuiButton)`
  width: 120px;
`

const PracticeError: React.FC<Props> = ({ handleClose }) => (
  <Container>
    <IconContainer>
      <ErrorRoundedIcon />
    </IconContainer>
    <Typography variant="h4">
      <Trans i18nKey={`${I18N_KEY}.sorry`}>Sorry, something went wrong.</Trans>
    </Typography>
    <NormalText variant="h4">
      <Trans i18nKey={`${I18N_KEY}.tryAgain`}>Please try again later</Trans>
    </NormalText>
    <Button variant="contained" color="primary" onClick={handleClose}>
      <Trans i18nKey={`${I18N_KEY}.close`}>Close</Trans>
    </Button>
  </Container>
)

export default PracticeError
