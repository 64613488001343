import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import {
  Dialog,
  MainContentBackground,
  StepInfo,
  Toolbar,
  XsPaddingZeroContainer,
} from 'src/modules/common'
import { Close } from '@material-ui/icons'
import { IconButton, Typography } from '@material-ui/core'
import styled, { css } from 'styled-components'
import {
  courseStrategiesLoadingSelector,
  courseStrategyDialogOpenSelector,
  updateDialogVisibility,
} from 'src/store/courseStrategySlice'
import { useTranslation } from 'react-i18next'
import Box from '@material-ui/core/Box'
import { AnimatePresence, motion } from 'framer-motion'
import { defaultTransition, fadeVariants } from 'src/utils/animations'
import CourseStrategyDetail from './CourseStrategyDetail'
import GenericLoader from 'src/components/layout/GenericLoader'

const I18N_KEY = 'CourseStrategy'

const HeaderContainer = styled.div(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(4)}px;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2, 2, 4, 2)};
    }
  `
)

const TextContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(56)};
    line-height: ${theme.typography.pxToRem(72)};

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(40)};
      line-height: ${theme.typography.pxToRem(48)};
      margin-bottom: ${theme.spacing(2)}px;
    }
  `
)

const AddCourseStrategyDialog: React.FC = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const courseStrategyTitle = t(
    `${I18N_KEY}.stepAddCourseStrategyTitle`,
    'Course Details'
  )
  const courseStrategySubTitle = t(
    `${I18N_KEY}.stepCourseDetailsSubTitle`,
    'Course Details'
  )

  const isLoading = useSelector(courseStrategiesLoadingSelector)
  const dialogOpen = useSelector(courseStrategyDialogOpenSelector)

  const closeDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: false }))
  }

  return (
    <Dialog open={dialogOpen}>
      {isLoading ? (
        <GenericLoader
          text={t(`${I18N_KEY}.loadingText`, 'Getting course strategy data')}
        />
      ) : (
        <>
          <AppBar elevation={0} color="default" position="relative">
            <XsPaddingZeroContainer>
              <Toolbar>
                <IconButton onClick={closeDialog}>
                  <Close />
                </IconButton>
              </Toolbar>
              <HeaderContainer>
                <Box display="flex" mb={2}>
                  <StepInfo
                    key={0}
                    color="primary"
                    $completed={false}
                    $selected
                  >
                    {courseStrategyTitle}
                  </StepInfo>
                </Box>
                <TextContainer>
                  <DialogTitle variant="h1">
                    {courseStrategySubTitle}
                  </DialogTitle>
                </TextContainer>
              </HeaderContainer>
            </XsPaddingZeroContainer>
          </AppBar>
          <MainContentBackground>
            <AnimatePresence exitBeforeEnter>
              <motion.div
                exit="exit"
                initial="enter"
                animate="visible"
                variants={fadeVariants}
                transition={defaultTransition}
                key={courseStrategyTitle}
              >
                <CourseStrategyDetail />
              </motion.div>
            </AnimatePresence>
          </MainContentBackground>
        </>
      )}
    </Dialog>
  )
}

export default AddCourseStrategyDialog
