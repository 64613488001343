import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'

import useBenchmark from 'src/hooks/useBenchmark'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import CategoryError from 'src/modules/categories/CategoryError'
import RadarComparison from 'src/components/dataDisplay/RadarComparison'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import {
  SummaryStatus,
  summaryRadarDataSelector,
  summaryShotsDistribution,
} from 'src/store/summarySlice'
import ComposedStrokesGrid from 'src/modules/player-dash/strokesGained/ComposedStrokesGrid'
import ShotsDistribution, { PieDataFormat } from '../shotsDistribution'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ShotsDistributionItem } from '../../../utils/golfConstants'

const I18N_KEY = 'StrokesGained'

interface Props {
  summaryStatus: SummaryStatus
}

const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const StyledRadarComparison = styled(RadarComparison)(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0)};
  `
)

const createShotsDistributionColorScheme = () => {
  const colors: Map<string, string> = new Map()
  colors.set('offTheTee', '#ACC5FC')
  colors.set('putting', '#94B5FF')
  colors.set('shortGame', '#DDE5F8')
  colors.set('approach', '#C4D5FA')
  return colors
}

const StrokesGained: React.FC<Props> = ({ summaryStatus }) => {
  const { t } = useTranslation()

  const { shotsDistributionFlag } = useFlags()

  const { benchmarkName } = useBenchmark()

  const radarData = useSelector(summaryRadarDataSelector)

  const shotsDistribution = useSelector(summaryShotsDistribution)

  const formattedShotsDistributionData: PieDataFormat[] = []
  if (shotsDistribution) {
    for (const [key, value] of Object.entries(shotsDistribution.aggregate)) {
      if (key !== 'totalStrokes') {
        formattedShotsDistributionData.push({
          name: key,
          value: value.percentage,
        })
      }
    }
  }

  const shotsDistributionItems: ShotsDistributionItem[] = []
  if (shotsDistribution) {
    for (const [key, value] of Object.entries(shotsDistribution.aggregate)) {
      if (key !== 'totalStrokes') {
        shotsDistributionItems.push({
          name: key,
          ...value,
        })
      }
    }
  }

  const getStrokesContent = () => {
    switch (summaryStatus) {
      case SummaryStatus.Loading:
        return (
          <Skeleton variant="rect" width="100%" height={350} animation="wave" />
        )
      case SummaryStatus.Valid:
        return (
          <>
            <StyledRadarComparison
              data={radarData}
              isRoundSummary={false}
              benchmarkName={benchmarkName}
            />
            {shotsDistributionFlag && formattedShotsDistributionData ? (
              <Box mt={8} mb={2}>
                <HeadingContainer>
                  <Box mb={2}>
                    <ReferencedTitle
                      title={t(
                        `${I18N_KEY}.shotDistribution`,
                        'Shot Distribution'
                      )}
                    />
                  </Box>
                </HeadingContainer>
                <ShotsDistribution
                  data={formattedShotsDistributionData}
                  items={shotsDistributionItems}
                  colors={createShotsDistributionColorScheme()}
                />
              </Box>
            ) : (
              ''
            )}
            <Box mt={8} mb={2}>
              <HeadingContainer>
                <Box mb={2}>
                  <ReferencedTitle
                    title={t(
                      `${I18N_KEY}.trendGraphs`,
                      'Strokes Gained Trend Graph'
                    )}
                  />
                </Box>
              </HeadingContainer>
              <ComposedStrokesGrid />
            </Box>
          </>
        )
      default:
        return <CategoryError summaryStatus={summaryStatus} />
    }
  }

  return (
    <>
      <HeadingContainer>
        <Box>
          <ReferencedTitle
            title={t(`${I18N_KEY}.title`, 'Strokes Gained Radar')}
          />
        </Box>
      </HeadingContainer>
      <Box mt={2}>{getStrokesContent()}</Box>
    </>
  )
}

export default StrokesGained
