import React, { useContext, ReactElement } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import MuiLink from '@material-ui/core/Link'
import MuiTooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import {
  DashIcon,
  CircleIcon,
  DifferenceText,
} from 'src/components/graph/common'
import Goal from 'src/assets/svgComponents/GoalIcon'
import { DECIMAL_PLACES } from 'src/utils/constants'
import { BenchmarkValue } from 'src/store/summarySlice'
import { TranslationContext } from 'src/utils/TranslationContext'

const I18N_KEY = 'DonutTooltip'

interface Props {
  valid: boolean
  loading: boolean
  toBenchmark: number
  strokesGained: number
  below: BenchmarkValue
  children: ReactElement
  current: BenchmarkValue
  onChangeGoal?: () => void
}

/* 
 Work around to style tooltip as MuiTooltip has no 
 root DOM element to apply the styled-components class to.
 Issue: https://github.com/mui-org/material-ui/issues/11467
*/
const Tooltip = styled(props => (
  <MuiTooltip
    classes={{ popper: props.className, tooltip: 'tooltip', arrow: 'arrow' }}
    {...props}
  />
))(
  ({ theme }) => css`
    & .tooltip {
      max-width: 250px;
      padding: ${theme.spacing(2, 1.5)};
      background-color: ${theme.palette.background.darkestGrey};
    }

    & .arrow {
      color: ${theme.palette.background.darkestGrey};
    }
  `
)

const TooltipText = styled(props => (
  <Typography component="p" variant="h6" {...props} />
))(
  ({ theme }) => css`
    font-weight: normal;
    color: ${theme.palette.background.paper};
  `
)

const TooltipKey = styled(TooltipText)`
  display: flex;
  align-items: center;
  text-transform: capitalize;
`

const TooltipValue = styled(TooltipText)`
  font-weight: 500;
`

const GoalIcon = styled(Goal)(
  ({ theme }) => css`
    width: 10px;
    margin-right: ${theme.spacing(1)}px;
  `
)

const Link = styled(MuiLink)(
  ({ theme }) => css`
    color: ${theme.palette.primary.light};
  `
)

const DonutTooltip: React.FC<Props> = ({
  below,
  valid,
  current,
  loading,
  children,
  toBenchmark,
  onChangeGoal,
  strokesGained,
}) => {
  const { getBenchmarkName } = useContext(TranslationContext)!

  const belowText = getBenchmarkName(below?.id)
  const currentText = getBenchmarkName(current?.id)

  const handleChangeGoal = (event: React.MouseEvent) => {
    event.preventDefault()
    if (onChangeGoal) {
      onChangeGoal()
    }
  }

  const getDescriptionText = () => {
    if (toBenchmark === 0) {
      return (
        <Trans i18nKey={`${I18N_KEY}.playingAt`}>
          You are playing at {{ currentText }} level.
        </Trans>
      )
    } else if (toBenchmark > 0) {
      return (
        <Trans i18nKey={`${I18N_KEY}.playingAbove`}>
          You are playing above {{ currentText }}.
        </Trans>
      )
    } else if (toBenchmark < 0 && strokesGained < below?.value) {
      return (
        <Trans i18nKey={`${I18N_KEY}.playingBelow`}>
          You are playing below {{ belowText }} currently.
        </Trans>
      )
    } else {
      return (
        <Trans i18nKey={`${I18N_KEY}.playingBetween`}>
          You are playing between {{ belowText }} and {{ currentText }}.
        </Trans>
      )
    }
  }

  const getTooltipContent = () => {
    if (loading) {
      return <TooltipText>Loading information</TooltipText>
    }

    if (valid) {
      return (
        <>
          <TooltipText>
            {getDescriptionText()}{' '}
            {onChangeGoal && (
              <Link onClick={handleChangeGoal} href="#" color="primary">
                <Trans i18nKey={`${I18N_KEY}.changeGoalLink`}>
                  Change Goal?
                </Trans>
              </Link>
            )}
          </TooltipText>
          <Box mt={2} display="flex" justifyContent="space-between">
            <TooltipKey>
              <CircleIcon $valueIsNull />
              {belowText}
            </TooltipKey>
            <TooltipValue>{below?.value?.toFixed(DECIMAL_PLACES)}</TooltipValue>
          </Box>
          <Box my={0.5} display="flex" justifyContent="space-between">
            <TooltipText>
              <DashIcon />
              <Trans i18nKey={`${I18N_KEY}.currentTotal`}>
                Your current total
              </Trans>
            </TooltipText>
            <TooltipValue>
              {strokesGained?.toFixed(DECIMAL_PLACES)}
            </TooltipValue>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <TooltipText>
              <DashIcon $isScratch />
              <Trans i18nKey={`${I18N_KEY}.vsCurrent`}>
                vs {{ currentText }}
              </Trans>
            </TooltipText>
            <DifferenceText $isGreater={strokesGained > current?.value}>
              {toBenchmark.toFixed(DECIMAL_PLACES)}
            </DifferenceText>
          </Box>
          <Box mt={0.5} display="flex" justifyContent="space-between">
            <TooltipKey>
              <GoalIcon />
              {currentText}
            </TooltipKey>
            <TooltipValue>
              {current?.value?.toFixed(DECIMAL_PLACES)}
            </TooltipValue>
          </Box>
        </>
      )
    }

    return (
      <TooltipText>
        <Trans i18nKey={`${I18N_KEY}.tooltipText`}>
          Your total progress to your goal will be shown here once you’ve
          entered round data.
        </Trans>
      </TooltipText>
    )
  }

  return (
    <Tooltip
      arrow
      interactive
      enterTouchDelay={700}
      title={getTooltipContent()}
    >
      {children}
    </Tooltip>
  )
}

export default React.memo(DonutTooltip)
