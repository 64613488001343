import { api } from '../utils/api'

export const downloadDiscussionDocument = async (
  uuid: string,
  playerUuid?: string
) => {
  const endpoint = `overview/player/${playerUuid}/report/discussion/${uuid}/document`
  const response = await api.get(endpoint, {
    responseType: 'blob',
  })
  return response.data
}
