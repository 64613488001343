import React from 'react'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'
import MuiCircularProgress from '@material-ui/core/CircularProgress'

import { ReactComponent as LoadingLogo } from 'src/assets/images/loadingLogo.svg'

const Container = styled(MuiContainer)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    padding: ${theme.spacing(12, 0, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(9, 0, 0)};
    }
  `
)

const CircularProgress = styled(MuiCircularProgress)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(1)}px;
  `
)

const BlackLoadingLogo = styled(LoadingLogo)`
  path,
  g {
    fill: #000;
  }
`

export type GenericLoaderProps = {
  text: React.ReactNode
}

const GenericLoader: React.FC<GenericLoaderProps> = ({ text, ...props }) => {
  return (
    <Container {...props}>
      <BlackLoadingLogo />
      <Box display="flex" mt={3}>
        <Typography variant="h5">{text}</Typography>
        <CircularProgress size={20} thickness={5} />
      </Box>
    </Container>
  )
}

export default GenericLoader
