import React, { useContext, useState } from 'react'
import CountUp from 'react-countup'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { useTheme } from '@material-ui/core'
import Typography, { TypographyProps } from '@material-ui/core/Typography'

import {
  getSummary,
  donutInfoSelector,
  summaryProgressSelector,
} from 'src/store/summarySlice'
import { fadeIn } from 'src/utils/animations'
import ProgressDonut, {
  GoalIcon,
  BackgroundRing,
} from 'src/components/dataDisplay/ProgressDonut'
import { TranslationContext } from 'src/utils/TranslationContext'
import DonutTooltip from 'src/modules/player-dash/donut/DonutTooltip'
import BenchmarkSelection from 'src/components/feedback/BenchmarkSelection'

const I18N_KEY = 'DashboardDonut'

interface Props {
  valid: boolean
  loading: boolean
}

const DONUT_SIZE = 150

const DonutContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    flex-direction: column;
    width: ${DONUT_SIZE}px;
    height: ${DONUT_SIZE}px;
    justify-content: center;

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(6, 0, 0)};
    }
  `
)

const fadeInAnimation = css`
  animation: ${fadeIn} 0.4s ease-in forwards;
`

const TypographyElementP = (props: TypographyProps<'p'>) => (
  <Typography component="p" {...props} />
)

const Value = styled(TypographyElementP)(
  ({ theme }) => css`
    font-weight: bold;
    color: ${theme.palette.background.paper};
    ${fadeInAnimation}

    @media (hover: none) {
      user-select: none;
    }
  `
)

const Benchmark = styled(TypographyElementP)(
  ({ theme }) => css`
    color: #b2b2b2;
    max-width: 100px;
    margin-top: ${theme.spacing(-1)}px;
    ${fadeInAnimation}

    @media (hover: none) {
      user-select: none;
    }
  `
)

const DashboardDonut: React.FC<Props> = ({ valid, loading }) => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const { getBenchmarkName } = useContext(TranslationContext)!

  const progressInfo = useSelector(summaryProgressSelector)
  const donutInfo = useSelector(donutInfoSelector)

  const progress = progressInfo?.progressValue as number
  const toBenchmark = progressInfo?.toBenchmark as number

  const below = donutInfo.benchmarkDetails?.below
  const current = donutInfo.benchmarkDetails?.current
  const benchmarkLabel = getBenchmarkName(current?.id)

  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const getDonutContent = () => {
    if (loading) {
      return (
        <BackgroundRing
          $progress={0}
          $opacity={0.4}
          stroke={theme.palette.primary.light}
        />
      )
    }

    if (valid) {
      return (
        <>
          <ProgressDonut progress={progress} showGoalIcon />
          <Value variant="h2">
            <CountUp
              decimals={2}
              duration={1.5}
              useEasing={false}
              end={Number(toBenchmark?.toFixed(2))}
            />
          </Value>
          <Benchmark align="center" variant="h6" color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.vsBenchmarkLabel`}>
              vs {{ benchmarkLabel }}
            </Trans>
          </Benchmark>
        </>
      )
    }

    return (
      <>
        <BackgroundRing
          $progress={0}
          $opacity={0.4}
          stroke={theme.palette.primary.light}
        />
        <GoalIcon />
        <Benchmark align="center" variant="h6" color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.noRounds`}>No Rounds</Trans>
        </Benchmark>
      </>
    )
  }

  const handleChangeGoal = () => {
    if (current) {
      setIsDialogOpen(true)
    }
  }

  const handleDialogClose = async (selectedBenchmark: string) => {
    if (selectedBenchmark && selectedBenchmark !== current.id) {
      dispatch(getSummary())
    }
    setIsDialogOpen(false)
  }

  return (
    <>
      <DonutTooltip
        below={below}
        valid={valid}
        loading={loading}
        current={current}
        toBenchmark={toBenchmark}
        onChangeGoal={handleChangeGoal}
        strokesGained={donutInfo.strokesGained}
      >
        <DonutContainer onClick={handleChangeGoal}>
          {getDonutContent()}
        </DonutContainer>
      </DonutTooltip>
      {current && (
        <BenchmarkSelection
          open={isDialogOpen}
          handleClose={handleDialogClose}
        />
      )}
    </>
  )
}

export default DashboardDonut
