import React, { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { Box, Typography } from '@material-ui/core'

import {
  Form,
  Container,
  StyledLink,
  TitleContainer,
} from 'src/modules/auth/common'
import { api } from 'src/utils/api'
import useLogin from 'src/hooks/useLogin'
import { login } from 'src/store/authSlice'
import { hashPassword } from 'src/utils/crypto'
import LoaderButton from 'src/components/inputs/LoaderButton'
import { getErrorToast, openToast } from 'src/store/toastSlice'
// To be added back in future
// import HookRecaptcha from 'src/components/inputs/HookRecaptcha'
import HookDatePicker from 'src/components/inputs/HookDatePicker'
import { createRegisterSchema } from 'src/utils/validationSchemas'
import HookToggleButton from 'src/components/inputs/HookToggleButton'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import HookPasswordField from 'src/components/inputs/HookPasswordField'
import { Routes, Gender, Unit, PlayerType, UserType } from 'src/utils/constants'
import { trackEvent } from 'src/utils/analytics'

const I18N_KEY = 'Register'

interface FormValues {
  email: string
  unit: string
  gender: string
  password: string
  userType: string
  lastName: string
  firstName: string
  playerType: string
  // To be added back in future
  // recaptchaToken: string
  confirmPassword: string
  providerCode: string
}

const motionProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}

const RegisterProvider: React.FC = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { isLoggedIn } = useLogin()
  const { t, i18n } = useTranslation()
  const [providerCode, setProviderCode] = useState<string>()

  const query = new URLSearchParams(useLocation().search)
  // get provider name
  const { providerName } = useParams<{ providerName: string }>()
  // get provider code
  const code = query.get('code')
  // redirect if code not present
  if (!code) {
    // todo: parameterise
    const appid = 'wxae941f9f92f18304'
    const path = window.location.pathname
    const redirectUrl = `http://drawmorecircles.com.cn${path}`
    const redirectUrlEncoded = encodeURIComponent(redirectUrl)
    const url = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${redirectUrlEncoded}&response_type=code&scope=snsapi_login#wechat_redirect`
    window.location.href = url
  }

  const inviteUUID = query.get('inviteUUID')

  const { language } = i18n

  const validationSchema = useMemo(() => createRegisterSchema(t), [t])

  const userTypeOptions = [
    { label: t(`Enums:UserType.${UserType.Player}`), value: UserType.Player },
    {
      label: t(`Enums:UserType.${UserType.Coach}`),
      value: UserType.Coach,
    },
  ]

  const genderOptions = [
    { label: t(`Enums:Gender.${Gender.Male}`), value: Gender.Male },
    {
      label: t(`Enums:Gender.${Gender.Female}`),
      value: Gender.Female,
    },
  ]

  const unitOptions = [
    { label: t(`Enums:Unit.${Unit.Metric}`), value: Unit.Metric },
    {
      label: t(`Enums:Unit.${Unit.Imperial}`),
      value: Unit.Imperial,
    },
  ]

  const playerTypeOptions = [
    {
      label: t(`Enums:PlayerType.${PlayerType.Amateur}`),
      value: PlayerType.Amateur,
    },
    { label: t(`Enums:PlayerType.${PlayerType.Pro}`), value: PlayerType.Pro },
  ]

  const { errors, control, formState, handleSubmit, setValue } =
    useForm<FormValues>({
      mode: 'onBlur',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        email: '',
        password: '',
        lastName: '',
        firstName: '',
        unit: Unit.Metric,
        // To be added back in future
        // recaptchaToken: '',
        confirmPassword: '',
        gender: Gender.Male,
        userType: UserType.Player,
        playerType: PlayerType.Amateur,
        providerCode: '',
      },
    })

  const { isDirty, isValid, isSubmitting } = formState

  const onSubmit = handleSubmit(
    async ({ password, confirmPassword, ...values }) => {
      try {
        await api.post('user/register', {
          language,
          inviteUUID,
          password: hashPassword(password),
          ...values,
        })

        trackEvent('Signed Up', {
          email: values.email,
          username: values.email,
          last_name: values.lastName,
          user_type: values.userType,
          first_name: values.firstName,
          type: inviteUUID ? 'invited' : 'organic',
        })

        await dispatch(login({ password, email: values.email }, false))
      } catch (e) {
        dispatch(
          openToast(
            getErrorToast(
              t(
                `${I18N_KEY}.apiRegistrationError`,
                'Something went wrong. Please try again'
              )
            )
          )
        )
      }
    }
  )

  // Once user is registered, navigate them to their dashboard
  useEffect(() => {
    setValue('providerCode', `${providerName}:${code}`)
    setProviderCode(`${providerName}:${code}`)
    if (isLoggedIn) {
      history.replace(Routes.Dashboard)
    }
  }, [history, isLoggedIn, code, providerName, setValue])

  if (!code) {
    return <Container></Container>
  }

  return (
    <Container {...motionProps}>
      <TitleContainer>
        <Typography variant="h1" align="center">
          <Trans i18nKey={`${I18N_KEY}.title`}>Create account</Trans>
        </Typography>
        <Box mt={1} component="span" display="flex" justifyContent="center">
          <Typography color="textSecondary">
            <Trans i18nKey={`${I18N_KEY}.hasAccount`}>
              Already have an account?
            </Trans>
          </Typography>
          <StyledLink to={Routes.SignIn}>
            <Trans i18nKey={`${I18N_KEY}.signIn`}>Sign in</Trans>
          </StyledLink>
        </Box>
      </TitleContainer>
      <Form onSubmit={onSubmit}>
        <Box mb={4}>
          <HookFormTextField
            name="email"
            type="email"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.emailLabel`, 'Email')}
            placeholder={t(`${I18N_KEY}.emailPlaceholder`, 'Your email')}
          />
        </Box>
        <Box mb={4}>
          <HookPasswordField
            name="password"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.passwordLabel`, 'Password')}
            placeholder={t(`${I18N_KEY}.passwordPlaceholder`, '8+ characters')}
          />
        </Box>
        <Box mb={4}>
          <HookPasswordField
            errors={errors}
            control={control}
            name="confirmPassword"
            label={t(`${I18N_KEY}.confirmPasswordLabel`, 'Confirm password')}
            placeholder={t(`${I18N_KEY}.passwordPlaceholder`, '8+ characters')}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="userType"
            control={control}
            options={userTypeOptions}
            label={t(`${I18N_KEY}.userType`, 'Sign up as')}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="gender"
            control={control}
            options={genderOptions}
            label={t(`${I18N_KEY}.gender`, 'Gender')}
          />
        </Box>
        <Box mb={4}>
          <HookFormTextField
            name="firstName"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.firstName`, 'First name')}
            placeholder={t(`${I18N_KEY}.firstName`, 'First name')}
          />
        </Box>
        <Box mb={4}>
          <HookFormTextField
            name="lastName"
            errors={errors}
            control={control}
            label={t(`${I18N_KEY}.lastName`, 'Last name')}
            placeholder={t(`${I18N_KEY}.lastName`, 'Last name')}
          />
        </Box>
        <Box mb={4}>
          <HookDatePicker
            name="dob"
            control={control}
            label={t(`${I18N_KEY}.dob`, 'Date of birth')}
            datePickerProps={{
              openTo: 'year',
              views: ['year', 'month', 'date'],
            }}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="unit"
            control={control}
            options={unitOptions}
            label={t(`${I18N_KEY}.units`, 'Scoring units')}
          />
        </Box>
        <Box mb={4}>
          <HookToggleButton
            name="playerType"
            control={control}
            options={playerTypeOptions}
            label={t(`${I18N_KEY}.playerType`, 'Player type')}
          />
        </Box>
        <Box hidden mb={4}>
          <HookFormTextField
            hidden
            name="providerCode"
            errors={errors}
            control={control}
            value={providerCode}
          />
        </Box>
        {/* To be added back in future */}
        {/* <Box mb={5}>
          <HookRecaptcha
            control={control}
            trigger={trigger}
            name="recaptchaToken"
            label={t(
              `${I18N_KEY}.challenge`,
              'Please complete the challenge below'
            )}
          />
        </Box> */}
        <LoaderButton
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          loading={isSubmitting}
          disabled={!isDirty || !isValid}
        >
          {t(`${I18N_KEY}.buttonLabel`, 'Create account')}
        </LoaderButton>
      </Form>
    </Container>
  )
}

export default RegisterProvider
