import React from 'react'
import { useTranslation } from 'react-i18next'
import useBreakpoints from 'src/hooks/useBreakpoints'

import { Grid } from '@material-ui/core'

import { CategoryType, MetricId } from 'src/utils/golfConstants'
import { CategoryTypeFromMetricId, fillMixedGraphPoints } from '../util/helper'
import DashboardGraph from 'src/modules/player-dash/strokesGained/DashboardGraph'
import { PracticeActivity } from 'src/models/practice-activity'
import { PracticeResult } from 'src/models/practice-summary'
import { SummaryGraphPoint, SummarySingleItem } from 'src/store/summarySlice'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import ErrorCard from 'src/components/dataDisplay/ErrorCard'

const I18N_KEY = 'PracticeStrokesGainedGraph'

interface Props {
  strokesGainedData: SummarySingleItem
  practiceResults: PracticeResult[]
  category: string | CategoryType
  isSubcategory: boolean
  isLoading: boolean
}

const PracticeStrokesGainedGraph: React.FC<Props> = ({
  strokesGainedData,
  practiceResults,
  category,
  isSubcategory,
  isLoading,
}) => {
  const { t } = useTranslation()
  const { isSmallScreen } = useBreakpoints()

  const getUniqueDatesForMetric = (
    practices: PracticeResult[],
    metricId: string,
    isSubcategory: boolean
  ): Map<number, { planName: string; planUuid: string }[]> => {
    const uniqueDates = new Map<
      number,
      { planName: string; planUuid: string; activities?: string[] }[]
    >()

    practices.forEach((result: PracticeResult) => {
      const useActivities =
        metricId === MetricId.All ||
        (!isSubcategory &&
          result.category === CategoryTypeFromMetricId(metricId as MetricId)) ||
        (isSubcategory && result.subcategories.includes(metricId as MetricId))

      if (useActivities) {
        result.activities.forEach((activity: PracticeActivity) => {
          const dateKey = new Date(activity.datePlayed).setHours(0, 0, 0, 0)
          const planName =
            activity.planName === 'Circles Default'
              ? 'Circles'
              : activity.planName
          if (uniqueDates.has(dateKey)) {
            const existingPracticeSession = uniqueDates.get(dateKey)!
            existingPracticeSession.push({
              planName: planName,
              planUuid: activity.planUuid,
            })
            existingPracticeSession.forEach(session => {
              if (session.planUuid === activity.planUuid) {
                if (!session.activities) {
                  session.activities = []
                }
                if (!session.activities.includes(activity.activityId)) {
                  session.activities.push(activity.activityId)
                }
              }
            })
          } else {
            uniqueDates.set(dateKey, [
              {
                planName: planName,
                planUuid: activity.planUuid,
                activities: [activity.activityId],
              },
            ])
          }
        })
      }
    })

    return uniqueDates
  }

  let practiceDates = getUniqueDatesForMetric(
    practiceResults,
    category,
    isSubcategory
  )
  practiceDates = new Map(
    Array.from(practiceDates.entries()).sort((a, b) => a[0] - b[0])
  )

  if (!strokesGainedData) {
    // When there is no data available for subcategory/time show default nodata message
    strokesGainedData = {
      benchmark: 0,
      data: [],
      sgTotal: null,
      progress: 0,
    }
  }

  const roundGraphPoints: SummaryGraphPoint[] = strokesGainedData.data

  const practiceGraphPoints: SummaryGraphPoint[] = []
  practiceDates.forEach((practiceSessions, key) => {
    const uniquePracticeSessions = practiceSessions.reduce<
      { planName: string; planUuid: string }[]
    >(
      (uniqueSessions, session) =>
        uniqueSessions.some(existing => existing.planUuid === session.planUuid)
          ? uniqueSessions
          : [...uniqueSessions, session],
      []
    )

    practiceGraphPoints.push({
      uuid: '',
      average: 0,
      benchmark: 0,
      roundTitle: t(`${I18N_KEY}.practiceSession`, 'Practice Sessions'),
      value: 0,
      datePlayed: key,
      rollingAverage: null,
      roundType: 'Practice',
      events: uniquePracticeSessions,
    })
  })

  strokesGainedData.data = fillMixedGraphPoints(
    roundGraphPoints,
    practiceGraphPoints
  )

  return isLoading ? (
    <Skeleton variant="rect" width="100%" height={350} animation="wave" />
  ) : (
    <Grid item xs={12}>
      {strokesGainedData.data.length === 0 ? (
        <ErrorCard
          errorMessage={t(
            `${I18N_KEY}.noPracticeSessionsError`,
            'No practice sessions match your selected filters'
          )}
        />
      ) : (
        <DashboardGraph
          showLegend
          width="100%"
          height={220}
          payload={strokesGainedData}
          isCollapsable={false}
          type={category as CategoryType}
          isSmallScreen={isSmallScreen}
          title={
            category === MetricId.All
              ? t(`Enums:CategoryMetricLabels.${category}`)
              : t(`Enums:MetricLabels.${category}`)
          }
          showDetailedView={false}
          isPracticeDashboard
        />
      )}
    </Grid>
  )
}

export default PracticeStrokesGainedGraph
