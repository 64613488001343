import React from 'react'
import styled from 'styled-components'

import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import TextField, { StandardTextFieldProps } from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

const StyledTextField = styled(TextField)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;

  .MuiFilledInput-root {
    border-radius: 4px;
  }

  .MuiFilledInput-input {
    padding: ${({ theme }) => theme.spacing(2, 1.5)};
  }

  .MuiFilledInput-underline:before {
    display: none;
  }

  .MuiInputAdornment-filled
    .MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin: 0;
  }

  svg {
    fill: #b2b2b2;
  }
`

const ClearSearchButton = styled(IconButton)`
  background: #ffffff;
  padding: 6px;

  :hover {
    background: #ffffff;
  }

  svg {
    width: 0.7em;
    height: 0.7em;
  }
`

interface Props extends StandardTextFieldProps {
  cleanAriaLabel: string
  onCleanSearch: () => void
}

const SearchTextField: React.FC<Props> = ({
  value,
  onCleanSearch,
  cleanAriaLabel,
  ...props
}) => {
  return (
    <StyledTextField
      variant="filled"
      fullWidth
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <ClearSearchButton
              onClick={onCleanSearch}
              aria-label={cleanAriaLabel}
            >
              <CloseIcon />
            </ClearSearchButton>
          </InputAdornment>
        ) : null,
      }}
      value={value}
      {...props}
    />
  )
}

export default SearchTextField
