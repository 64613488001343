import React from 'react'

const CourseStrategyIcon: React.FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.92 2.26L19.43 5.77C20.19 6.18 20.19 7.35 19.43 7.76L12.92 11.27C12.34 11.58 11.66 11.58 11.08 11.27L4.57 7.76C3.81 7.35 3.81 6.18 4.57 5.77L11.08 2.26C11.66 1.95 12.34 1.95 12.92 2.26Z"
      stroke="#FFFFFF"
    />
    <path
      d="M3.61 10.13L9.66 13.16C10.41 13.54 10.89 14.31 10.89 15.15V20.87C10.89 21.7 10.02 22.23 9.28 21.86L3.23 18.83C2.48 18.45 2 17.68 2 16.84V11.12C2 10.29 2.87 9.76 3.61 10.13Z"
      stroke="#FFFFFF"
    />
    <path
      d="M20.3899 10.13L14.3399 13.16C13.5899 13.54 13.1099 14.31 13.1099 15.15V20.87C13.1099 21.7 13.9799 22.23 14.7199 21.86L20.7699 18.83C21.5199 18.45 21.9999 17.68 21.9999 16.84V11.12C21.9999 10.29 21.1299 9.76 20.3899 10.13Z"
      stroke="#FFFFFF"
    />
  </svg>
)

export default React.memo(CourseStrategyIcon)
