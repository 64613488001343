import React from 'react'
import { NavLinkProps, NavLink as ReactRouterNavLink } from 'react-router-dom'
import { localizeLocationDescriptor } from 'src/utils/i18n'
import { useTranslation } from 'react-i18next'
import { Languages } from 'src/utils/constants'

export const NavLink: React.FC<NavLinkProps> = ({ to, ...rest }) => {
  const { i18n } = useTranslation()
  return (
    <ReactRouterNavLink
      {...rest}
      to={localizeLocationDescriptor(to, i18n.language as Languages)}
    />
  )
}

export default NavLink
