import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, useTranslation } from 'react-i18next'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'

import LoaderButton from 'src/components/inputs/LoaderButton'
import { Label } from 'src/components/inputs/FormTextField'
import { createInviteFormSchema } from 'src/utils/validationSchemas'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import { invite } from 'src/store/invitationSlice'

const I18N_KEY = 'InviteDialog'

const Title = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(2.5, 0, 1, 0)};
  `
)

interface InviteFormFields {
  email: string
  recaptchaToken: string
}

interface InviteDialogProps extends Omit<DialogProps, 'onError'> {
  onCancel?: () => void
  onSuccess?: (email: string) => void
  onError?: (email: string) => void
}
const InviteDialog: React.FC<InviteDialogProps> = ({
  onCancel,
  onSuccess,
  onError,
  onClose,
  ...props
}) => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dispatch = useDispatch()
  const validationSchema = useMemo(() => createInviteFormSchema(t), [t])
  const { formState, control, handleSubmit, errors } =
    useForm<InviteFormFields>({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        email: '',
        recaptchaToken: '',
      },
    })
  const { isSubmitting, isValid, isDirty } = formState

  const handleFormSubmission = handleSubmit(async values => {
    const { email } = values
    try {
      try {
        if (!executeRecaptcha) return
        values.recaptchaToken = await executeRecaptcha('invite')
      } catch (error) {
        values.recaptchaToken = ''
      }

      await dispatch(invite(email, values.recaptchaToken))
      if (onSuccess) {
        onSuccess(email)
      }
    } catch (error: any) {
      if (onError) {
        onError(email)
      }
    }
  })

  const handleDialogClose = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (onClose) {
      onClose(event, reason)
    }
    if (onCancel) {
      onCancel()
    }
  }

  const handleCancelButtonClick = () => {
    if (onCancel) {
      onCancel()
    }
  }

  return (
    <Dialog onClose={handleDialogClose} {...props}>
      <DialogContent>
        <Title variant="h4">
          <Trans i18nKey={`${I18N_KEY}.title`}>Add player</Trans>
        </Title>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.description`}>
            Players will need to confirm the connection before you have access
            to their dashbaord.
          </Trans>
        </Typography>
        <Box mt={4} mb={5}>
          <Label
            variant="caption"
            color={!isValid && isDirty ? 'error' : undefined}
          >
            <Trans i18nKey={`${I18N_KEY}.emailLabel`}>Player Email</Trans>
          </Label>
          <HookFormTextField
            control={control}
            name="email"
            fullWidth
            errors={errors}
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleCancelButtonClick} disabled={isSubmitting}>
          <Trans i18nKey={`${I18N_KEY}.cancelButtonLabel`}>Cancel</Trans>
        </Button>
        <LoaderButton
          loading={isSubmitting}
          disabled={!isValid}
          onClick={handleFormSubmission}
          variant="contained"
          color="primary"
        >
          <Trans i18nKey={`${I18N_KEY}.inviteButtonLabel`}>Add Player</Trans>
        </LoaderButton>
      </DialogActions>
    </Dialog>
  )
}

export default InviteDialog
