import React from 'react'
import styled, { css } from 'styled-components'
import { Control, Controller } from 'react-hook-form'

import ToggleButtonGroup, {
  ToggleButtonGroupProps,
} from '@material-ui/lab/ToggleButtonGroup'
import Typography from '@material-ui/core/Typography'
import ToggleButton from '@material-ui/lab/ToggleButton'

interface RequiredProps {
  name: string
  label?: string
  options: Options
  control: Control
  defaultValue?: string
}

interface Option {
  label: string
  value: string
}

type Options = Option[]

type Props = ToggleButtonGroupProps & RequiredProps

const ButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => css`
    width: 100%;
    border: none;
    padding: 4px;
    border-radius: 6px;
    background-color: #f5f5f5;
    margin: ${theme.spacing(1, 0, 0)};
  `
)

const Button = styled(ToggleButton)(
  ({ theme }) => css`
    flex: 1;
    border: none;
    text-transform: none;
    background-color: #f5f5f5;
    border-radius: 4px !important;
    color: ${theme.palette.text.primary};
    padding: 11px ${theme.spacing(2.5)}px;
    transition: color 0.3s ease-out, background-color 0.3s ease-out,
      box-shadow 0.3s ease-out;

    &:hover {
      background-color: #f5f5f5;
      color: ${theme.palette.primary.main};
    }

    &.MuiToggleButton-root {
      letter-spacing: 0;
    }

    &.MuiToggleButton-root.Mui-selected {
      color: ${theme.palette.primary.main};
      box-shadow: 0px 0px 15px rgba(43, 42, 98, 0.1);
      background-color: ${theme.palette.background.paper};
    }
  `
)

const FormToggleButton: React.FC<Props> = ({
  name,
  label,
  control,
  options,
  className,
  defaultValue,
  ...props
}) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ value: currentValue, onChange, ...renderProps }) => {
      const handleSelection = (
        event: React.MouseEvent<HTMLElement>,
        selection: string
      ) => {
        if (selection) {
          onChange(selection)
        }
      }

      return (
        <div className={className}>
          {label && <Typography variant="caption">{label}</Typography>}
          <ButtonGroup
            exclusive
            value={currentValue}
            onChange={handleSelection}
            {...props}
            {...renderProps}
          >
            {options.map(({ label, value }) => (
              <Button key={value} value={value}>
                {label}
              </Button>
            ))}
          </ButtonGroup>
        </div>
      )
    }}
  />
)

export default React.memo(FormToggleButton)
