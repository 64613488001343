import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const I18N_KEY = 'CourseStrategyError'

const Card = styled(Paper)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(16, 4)};
  `
)

const ErrorText = styled(Typography)`
  max-width: 600px;
`

const CourseStrategyError: React.FC = () => {
  const { t } = useTranslation()

  const errorMessage = useMemo(() => {
    return t(
      `${I18N_KEY}.roundRestriction`,
      "Once you've entered 3 rounds data you'll be able to add Course Strategies."
    )
  }, [t])

  return (
    <Card elevation={0}>
      <ErrorText color="textSecondary" align="center">
        {errorMessage}
      </ErrorText>
    </Card>
  )
}

export default CourseStrategyError
