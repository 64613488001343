import React from 'react'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { DECIMAL_PLACES } from 'src/utils/constants'
import { getAbsolutelyFixedNumber } from 'src/utils/helpers'
import ScoreCard, {
  ScoreCardItemType,
} from 'src/components/dataDisplay/ScoreCard'
import { Score, StrokesGainedValue } from 'src/utils/golfConstants'
import { SummaryContainer, FadingDotIllustration } from 'src/modules/common'

const I18N_KEY = 'RoundsSummaryScoreDetails'

interface Props {
  score?: Score
  benchmarkName: string
  strokesGained: StrokesGainedValue | undefined
}

const CardsContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    margin: ${theme.spacing(2, 0, 0)};

    & > div:first-child {
      margin-right: ${theme.spacing(2)}px;
    }

    ${theme.breakpoints.down('sm')} {
      & > div:first-child {
        margin: ${theme.spacing(0, 2, 0, 0)};
      }
    }
  `
)

const StyledFadingDotIllustration = styled(FadingDotIllustration)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

/*
 * Duplicate of rounds summary for Partner API implementation
 * TODO: Refactor to remove duplication and allow partner API to use existing code
 * The duplication allows up to easily remove some UI elements and make our own request
 * to the backend partner's API endpoint
 */
const ScoreDetails: React.FC<Props> = ({
  score,
  benchmarkName,
  strokesGained,
}) => {
  const { t } = useTranslation()
  const sgGap =
    (strokesGained?.overall as number) - (strokesGained?.benchmark as number)
  const sgIsNegative = sgGap < 0
  const description = sgIsNegative
    ? t(`${I18N_KEY}.descriptionBelow`, 'below')
    : t(`${I18N_KEY}.descriptionAbove`, 'above')

  const strokesGainedFormatted = strokesGained?.overall?.toFixed(DECIMAL_PLACES)
  const sgGapFormatted = getAbsolutelyFixedNumber(sgGap)

  const playerScore = [
    {
      title: t(`${I18N_KEY}.strokes`, 'Strokes'),
      value: score?.total as number,
    },
    {
      title: t(`${I18N_KEY}.par`, 'Par'),
      value: score?.par as number,
    },
    {
      title: t(`${I18N_KEY}.toPar`, 'To Par'),
      value:
        score && score.toPar > 0
          ? `+${score?.toPar}`
          : (score?.toPar as number),
    },
  ]

  const playerStrokesGained = [
    {
      title: t(`${I18N_KEY}.strokesGained`, 'Strokes Gained'),
      value: strokesGained?.overall?.toFixed(DECIMAL_PLACES) as string,
    },
    {
      title: benchmarkName,
      value: strokesGained?.benchmark?.toFixed(DECIMAL_PLACES) as string,
    },
    {
      title: t(`${I18N_KEY}.gap`, 'Gap'),
      value: sgGapFormatted,
      type:
        sgGap >= 0 ? ScoreCardItemType.Positive : ScoreCardItemType.Negative,
    },
  ]

  return (
    <SummaryContainer>
      <StyledFadingDotIllustration />
      <Container>
        <Typography variant="h3">
          <Trans i18nKey={`${I18N_KEY}.summaryTitle`}>Round Summary</Trans>
        </Typography>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.summary`}>
            You scored a total of{' '}
            <strong>{{ strokesGainedFormatted }}SG</strong> this round.
          </Trans>
        </Typography>
        <Typography>
          <Trans i18nKey={`${I18N_KEY}.description`}>
            Which means it was {{ description }} {{ benchmarkName }} by{' '}
            <Box
              component="span"
              fontWeight={500}
              color={sgIsNegative ? 'error.main' : 'success.main'}
            >
              <>{{ sgGapFormatted }}SG</>
            </Box>
            .
          </Trans>
        </Typography>
        <CardsContainer>
          <ScoreCard items={playerScore} />
          <ScoreCard items={playerStrokesGained} />
        </CardsContainer>
      </Container>
    </SummaryContainer>
  )
}

export default React.memo(ScoreDetails)
