import React from 'react'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'

import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import ForecastGraph from 'src/components/graph/forecast'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import { totalRoundsSelector } from 'src/store/roundSlice'
import UpgradePanel from 'src/components/membership/UpgradePanel'
import ReferencedTitle from 'src/components/dataDisplay/ReferencedTitle'
import { SummaryStatus } from 'src/store/summarySlice'
import PredictionError from 'src/modules/player-dash/prediction/PredictionError'
import { PlanType } from 'src/utils/subscriptionConstants'
import { forecastDataSelector, ForecastStatus } from 'src/store/forecastSlice'

const I18N_KEY = 'PredictionModel'

interface PredictionModelProps {
  summaryStatus: SummaryStatus
  forecastStatus: ForecastStatus
}

const Container = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(8, 0, 0)};
  `
)

const StyledReferencedTitle = styled(ReferencedTitle)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const PredictionModel: React.FC<PredictionModelProps> = ({
  summaryStatus,
  forecastStatus,
}) => {
  const { t } = useTranslation()

  const totalRounds = useSelector(totalRoundsSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const isPlayerTrialing = useSelector(userIsTrialingSelector)
  const { forecast, benchmarkInfo } = useSelector(forecastDataSelector)

  const getContent = () => {
    if (!isPlayerTrialing && !isPlayerPremium) {
      return (
        <UpgradePanel planType={PlanType.Train}>
          <Trans i18nKey={`${I18N_KEY}.upgradeDescription`}>
            Prediction Model displays your potential improvement trajectory.
            This is calculated using Circles advanced Artificial Intelligence.
            After you have entered 20 rounds, we will automatically display your
            Strokes Gained potential looking forward 12 weeks.
          </Trans>
        </UpgradePanel>
      )
    }

    if (
      (summaryStatus === SummaryStatus.UnInitialised ||
        summaryStatus === SummaryStatus.Loading ||
        summaryStatus === SummaryStatus.Valid) &&
      forecastStatus === ForecastStatus.Loading
    ) {
      return (
        <Skeleton variant="rect" width="100%" height={350} animation="wave" />
      )
    }

    if (totalRounds < 20) {
      return <PredictionError summaryStatus={SummaryStatus.NoRoundsError} />
    }

    if (
      summaryStatus === SummaryStatus.Valid &&
      forecastStatus === ForecastStatus.Valid
    ) {
      return <ForecastGraph forecast={forecast} benchmarkInfo={benchmarkInfo} />
    }

    return <PredictionError summaryStatus={summaryStatus} />
  }

  return (
    <Container>
      <StyledReferencedTitle
        title={t(`${I18N_KEY}.score`, 'Prediction Model')}
        tooltipText={t(
          `${I18N_KEY}.tooltip`,
          'Circles has analysed your potential improvement trajectory based on your past 20 rounds. This graph shows your Strokes Gained potential looking forward 12 weeks.'
        )}
      />
      {getContent()}
    </Container>
  )
}

export default PredictionModel
