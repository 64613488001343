import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { useSelector } from 'react-redux'

import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import { getGreeting } from 'src/utils/helpers'
import Players from 'src/modules/coach-dash/Players'
import { coachNameSelector } from 'src/store/coachSlice'
import { TranslationContext } from 'src/utils/TranslationContext'

const CoachContainer = styled.div`
  width: 100%;
  display: flex;
  min-height: 100%;
  position: absolute;
  flex-direction: column;
`

const DashHero = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(10, 0)};
    background-color: ${theme.palette.background.black};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(5, 0)};
    }
  `
)

const HeroText = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.background.paper};
  `
)

const Content = styled.div(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(6, 0, 16)};
    background-color: ${theme.palette.background.xlight};
  `
)

const CoachDashboard: React.FC = () => {
  const { firstName } = useSelector(coachNameSelector)
  const { greetingLabels } = useContext(TranslationContext)!

  const greeting = greetingLabels[getGreeting()]

  return (
    <CoachContainer>
      <DashHero>
        <Container>
          <HeroText variant="h1">
            {greeting} {firstName}
          </HeroText>
        </Container>
      </DashHero>
      <Content>
        <Container>
          <Players />
        </Container>
      </Content>
    </CoachContainer>
  )
}

export default CoachDashboard
