import React from 'react'

const DifferenceIcon: React.FC = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="6"
    fill="none"
    viewBox="0 0 9 6"
    {...props}
  >
    <path fill="#64696F" d="M5 0H0l2.5 4L5 0z" />
    <path fill="#B2B2B2" d="M9 6H4l2.5-4L9 6z" />
  </svg>
)

export default React.memo(DifferenceIcon)
