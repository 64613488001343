import React from 'react'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Check } from '@material-ui/icons'
import { Button, Typography } from '@material-ui/core'

import { userSelector } from 'src/store/userSlice'
import { updateVerifyDialog } from 'src/store/authSlice'
import HookFormTextField from 'src/components/inputs/HookFormTextField'
import useResendEmailVerification from 'src/hooks/useResendEmailVerification'

const I18N_KEY = 'AccountInformation'

const EmailContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    max-width: 650px;
    align-items: flex-end;
    margin: ${theme.spacing(2, 0, 0)};

    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

const VerifyButton = styled(Button)(
  ({ theme }) => css`
    width: 85px;
    padding: 15px 16px;
    margin-left: ${theme.spacing(2)}px;

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(2, 0, 0)};
    }
  `
)

const VerifiedStatus = styled.div(
  ({ theme }) => css`
    width: 140px;
    height: 55px;
    display: flex;
    font-weight: 500;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    margin-left: ${theme.spacing(2)}px;
    color: ${theme.palette.primary.main};
    border: 1px solid ${theme.palette.background.light};

    svg {
      margin-right: ${theme.spacing(1)}px;
      font-size: ${theme.typography.pxToRem(18)};
    }

    ${theme.breakpoints.down('xs')} {
      margin: ${theme.spacing(2, 0, 0)};
    }
  `
)

const AccountInformation: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { email, userVerified } = useSelector(userSelector)

  const { resendVerificationEmail } = useResendEmailVerification(email)

  //   TODO: Allow email to be updated
  const { control } = useForm({ mode: 'onChange', defaultValues: { email } })

  const handleVerifyClick = () => {
    resendVerificationEmail()
    dispatch(updateVerifyDialog({ showVerifyDialog: true }))
  }

  return (
    <div>
      <Typography variant="h4">
        <Trans i18nKey={`${I18N_KEY}.title`}>Account Information</Trans>
      </Typography>
      <EmailContainer>
        <HookFormTextField
          disabled
          name="email"
          control={control}
          label={t(`${I18N_KEY}.email`, 'Email')}
        />
        {userVerified ? (
          <VerifiedStatus>
            <Check />
            <Trans i18nKey={`${I18N_KEY}.verified`}>Verified</Trans>
          </VerifiedStatus>
        ) : (
          <VerifyButton
            color="primary"
            variant="contained"
            onClick={handleVerifyClick}
          >
            <Trans i18nKey={`${I18N_KEY}.verify`}>Verify</Trans>
          </VerifyButton>
        )}
      </EmailContainer>
    </div>
  )
}

export default AccountInformation
