import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSummary,
  SummaryStatus,
  summaryStatusSelector,
} from '../../store/summarySlice'
import ImprovementOpportunities from '../player-dash/improvement'
import styled, { css } from 'styled-components'
import MuiContainer from '@material-ui/core/Container'
import useAppState from '../../hooks/useAppState'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
  userPlanSelector,
} from '../../store/subscriptionSlice'
import PredictionModel from '../player-dash/prediction'
import Strengths from './Strengths'
import { CategoryType } from '../../utils/golfConstants'
import useBreakpoints from '../../hooks/useBreakpoints'
import CategoryFilter from '../categories/CategoryFilter'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { Trans } from 'react-i18next'
import {
  ForecastStatus,
  forecastStatusSelector,
  getForecast,
} from 'src/store/forecastSlice'
import { totalRoundsSelector } from 'src/store/roundSlice'

const Container = styled.div<{ $addPagePadding: boolean }>(
  ({ theme, $addPagePadding }) => css`
    width: 100%;
    display: grid;
    min-height: 100%;
    position: absolute;
    background-color: ${theme.palette.background.xlight};
    padding: ${$addPagePadding
      ? theme.spacing(14, 0, 0)
      : theme.spacing(8, 0, 0)};
  `
)

const Header = styled(MuiContainer)(
  ({ theme }) => css`
    flex: 1;
    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

const Content = styled(MuiContainer)(
  ({ theme }) => css`
    flex: 1;
    margin-bottom: 32px;
    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

const Title = styled.div(
  ({ theme }) => css`
    padding-left: ${theme.spacing(2)}px;
  `
)

const I18N_KEY = 'Insight'

const InsightComponent: React.FC = () => {
  const dispatch = useDispatch()
  const { isPlayer } = useAppState()
  const summaryStatus = useSelector(summaryStatusSelector)

  const userNotSubscribed = !useSelector(userPlanSelector)
  const playerIsTrialing = useSelector(userIsTrialingSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const addPagePadding = isPlayer && (userNotSubscribed || playerIsTrialing)
  const totalRounds = useSelector(totalRoundsSelector)
  const forecastStatus = useSelector(forecastStatusSelector)

  const { isDesktop } = useBreakpoints()

  useEffect(() => {
    if (summaryStatus === SummaryStatus.UnInitialised) {
      dispatch(getSummary())
    }
  }, [summaryStatus, dispatch])

  useEffect(() => {
    if (
      forecastStatus === ForecastStatus.UnInitialised &&
      isPlayerPremium &&
      totalRounds >= 20
    ) {
      dispatch(getForecast())
    }
  }, [forecastStatus, isPlayerPremium, totalRounds, dispatch])

  return (
    <Container $addPagePadding={addPagePadding}>
      <Header>
        {!isDesktop && (
          <Title>
            <Typography variant="h2">
              <Trans i18nKey={`${I18N_KEY}.insight`}>Insight</Trans>
            </Typography>
          </Title>
        )}
        <CategoryFilter
          category={CategoryType.All}
          isLoading={summaryStatus === SummaryStatus.Loading}
          isDesktop={isDesktop}
        />
      </Header>
      <Divider />
      <Content>
        <Strengths summaryStatus={summaryStatus} />
        <ImprovementOpportunities summaryStatus={summaryStatus} />
        <PredictionModel
          summaryStatus={summaryStatus}
          forecastStatus={forecastStatus}
        />
      </Content>
    </Container>
  )
}

export default InsightComponent
