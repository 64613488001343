import {
  Report,
  ReportComments,
  ReportGeneration,
} from '../../../utils/golfConstants'
import useReportDetail from '../../../hooks/useReportDetail'
import { useDispatch, useSelector } from 'react-redux'
import {
  reportStatisticsSelector,
  updateComments,
} from '../../../store/reportSlice'
import { getErrorToast, openToast } from '../../../store/toastSlice'
import { Box, Typography } from '@material-ui/core'
import { MainContentBackground, XsPaddingZeroContainer } from '../../common'
import { Trans } from 'react-i18next'
import Score from '../ReportDetail/Score'
import StrokesGained from '../ReportDetail/StrokesGained'
import ReportIO from '../ReportDetail/io'
import ReportComment from '../ReportDetail/ReportComment'
import PerformanceIndicatorTable from '../../../components/dataDisplay/PerformanceIndicatorTable'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { TranslationContext } from '../../../utils/TranslationContext'
import { coachSelector } from '../../../store/coachSlice'
import ReportHeader from '../ReportHeader'

const SectionTitle = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 2)};

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(0, 2, 2)};
    }
  `
)

const I18N_KEY = 'ReportDetailDialog'

export interface GeneralReportDetailProps {
  report: Report
  isCoachView: boolean
  closeDialog: () => void
}

const GeneralReportDetail: React.FC<GeneralReportDetailProps> = ({
  report,
  isCoachView,
  closeDialog,
}) => {
  const dispatch = useDispatch()
  const { t } = useContext(TranslationContext)!
  const { uuid: userUuid } = useSelector(coachSelector)

  const {
    name,
    email,
    coachUuid,
    coachName,
    playerType,
    benchmarkName,
    referencePoint,
    generationType,
    coachProfileImageUrl,
  } = useReportDetail(report)

  const isCreatedByUser = userUuid === coachUuid

  const isCoachGenerated = generationType === ReportGeneration.Manual

  const generalComment = report?.comments?.general
  const practicePlanComment = report?.comments?.practicePlan

  const playerTypeLabel = playerType ? t(`Enums:PlayerType.${playerType}`) : ''

  const practicePlanTitle = t(`${I18N_KEY}.practicePlan`, 'Practice plan')
  const generalCommentTitle = t(`${I18N_KEY}.generalComment`, 'General comment')

  const performanceIndicators = useSelector(reportStatisticsSelector) || []

  const generateSaveComment = (param: string) => async (comment: string) => {
    const comments = {
      ...report?.comments,
      [param]: comment,
    } as ReportComments
    try {
      await dispatch(updateComments(report.uuid, comments))
      return true
    } catch {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.updateCommentErrorToast`, 'Unable to update comment')
          )
        )
      )
      return false
    }
  }

  return (
    <>
      <ReportHeader
        closeDialog={closeDialog}
        title={report.title}
        createdAt={report.createdAt}
        name={name}
        email={email!}
        playerType={playerTypeLabel}
        generationType={report.generationType}
        coachName={coachName}
        coachProfileImageUrl={coachProfileImageUrl!}
      />
      <MainContentBackground>
        <XsPaddingZeroContainer>
          <Box my={8}>
            <Score referencePoint={referencePoint} />
            <StrokesGained
              benchmarkName={benchmarkName}
              referencePoint={referencePoint}
            />
            <ReportIO
              isCoachView={isCoachView}
              comments={report.comments}
              benchmarkName={benchmarkName}
              referencePoint={referencePoint}
              isCreatedByUser={isCreatedByUser}
              isCoachGenerated={isCoachGenerated}
              generateSaveComment={generateSaveComment}
            />
            {isCoachGenerated && (
              <Box my={8}>
                <SectionTitle variant="h3">{practicePlanTitle}</SectionTitle>
                <ReportComment
                  isCoachView={isCoachView}
                  comment={practicePlanComment}
                  dialogTitle={practicePlanTitle}
                  isCreatedByUser={isCreatedByUser}
                  onChangeComment={generateSaveComment('practicePlan')}
                  addButtonLabel={t(`${I18N_KEY}.addPlan`, 'Add a plan')}
                />
              </Box>
            )}
            {isCoachGenerated && (
              <Box my={8}>
                <SectionTitle variant="h3">{generalCommentTitle}</SectionTitle>
                <ReportComment
                  comment={generalComment}
                  isCoachView={isCoachView}
                  isCreatedByUser={isCreatedByUser}
                  dialogTitle={generalCommentTitle}
                  addButtonLabel={t(`${I18N_KEY}.addComment`, 'Add comment')}
                  onChangeComment={generateSaveComment('general')}
                />
              </Box>
            )}
            <Box my={8}>
              <SectionTitle variant="h3">
                <Trans i18nKey={`${I18N_KEY}.statistics`}>Statistics</Trans>
              </SectionTitle>
              <PerformanceIndicatorTable
                performanceIndicators={performanceIndicators}
              />
            </Box>
          </Box>
        </XsPaddingZeroContainer>
      </MainContentBackground>
    </>
  )
}

export default GeneralReportDetail
