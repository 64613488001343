import React from 'react'
import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'

import RadarGraph from 'src/components/graph/radar'
import { CategoryType } from 'src/utils/golfConstants'
import StrokesComparisonTable from 'src/components/dataDisplay/StrokesComparisonTable'
import { breakpoints } from 'src/utils/constants'
interface Props {
  data: RadarData[]
  benchmarkName: string
  isRoundSummary: boolean
  isCompactSummary?: boolean
}

export interface RadarData {
  reference: number
  benchmark?: number
  category: CategoryType
  overall?: number | null
}

const Container = styled(Paper)(
  ({ theme }) =>
    css`
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: ${theme.spacing(2, 4)};

      ${theme.breakpoints.down(1000)} {
        flex-direction: column;
        justify-content: center;
      }

      ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(2, 2)};
      }
    `
)

const ComparisonTable = styled(StrokesComparisonTable)(
  ({ theme, isCompactSummary }) => css`
    width: 90%;
    overflow-x: ${isCompactSummary ? 'hidden' : 'auto'};
    margin-left: ${isCompactSummary ? '10em' : 'auto'};

    ${theme.breakpoints.down(1000)} {
      width: 100%;
      margin: auto;
    }

    ${theme.breakpoints.down(breakpoints.mobile)} {
      width: 100%;
    }
  `
)

const Radar = styled(RadarGraph)(
  ({ theme }) =>
    css`
      margin-left: ${theme.spacing(-4)}px;

      .recharts-surface {
        overflow: visible;
      }

      ${theme.breakpoints.down(1000)} {
        margin-left: ${theme.spacing(-10)}px;
      }

      ${theme.breakpoints.down(breakpoints.mobile)} {
        margin-left: ${theme.spacing(-4)}px;
      }

      ${theme.breakpoints.down(breakpoints.xxs)} {
        max-width: 210px;
      }
    `
)

const RadarContainer = styled.div(
  ({ theme }) => css`
    width: 90%;
    display: flex;
    justify-content: center;

    ${theme.breakpoints.down(breakpoints.mobile)} {
      width: 80%;
      flex-direction: column;
    }
  `
)

const RadarComparison: React.FC<Props> = ({
  data,
  benchmarkName,
  isRoundSummary,
  isCompactSummary = false,
  ...props
}) => (
  <Container elevation={0} {...props}>
    <RadarContainer>
      <Radar height={350} width="100%" data={data} />
    </RadarContainer>
    <ComparisonTable
      data={data}
      benchmarkName={benchmarkName}
      isRoundSummary={isRoundSummary}
      isCompactSummary={isCompactSummary}
    />
  </Container>
)

export default RadarComparison
