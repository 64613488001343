import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'

import {
  getErrorStatus,
  authRequestFail,
  isForbiddenOrUnauthorised,
  getSinglePayloadFromResponse,
} from 'src/utils/helpers'

import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { getBasicReducers } from 'src/utils/store'

import { State } from 'typings/store'
import { Plan } from 'src/models/plan'
import { RootState } from 'src/store/rootReducer'

const SLICE_NAME = 'plan'

type PlanState = State<Plan>

const initialState: PlanState = {
  data: null,
  requestInProgress: false,
  loaded: false,
  error: null,
}

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    ...getBasicReducers<Plan>(initialState),
    loaded: (state: PlanState, action: PayloadAction<Plan>) => {
      state.data = action.payload
      state.error = null
      state.requestInProgress = false
      state.loaded = true
    },
  },
})

export default reducer

// Actions
export const {
  loaded,
  reinitialise,
  requestInProgress,
  requestFinished,
  requestError,
} = actions

// Selectors

export const planState = (state: RootState) => state[SLICE_NAME]

export const planSelector = createSelector(
  planState,
  (state: PlanState): Plan | null => state.data
)

export const requestIsInProgress = createSelector(
  planState,
  (state: PlanState): boolean => state.requestInProgress
)

export const getError = createSelector(
  planState,
  (state: PlanState): string | null => state.error
)

export const hasError = createSelector(
  planState,
  (state: PlanState): boolean => !!state.error
)

export const getPlanByName =
  (name: string): AppThunk =>
  async dispatch => {
    dispatch(requestInProgress())
    try {
      const response = await api.post(`subscription/plan`, { name })
      const plan: Plan = getSinglePayloadFromResponse(response) as Plan
      dispatch(loaded(plan))
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      dispatch(requestError(getErrorStatus(error)))
    }
  }
