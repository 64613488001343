import React, { useState } from 'react'
import styled, { css } from 'styled-components'

import { Paper, Typography } from '@material-ui/core'

import useBenchmark from 'src/hooks/useBenchmark'
import Legend from 'src/components/graph/composed/Legend'
import ComposedGraph from 'src/components/graph/composed'
import { SummarySingleItem } from 'src/store/summarySlice'
import StrokesProgressLabel from 'src/components/dataDisplay/StrokesProgressLabel'

interface Props {
  title: string
  width: string | number
  height: string | number
  payload: SummarySingleItem
}

const Container = styled(Paper)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(3, 3, 3, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 2, 2, 0)};
    }
  `
)

const HeaderContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${theme.breakpoints.up(750)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

const HeaderInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${theme.spacing(4.5)}px;

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      margin: ${theme.spacing(0, 0, 1.5)};
      padding-left: ${theme.spacing(2)}px;
    }
  `
)

const StrokesGainedGraph: React.FC<Props> = ({
  title,
  width,
  height,
  payload,
}) => {
  const [isActive, setIsActive] = useState(false)

  const { benchmarkId, ...benchmarkInfo } = useBenchmark()

  const dataExists = payload.sgTotal !== null

  const handleActive = () => {
    setIsActive(true)
  }

  const handleInActive = () => {
    setIsActive(false)
  }

  return (
    <Container
      elevation={0}
      onMouseEnter={handleActive}
      onMouseLeave={handleInActive}
    >
      <HeaderContent>
        <HeaderInfo>
          <Typography>{title}</Typography>
          <StrokesProgressLabel
            dataExists={dataExists}
            sgValue={payload.sgTotal}
            progress={payload.progress}
          />
        </HeaderInfo>
        <Legend />
      </HeaderContent>
      <ComposedGraph
        width={width}
        id="report-sg"
        height={height}
        active={isActive}
        payload={payload}
        dataExists={dataExists}
        {...benchmarkInfo}
      />
    </Container>
  )
}

export default StrokesGainedGraph
