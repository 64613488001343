import React, { useState } from 'react'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js'
import { useForm } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import Box from '@material-ui/core/Box'
import Close from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import MuiIconButton from '@material-ui/core/IconButton'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'

import { CardInfo, CardFormFields } from 'src/components/subscriptionFlow/types'
import CardForm, {
  CardFormTheme,
} from 'src/components/subscriptionFlow/CardForm'
import { playerNameSelector } from 'src/store/playerSlice'
import CommonLoaderButton from 'src/components/inputs/LoaderButton'
import { useStripeCardSetup } from './useStripeCardSetup'

const I18N_KEY = 'AddCardDialog'

const LoaderButton = styled(CommonLoaderButton)(
  ({ theme }) => css`
    padding-left: ${theme.spacing(6.25)}px;
    padding-right: ${theme.spacing(6.25)}px;
  `
)

const DialogTitle = styled(MuiDialogTitle)(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    padding-bottom: 0;
    padding-top: ${theme.spacing(5)}px;
  `
)

const IconButton = styled(MuiIconButton)(
  ({ theme }) => css`
    margin-left: auto;
    padding: ${theme.spacing(1.5)}px;
  `
)

interface Props {
  open: boolean
  handleClose: () => void
}

const AddCardDialog: React.FC<Props> = ({ open, handleClose }) => {
  const userName = useSelector(playerNameSelector)
  const [cardInfo, setCardInfo] = useState<CardInfo>({})
  const { control } = useForm<CardFormFields>({
    mode: 'onChange',
    defaultValues: {
      nameOnCard: `${userName.firstName} ${userName.lastName}`,
      postcode: '',
    },
  })
  const { submitting, isPaymentValid, handleAddCard } = useStripeCardSetup({
    I18N_KEY,
    handleClose,
    dialogOpened: open,
  })

  const handleChange = (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent
      | StripeCardCvcElementChangeEvent
  ) => {
    const { elementType } = event
    setCardInfo({
      ...cardInfo,
      [elementType]: event,
    })
  }

  const localHandleClose = () => {
    if (!submitting) {
      handleClose()
    }
  }

  return (
    <Dialog
      open={open}
      onClose={localHandleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography variant="h4" id="form-dialog-title">
          <Trans i18nKey={`${I18N_KEY}.dialogTitle`}>New Card</Trans>
        </Typography>
        <IconButton onClick={localHandleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <CardForm
            control={control}
            handleChange={handleChange}
            theme={CardFormTheme.Light}
            showPostCode
            stripeUpgrade
          />
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <LoaderButton
          color="primary"
          disabled={!isPaymentValid}
          loading={submitting}
          onClick={handleAddCard}
          variant="contained"
        >
          <Trans i18nKey={`${I18N_KEY}.saveCardButtonLabel`}>Save Card</Trans>
        </LoaderButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddCardDialog
