import axios, { AxiosRequestConfig } from 'axios'
import { config } from '../configLoader'

export const baseURL = config.REACT_APP_API_URL
export const gatewayURL = config.REACT_APP_GATEWAY_URL

export const defaultConfig: AxiosRequestConfig = {
  baseURL,
  timeout: 60000,
  responseType: 'json',
}

export const gatewayConfig: AxiosRequestConfig = {
  baseURL: gatewayURL,
  timeout: 60000,
  responseType: 'json',
}

export const api = axios.create(defaultConfig)
export const gatewayApi = axios.create(gatewayConfig)

export const addDefaultHeader = (key: string, value: string) => {
  api.defaults.headers[key] = value
  gatewayApi.defaults.headers[key] = value
}

export const setAuthTokenHeader = (token: string) => {
  addDefaultHeader('Authorization', `Bearer ${token}`)
}

export const getCancelTokenSource = () => axios.CancelToken.source()

export const isCancel = (error: any) => axios.isCancel(error)

export enum Errors {
  NotFound = '404',
}
