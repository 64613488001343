import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  extractIOPotential,
  extractRadarData,
  extractScoreInfo,
} from 'src/store/utils'
import {
  authRequestFail,
  getPayloadFromResponse,
  getRoundTitle,
  getSinglePayloadFromResponse,
  getUserInfo,
  isForbiddenOrUnauthorised,
} from 'src/utils/helpers'
import {
  PAGINATION_LIMIT,
  REPORT_OWNER_ERROR,
  SummaryErrors,
  TimeFilter,
} from 'src/utils/constants'
import {
  MetricId,
  MonthlyReport,
  Report,
  ReportComments,
  ReportInfo,
  ReportType,
} from 'src/utils/golfConstants'
import { api } from 'src/utils/api'
import { AppThunk } from 'src/store'
import { RootState } from 'src/store/rootReducer'
import { getErrorToast, openToast } from 'src/store/toastSlice'
import {
  BenchmarkDetails,
  summaryFiltersSelector,
  SummarySingleItem,
} from 'src/store/summarySlice'

type ReportState = {
  reports: ReportInfo[]
  isReportsLoaded: boolean
  isReportsLoading: boolean
  totalReports: number
  reportsDialogOpen: boolean
  selected?: Report | MonthlyReport
  selectedType?: ReportType
  isSelectedLoading: boolean
}

export interface ReportOpportunityItem extends SummarySingleItem {
  metricId: MetricId
}

interface OpenPayload {
  isOpen: boolean
}

interface ReportParams {
  [key: string]: any
}

const initialState: ReportState = {
  reports: [],
  isReportsLoaded: false,
  isReportsLoading: false,
  reportsDialogOpen: false,
  totalReports: 0,
  selected: undefined,
  selectedType: undefined,
  isSelectedLoading: false,
}

const SLICE_NAME = 'report'

const { actions, reducer } = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    updateState: (state, action: PayloadAction<Partial<ReportState>>) => {
      return { ...state, ...action.payload }
    },
    updateReportsDialogVisibility: (
      state,
      action: PayloadAction<OpenPayload>
    ) => {
      state.reportsDialogOpen = action.payload.isOpen
    },
  },
})

export default reducer

export const { updateReportsDialogVisibility } = actions

// SELECTORS

export const reportSelector = (state: RootState): ReportState =>
  state[SLICE_NAME]
export const reportPlayerSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).player
export const reportStrokesGainedSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.summary.totalSummary.strokesGained
export const reportRoundsSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.summary.rounds
export const auxReportRoundsSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.summary.auxRounds
export const reportPISelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.summary.totalSummary
    .performanceIndicators
export const reportBenchmarkDetailsSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.summary.benchmarkDetails
export const reportProgressDonutSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.summary.totalSummary.progressDonut
export const reportCategoriesSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.summary.totalSummary.categories
export const reportSavedIOSelector = (state: RootState) =>
  (state[SLICE_NAME].selected as Report).data.io.savedMetrics

export const reportTotalSGDataSelector = createSelector(
  reportStrokesGainedSelector,
  reportRoundsSelector,
  (strokesGained, rounds) => {
    const totalStrokesGained: SummarySingleItem = {
      data: [],
      sgTotal: strokesGained?.total.overall,
      progress: strokesGained?.total.progress,
      benchmark: strokesGained?.total.benchmark as number,
    }

    rounds?.forEach(round => {
      const roundTitle = getRoundTitle(round)
      const dateValue = new Date(round.datePlayed).getTime()

      totalStrokesGained.data.push({
        roundTitle,
        uuid: round.uuid,
        datePlayed: dateValue,
        value: round!.summary!.strokesGained.total.overall,
        average: round!.summary!.strokesGained.total.average,
        benchmark: round!.summary!.strokesGained.total.benchmark,
      })
    })

    return totalStrokesGained
  }
)

export const reportScoreSelector = createSelector(
  reportRoundsSelector,
  extractScoreInfo
)

export const reportRadarDataSelector = createSelector(
  reportStrokesGainedSelector,
  extractRadarData
)

export const reportDonutInfoSelector = createSelector(
  reportProgressDonutSelector,
  reportStrokesGainedSelector,
  reportBenchmarkDetailsSelector,
  (progressDonut, strokesGained, benchmarkDetails) => ({
    progressDonut,
    strokesGained: strokesGained?.total.overall as number,
    benchmarkDetails: benchmarkDetails as BenchmarkDetails,
  })
)

export const reportStatisticsSelector = createSelector(
  reportPISelector,
  performanceIndicators =>
    performanceIndicators?.filter(({ category }) => category === MetricId.All)
)

export const selectedReportSelector = createSelector(
  reportSelector,
  ({ selected }) => selected
)

export const reportIOGraphDataSelector = createSelector(
  reportRoundsSelector,
  auxReportRoundsSelector,
  reportSavedIOSelector,
  reportCategoriesSelector,
  reportStrokesGainedSelector,
  (rounds, auxRounds, savedMetrics, categories, strokesGained) => {
    const graphData: ReportOpportunityItem[] = []

    savedMetrics?.forEach(({ metricId }) => {
      if (metricId === MetricId.Drives) {
        const payload: ReportOpportunityItem = {
          metricId,
          data: [],
          sgTotal: strokesGained?.drives.overall,
          progress: strokesGained?.drives.progress,
          benchmark: strokesGained?.drives.benchmark as number,
        }

        let valueSum = 0
        let roundCount = 0

        if (auxRounds) {
          auxRounds.forEach(round => {
            valueSum += round!.summary!.strokesGained.drives.overall ?? 0
            roundCount += 1
          })
        }

        rounds?.forEach(round => {
          const roundTitle = getRoundTitle(round)
          const dateValue = new Date(round.datePlayed).getTime()

          valueSum += round!.summary!.strokesGained.drives.overall ?? 0
          roundCount += 1

          payload.data.push({
            roundTitle,
            uuid: round.uuid,
            datePlayed: dateValue,
            value: round!.summary!.strokesGained.drives.overall,
            average: round!.summary!.strokesGained.drives.average,
            benchmark: round!.summary!.strokesGained.drives.benchmark,
            rollingAverage: valueSum / roundCount,
          })
        })

        payload.progress =
          payload.data[payload.data.length - 1].rollingAverage! -
          payload.data[0].rollingAverage!

        graphData.push(payload)
      } else {
        const rootCategory = categories?.find(
          item => metricId === item.metricId
        )

        const payload: ReportOpportunityItem = {
          metricId,
          data: [],
          progress: rootCategory?.progress,
          sgTotal: rootCategory?.strokesGained,
          benchmark: rootCategory?.benchmark as number,
        }

        let valueSum = 0
        let roundCount = 0

        if (auxRounds) {
          auxRounds.forEach(round => {
            const category = round.summary?.categories.find(
              item => metricId === item.metricId
            )
            valueSum += category?.strokesGained ?? 0
            roundCount += 1
          })
        }

        rounds?.forEach(round => {
          const roundTitle = getRoundTitle(round)
          const dateValue = new Date(round.datePlayed).getTime()
          const category = round.summary?.categories.find(
            item => metricId === item.metricId
          )

          valueSum += category?.strokesGained ?? 0
          roundCount += 1

          payload.data.push({
            roundTitle,
            uuid: round.uuid,
            datePlayed: dateValue,
            average: category?.average as number,
            value: category?.strokesGained || null,
            benchmark: category?.benchmark as number,
            rollingAverage: valueSum / roundCount,
          })
        })

        payload.progress =
          payload.data[payload.data.length - 1].rollingAverage! -
          payload.data[0].rollingAverage!

        graphData.push(payload)
      }
    })

    return graphData
  }
)

export const reportIOPotential = createSelector(
  reportSavedIOSelector,
  reportCategoriesSelector,
  reportStrokesGainedSelector,
  extractIOPotential
)

// ACTIONS

export const getReports =
  (start: number = 0, showLoading: boolean = true): AppThunk =>
  async (dispatch, getState) => {
    if (showLoading) {
      dispatch(actions.updateState({ isReportsLoading: true }))
    }
    const { playerUuid } = getUserInfo(getState())
    const endpoint = `reportInfo/${playerUuid}`

    try {
      const response = await api.get(endpoint, {
        params: { count: true, start, limit: PAGINATION_LIMIT },
      })
      const reports: ReportInfo[] = getPayloadFromResponse(response)
      const totalReports = response.data.count || reports.length
      dispatch(
        actions.updateState({
          reports,
          totalReports,
          isReportsLoaded: true,
          isReportsLoading: false,
        })
      )
    } catch (error: any) {
      dispatch(actions.updateState({ isReportsLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        dispatch(openToast(getErrorToast('Could not load reports')))
      }
    }
  }

export const getReport =
  (uuid: string): AppThunk =>
  async (dispatch, getState) => {
    dispatch(actions.updateState({ isSelectedLoading: true }))
    let reportInfo = getState().report.reports.find(
      report => report.reportUUID === uuid
    )
    if (!reportInfo) {
      await dispatch(getReports())
      reportInfo = getState().report.reports.find(
        report => report.reportUUID === uuid
      )
    }
    let endpoint = ''
    switch (reportInfo!.type) {
      case ReportType.MonthlyReport:
        endpoint = `monthlyReport/${uuid}`
        break
      case ReportType.Evaluation:
      default:
        endpoint = `report/${uuid}`
        break
    }

    try {
      const response = await api.get(endpoint)
      const report = getSinglePayloadFromResponse(response)
      dispatch(
        actions.updateState({
          selected: report,
          isSelectedLoading: false,
          selectedType: reportInfo!.type,
        })
      )
    } catch (error: any) {
      dispatch(actions.updateState({ isSelectedLoading: false }))
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else {
        throw new Error()
      }
    }
  }

export const createReport =
  (title: string, time: TimeFilter): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const { playerUuid } = getUserInfo(state)
    const { customTimeRange } = summaryFiltersSelector(state)
    const endpoint = 'report'
    const version = '1.0'

    const reportParams: ReportParams = {
      title,
      playerUuid,
      version,
      time,
    }
    if (time === TimeFilter.Custom) {
      reportParams.customTimeRange = customTimeRange
    }

    try {
      const response = await api.post(endpoint, reportParams)
      return getSinglePayloadFromResponse(response).uuid as string
    } catch (error: any) {
      const errorMessage = error.response?.data.message

      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      } else if (errorMessage === SummaryErrors.NoRoundsEntered) {
        throw new Error(SummaryErrors.NoRoundsEntered)
      } else if (errorMessage === SummaryErrors.NoRoundsForFilters) {
        throw new Error(SummaryErrors.NoRoundsForFilters)
      } else {
        throw new Error()
      }
    }
  }

export const deleteReport =
  (uuid: string, type?: ReportType): AppThunk =>
  async (dispatch, getState) => {
    const playerUuid = getState().player.uuid
    let endpoint = ''

    switch (type) {
      case ReportType.MonthlyReport:
        endpoint = `monthlyReport/${uuid}`
        break
      case ReportType.DiscussionDocument:
        endpoint = `overview/player/${playerUuid}/report/discussion/${uuid}`
        break
      case ReportType.Evaluation:
      default:
        endpoint = `report/${uuid}/player/${playerUuid}`
        break
    }

    try {
      await api.delete(endpoint)
    } catch (error: any) {
      const errorMessage = error.response?.data.message

      if (isForbiddenOrUnauthorised(error)) {
        if (errorMessage === REPORT_OWNER_ERROR) {
          throw new Error(REPORT_OWNER_ERROR)
        } else {
          authRequestFail(dispatch)
        }
      }
      throw new Error()
    }
  }

export const updateComments =
  (reportUuid: string, comments: ReportComments): AppThunk =>
  async (dispatch, getState) => {
    const endpoint = 'report/comments'

    const state = getState()
    const playerUuid = state.player.uuid
    const selected = state.report.selected as Report

    try {
      await api.put(endpoint, { reportUuid, comments, playerUuid })
      if (selected.uuid === reportUuid) {
        dispatch(actions.updateState({ selected: { ...selected, comments } }))
      }
    } catch (error: any) {
      if (isForbiddenOrUnauthorised(error)) {
        authRequestFail(dispatch)
      }
      throw new Error()
    }
  }
