import H from 'history'
import { Languages } from 'src/utils/constants'
import i18n from 'src/i18n'

const langParamRegexp = new RegExp(
  `^/(${Object.values(Languages).join('|')})`,
  'i'
)
export const removeLangParamFromPathStr = (path: string) =>
  path.replace(langParamRegexp, '')

type LocationType =
  | H.LocationDescriptor<H.LocationState>
  | ((
      location: H.Location<H.LocationState>
    ) => H.LocationDescriptor<H.LocationState>)

/**
 * Here you can pass a path for a Route element
 * to set the language parameter in the URL
 * @param path string | string[]
 * @param lang Languages
 */
export const localizeRoutePath = (
  path: string | string[],
  lang: Languages = i18n.language as Languages
) => {
  switch (typeof path) {
    case 'object':
      return path.map(key => `/${lang}${removeLangParamFromPathStr(key)}`)
    default:
      return path === '*' ? path : `/${lang}${removeLangParamFromPathStr(path)}`
  }
}

/**
 * Here you can pass a location descriptor for a Link element (the `to` prop)
 * and the function will add the given language as URL parameter
 * @param location
 * @param lang
 */
export const localizeLocationDescriptor = (
  location: LocationType,
  lang: Languages = i18n.language as Languages
): LocationType => {
  let updatedLocation = location
  const typeOfLocation = typeof location
  if (typeof location === 'string') {
    updatedLocation = localizeRoutePath(location as string, lang) as string
  } else if (typeOfLocation === 'function') {
    updatedLocation = (...args): H.LocationDescriptor<H.LocationState> => {
      const result = (location as Function)(...args)
      return localizeLocationDescriptor(
        result,
        lang
      ) as H.LocationDescriptor<H.LocationState>
    }
  } else if (
    typeof location !== 'string' &&
    typeof location !== 'function' &&
    location.pathname
  ) {
    Object.assign(updatedLocation, {
      pathname: localizeRoutePath(location.pathname as string, lang),
    })
  }
  return updatedLocation
}
