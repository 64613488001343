import {
  Activity,
  Measurement,
  ActivityId,
  CategoryType,
} from 'src/utils/golfConstants'

export interface CategorisedActivities {
  [key: string]: Activity[]
}

export const activities: CategorisedActivities = {
  [CategoryType.Drives]: [
    {
      input: null,
      focus: false,
      unit: Measurement.Yards,
      category: CategoryType.Drives,
      activityId: ActivityId.DrivingDistance,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Yards,
      category: CategoryType.Drives,
      activityId: ActivityId.DrivingAccuracyCourse,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Yards,
      category: CategoryType.Drives,
      activityId: ActivityId.DrivingAccuracyRange,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.MPH,
      category: CategoryType.Drives,
      activityId: ActivityId.ClubHeadSpeed,
    },
  ],
  [CategoryType.Approach]: [
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole50To75Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole75To100Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole100To125Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole125To150Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole150To175Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole175To200Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole200To225Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole225To250Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole250To275Rough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHoleOver275Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole50To75Rough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole75To100Rough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole100To125Rough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole125To150Rough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole150To175Rough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole175To200Rough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole200To225Rough,
    },
    {
      input: null,
      focus: false,
      activityId: ActivityId.ProxToHole225To250Rough,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHole250To275Fairway,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Approach,
      activityId: ActivityId.ProxToHoleOver275Rough,
    },
  ],
  [CategoryType.Short]: [
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Short,
      activityId: ActivityId.ProxToHoleFromFringe,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Short,
      activityId: ActivityId.ProxToHoleFromRough,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Short,
      activityId: ActivityId.ProxToHoleUnder10,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Short,
      activityId: ActivityId.ProxToHole10To20,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Short,
      activityId: ActivityId.ProxToHole20To30,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Short,
      activityId: ActivityId.ProxToHoleSand,
    },
  ],
  [CategoryType.Putt]: [
    {
      input: null,
      focus: false,
      category: CategoryType.Putt,
      unit: Measurement.PuttsOfTen,
      activityId: ActivityId.PuttsUnder6,
    },
    {
      input: null,
      focus: false,
      category: CategoryType.Putt,
      unit: Measurement.PuttsOfTen,
      activityId: ActivityId.Putts7To11,
    },
    {
      input: null,
      focus: false,
      category: CategoryType.Putt,
      unit: Measurement.PuttsOfTen,
      activityId: ActivityId.Putts12To16,
    },
    {
      input: null,
      focus: false,
      category: CategoryType.Putt,
      unit: Measurement.PuttsOfTen,
      activityId: ActivityId.Putts17To21,
    },
    {
      input: null,
      focus: false,
      category: CategoryType.Putt,
      unit: Measurement.PuttsOfTen,
      activityId: ActivityId.PuttsOver21,
    },
    {
      input: null,
      focus: false,
      unit: Measurement.Feet,
      category: CategoryType.Putt,
      activityId: ActivityId.ApproachPuttPerformance,
    },
  ],
}
