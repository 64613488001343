import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'

import { PracticeStatus } from 'src/store/practiceSlice'

import { ReactComponent as PracticeSessionsImage } from 'src/assets/images/PracticeSessions.svg'

const I18N_KEY = 'EmptyPractice'

interface Props {
  status: PracticeStatus
}

const Container = styled.div(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    justify-content: center;
    padding: ${theme.spacing(8, 0)};

    & > svg {
      width: 260px;
    }
  `
)

const EmptyPractice: React.FC<Props> = ({ status }) => (
  <Container>
    <PracticeSessionsImage />
    <Typography>
      {status === PracticeStatus.NoPracticesError ? (
        <Trans i18nKey={`${I18N_KEY}.noPractices`}>
          Practice sessions will appear here once logged.
        </Trans>
      ) : (
        <Trans i18nKey={`${I18N_KEY}.error`}>
          We could not load your practices. Please try again.
        </Trans>
      )}
    </Typography>
  </Container>
)

export default EmptyPractice
