import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import {
  Typography,
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
} from '@material-ui/core'

import { userSelector } from 'src/store/userSlice'
import SmallLogo from 'src/assets/svgComponents/SmallLogo'
import useResendEmailVerification from 'src/hooks/useResendEmailVerification'
import { updateVerifyDialog, verifyDialogSelector } from 'src/store/authSlice'

const I18N_KEY = 'WelcomeDialog'

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperWidthSm {
    width: 100%;
    max-width: 650px;
  }
`

const DialogContent = styled(MuiDialogContent)(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: ${theme.spacing(5)}px;

    &.MuiDialogContent-root:first-child {
      padding-top: ${theme.spacing(5)}px;
    }
  `
)

const IOGLogo = styled(SmallLogo)(
  ({ theme }) => css`
    width: 80px;
    margin: ${theme.spacing(0, 0, 3)};

    g {
      fill: #000;
    }
  `
)

const Description = styled(Typography)<{ $maxWidth: number }>(
  ({ theme, $maxWidth }) => css`
    max-width: ${$maxWidth}px;
    margin: ${theme.spacing(1, 0, 0)};

    strong {
      font-weight: bold;
    }
  `
)

const ResendButton = styled.button(
  ({ theme }) => css`
    padding: 0;
    border: none;
    cursor: pointer;
    font-weight: 500;
    white-space: nowrap;
    background: transparent;
    text-decoration: underline;
    margin: ${theme.spacing(3, 0, 0)};
    font-size: ${theme.typography.pxToRem(16)};
    font-family: ${theme.typography.fontFamily};
    line-height: ${theme.typography.pxToRem(26)};
  `
)

const Button = styled(MuiButton)(
  ({ theme }) => css`
    width: 220px;
    margin: ${theme.spacing(3, 0, 0)};
  `
)

const WelcomeDialog: React.FC = () => {
  const dispatch = useDispatch()

  const { email } = useSelector(userSelector)
  const showVerifyDialog = useSelector(verifyDialogSelector)

  const { resendVerificationEmail } = useResendEmailVerification(email)

  const handleDialogClose = () => {
    dispatch(updateVerifyDialog({ showVerifyDialog: false }))
  }

  return (
    <Dialog open={showVerifyDialog}>
      <DialogContent>
        <IOGLogo />
        <Typography variant="h3" align="center">
          <Trans i18nKey={`${I18N_KEY}.verify`}>Verify your email</Trans>
        </Typography>
        <Description $maxWidth={452} align="center">
          <Trans i18nKey={`${I18N_KEY}.sentEmail`}>
            We’ve sent a verification email to <strong>{{ email }}</strong>.
            Please follow the link in the email to confirm your email address.
          </Trans>
        </Description>
        <Description $maxWidth={418} align="center" color="textSecondary">
          <Trans i18nKey={`${I18N_KEY}.verifyWarning`}>
            Please verify your email within 2 weeks. After this period you will
            not be able to skip the email verification process.
          </Trans>
        </Description>
        <ResendButton onClick={resendVerificationEmail}>
          <Trans i18nKey={`${I18N_KEY}.resend`}>Resend</Trans>
        </ResendButton>
        <Button color="primary" onClick={handleDialogClose}>
          <Trans i18nKey={`${I18N_KEY}.doThisLater`}>Do this later</Trans>
        </Button>
      </DialogContent>
    </Dialog>
  )
}

export default WelcomeDialog
