import React from 'react'

import Dialog, { DialogProps } from '@material-ui/core/Dialog'

import SlideTransition from 'src/components/navigation/SlideTransition'

type Props = Omit<DialogProps, 'TransitionComponent' | 'fullScreen'>

const FullScreenDialog: React.FC<Props> = ({ open, ...props }) => (
  <Dialog
    {...props}
    fullScreen
    open={open}
    TransitionComponent={SlideTransition}
  />
)

export default FullScreenDialog
