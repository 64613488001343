import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'

import Typography from '@material-ui/core/Typography'

import { parsePar } from 'src/utils/helpers'
import { Hole } from 'src/utils/golfConstants'
import HoleList from 'src/modules/rounds/table/HoleList'
import { Cell, Column, HoleText } from 'src/modules/rounds/table/common'

const I18N_KEY = 'EighteenTable'

interface Props {
  holes: Hole[]
  currentHole: number
  isComplete: boolean
  getHoleClickHandler: (index: number) => () => void
}

const mappingArray = Array.from(Array(18).keys())

const inArray = mappingArray.slice(9)
const outArray = mappingArray.slice(0, 9)

const EighteenTable: React.FC<Props> = ({
  holes,
  isComplete,
  currentHole,
  getHoleClickHandler,
}) => {
  const outHoles = useMemo(() => holes.slice(0, 9), [holes])
  const inHoles = useMemo(() => holes.slice(9, 18), [holes])

  // Scores and par for the in and out halves of an 18 hole round
  const outScoreTotal = useMemo(
    () =>
      holes.length >= 9
        ? outHoles.reduce((acc, cur) => acc + cur.shots.length, 0)
        : '•',
    [holes, outHoles]
  )

  const outParTotal = useMemo(
    () =>
      outHoles.reduce((acc, cur) => acc + (parsePar(cur.par) || 4), 0) +
      4 * (9 - outHoles.length),
    [outHoles]
  )

  const inScoreTotal = useMemo(
    () =>
      isComplete
        ? inHoles.reduce((acc, cur) => acc + cur.shots.length, 0)
        : '•',
    [inHoles, isComplete]
  )

  const inParTotal = useMemo(
    () =>
      holes.length > 9
        ? inHoles.reduce((acc, cur) => acc + (parsePar(cur.par) || 4), 0) +
          4 * (9 - inHoles.length)
        : 36,
    [holes, inHoles]
  )

  return (
    <>
      <HoleList
        holes={holes}
        mappingArray={outArray}
        currentHole={currentHole}
        getHoleClickHandler={getHoleClickHandler}
      />
      <Column disabled>
        <Cell>
          <Typography variant="body2">
            <Trans i18nKey={`${I18N_KEY}.out`}>Out</Trans>
          </Typography>
        </Cell>
        <Cell>
          <HoleText variant="body2" completed={holes.length >= 9}>
            {outParTotal}
          </HoleText>
        </Cell>
        <Cell>
          <HoleText variant="body2" completed={holes.length >= 9}>
            {outScoreTotal}
          </HoleText>
        </Cell>
      </Column>
      <HoleList
        holes={holes}
        mappingArray={inArray}
        currentHole={currentHole}
        getHoleClickHandler={getHoleClickHandler}
      />
      <Column disabled>
        <Cell>
          <Typography variant="body2">
            <Trans i18nKey={`${I18N_KEY}.in`}>In</Trans>
          </Typography>
        </Cell>
        <Cell>
          <HoleText variant="body2" completed={isComplete}>
            {inParTotal}
          </HoleText>
        </Cell>
        <Cell>
          <HoleText variant="body2" completed={isComplete}>
            {inScoreTotal}
          </HoleText>
        </Cell>
      </Column>
    </>
  )
}

export default React.memo(EighteenTable)
