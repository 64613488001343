import React, { Dispatch, SetStateAction } from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'
import { AnimateSharedLayout } from 'framer-motion'

import Typography from '@material-ui/core/Typography'

import { breakpoints } from 'src/utils/constants'
import HoleList from 'src/modules/rounds/table/HoleList'
import { Hole, Score, RoundState } from 'src/utils/golfConstants'
import EighteenTable from 'src/modules/rounds/table/EighteenTable'
import { Cell, Column, HoleText } from 'src/modules/rounds/table/common'
import { ActiveBar } from 'src/components/layout/SharedLayoutComponents'

const I18N_KEY = 'RoundsTableShotsTable'

interface Props {
  holes: Hole[]
  score?: Score
  currentHole: number
  numberOfHoles: number
  roundState: RoundState
  setCurrentHole: Dispatch<SetStateAction<number>>
  setTransitionDirection: Dispatch<SetStateAction<number>>
}

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    margin: ${theme.spacing(0, 0, 3)};
    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(0, 2, 3)};
    }

    ${theme.breakpoints.down(breakpoints.xxs)} {
      margin: ${theme.spacing(0, 4.25, 3)};
    }
  `
)

const TotalCell = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(1.25, 0)};
  `
)

const RowTitle = styled(Typography)(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(14)};
      line-height: ${theme.typography.pxToRem(20)};
    }
  `
)

const BodyContainer = styled.div(
  ({ theme }) => css`
    ${theme.breakpoints.down('sm')} {
      overflow: scroll;
      display: flex;
    }
  `
)

const nineMappingArray = Array.from(Array(9).keys())

const ShotsTable: React.FC<Props> = ({
  score,
  holes,
  roundState,
  currentHole,
  numberOfHoles,
  setCurrentHole,
  setTransitionDirection,
  ...props
}) => {
  const isComplete = roundState === RoundState.Complete
  const totalSelected = currentHole === numberOfHoles

  const scoreTotal = isComplete && score ? score.total : '•'

  const getHoleClickHandler = (index: number) => () => {
    index > currentHole ? setTransitionDirection(1) : setTransitionDirection(-1)
    currentHole !== index && setCurrentHole(index)
  }

  const handleTotalClick = () => {
    setCurrentHole(numberOfHoles)
  }

  return (
    <Container {...props}>
      <div>
        <Cell>
          <RowTitle variant="body2">
            <Trans i18nKey={`${I18N_KEY}.holeTitle`}>Hole</Trans>
          </RowTitle>
        </Cell>
        <Cell>
          <RowTitle variant="body2">
            <Trans i18nKey={`${I18N_KEY}.parTitle`}>Par</Trans>
          </RowTitle>
        </Cell>
        <Cell>
          <RowTitle variant="body2">
            <Trans i18nKey={`${I18N_KEY}.scoreTitle`}>Score</Trans>
          </RowTitle>
        </Cell>
      </div>
      <AnimateSharedLayout>
        <BodyContainer>
          {numberOfHoles === 9 ? (
            <HoleList
              holes={holes}
              currentHole={currentHole}
              mappingArray={nineMappingArray}
              getHoleClickHandler={getHoleClickHandler}
            />
          ) : (
            <EighteenTable
              holes={holes}
              isComplete={isComplete}
              currentHole={currentHole}
              getHoleClickHandler={getHoleClickHandler}
            />
          )}
          <Column disabled={!isComplete} onClick={handleTotalClick}>
            <TotalCell>
              <HoleText variant="body2" completed selected={totalSelected}>
                <Trans i18nKey={`${I18N_KEY}.totalTitle`}>Total</Trans>
              </HoleText>
            </TotalCell>
            <Cell>
              <HoleText
                variant="body2"
                completed={isComplete}
                selected={totalSelected}
              >
                {score?.par}
              </HoleText>
            </Cell>
            <Cell>
              <HoleText
                variant="body2"
                completed={isComplete}
                selected={totalSelected}
              >
                {scoreTotal}
              </HoleText>
            </Cell>
            {totalSelected && <ActiveBar layoutId="shots-table" />}
          </Column>
        </BodyContainer>
      </AnimateSharedLayout>
    </Container>
  )
}

export default React.memo(ShotsTable)
