import {
  createBrowserHistory,
  History,
  LocationDescriptorObject,
} from 'history'
import { localizeRoutePath, localizeLocationDescriptor } from './i18n'

type PoorMansUnknown = {} | null | undefined

const history = createBrowserHistory<PoorMansUnknown>()

export const originalPush = history.push
export const originalReplace = history.replace

const localize = (
  path: History.Path | LocationDescriptorObject
): History.Path | LocationDescriptorObject => {
  return typeof path === 'string' || Array.isArray(path)
    ? (localizeRoutePath(path) as History.Path)
    : (localizeLocationDescriptor(path) as LocationDescriptorObject)
}

history.push = function (
  path: History.Path | LocationDescriptorObject,
  state?: History
): void {
  originalPush(localize(path) as string, state)
}

history.replace = function (
  path: History.Path | LocationDescriptorObject,
  state?: History
): void {
  originalReplace(localize(path) as string, state)
}

export default history
