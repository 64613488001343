import React, { useState, MouseEvent } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MUIAvatar from '@material-ui/core/Avatar'
import MUIMenuItem from '@material-ui/core/MenuItem'
import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import ExpandMore from '@material-ui/icons/ExpandMore'

import {
  userPlanSelector,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import { logout } from 'src/store/authSlice'
import useAppState from 'src/hooks/useAppState'
import useBreakpoints from 'src/hooks/useBreakpoints'
import { Routes, breakpoints } from 'src/utils/constants'
import { nameSelector, profileImageUrlSelector } from 'src/store/userSlice'
import LanguageSelectionDialog from 'src/components/feedback/LanguageSelectionDialog'

import { ReactComponent as LogoutIcon } from 'src/assets/images/LogoutIcon.svg'
import { ReactComponent as SettingsIcon } from 'src/assets/images/SettingsIcon.svg'
import { ReactComponent as LanguageIcon } from 'src/assets/images/LanguageIcon.svg'

const I18N_KEY = 'User'

const Avatar = styled(MUIAvatar)(
  ({ theme }) =>
    css`
      width: 39px;
      height: 39px;
      margin-right: 0;
      background-color: ${theme.palette.primary.main};

      ${theme.breakpoints.up(breakpoints.mobile)} {
        margin-right: ${theme.spacing(1.5)}px;
      }
    `
)

const Container = styled(ButtonBase)(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    &:hover {
      ${Avatar} {
        background-color: ${theme.palette.primary.light};
      }
    }
  `
)

const UserContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      color: ${theme.palette.primary.main};
    }
  `
)

const MenuItem = styled(MUIMenuItem)(
  ({ theme }) =>
    css`
      font-weight: 500;
      padding: ${theme.spacing(2, 8, 2, 2)};

      svg {
        margin-right: ${theme.spacing(2)}px;
      }
    `
)

const LogoutMenuItem = styled(MenuItem)(
  ({ theme }) =>
    css`
      color: ${theme.palette.error.main};
    `
)

const NameIconContainer = styled.div<{ $hasSubscription: boolean }>(
  ({ $hasSubscription }) =>
    css`
      display: flex;
      align-items: ${$hasSubscription ? 'center' : 'start'};
    `
)

const Name = styled(Typography)(
  ({ theme }) =>
    css`
      margin: ${theme.spacing(0, 0, -0.75)};
    `
)

const ExpandIcon = styled(ExpandMore)(
  ({ theme }) =>
    css`
      fill: #fff;
      margin: ${theme.spacing(0, 0, 0, 0.5)};
    `
)

const User: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [languageDialogIsOpen, setLanguageDialogIsOpen] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const { i18n, t } = useTranslation()

  const { isCoach } = useAppState()
  const { isDesktop } = useBreakpoints()

  const { language } = i18n
  const menuIsOpen = !!anchorEl

  const { lastName, firstName } = useSelector(nameSelector)
  const profileImageUrl = useSelector(profileImageUrlSelector)

  const plan = useSelector(userPlanSelector)
  const isTrialing = useSelector(userIsTrialingSelector)

  const handleLogout = async () => {
    await dispatch(logout())
  }

  const handleLanguageMenuItemClick = () => {
    setAnchorEl(null)
    setLanguageDialogIsOpen(true)
  }

  const handleProfileClick = () => {
    setAnchorEl(null)
    history.push(Routes.Settings)
  }

  const handleLanguageDialogOnClose = () => {
    setLanguageDialogIsOpen(false)
  }

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const langLabel = t(`Enums:Language.${language}`)

  return (
    <Container disableRipple>
      <UserContainer onClick={openMenu}>
        {profileImageUrl ? (
          <Avatar
            src={profileImageUrl}
            alt={t(`${I18N_KEY}.userAlt`, 'User profile')}
          />
        ) : (
          <Avatar>{`${firstName[0].toUpperCase()}`}</Avatar>
        )}
        {isDesktop && (
          <NameIconContainer $hasSubscription={!!plan}>
            <Box display="flex" flexDirection="column" alignItems="start">
              <Name variant="body1">{`${firstName} ${lastName}`}</Name>
              {isCoach ? (
                <Typography align="left" variant="h6" component="p">
                  <Trans i18nKey={`${I18N_KEY}.coachLabel`}>Coach</Trans>
                </Typography>
              ) : isTrialing ? (
                <Typography align="left" variant="h6" component="p">
                  <Trans i18nKey={`${I18N_KEY}.trial`}>Evaluation</Trans>
                </Typography>
              ) : (
                !!plan && (
                  <Typography align="left" variant="h6" component="p">
                    {t(`Enums:PlanType.${plan.planType}`)}
                  </Typography>
                )
              )}
            </Box>
            <ExpandIcon />
          </NameIconContainer>
        )}
      </UserContainer>
      <Menu
        elevation={1}
        open={menuIsOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={handleProfileClick}>
          <SettingsIcon />
          <Trans i18nKey={`${I18N_KEY}.menuItemSettings`}>Settings</Trans>
        </MenuItem>
        <MenuItem onClick={handleLanguageMenuItemClick}>
          <LanguageIcon />
          <Trans i18nKey={`${I18N_KEY}.menuItemLanguage`}>Language:</Trans>
          <Box ml={0.5} fontWeight={400} component="span">
            {langLabel}
          </Box>
        </MenuItem>
        <LogoutMenuItem onClick={handleLogout}>
          <LogoutIcon />
          <Trans i18nKey={`${I18N_KEY}.menuItemLogout`}>Logout</Trans>
        </LogoutMenuItem>
      </Menu>
      <LanguageSelectionDialog
        language={language}
        open={languageDialogIsOpen}
        handleClose={handleLanguageDialogOnClose}
      />
    </Container>
  )
}

export default User
