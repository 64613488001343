import React from 'react'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { Trans, useTranslation } from 'react-i18next'

import Grid from '@material-ui/core/Grid'

import { Title, Form, Row, ActionRow } from 'src/modules/player-settings/common'
import { BillingAddressFields } from 'src/modules/player-settings/billing'
import FormTextField, {
  ErrorMessage,
} from 'src/components/inputs/FormTextField'

import { countries } from 'src/models/countries'
import { SettingsSubmitButton } from 'src/modules/common'
import Autocomplete from 'src/components/inputs/Autocomplete'

const I18N_KEY = 'SettingsBillingAddress'

const StyledForm = styled(Form)`
  max-width: 700px;
  width: 100%;
`

const StyledShortFormTextField = styled(FormTextField)`
  ${ErrorMessage} {
    bottom: -29px;
  }
`

interface Props {}

const BillingAddressForm: React.FC<Props> = () => {
  const { t } = useTranslation()
  const formik = useFormikContext<BillingAddressFields>()
  const { dirty, isValid, isSubmitting } = formik

  return (
    <StyledForm>
      <Title variant="h4">
        <Trans i18nKey={`${I18N_KEY}.title`}>Billing Address</Trans>
      </Title>
      <Grid container spacing={1}>
        <Row item xs={12} sm={10}>
          <FormTextField
            name="fullName"
            label={t(`${I18N_KEY}.fullNameLabel`, 'Full name')}
            fullWidth
            disabled={isSubmitting}
          />
        </Row>
        <Row item xs={12}>
          <FormTextField
            name="address"
            label={t(`${I18N_KEY}.addressLabel`, 'Street Address')}
            fullWidth
            disabled={isSubmitting}
          />
        </Row>
        <Row item xs={12} sm={5}>
          <FormTextField
            name="city"
            label={t(`${I18N_KEY}.cityLabel`, 'City')}
            fullWidth
            disabled={isSubmitting}
          />
        </Row>
        <Row item xs={12} sm={5}>
          <Autocomplete
            name="country"
            options={countries}
            getOptionLabel={country => country.name}
            label={t(`${I18N_KEY}.countryLabel`, 'Country')}
            placeholder={t(`${I18N_KEY}.countryPlaceHolder`, 'Please select')}
            fullWidth
          />
        </Row>
        <Row item xs={12} sm={2}>
          <StyledShortFormTextField
            label={t(`${I18N_KEY}.postcodeLabel`, 'Post/ZIP code')}
            name="postcode"
            fullWidth
            disabled={isSubmitting}
          />
        </Row>
        <ActionRow item xs={12}>
          <SettingsSubmitButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={!dirty || !isValid}
            loading={isSubmitting}
          >
            <Trans i18nKey={`${I18N_KEY}.saveChangesButtonLabel`}>
              Save Changes
            </Trans>
          </SettingsSubmitButton>
        </ActionRow>
      </Grid>
    </StyledForm>
  )
}

export default BillingAddressForm
