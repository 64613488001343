import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'

import CloseIcon from '@material-ui/icons/Close'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

import useQuery from 'src/hooks/useQuery'
import {
  FlowComponentProps,
  SubscriptionFlowData,
} from 'src/components/subscriptionFlow/types'
import useAppState from 'src/hooks/useAppState'
import useUpgradeFlow from 'src/hooks/useUpgradeFlow'
import { playerNameSelector } from 'src/store/playerSlice'
import Success from 'src/components/subscriptionFlow/Success'
import { BillingDuration } from 'src/utils/subscriptionConstants'
import Membership from 'src/components/subscriptionFlow/Membership'
import { defaultTransition, fadeVariants } from 'src/utils/animations'
import { billingAddress, userPlanSelector } from 'src/store/subscriptionSlice'
import { isChina, Routes, UPGRADE_QUERY_PARAM_NAME } from 'src/utils/constants'
import ChinaPayment from 'src/components/subscriptionFlow/payment/ChinaPayment'
import StripePayment from 'src/components/subscriptionFlow/payment/StripePayment'
import UpgradeDialog from 'src/components/subscriptionFlow/UpgradeDialog'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NewStripePaymentContainer } from './payment/NewStripePaymentContainer'

const SubscriptionFlow: React.FC<{ open: boolean }> = ({ open }) => {
  const history = useHistory()
  const { close } = useUpgradeFlow()
  const { isCoachView } = useAppState()
  const { stripeUpgrade } = useFlags()
  const billing = useSelector(billingAddress)
  const userPlan = useSelector(userPlanSelector)
  const userName = useSelector(playerNameSelector)

  const queryParams = useQuery()
  const queryParamStep = Number(
    queryParams.get(UPGRADE_QUERY_PARAM_NAME) || '0'
  )

  const userPlanDuration = userPlan
    ? userPlan.duration === BillingDuration.Monthly
      ? BillingDuration.Quarterly
      : BillingDuration.Annually
    : BillingDuration.Annually

  const [step, setStep] = useState(queryParamStep)
  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionFlowData>({
      plan: userPlan || null,
      billingDuration: userPlanDuration,
    })

  const steps: React.FC<FlowComponentProps>[] = [
    Membership,
    isChina
      ? ChinaPayment
      : stripeUpgrade
      ? NewStripePaymentContainer
      : StripePayment,
    Success,
  ]

  useEffect(() => {
    if (
      (userName?.firstName || userName?.lastName) &&
      !subscriptionData.billing?.fullName
    ) {
      const { billing, ...restData } = subscriptionData
      setSubscriptionData({
        ...restData,
        billing: {
          address: '',
          city: '',
          postcode: '',
          country: billing?.country!,
          ...billing,
          fullName: `${userName.firstName} ${userName.lastName}`,
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName])

  useEffect(() => {
    if (billing) {
      setSubscriptionData({
        ...subscriptionData,
        billing,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billing])

  const handleNavClick = () => {
    if (step === 0 || step === steps.length - 1) {
      close()
      return
    }
    setStep(step - 1)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Component = useMemo(() => steps[step], [step])

  const handleNext = (data?: SubscriptionFlowData) => {
    if (data) {
      setSubscriptionData(data)
    }
    if (step < steps.length - 1) {
      setStep(step + 1)
      return
    }
    if (step === steps.length - 1) {
      if (isCoachView) {
        close()
      } else {
        history.push(Routes.Dashboard)
      }
    }
  }

  useEffect(() => {
    setStep(0)
  }, [open])

  const handleChange = (data: SubscriptionFlowData) => setSubscriptionData(data)

  const navButton = (
    <AnimatePresence>
      <motion.div
        exit="exit"
        initial="enter"
        animate="visible"
        variants={fadeVariants}
        transition={defaultTransition}
      >
        {step > 0 && step < steps.length - 1 ? (
          <KeyboardBackspaceIcon />
        ) : (
          <CloseIcon />
        )}
      </motion.div>
    </AnimatePresence>
  )

  return (
    <UpgradeDialog
      open={open}
      navButton={navButton}
      onNavButtonClick={handleNavClick}
    >
      <Component
        onNext={handleNext}
        data={subscriptionData}
        onChange={handleChange}
      />
    </UpgradeDialog>
  )
}

export default SubscriptionFlow
