import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

import IconButton from '@material-ui/core/IconButton'

const I18N_KEY = 'PasswordVisibility'

interface Props {
  isVisible: boolean
  toggleVisibility: () => void
}

const lineVariants = {
  hidden: { pathLength: 1 },
  visible: { pathLength: 0 },
}

const PasswordVisibility: React.FC<Props> = ({
  isVisible,
  toggleVisibility,
}) => {
  const { t } = useTranslation()

  return (
    <IconButton
      onClick={toggleVisibility}
      aria-label={
        isVisible
          ? t(`${I18N_KEY}.hidePassword`, 'Hide password')
          : t(`${I18N_KEY}.showPassword`, 'Show password')
      }
    >
      <motion.svg
        width="30"
        fill="none"
        height="16"
        initial={false}
        viewBox="0 0 30 16"
        xmlns="http://www.w3.org/2000/svg"
        animate={isVisible ? 'visible' : 'hidden'}
      >
        <path
          fill="#64696F"
          fillRule="evenodd"
          d="M15 0c5.12.001 10.238 2.327 14.142 6.978L30 8l-.858 1.022c-7.81 9.304-20.474 9.304-28.284 0L0 8l.858-1.022C4.763 2.326 9.882 0 15 0zm.05 2h-.101A6 6 0 0015 14h-.076.152A6 6 0 0015 2h.05zm6.59 1.538l.034.05A7.962 7.962 0 0123 8c0 1.652-.5 3.187-1.359 4.462 1.99-.96 3.87-2.368 5.534-4.224l.21-.238-.21-.238c-1.664-1.856-3.543-3.264-5.534-4.224zm-13.281 0l-.232.114C6.138 4.65 4.266 6.1 2.615 8c1.715 1.974 3.669 3.462 5.744 4.461A7.96 7.96 0 017 8c0-1.652.5-3.187 1.359-4.462zM15 4.5c.296 0 .583.037.857.106a2 2 0 102.537 2.537A3.505 3.505 0 0115 11.5a3.5 3.5 0 110-7z"
          clipRule="evenodd"
        />
        <motion.path
          strokeWidth="2"
          stroke="#64696E"
          variants={lineVariants}
          d="M1.422 18.184L25.422 1.184"
        />
      </motion.svg>
    </IconButton>
  )
}

export default React.memo(PasswordVisibility)
