import React from 'react'
import { CountrySelector } from './CountrySelector'
import HookFormTextField from './HookFormTextField'
import { FieldErrors } from 'react-hook-form/dist/types/errors'
import { Control } from 'react-hook-form/dist/types/form'

interface FormPhoneFieldProps {
  errors: FieldErrors
  control: Control
  placeholder: string
}

/**
 * Can be used to render a phone number input into a react-hook form.
 * @param control react hook control
 * @param errors react hook errors
 * @param placeholder text
 * @constructor
 */
export const HookFormPhoneField: React.FC<FormPhoneFieldProps> = ({
  control,
  errors,
  placeholder,
}) => (
  <>
    <CountrySelector control={control} />
    <HookFormTextField
      name="phoneNumber"
      type="number"
      control={control}
      errors={errors}
      placeholder={placeholder}
    />
  </>
)
