import React from 'react'

const DashIcon: React.FC = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="2"
    fill="none"
    viewBox="0 0 11 2"
    {...props}
  >
    <path
      stroke="#F6A609"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 1L10 1"
    />
  </svg>
)

export default React.memo(DashIcon)
