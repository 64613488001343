import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { Paper, Divider, Typography } from '@material-ui/core'

import ComposedGraph from 'src/components/graph/composed'
import { ReportOpportunityItem } from 'src/store/reportSlice'
import { TranslationContext } from 'src/utils/TranslationContext'
import ReportComment from 'src/modules/reports/ReportDetail/ReportComment'
import StrokesProgressLabel from 'src/components/dataDisplay/StrokesProgressLabel'

const I18N_KEY = 'IOGraph'

interface Props {
  comment: string
  isCoachView: boolean
  benchmarkName: string
  referencePoint: string
  isCreatedByUser: boolean
  isCoachGenerated: boolean
  payload: ReportOpportunityItem
  generateSaveComment: (param: string) => (comment: string) => Promise<boolean>
}

const HeaderContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${theme.breakpoints.up(750)} {
      flex-direction: row;
      justify-content: space-between;
    }
  `
)

const HeaderInfo = styled.div(
  ({ theme }) => css`
    display: flex;
    width: 100%;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    padding: ${theme.spacing(3, 3, 0, 4.5)};

    ${theme.breakpoints.down('xs')} {
      width: 100%;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      padding: ${theme.spacing(2, 2, 1.5, 4.5)};
    }
  `
)

const GraphContainer = styled.div(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 3, 1, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2, 1, 0)};
    }
  `
)

const IOGraph: React.FC<Props> = ({
  payload,
  comment,
  isCoachView,
  benchmarkName,
  referencePoint,
  isCreatedByUser,
  isCoachGenerated,
  generateSaveComment,
}) => {
  const [isActive, setIsActive] = useState(false)

  const { t } = useTranslation()

  const { metricLabels } = useContext(TranslationContext)!

  const metricId = payload.metricId
  const title = metricLabels[metricId]

  const dataExists = payload.sgTotal !== null

  const handleActive = () => {
    setIsActive(true)
  }

  const handleInActive = () => {
    setIsActive(false)
  }

  return (
    <Paper
      elevation={0}
      onMouseEnter={handleActive}
      onMouseLeave={handleInActive}
    >
      <HeaderContent>
        <HeaderInfo>
          <Typography>{title}</Typography>
          <StrokesProgressLabel
            dataExists={dataExists}
            sgValue={payload.sgTotal}
            progress={payload.progress}
          />
        </HeaderInfo>
      </HeaderContent>
      <GraphContainer>
        <ComposedGraph
          width="100%"
          height={225}
          active={isActive}
          payload={payload}
          showXAxis={false}
          dataExists={dataExists}
          benchmarkName={benchmarkName}
          referencePoint={referencePoint}
          id={`report-io-${payload.metricId}`}
        />
      </GraphContainer>

      {isCoachGenerated && (
        <>
          <Divider />
          <ReportComment
            comment={comment}
            dialogTitle={title}
            isCoachView={isCoachView}
            isCreatedByUser={isCreatedByUser}
            onChangeComment={generateSaveComment(metricId)}
            addButtonLabel={t(`${I18N_KEY}.addComment`, 'Add comment')}
          />
        </>
      )}
    </Paper>
  )
}

export default IOGraph
