import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Formik, FormikConfig } from 'formik'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'

import { ErrorNames } from 'src/utils/constants'
import { updateUserPassword } from 'src/store/userSlice'
import { createChangePasswordSchema } from 'src/utils/validationSchemas'
import CloseAccount from 'src/modules/player-settings/account/CloseAccount'
import { Content as CommonContent } from 'src/modules/player-settings/common'
import { openToast, getSuccessToast, getErrorToast } from 'src/store/toastSlice'
import AccountInformation from 'src/modules/player-settings/account/AccountInformation'
import ChangePasswordForm from 'src/modules/player-settings/account/ChangePasswordForm'

const I18N_KEY = 'SettingsAccount'

export interface PasswordFormFields {
  currentPassword: string
  newPassword: string
  confirmPassword: string
}

const Content = styled(CommonContent)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)}px;
  `
)

const Account: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const changePasswordValidationSchema = useMemo(
    () => createChangePasswordSchema(t),
    [t]
  )

  const handlePasswordChangeSubmit = async ({
    newPassword,
    currentPassword,
  }: PasswordFormFields) => {
    try {
      await dispatch(
        updateUserPassword({
          newPassword,
          currentPassword,
        })
      )
      dispatch(
        openToast(
          getSuccessToast(
            t(
              `${I18N_KEY}.passwordChangeSuccessToast`,
              'Password successfully updated'
            )
          )
        )
      )
    } catch (error: any) {
      const message =
        error.name === ErrorNames.IncorrectPassword
          ? t(
              `${I18N_KEY}.passwordChangeErrorIncorrectPasswordToast`,
              'Incorrect password'
            )
          : t(
              `${I18N_KEY}.passwordChangeErrorGeneralToast`,
              'Error updating your password'
            )
      dispatch(openToast(getErrorToast(message)))
    }
  }

  const changePasswordFormikOptions: FormikConfig<PasswordFormFields> = {
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values: PasswordFormFields, { resetForm }) => {
      handlePasswordChangeSubmit(values).then(() => {
        resetForm()
      })
    },
  }

  return (
    <Box>
      <Content>
        <AccountInformation />
      </Content>
      <Content>
        <Formik {...changePasswordFormikOptions}>
          <ChangePasswordForm />
        </Formik>
      </Content>
      <Content>
        <CloseAccount />
      </Content>
    </Box>
  )
}

export default Account
