import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'

import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import { PerformanceIndicator } from 'src/utils/golfConstants'
import UpgradePanel from 'src/components/membership/UpgradePanel'
import PerformanceIndicatorTable from 'src/components/dataDisplay/PerformanceIndicatorTable'
import { PlanType } from 'src/utils/subscriptionConstants'

const I18N_KEY = 'RoundStatistics'

interface Props {
  performanceIndicators: PerformanceIndicator[]
}

const Container = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 16)};
  `
)

const Title = styled(Typography)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 3)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const Statistics: React.FC<Props> = ({ performanceIndicators }) => {
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const isPlayerTrialing = useSelector(userIsTrialingSelector)

  return (
    <Container>
      <Title variant="h3">
        <Trans i18nKey={`${I18N_KEY}.statistics`}>Statistics</Trans>
      </Title>
      {isPlayerPremium || isPlayerTrialing ? (
        <PerformanceIndicatorTable
          isSingleRound
          performanceIndicators={performanceIndicators}
        />
      ) : (
        <UpgradePanel planType={PlanType.Train}>
          <Trans i18nKey={`${I18N_KEY}.upgradeDescription`}>
            Upgrade to Premium to get more statistics for this round
          </Trans>
        </UpgradePanel>
      )}
    </Container>
  )
}

export default Statistics
