import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components'

import MuiButton, { ButtonProps } from '@material-ui/core/Button'

import useUpgradeFlow from 'src/hooks/useUpgradeFlow'

const I18N_KEY = 'UpgradeButton'

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin: ${theme.spacing(3, 0)};
  `
)

interface UpgradeButtonProps extends ButtonProps {
  onUpgradeOpen?: () => void
}

const UpgradeButton: React.FC<UpgradeButtonProps> = ({
  onUpgradeOpen,
  ...props
}) => {
  const { open } = useUpgradeFlow()
  const handleOnClick = () => {
    open()
    if (onUpgradeOpen) {
      onUpgradeOpen()
    }
  }
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleOnClick}
      {...props}
    >
      <Trans i18nKey={`${I18N_KEY}.upgradeLabel`}>Upgrade</Trans>
    </Button>
  )
}

export default UpgradeButton
