import React from 'react'

const DownloadIcon: React.FC = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="19"
    fill="none"
    viewBox="0 0 17 19"
    {...props}
  >
    <path
      fill="#2857E3"
      fillRule="evenodd"
      d="M15 19H2a1 1 0 01-1-1V1a1 1 0 011-1h7.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V18a1 1 0 01-1 1zM10 2l4 4h-3.5a.5.5 0 01-.5-.5V2z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M9.07 8v5.261l1.742-1.7.765.694L8.54 15.22 5.5 12.254l.765-.694 1.742 1.701V8H9.07z"
      clipRule="evenodd"
    />
  </svg>
)

export default React.memo(DownloadIcon)
