import React from 'react'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import MuiPaper from '@material-ui/core/Paper'
import LabSkeleton from '@material-ui/lab/Skeleton'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import useBreakpoints from 'src/hooks/useBreakpoints'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import { Trans } from 'react-i18next'

const I18N_KEY = 'PlayerCard'

interface Props {
  firstName?: string
  lastName?: string
  generateNavigateToPlayer?: (toActivity?: boolean) => () => void
}

const Paper = styled(MuiPaper)(
  ({ theme }) => css`
    height: 100%;

    ${theme.breakpoints.down('xs')} {
      height: 290px;
      margin: ${theme.spacing(0, 0, 2)};
    }
  `
)

const MuiSkeleton = styled(LabSkeleton)(
  ({ theme }) =>
    css`
      background-color: ${theme.palette.background.xlight};
    `
)

const PlayerContent = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;

    ${theme.breakpoints.down('xs')} {
      align-items: flex-start;
    }
  `
)

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin: ${theme.spacing(2, 0, 0)};
    background-color: ${theme.palette.background.xlight};
    width: fit-content;

    ${theme.breakpoints.down('xs')} {
      display: none;
    }
  `
)

const PlayerCardLoading: React.FC<Props> = ({
  firstName,
  lastName,
  generateNavigateToPlayer,
}) => {
  const { isSmallScreen } = useBreakpoints()
  const containsName = firstName != null && firstName.trim() !== ''

  const handlePlayerNavigate = generateNavigateToPlayer
    ? generateNavigateToPlayer()
    : undefined

  return isSmallScreen ? (
    <Paper elevation={0}>
      <Box display="flex" px={3} py={3} alignItems="center">
        <Box>
          <MuiSkeleton
            variant="circle"
            width={84}
            height={84}
            animation="wave"
          />
        </Box>
        <Box flex={1} ml={2}>
          <Skeleton width="100%" height={75} variant="rect" animation="wave" />
        </Box>
      </Box>
      <Box display="flex" px={3} pb={3}>
        <Skeleton width="100%" height={125} variant="rect" animation="wave" />
      </Box>
    </Paper>
  ) : (
    <Paper elevation={0}>
      <Box display="flex" justifyContent="center" pt={6} mb={3} px="auto">
        <MuiSkeleton
          variant="circle"
          width={120}
          height={120}
          animation="wave"
        />
      </Box>
      {containsName ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb={14}
        >
          <PlayerContent>
            <Typography variant="body2">
              {`${firstName} ${lastName}`}
            </Typography>
          </PlayerContent>
          <Button size="small" color="primary" onClick={handlePlayerNavigate}>
            <Trans i18nKey={`${I18N_KEY}.playerDash`}>Player Dashboard</Trans>
          </Button>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" mb={14}>
          <Skeleton width={140} height={50} variant="rect" animation="wave" />
        </Box>
      )}
      <Box px={3} mb={8}>
        <Skeleton width="100%" height={50} variant="rect" animation="wave" />
      </Box>
    </Paper>
  )
}

export default React.memo(PlayerCardLoading)
