import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Paper from '@material-ui/core/Paper'
import MuiButton from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import useBenchmark from 'src/hooks/useBenchmark'
import { MetricId } from 'src/utils/golfConstants'
import ComposedGraph from 'src/components/graph/composed'
import { SummarySingleItem } from 'src/store/summarySlice'
import StrokesProgressLabel from 'src/components/dataDisplay/StrokesProgressLabel'

const I18N_KEY = 'SubCategoryGraph'

interface SubCategoryGraphProps {
  path: string
  title: string
  metricId: MetricId
  width: string | number
  height: string | number
  payload: SummarySingleItem
}

const Container = styled(Paper)(
  ({ theme }) => css`
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: ${theme.spacing(5, 5, 3, 0)};

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(2, 2, 2, 0)};
    }
  `
)

const HeaderContent = styled.div(
  ({ theme }) => css`
    padding-left: ${theme.spacing(4.5)}px;

    ${theme.breakpoints.down('xs')} {
      display: flex;
      justify-content: space-between;
      padding-left: ${theme.spacing(2)}px;
    }
  `
)

const Button = styled(MuiButton)(
  ({ theme }) => css`
    margin-left: auto;
    margin-top: ${theme.spacing(2)}px;
    background-color: ${theme.palette.background.xlight};
  `
)

const SubCategoryGraph: React.FC<SubCategoryGraphProps> = ({
  path,
  title,
  width,
  height,
  payload,
  metricId,
}) => {
  const [isActive, setIsActive] = useState(false)

  const history = useHistory()
  const { benchmarkId, ...benchmarkInfo } = useBenchmark()

  const dataExists = payload.sgTotal !== null

  const handleActive = () => {
    setIsActive(true)
  }

  const handleInActive = () => {
    setIsActive(false)
  }

  const handleDetailsClick = () => {
    if (dataExists) {
      history.push(path)
    }
  }

  return (
    <Container
      elevation={0}
      onMouseEnter={handleActive}
      onMouseLeave={handleInActive}
    >
      <HeaderContent>
        <Typography>{title}</Typography>
        <StrokesProgressLabel
          dataExists={dataExists}
          sgValue={payload.sgTotal}
          progress={payload.progress}
        />
      </HeaderContent>
      <ComposedGraph
        id={metricId}
        width={width}
        height={height}
        active={isActive}
        payload={payload}
        dataExists={dataExists}
        {...benchmarkInfo}
      />
      {dataExists && (
        <Button size="small" color="primary" onClick={handleDetailsClick}>
          <Trans i18nKey={`${I18N_KEY}.detailedView`}>Detailed view</Trans>
        </Button>
      )}
    </Container>
  )
}

export default SubCategoryGraph
