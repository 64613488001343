import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'

import { Title, Container } from 'src/modules/player-settings/common'
import { cardLastFourDigits, deleteCard } from 'src/store/subscriptionSlice'
import AddCardDialog from 'src/modules/player-settings/billing/AddCardDialog'
import { getErrorToast, getSuccessToast, openToast } from 'src/store/toastSlice'
import RemoveCardConfirmDialog from 'src/modules/player-settings/billing/RemoveCardConfirmDialog'

const I18N_KEY = 'CardInfo'

const RemoveButton = styled(Button)(
  ({ theme }) => css`
    color: ${theme.palette.error.main};
    padding-left: ${theme.spacing(2)}px;
  `
)

const ButtonGrid = styled(Grid)(
  ({ theme }) => css`
    button:first-child {
      margin-right: ${theme.spacing(1)}px;
    }
  `
)

const CardInfo: React.FC = () => {
  const { t } = useTranslation()
  const lastFourDigits = useSelector(cardLastFourDigits)
  const dispatch = useDispatch()

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [addCardDialogOpen, setAddCardDialogOpen] = useState(false)

  const handleRemoveClick = () => setConfirmDialogOpen(true)
  const handleAddClick = () => setAddCardDialogOpen(true)

  const handleCloseConfirmDialog = () => {
    if (!inProgress) {
      setConfirmDialogOpen(false)
    }
  }
  const handleActionConfirmDialog = async () => {
    setInProgress(true)
    try {
      await dispatch(deleteCard())
      dispatch(
        openToast(
          getSuccessToast(
            t(`${I18N_KEY}.removeCardSuccess`, 'Your card has been removed')
          )
        )
      )
      setConfirmDialogOpen(false)
    } catch (error: any) {
      dispatch(
        openToast(
          getErrorToast(
            t(`${I18N_KEY}.removeCardError`, 'Error removing your card')
          )
        )
      )
    } finally {
      setInProgress(false)
    }
  }

  const handleCloseAddCardDialog = () => {
    setAddCardDialogOpen(false)
  }

  const cardButtonLabel = lastFourDigits
    ? t(`${I18N_KEY}.changeCardButtonLabel`, 'Change Card')
    : t(`${I18N_KEY}.addCardButtonLabel`, 'Add Card')

  return (
    <>
      <Title variant="h4" $gutterBottom={2}>
        {t(`${I18N_KEY}.title`, 'Card')}
      </Title>
      {lastFourDigits ? (
        <Container container spacing={1}>
          <Grid item xs={12}>
            <Trans i18nKey={`${I18N_KEY}.cardEnding`}>
              Card Ending: <span>**** **** **** {{ lastFourDigits }}</span>
            </Trans>
          </Grid>
          <ButtonGrid item xs={12}>
            <Button variant="outlined" color="primary" onClick={handleAddClick}>
              {cardButtonLabel}
            </Button>
            <RemoveButton variant="text" onClick={handleRemoveClick}>
              {t(`${I18N_KEY}.removeButtonLabel`, 'Remove')}
            </RemoveButton>
          </ButtonGrid>
        </Container>
      ) : (
        <Box mb={2}>
          <Trans i18nKey={`${I18N_KEY}.noCardMessage`}>
            You don’t currently have a card on file. Add a card to pay for
            premium membership.
          </Trans>
        </Box>
      )}
      {!lastFourDigits && (
        <Button variant="outlined" color="primary" onClick={handleAddClick}>
          {cardButtonLabel}
        </Button>
      )}
      <RemoveCardConfirmDialog
        inProgress={inProgress}
        open={confirmDialogOpen}
        handleClose={handleCloseConfirmDialog}
        handleAction={handleActionConfirmDialog}
      />
      <AddCardDialog
        open={addCardDialogOpen}
        handleClose={handleCloseAddCardDialog}
      />
    </>
  )
}

export default CardInfo
