import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import {
  SummaryStatus,
  summaryIOListSelector,
  opportunitiesGraphData,
  improvementPotentialSelector,
} from 'src/store/summarySlice'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
} from 'src/store/subscriptionSlice'
import useBenchmark from 'src/hooks/useBenchmark'
import { getImprovementCaption } from 'src/utils/helpers'
import Skeleton from 'src/components/dataDisplay/Skeleton'
import UpgradePanel from 'src/components/membership/UpgradePanel'
import ImprovementError from 'src/modules/player-dash/improvement/ImprovementError'
import OpportunityGraphGrid from 'src/modules/player-dash/improvement/OpportunityGraphGrid'
import { PlanType } from 'src/utils/subscriptionConstants'
import InsightDialogButton from '../../insight/generation/InsightDialogButton'
import { totalRoundsSelector } from '../../../store/roundSlice'
import useAppState from '../../../hooks/useAppState'

const I18N_KEY = 'ImprovementOpportunities'

interface Props {
  summaryStatus: SummaryStatus
}

const Container = styled.div(
  ({ theme }) => css`
    margin: ${theme.spacing(8, 0, 0)};
  `
)

const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-rows: auto auto;
    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const ButtonContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`

const GridSubtitleContainer = styled.div(
  ({ theme }) => css`
    grid-column: 1 / 2;
    grid-row: 2 / -1;
    display: flex;
    align-content: center;
    justify-content: center;
    align-self: center;
    justify-self: start;
    ${theme.breakpoints.down('xs')} {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }
    ${theme.breakpoints.down('sm')} {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }
    ${theme.breakpoints.down('md')} {
      grid-column: 1 / -1;
      grid-row: 2 / 3;
    }
  `
)

const GridButtonContainer = styled.div(
  ({ theme }) => css`
    grid-column: 2 / -1;
    grid-row: 1 / -1;
    display: flex;
    align-content: center;
    justify-content: end;
    align-self: center;
    height: 50px;
    ${theme.breakpoints.down('xs') || theme.breakpoints.down('sm')} {
      justify-self: start;
      grid-column: 1 / -1;
      grid-row: 3 / -1;
      height: 40px;
      margin-top: 5px;
    }
    ${theme.breakpoints.down('sm')} {
      justify-self: start;
      grid-column: 1 / -1;
      grid-row: 3 / -1;
      height: 40px;
      margin-top: 5px;
    }
    ${theme.breakpoints.down('md')} {
      justify-self: start;
      grid-column: 1 / -1;
      grid-row: 3 / -1;
      height: 40px;
      margin-top: 5px;
    }
  `
)

const ImprovementOpportunities: React.FC<Props> = ({ summaryStatus }) => {
  const { referencePoint } = useBenchmark()
  const isPlayerTrialing = useSelector(userIsTrialingSelector)
  const isPlayerPremium = useSelector(hasActiveSubscription())
  const { isCoach } = useAppState()
  const totalRounds = useSelector(totalRoundsSelector)
  const data = useSelector(opportunitiesGraphData)
  const improvementPotential = useSelector(improvementPotentialSelector)
  // IOList is only populated once user adds 5 rounds
  const hasAddedFiveRounds = !!useSelector(summaryIOListSelector)?.length

  const getImprovementContent = () => {
    switch (summaryStatus) {
      case SummaryStatus.Loading:
        return (
          <Skeleton variant="rect" width="100%" height={350} animation="wave" />
        )
      case SummaryStatus.Valid:
        if (data?.length) {
          return (
            <OpportunityGraphGrid
              data={data}
              isPlayerPremium={isPlayerPremium}
            />
          )
        } else {
          return (
            <ImprovementError
              summaryStatus={summaryStatus}
              hasAddedFiveRounds={hasAddedFiveRounds}
            />
          )
        }
      default:
        return (
          <ImprovementError
            summaryStatus={summaryStatus}
            hasAddedFiveRounds={hasAddedFiveRounds}
          />
        )
    }
  }

  return (
    <Container>
      <HeadingContainer>
        <Typography variant="h3">
          <Trans i18nKey={`${I18N_KEY}.title`}>Improvement Opportunities</Trans>
        </Typography>
        {!!data?.length && (
          <GridSubtitleContainer>
            <Typography component="p" variant="caption" color="textSecondary">
              {getImprovementCaption(improvementPotential, referencePoint)}
            </Typography>
          </GridSubtitleContainer>
        )}
        {isCoach || totalRounds >= 5 ? (
          <GridButtonContainer>
            <ButtonContainer>
              <InsightDialogButton />
            </ButtonContainer>
          </GridButtonContainer>
        ) : null}
      </HeadingContainer>
      <Box mt={2}>
        {isPlayerPremium || isPlayerTrialing ? (
          getImprovementContent()
        ) : (
          <UpgradePanel planType={PlanType.Train}>
            <Trans i18nKey={`${I18N_KEY}.upgradeDescription`}>
              Get automatic insight into your biggest opportunities to improve
              your game, re-analysed every 5 rounds.
            </Trans>
          </UpgradePanel>
        )}
      </Box>
    </Container>
  )
}

export default ImprovementOpportunities
