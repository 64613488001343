export enum BillingDuration {
  Annually = 'annually',

  Quarterly = 'quarterly',
  Monthly = 'monthly',
}

// Lite = Benchmark
// Pro === All access
// Premium = Old Pro => Pro
// Premium New = Pro
// Premium Plus = Elite => Pro + Consulting
export enum PlanType {
  Lite = 'lite', // Plan deprecated - 2023 -> PremiumNew
  Free = 'free', // Plan deprecated
  Premium = 'premium', // Plan deprecated
  PremiumNew = 'premiumnew', // Plan deprecated - 2023 -> Play
  PremiumPlus = 'premiumplus', // Plan deprecated - 2023 -> Play
  Insight = 'insight', // Same a lite (Pre-Upgrade to Premium New)
  Train = 'train', // Pro/Premium New
  Play = 'play', // Pro/Premium New + Course Strategy (Can Trial)
  Consult = 'consult', // Elite
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Deleted = 'deleted',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}
