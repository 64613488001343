import styled, { css } from 'styled-components'

import Typography from '@material-ui/core/Typography'
import { breakpoints } from 'src/utils/constants'

export const HeaderSection = styled.div(
  ({ theme }) => css`
    background-color: #fff;
    padding: ${theme.spacing(2, 0, 4)};

    ${theme.breakpoints.down(breakpoints.xxs)} {
      padding: ${theme.spacing(2, 4, 4)};
    }
  `
)

export const Description = styled(Typography)(
  ({ theme }) => css`
    margin-top: ${theme.spacing(-1)}px;
    padding-left: ${theme.spacing(0.5)}px;
  `
)
