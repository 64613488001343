import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useAppState from 'src/hooks/useAppState'
import {
  hasActiveSubscription,
  userIsTrialingSelector,
  userPlanSelector,
} from 'src/store/subscriptionSlice'
import styled, { css } from 'styled-components'
import { Trans } from 'react-i18next'

import Typography from '@material-ui/core/Typography'
import MuiContainer from '@material-ui/core/Container'

import {
  courseStrategyDownloadDialogOpenSelector,
  getCourseStrategies,
  updateDownloadDialogVisibility,
} from 'src/store/courseStrategySlice'
import CourseStrategyTable from './courseStrategyTable'
import CustomDialog from 'src/components/feedback/CustomDialog'
import CourseStrategyDownload from './CourseStrategyDownload'
import UpgradePanel from 'src/components/membership/UpgradePanel'
import { PlanType } from 'src/utils/subscriptionConstants'
import { MembershipLevel } from 'src/utils/constants'

const I18N_KEY = 'CourseStrategy'

const CourseStrategyContainer = styled.div<{ $addPagePadding: boolean }>(
  ({ theme, $addPagePadding }) => css`
    width: 100%;
    display: flex;
    min-height: 100%;
    position: absolute;
    padding: ${$addPagePadding
      ? theme.spacing(14, 0, 0)
      : theme.spacing(8, 0, 0)};
    background-color: ${theme.palette.background.xlight};
  `
)

const Content = styled(MuiContainer)(
  ({ theme }) => css`
    flex: 1;

    ${theme.breakpoints.down('xs')} {
      padding: 0;
    }
  `
)

const HeadingContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('xs')} {
      padding: ${theme.spacing(0, 2)};
    }
  `
)

const CourseStrategy: React.FC = () => {
  const dispatch = useDispatch()
  const { isPlayer } = useAppState()

  const userNotSubscribed = !useSelector(userPlanSelector)
  const playerIsTrialing = useSelector(userIsTrialingSelector)
  const playerIsPremium = useSelector(
    hasActiveSubscription(MembershipLevel.CourseStrategy)
  )
  const addPagePadding = isPlayer && (userNotSubscribed || playerIsTrialing)

  useEffect(() => {
    dispatch(getCourseStrategies())
  }, [dispatch])

  const downloadDialogOpen = useSelector(
    courseStrategyDownloadDialogOpenSelector
  )

  const closeDownloadDialog = () => {
    dispatch(updateDownloadDialogVisibility({ isDownloadDialogOpen: false }))
  }

  return (
    <>
      <CourseStrategyContainer $addPagePadding={addPagePadding}>
        <Content>
          <HeadingContainer>
            <Typography variant="h3" gutterBottom>
              <Trans i18nKey={`${I18N_KEY}.courseStrategy`}>
                Course Strategy
              </Trans>
            </Typography>
          </HeadingContainer>
          {playerIsPremium || playerIsTrialing ? (
            <CourseStrategyTable />
          ) : (
            <UpgradePanel planType={PlanType.Play}>
              <Trans i18nKey={`${I18N_KEY}.upgradeDescription`}>
                Learn how to play any course in the most statistically optimized
                way, personalized for your game.
              </Trans>
            </UpgradePanel>
          )}
        </Content>
      </CourseStrategyContainer>
      <CustomDialog open={downloadDialogOpen}>
        <CourseStrategyDownload handleClose={closeDownloadDialog} />
      </CustomDialog>
    </>
  )
}

export default CourseStrategy
