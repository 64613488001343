import React, { MouseEvent, useContext, useState } from 'react'
import { Trans } from 'react-i18next'
import styled, { css, keyframes } from 'styled-components'

import Box from '@material-ui/core/Box'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import Typography from '@material-ui/core/Typography'

import { DataRow } from 'src/components/dataDisplay/tableStyles'
import { DateFormats } from 'src/utils/constants'
import {
  ReportGeneration,
  ReportGenerationStatus,
  ReportInfo,
} from 'src/utils/golfConstants'
import { TranslationContext } from 'src/utils/TranslationContext'
import { EndCell, IconButton, StyledCell } from 'src/modules/activity/common'
import CircleCheck from '../../../assets/svgComponents/CircleCheck'
import GetAppIcon from '@material-ui/icons/GetApp'
import Close from '@material-ui/icons/Close'
import Loop from '@material-ui/icons/Loop'

const I18N_KEY = 'ReportsRow'

interface Props {
  isCoachView: boolean
  report: ReportInfo
  handleDeleteClick: (e: MouseEvent<HTMLLIElement>) => void
  handleReportClick: (e: MouseEvent<HTMLTableRowElement>) => void
  isDownloading: boolean
}

const ReportText = styled(Typography)(
  ({ theme }) => css`
    text-transform: capitalize;

    span {
      color: ${theme.palette.error.main};
    }
  `
)

const rotationAnimation = keyframes`
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
`

const downloadingAnimation = keyframes`
  100% {
    transform: translateY(30px);
  }
`

const IconContainer = styled.div<{
  $draft?: boolean
  $error?: boolean
  $isDownloading?: boolean
}>(
  ({ theme, $draft, $error, $isDownloading }) => css`
    width: 35px;
    height: 35px;
    display: flex;
    min-width: 35px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: ${$draft
      ? theme.palette.background.light
      : $error
      ? 'red'
      : theme.palette.primary.light};
    animation-name: ${$draft ? rotationAnimation : 'none'};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    color: ${$isDownloading ? theme.palette.text.white : '#000'};
    svg {
      width: 90%;
      ${$isDownloading
        ? css`
            position: relative;
            top: -15px;
          `
        : ''}
      animation-name: ${$isDownloading ? downloadingAnimation : 'none'};
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    ${theme.breakpoints.down('sm')} {
      display: none;
    }
  `
)

const TextContainer = styled.div(
  ({ theme }) => css`
    margin-left: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} {
      margin-left: 0;
    }
  `
)

const StyledDataRow = styled(DataRow)<{ clickable: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  pointer-events: ${({ clickable }) => (clickable ? 'auto' : 'none')};
`

const StyledIconButton = styled(IconButton)<{ clickable: boolean }>`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  pointer-events: ${({ clickable }) => (clickable ? 'auto' : 'none')};
`

const ReportsRow: React.FC<Props> = ({
  report,
  isCoachView,
  handleReportClick,
  handleDeleteClick,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const { format } = useContext(TranslationContext)!

  const { reportUUID, title, createdAt, coachName, generationType, status } =
    report

  const evaluateIcon = () => {
    if (props.isDownloading) {
      return (
        <IconContainer $isDownloading>
          <GetAppIcon />
        </IconContainer>
      )
    }
    switch (status) {
      case ReportGenerationStatus.Complete:
        return (
          <IconContainer>
            <CircleCheck />
          </IconContainer>
        )
      case ReportGenerationStatus.InProgress:
        return (
          <IconContainer $draft>
            <Loop />
          </IconContainer>
        )
      case ReportGenerationStatus.Error:
        return (
          <IconContainer $error>
            <Close />
          </IconContainer>
        )
    }
  }

  const isClickable = () => {
    return status === ReportGenerationStatus.Complete && !props.isDownloading
  }

  const isButtonClickable = () => {
    return (
      (status === ReportGenerationStatus.Complete ||
        status === ReportGenerationStatus.Error) &&
      !props.isDownloading
    )
  }

  const openMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StyledDataRow
        id={reportUUID}
        onClick={handleReportClick}
        {...props}
        clickable={isClickable()}
      >
        <StyledCell>
          <Box display="flex" alignItems="center">
            {evaluateIcon()}
            <div>
              <TextContainer>
                <ReportText variant="body2">{title}</ReportText>
                <Typography variant="caption" color="textSecondary">
                  {format(new Date(createdAt), DateFormats.TableRow)}
                </Typography>
              </TextContainer>
            </div>
          </Box>
        </StyledCell>
        <StyledCell align="right">
          <Typography>
            {/* Since "Circles" is a name, we don't need to translate it */}
            {generationType === ReportGeneration.Automatic
              ? 'Circles'
              : coachName}
          </Typography>
        </StyledCell>
        {isCoachView && (
          <EndCell align="center">
            <StyledIconButton
              size="small"
              color="primary"
              onClick={openMenu}
              aria-label="Options"
              clickable={isButtonClickable()}
            >
              <MoreIcon />
            </StyledIconButton>
          </EndCell>
        )}
      </StyledDataRow>
      <Menu
        elevation={1}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={e => {
            handleDeleteClick(e)
            closeMenu()
          }}
        >
          <Trans i18nKey={`${I18N_KEY}.delete`}>Delete</Trans>
        </MenuItem>
      </Menu>
    </>
  )
}

export default ReportsRow
