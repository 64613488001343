import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useTranslation, Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { motion, AnimatePresence } from 'framer-motion'
import Box from '@material-ui/core/Box'
import Close from '@material-ui/icons/Close'
import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import {
  Dialog,
  Toolbar,
  DoneIcon,
  StepInfo,
  MainContentBackground,
  XsPaddingZeroContainer,
} from 'src/modules/common'
import {
  updateDialogVisibility,
  roundsDialogOpenSelector,
  selectedRoundLoadingSelector,
} from 'src/store/roundSlice'
import useAddShots from 'src/hooks/useAddShots'
import ShotData from 'src/modules/rounds/shots'
import useAppState from 'src/hooks/useAppState'
import Summary from 'src/modules/rounds/summary'
import ShotsTable from 'src/modules/rounds/table'
import { getRoundTitle } from 'src/utils/helpers'
import { RoundState } from 'src/utils/golfConstants'
import RoundDetails from 'src/modules/rounds/RoundDetails'
import GenericLoader from 'src/components/layout/GenericLoader'
import DownloadIcon from 'src/assets/svgComponents/DownloadIcon'
import { TranslationContext } from 'src/utils/TranslationContext'
import { fadeVariants, defaultTransition } from 'src/utils/animations'
import { DateFormats, TemplateLink, Routes } from 'src/utils/constants'
import { useFlags } from 'launchdarkly-react-client-sdk'

const I18N_KEY = 'Rounds'

const GuideLink = styled.a(
  ({ theme }) => css`
    display: flex;
    align-items: center;

    svg {
      margin-right: ${theme.spacing(1)}px;
    }
  `
)

const TextContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${theme.breakpoints.down('sm')} {
      flex-direction: column;
      align-items: flex-start;
    }
  `
)

const HeaderContainer = styled.div(
  ({ theme }) => css`
    padding-bottom: ${theme.spacing(4)}px;
    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(2, 2, 4, 2)};
    }
  `
)

const DialogTitle = styled(Typography)(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(56)};
    line-height: ${theme.typography.pxToRem(72)};

    ${theme.breakpoints.down('sm')} {
      font-size: ${theme.typography.pxToRem(40)};
      line-height: ${theme.typography.pxToRem(48)};
      margin-bottom: ${theme.spacing(2)}px;
    }
  `
)

const AddRoundsDialog: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(0)

  const history = useHistory()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { teeLayup } = useFlags()
  const { isCoachView, playerUuid } = useAppState()
  const { format } = useContext(TranslationContext)!
  const dialogOpen = useSelector(roundsDialogOpenSelector)
  const isLoading = useSelector(selectedRoundLoadingSelector)

  const {
    roundData,
    currentHole,
    getNextHole,
    numberOfHoles,
    setCurrentHole,
    getPreviousHole,
    transitionDirection,
    setTransitionDirection,
  } = useAddShots(teeLayup)

  const roundCreated = !!roundData
  const roundEntryComplete = roundData?.state === RoundState.Complete

  const steps = [
    {
      title: t(`${I18N_KEY}.stepAddRoundTitle`, 'Add round'),
      stepTitle: t(`${I18N_KEY}.stepRoundDetailsStepTitle`, 'Round Details'),
    },
    {
      title: t(`${I18N_KEY}.stepAddShotsTitle`, 'Add shots'),
      stepTitle: t(`${I18N_KEY}.stepShotDataStepTitle`, 'Shot Data'),
    },
    {
      title: t(`${I18N_KEY}.stepRoundSummaryTitle`, 'Round summary'),
      stepTitle: t(`${I18N_KEY}.stepSummaryStepTitle`, 'Summary'),
    },
  ]

  // Open dialog into the step they are currently on for the round
  useEffect(() => {
    if (dialogOpen) {
      roundData?.state === RoundState.Complete
        ? setCurrentStep(2)
        : roundData?.state === RoundState.Incomplete
        ? setCurrentStep(1)
        : setCurrentStep(0)

      setCurrentHole(0)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dialogOpen])

  const closeDialog = () => {
    dispatch(updateDialogVisibility({ isOpen: false }))
    if (roundCreated) {
      // For existing round, return to the activity page when closing
      history.push(
        isCoachView
          ? Routes.CoachPlayerRounds.replace(':playerUuid', playerUuid ?? '')
          : Routes.Rounds
      )
    }
  }

  const generateStepClick = (index: number) => () => {
    switch (index) {
      case 0:
        setCurrentStep(0)
        return
      case 1:
        roundCreated && setCurrentStep(1)
        return
      case 2:
        roundEntryComplete && setCurrentStep(2)
    }
  }

  const generateStepDisabled = (index: number) => {
    switch (index) {
      case 0:
        return false
      case 1:
        return !roundCreated
      case 2:
        return !roundEntryComplete
    }
  }

  const handleSummaryEdit = () => {
    setCurrentHole(0)
    setCurrentStep(1)
  }

  return (
    <Dialog open={dialogOpen}>
      {isLoading ? (
        <GenericLoader
          text={t(`${I18N_KEY}.loadingText`, 'Getting round data')}
        />
      ) : (
        <>
          <AppBar elevation={0} color="default" position="relative">
            <XsPaddingZeroContainer>
              <Toolbar>
                <IconButton onClick={closeDialog}>
                  <Close />
                </IconButton>
              </Toolbar>
              <HeaderContainer>
                <Box display="flex" mb={2}>
                  {steps.map((step, index) => {
                    const completed = index < currentStep
                    return (
                      <StepInfo
                        key={index}
                        color="primary"
                        $completed={completed}
                        $selected={index === currentStep}
                        onClick={generateStepClick(index)}
                        disabled={generateStepDisabled(index)}
                      >
                        {completed ? <DoneIcon /> : `${index + 1}. `}{' '}
                        {step.stepTitle}
                      </StepInfo>
                    )
                  })}
                </Box>
                {currentStep === 2 ? (
                  <>
                    <Typography variant="h1">
                      {getRoundTitle(roundData!)}
                    </Typography>
                    <Box mt={-2}>
                      <Typography>
                        {roundData &&
                          format(
                            new Date(roundData.datePlayed),
                            DateFormats.WeekDayMonthDay
                          )}
                      </Typography>
                    </Box>
                  </>
                ) : (
                  <TextContainer>
                    <DialogTitle variant="h1">
                      {steps[currentStep].title}
                    </DialogTitle>
                    <GuideLink
                      target="_blank"
                      rel="noopener noreferrer"
                      href={TemplateLink.ShotCollection}
                    >
                      <DownloadIcon />
                      <Typography variant="body2" color="primary">
                        <Trans i18nKey={`${I18N_KEY}.templateButtonLabel`}>
                          Shot Collection Template
                        </Trans>
                      </Typography>
                    </GuideLink>
                  </TextContainer>
                )}
              </HeaderContainer>
            </XsPaddingZeroContainer>
          </AppBar>
          <AnimatePresence>
            {currentStep === 1 && (
              <motion.div
                exit="exit"
                initial="enter"
                animate="visible"
                key="shots-table"
                variants={fadeVariants}
                transition={defaultTransition}
              >
                <XsPaddingZeroContainer>
                  <ShotsTable
                    currentHole={currentHole}
                    numberOfHoles={numberOfHoles}
                    holes={roundData?.holes || []}
                    setCurrentHole={setCurrentHole}
                    score={roundData?.summary?.score}
                    setTransitionDirection={setTransitionDirection}
                    roundState={roundData?.state || RoundState.Incomplete}
                  />
                </XsPaddingZeroContainer>
              </motion.div>
            )}
          </AnimatePresence>
          <MainContentBackground>
            <AnimatePresence exitBeforeEnter>
              <motion.div
                exit="exit"
                initial="enter"
                animate="visible"
                variants={fadeVariants}
                transition={defaultTransition}
                key={steps[currentStep].title}
              >
                {currentStep === 0 && (
                  <RoundDetails setCurrentStep={setCurrentStep} />
                )}
                {currentStep === 1 && (
                  <ShotData
                    roundData={roundData}
                    currentHole={currentHole}
                    getNextHole={getNextHole}
                    numberOfHoles={numberOfHoles}
                    setCurrentStep={setCurrentStep}
                    getPreviousHole={getPreviousHole}
                    transitionDirection={transitionDirection}
                  />
                )}
                {currentStep === 2 && (
                  <Summary
                    roundData={roundData}
                    handleEdit={handleSummaryEdit}
                  />
                )}
              </motion.div>
            </AnimatePresence>
          </MainContentBackground>
        </>
      )}
    </Dialog>
  )
}

export default AddRoundsDialog
