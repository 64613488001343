import styled, { css } from 'styled-components'
import { FormLabel } from '@material-ui/core'

/**
 * Reusable label component
 */
export const Label = styled(FormLabel)<{ $hasError: boolean }>(
  ({ theme, $hasError }) => css`
    margin: ${theme.spacing(0, 0, 1)};
    color: ${$hasError ? theme.palette.error.main : '#000'};
  `
)
